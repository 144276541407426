import React, { useEffect, useLayoutEffect, useState, ReactNode, useRef } from "react";
import useEffectOnce from "hooks/useEffectOnce";
import { Box, Tag } from "@chakra-ui/react";
import { useTrans } from "locales/hook";

interface IProps {
    innerRef?: any;
    children: ReactNode;
}

function TabContainer(props: IProps) {
    const { innerRef, children, ...restProps } = props;

    return (
        <ul
            {...restProps}
            ref={innerRef}
            style={{
                display: "flex",
                minWidth: "1px",
                alignItems: "center",
                margin: 0,
                listStyle: "none",
            }}
        >
            {children}
        </ul>
    )
}

function CouponsDynamic(props) {
    const { children, width } = props;
    const [measuringRender, setMeasuringRender] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [visibleTabIndices, setVisibleTabIndices]: any = useState([]);
    const t = useTrans();

    const containerRef: any = useRef();
    const contentRef: any = useRef();

    // * 2. componentDidMount
    useEffectOnce(() => {
        setIsMounted(true);
        setMeasuringRender(true);
    });

    // * 3. Measure DOM elements
    useLayoutEffect(() => {
        if (measuringRender) {
            if (containerRef !== null && contentRef !== null) {
                // Get the child tab elements
                const tabElements: any = Array.from(containerRef.current.children);
                const widthOfContent = 160;
                const containerWidth = width - widthOfContent

                let stopWidth = 0;
                let visible: any = [];

                tabElements.forEach((tab: any, index) => {
                    if (visible.length === tabElements.length - 1) {
                        stopWidth -= 31;
                    }

                    stopWidth += tab.offsetWidth;

                    if (containerWidth >= stopWidth) {
                        visible.push(index);
                    } else {
                        if (tabElements) {
                            let tabList = tabElements.map((y, index) => {
                                return {
                                    id: index,
                                    width: y.offsetWidth,
                                }
                            });


                            const result = tabList.reduce((acc: any, curr: any) => {
                                let calcWidth = widthOfContent;

                                if (tabList.length === 1) {
                                    calcWidth += stopWidth
                                } else {
                                    const firstTab = tabList[0].width;

                                    if (containerWidth <= firstTab) {
                                        calcWidth += stopWidth
                                    }
                                }

                                const totalWidth = acc.reduce((sum, item) => sum + item.width, calcWidth);

                                if (totalWidth <= containerWidth) {
                                    acc.push(curr);
                                };

                                return acc;
                            }, []);

                            let pushActive = result.map(y => y?.id);
                            visible = pushActive
                        }
                    }
                });

                setVisibleTabIndices(visible);
                setMeasuringRender(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measuringRender]);

    // * Trigger a measuringRender when the window is resized
    useEffectOnce(() => {
        function handleResize() {
            setMeasuringRender(true);
        }

        window.addEventListener('resize', handleResize);

        return function cleanUp() {
            window.removeEventListener('resize', handleResize);
        };
    });

    // * Trigger a measuringRender when the tabs (children) prop changes
    useEffect(() => {
        setMeasuringRender(true);
    }, [children]);

    const allTabs = React.Children.map(children, (tab, index) => {
        return React.cloneElement(tab, {
            key: index,
        });
    });

    let visibleTabs: any = [];
    const overflowTabs: any = [];

    if (!isMounted || measuringRender) {
        visibleTabs = allTabs;
    } else {
        allTabs.forEach((tab, index) => {
            if (visibleTabIndices.includes(index)) {
                const visible = React.cloneElement(tab);
                visibleTabs.push(visible);
            } else {
                overflowTabs.push(index);
            }
        });
    }

    return <Box
        display="flex"
        alignItems={'center'}
    >
        <span
            style={{
                width: "max-content",
                marginRight: "10px"
            }}
            ref={contentRef}
        >
            {t("Khuyến mãi")}:
        </span>

        <TabContainer innerRef={containerRef}>
            {visibleTabs}
        </TabContainer>

        {(measuringRender || overflowTabs.length > 0) && (
            <Tag
                m={0}
                size="sm"
                style={{
                    flexShrink: 0
                }}
            >
                +{overflowTabs.length}
            </Tag>
        )}
    </Box>
}

function Coupon(props) {
    const { coupons, width } = props;

    return <CouponsDynamic width={width}>
        {coupons.map((item, idx) => {
            return <li
                key={idx}
                style={{
                    flexShrink: 0
                }}
            >
                <Tag
                    m={0}
                    mr={4}
                    size="sm"
                    fontWeight={400}
                >
                    {item.query}
                </Tag>
            </li>
        })}
    </CouponsDynamic>
}

export default Coupon