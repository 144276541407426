import { useEffect, useState } from 'react'

function Dropdowns(props) {
    const {
        onChange,
        defaultMonth,
        defaultYear,
        minDate,
        maxDate,
        minYear,
        maxYear,
        locale,
        onClickNext,
        onClickPrev,
        singleCalendar
    } = props;

    const [month, setMonth] = useState(defaultMonth);
    const [year, setYear] = useState(defaultYear);

    useEffect(() => {
        setMonth(defaultMonth)
        setYear(defaultYear)
    }, [defaultMonth, defaultYear])

    const maximumYear: any = (maxDate && maxDate.year()) || maxYear;
    const minimumYear: any = (minDate && minDate.year()) || minYear;
    const inMinYear = year === minimumYear;
    const inMaxYear = year === maximumYear;

    const NUMBER_OF_MONTHS = 12; // 1 năm có 12 tháng
    const RANGES_OF_YEARS = parseInt(maximumYear) - parseInt(minimumYear) + 1;

    const handleOnChangeInput = (month, year) => {
        setMonth(parseInt(month))
        setYear(parseInt(year))
        onChange && onChange(month, year)
    };

    const handleMonthChange = (direction) => {
        let newMonth = parseInt(month);
        let newYear = parseInt(year);

        if (direction === 'next') {
            if (newMonth + 1 > 11) {
                newYear += 1;
                newMonth = 0;
            } else {
                newMonth += 1;
            }

            onClickNext && onClickNext(newMonth, newYear);
        } else if (direction === 'prev') {
            if (newMonth - 1 < 0) {
                newYear -= 1;
                newMonth = 11;
            } else {
                newMonth -= 1;
            }

            onClickPrev && onClickPrev(newMonth, newYear);
        }

        setMonth(newMonth);
        setYear(newYear);
    };

    const isActivePrev = !inMinYear || (inMinYear && month > 0);
    const limit = singleCalendar ? 11 : 10
    const isActiveNext = !inMaxYear || (inMaxYear && month < limit);

    return (
        <>
            <div className='hrv-dropdown-container'>
                <div className='hrv-dropdown-container__selection'>
                    <div
                        className='hrv-dropdown-container__arrow hrv-btn-default hrv-btn-datepicker prev'
                        style={{
                            visibility: isActivePrev ? "visible" : 'hidden'
                        }}
                        onClick={() => {
                            if (isActivePrev) {
                                handleMonthChange("prev")
                            }
                        }}
                    >
                        {ArrowLeftSvg}
                    </div>

                    <select
                        className='monthselect'
                        value={month}
                        onChange={(e) => {
                            handleOnChangeInput(e.target.value, year)
                        }}
                    >
                        {[...Array(NUMBER_OF_MONTHS).keys()].map((m, index) => {
                            return <option
                                key={index}
                                value={m}
                            >
                                {locale.monthNames[m]}
                            </option>
                        })}
                    </select>
                </div>


                <div className='hrv-dropdown-container__selection'>
                    <select
                        className='yearselect'
                        value={year}
                        onChange={(e) => {
                            handleOnChangeInput(month, e.target.value)
                        }}
                    >
                        {[...Array(RANGES_OF_YEARS).keys()].map((y, index) => {
                            const yearDisplay = y + parseInt(minimumYear);

                            return <option
                                key={index}
                                value={yearDisplay}
                            >
                                {yearDisplay}
                            </option>
                        })}
                    </select>

                    <div
                        style={{
                            marginLeft: "10px",
                            marginRight: 0,
                            visibility: isActiveNext ? "visible" : "hidden"
                        }}
                        className='hrv-dropdown-container__arrow hrv-btn-default hrv-btn-datepicker next'
                        onClick={() => {
                            if (isActiveNext) {
                                handleMonthChange("next")
                            }
                        }}>
                        {ArrowRightSvg}
                    </div>
                </div>

            </div>

        </>
    )
}

export default Dropdowns;

const ArrowLeftSvg = <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08711 8.825L3.27044 5L7.08711 1.175L5.91211 0L0.912109 5L5.91211 10L7.08711 8.825Z" fill="#4B5563" />
</svg>

const ArrowRightSvg = <svg viewBox="0 0 8 10" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.08711 0L0.912109 1.175L4.72878 5L0.912109 8.825L2.08711 10L7.08711 5L2.08711 0Z" fill="#4B5563" />
</svg>
