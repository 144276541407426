/**
 * Header: Tiêu đề cho Template Report
 */

import { isEmpty, setPrintDescription } from "helpers/helpers"


const TEMPLATE_TAGS_BY_REPORT = {
    stock_transactionhistory_alltime: ["LocId", "ProductCategoryId"],
    stock_transactionhistory_adv: ["LocId", "ProductCategoryId"],
    stock_invreceive_by_suppliers: ["LocationId", "SupplierId"],
    sales_order_assigned_location: ["OrderAssignedLocationId"],
    default: ["LocationId"]
}

export const HeaderReport = ({
    selectFilter,
    filters,
    reportNameSystem,
    name,
    dateRange,
    translate
}: {
    selectFilter: any,
    filters: any[],
    reportNameSystem: string,
    name: string,
    dateRange: any,
    translate: Function
}) => {
    const { startdate, enddate } = dateRange;

    let description = setPrintDescription({
        selectFilter,
        filters,
        template: TEMPLATE_TAGS_BY_REPORT,
        reportNameSystem
    });

    let descriptionTag = "";

    if (!isEmpty(description)) {
        Object.keys(description).forEach((key) => {
            descriptionTag += `<div class="hrv-report-mb-8 hrv-report-text-center">${translate(key)}: ${!description[key].length ? translate("tất cả") : translate(description[key])}</div>`
        })
    }

    return `<h5 class="hrv-report-mb-8 hrv-report-fw-600 hrv-report-text-center">${name}</h5>
    <div class="hrv-report-mb-8 hrv-report-text-center">${translate("Từ ngày")} ${startdate} ${translate("đến ngày")} ${enddate}</div>
    
    ${descriptionTag}
   `
}