import useRoles from "hooks/useRoles";

interface IProps {
  validRoles: Array<any>;
  render: Function;
  children?: any;
}

export default function RoleCheck({ validRoles,
  render = (allowed, children?) => {
    return <>{allowed && children}</>;
  },
  children }: IProps) {
  const roles = useRoles();
  let allowed = false;

  validRoles.forEach(role => {
    if (roles.includes(role)) {
      allowed = true;
    }
  });

  return render(allowed, children);
}
