import { slugify } from "helpers/helpers";
import { useTrans } from "locales/hook";

export const useSearchLocal = () => {
    const t = useTrans();

    const searchGroupBy = (text: string, list: Array<any>) => {
        if (!text.trim().length) return list;

        const result = list.filter((y) => {
            return slugify(t(y["key"]).toLowerCase()).includes(
                slugify(text.toLocaleLowerCase()),
            );
        });

        return result;
    }

    return { searchGroupBy }
}