import { GetCurrencyFormater, PointsToCommas, formatDate, formatNumber } from "helpers/helpers";
import { useTrans } from "locales/hook";

function TooltipLine(props) {
    const { data, type, isShowYear, dataFormat } = props;
    let formater = GetCurrencyFormater();
    const t = useTrans();

    const title = () => {
        return data.body[0].header !== "Day" ? <p className="hrv-report-fw-500 hrv-report-mb-0">{formatDate(data.date, typeDate())}</p> : ""
    }

    const typeDate = () => {
        return type === "hour"
            ? `DD/MM${isShowYear ? "/YYYY" : ""}, HH:mm`
            : type === "month"
                ? `MM/YYYY`
                : type === "year"
                    ? "YYYY"
                    : `DD/MM${isShowYear ? "/YYYY" : ""}`;
    };

    const contentTooltip = (line) => {
        // * Nếu key là Day thì hiển thị dạng ngày hiện tại, ngày so sánh. Nếu key là Đối tượng thì hiển thị là đối tượng (Không show ngày)

        const objectLine = line.header === "Day" ? line.date === "--" ? "--" : formatDate(line.date, typeDate()) : t(line.header);

        const valueLine = line.data === "--" ? "--" : dataFormat === "currency"
            ? PointsToCommas(
                formater.format(line.data),
            )
            : dataFormat === "number"
                ? formatNumber(line.data)
                : formatNumber(
                    line.data * 100,
                ) + "%";

        return <div className="hrv-report-d-flex hrv-report-my-4 hrv-report-items-center hrv-report-line-promotion">
            <div className="hrv-report-row hrv-report-w-100">
                <div className="hrv-report-col-7 hrv-report-d-flex hrv-report-items-center ">
                    <div
                        className={`${line.isPrevious ? "hrv-report-border-dashed-blue-custom" : "hrv-report-border-blue-custom"}`}
                        style={{
                            background: !line.isPrevious ? line.color : "transparent",
                            border: line.isPrevious ? `1px dashed ${line.color}` : ""
                        }}
                    />

                    <span className="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">
                        {objectLine}
                    </span>
                </div>

                <p className="hrv-report-text-right hrv-report-col-5 hrv-report-mb-0 hrv-report-flex-end hrv-report-mt-0 hrv-report-fs-14 hrv-report-fw-400 tooltip-value">
                    {valueLine}
                </p>
            </div>
        </div>
    };

    const containerStyles: any = {
        position: 'absolute',
        pointerEvents: 'none',
        left: data?.left || 0,
        top: data?.top || 0,
    };

    if (!data || !data.opacity) return <div
        style={{
            ...containerStyles,
            width: 300,
            opacity: 0
        }}
        className="tooltip-container hrv-report-py-8 hrv-report-px-16"
    />;



    return <div
        style={containerStyles}
        className="tooltip-container hrv-report-py-8 hrv-report-px-16"
    >
        {title()}

        {
            !!data?.body?.length && <>
                {data?.body.map((line, index) => {
                    return <div key={index}>
                        {contentTooltip(line)}
                    </div>
                })}
            </>
        }
    </div>
}

export default TooltipLine