import { useTrans } from "locales/hook";

import HLimitLongString from "components/LimitLongString";
import MoneyWithLocale from "components/Money/locale";

import { GetCurrencyCode, alignmentText, formatNumber, valueHandler } from "helpers/helpers";
import { useCallback } from "react";

interface IProps {
    displaySummary: boolean;
    data: Array<any>
}

enum FormatType {
    LINK = 'link',
    NUMBERLINK = 'numberlink',
    MONEY = "money",
    NUMBERDAY = "numberday",
    NUMBERHOUR = "numberhour"
}

function SummaryLine(props: IProps) {
    const t = useTrans();
    const { displaySummary, data } = props;
    const currencyCode = GetCurrencyCode();

    const displayTextByFormat = useCallback((data) => {
        let { dataFormat, name, value } = data;

        switch (dataFormat) {
            case FormatType.NUMBERDAY:
                value = `${formatNumber(value)} ${t("ngày")}`;
                break;

            case FormatType.NUMBERHOUR:
                value = `${value} ${t("giờ")}`;
                break;

            default:
                break;
        }

        switch (dataFormat) {
            case FormatType.MONEY:
                return <MoneyWithLocale
                    value={value}
                    currencyCode={currencyCode}
                    className="hrv-report-text-right"
                />

            case FormatType.NUMBERDAY || FormatType.NUMBERHOUR:
                return <HLimitLongString
                    customClass={`hrv-report-transparent-bottom hrv-report-mb-0 ${alignmentText(
                        dataFormat,
                    )}`}
                    text={value}
                />

            default:
                return <div
                    className={`${dataFormat === FormatType.NUMBERDAY ? "hrv-report-mr-18" : ""
                        }`}
                >
                    {valueHandler(dataFormat, name, value)}
                </div>
        }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

    return (
        <>
            {displaySummary && !!data?.length && (
                <tr>
                    {data?.map((sum, id) => {
                        let firstColumn = id === 0 ? "hrv-report-first-column" : "";

                        return (
                            <td
                                key={`table_tr_sum_td_${id}`}
                                className={`hrv-report-fw-500 ${firstColumn} ${alignmentText(
                                    sum.dataFormat,
                                )} `}
                            >
                                {sum !== 0 ? displayTextByFormat(sum) : null}
                            </td>
                        );
                    })}
                </tr>
            )}
        </>
    )
}

export default SummaryLine