import { createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * Error and Not Found Page
 */
import ErrorPage from "pages/Error/ErrorPage";
import NotFound from "pages/NotFound";

/**
 * Root Template for all apps
 */
import RootPage from "../utils/RootPage";

// ! Template playout
import DashboardLayout from "components/Layout/Dashboard";
import MasterLayout from "components/Layout/Master";

// ! Pages
import ReportStandard from "pages/ReportStandard";
import ReportListV2 from "pages/ReportListV2";
import ReportDashboardV2 from "pages/ReportDashboardV2";
import ShipmentDashboard from "pages/Shipments/Dashboard";

import { ENV_LOCAL, ENV_STAGING } from "services/constants";

export const PublicRoutes: any = [
  {
    path: `/:name/promotion/:id`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ReportStandard />,
      }
    ]
  },
  {
    path: `/shipments/dashboard`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ShipmentDashboard />,
      }
    ]
  },
  {
    path: `/listing`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ReportListV2 />,
      }
    ]
  },
  {
    path: `/listing/:name/:id`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ReportStandard />,
      }
    ]
  },
  {
    path: `/:name/:id`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ReportStandard />,
      }
    ]
  },
  {
    path: `/`,
    element: <MasterLayout />,
    children: [
      {
        path: '',
        element: <ReportListV2 />
      }
    ]
  },
  {
    path: `/dashboard`,
    element: <DashboardLayout />,
    children: [
      {
        path: ``,
        element: <ReportDashboardV2 />
      }
    ]
  },
  {
    path: `/dashboardv2`,
    element: <DashboardLayout />,
    children: [
      {
        path: ``,
        element: <ReportDashboardV2 />
      }
    ]
  },
  {
    path: `*`,
    element: <NotFound />,
  },
];


export interface INavigate {
  label: string,
  destination: string
}

const constantLink: Record<string, any> = {
  dashboard: "/dashboard",
  listing: "/listing"
}

export const MENU_NAVIGATIONS: Array<INavigate> = [
  {
    label: "Bảng phân tích",
    destination: constantLink.dashboard
  },
  {
    label: "Danh sách báo cáo",
    destination: constantLink.listing
  },
]

const getENV = import.meta.env.VITE_APP_ENV
const isLocal = getENV === ENV_LOCAL;
const isSKU = getENV === ENV_STAGING;
const inDevMode = isLocal || isSKU;


const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    errorElement: inDevMode ? <></> : <ErrorPage />,
    element: <RootPage />,
    children: PublicRoutes,
  },
]);