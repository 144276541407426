import AccountantSVG from "assets/images/template/accountant-templalte.svg"
import AccountantScreen from "assets/images/template/accountant-screen.png";

export const AcccountantTemplate = {
    id: 4,
    name: "Kế toán",
    description: "Theo dõi tình hình tài chính, quản lý thu chi, lợi nhuận và các báo cáo tài chính để đảm bảo hiệu quả tài chính.",
    actions: ["preview"],
    icon: <AccountantSVG />,
    template: [
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Tiền thu được từ bán sản phẩm. Doanh thu = Số lượng sản phẩm đã bán (x) Giá bán",
            "isDropdownMeasure": false,

            "widgetId": "1000000023",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 4,
                "y": 0,
                "i": "1000000023",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số tiền thu được do bán sản phẩm sau khi trừ toàn bộ các giảm giá. Doanh thu thuần = Doanh thu (-) Giảm giá (-) Giảm giá KHTT",
            "isDropdownMeasure": false,

            "widgetId": "1000000000",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 5,
                "i": "1000000000",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Doanh thu thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100741,
            "systemName": "sales_orders_salechannel",
            "description": "Số lượng đơn hàng, doanh thu thuần phân theo kênh bán (bao gồm cả online và offline)",
            "isDropdownMeasure": false,

            "widgetId": "1000000002",
            "supportChartTypes": null,
            "template": {
                "w": 3,
                "h": 2,
                "x": 2,
                "y": 3,
                "i": "1000000002",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "SourceName",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "channel",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần - từ kênh bán hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100745,
            "systemName": "sales_orders_location",
            "description": "Doanh thu thuần phân theo chi nhánh bán",
            "isDropdownMeasure": false,

            "widgetId": "1000000013",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 5,
                "i": "1000000013",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "PaidAmount",
                    "measureField": "PaidAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "LocationBranch",
                    "fieldName": "LocationBranch",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần theo chi nhánh bán",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100758,
            "systemName": "sales_orders_product_itemtype",
            "description": "Doanh thu thuần phân theo nhà cung cấp sản phẩm",
            "isDropdownMeasure": false,

            "widgetId": "1000000018",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 0,
                "i": "1000000018",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductVendorName",
                    "fieldName": "ProductVendorName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần theo nhà cung cấp",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100738,
            "systemName": "sales_orders_product",
            "description": "Top các sản phẩm có số lượng bán hoặc doanh thu thuần ghi nhận cao nhất. Tính từ tất cả các kênh bán hàng",
            "isDropdownMeasure": true,

            "widgetId": "1000000005",
            "supportChartTypes": null,
            "template": {
                "w": 3,
                "h": 2,
                "x": 5,
                "y": 3,
                "i": "1000000005",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductId",
                    "fieldName": "ProductId",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "or",
                    "dimension": "saleitemtype",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Phân loại là Sản phẩm",
                            "query": "Sản phẩm",
                            "symbol": "is",
                            "value": "product"
                        }
                    ]
                }
            ],
            "chartType": "product",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Sản phẩm bán chạy",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Thực nhận = Đã thu (-) Hoàn trả",
            "isDropdownMeasure": false,

            "widgetId": "1000000031",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 6,
                "y": 0,
                "i": "1000000031",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Thực nhận",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 1100000,
            "systemName": "cas_transaction_pnl",
            "description": "Là các chi phí liên quan đến quá trình bán hàng như: Phí trả đối tác giao hàng, Phí trả sàn",
            "isDropdownMeasure": false,

            "widgetId": "1000000045",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 4,
                "y": 1,
                "i": "1000000045",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "expense",
                    "measureField": "expense",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "payments",
            "reportName": "Chi phí bán hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 1100000,
            "systemName": "cas_transaction_pnl",
            "description": "Là tổng số tiền chi từ các Phiếu chi có chọn Ghi nhận báo cáo kết quả kinh doanh khi lập trong mục Sổ quỹ",
            "isDropdownMeasure": false,

            "widgetId": "1000000044",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 6,
                "y": 1,
                "i": "1000000044",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "other_expense",
                    "measureField": "other_expense",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "payments",
            "reportName": "Chi phí khác",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 1100000,
            "systemName": "cas_transaction_pnl",
            "description": "Là lợi nhuận từ hoạt động kinh doanh của doanh nghiệp mà không bao gồm lợi nhuận khác. Lợi nhuận thuần = Lợi nhuận gộp (-) Chi phí bán hàng. Trong đó, Lợi nhuận gộp lấy từ Báo cáo kết quả hoạt động kinh doanh.",
            "isDropdownMeasure": false,

            "widgetId": "1000000046",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 6,
                "y": 2,
                "i": "1000000046",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "net_profit",
                    "measureField": "net_profit",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "payments",
            "reportName": "Lợi nhuận thuần",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 1100000,
            "systemName": "cas_transaction_pnl",
            "description": "Là tổng số tiền thu từ các Phiếu thu đã được chọn \"Ghi nhận báo cáo kết quả kinh doanh\" trong mục Sổ quỹ",
            "isDropdownMeasure": false,

            "widgetId": "1000000043",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 4,
                "y": 2,
                "i": "1000000043",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "other_income",
                    "measureField": "other_income",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "payments",
            "reportName": "Thu nhập khác",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Giá bán trung bình = Doanh thu (/) Số lượng sản phẩm đã bán",
            "isDropdownMeasure": false,

            "widgetId": "1000000040",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 4,
                "i": "1000000040",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "AvgProductPriceAmount",
                    "measureField": "AvgProductPriceAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Giá bán trung bình (ASP)",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Là số sản phẩm đã bán được trong kỳ thống kê. Nếu 1 mã sản phẩm được bán với số lượng 10, thì số sản phẩm đã bán được tính là 10. Dữ liệu này không bao gồm đơn huỷ",
            "isDropdownMeasure": false,

            "widgetId": "1000000038",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 3,
                "i": "1000000038",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Số sản phẩm đã bán",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        }
    ],
    previewUrl: AccountantScreen
};