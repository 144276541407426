import { GetAppLoaded, GetPushInApp, SetAppLoaded, SetPushInApp } from "helpers/helpers";
import useApp from "hooks/useApp";
import { useLocation } from "react-router-dom";
import { useDataSearch } from "hooks/useQueryString";

import { memo, useEffect } from "react";

const REPLACE = "APP::NAVIGATION::HISTORY::REPLACE";

const RoutePropagator = () => {
  const location = useLocation();
  const { history } = useApp();
  const { searchDataString } = useDataSearch();

  // ! Lắng nghe event thay đổi path của app và Cập nhật lại browser path
  useEffect(() => {
    const isIframe = window.self !== window.top;
    if (!isIframe) {
      return;
    }
    let path = location.pathname;

    if (location.key !== 'default') {
      path += `${location.search}`;
    } else {
      path += searchDataString;
    }

    if (GetAppLoaded() && !GetPushInApp() && path !== '/shipments/dashboard') {
      history(REPLACE, path);
    }

    SetAppLoaded();
    SetPushInApp(false);
  }, [location, history]);


  return <></>;
}

export default memo(RoutePropagator);