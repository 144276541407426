type Strategy = {
    handler(args: any): any;
}

enum DrillDownName {
    ProductCategoryId = "ProductCategoryId",
    SupplierId = "SupplierId"
}

export enum DrilldownAction {
    OPEN = 'open',
    BACK = 'back'
}

enum DrilldownNameAction {
    ProductCategoryId_open = "ProductCategoryId_open",
    ProductCategoryId_back = "ProductCategoryId_back",
    SupplierId_open = "SupplierId_open",
    SupplierId_back = "SupplierId_back",
}

function addOrUpdateObjectInArray(array, object, field) {
    const conditions = {
        "ProductByCategoryId": item => item.dimension !== 'ProductCategoryId' && item.dimension !== field,
        "ProductCategoryId": item => item.dimension !== 'ProductByCategoryId' && item.dimension !== field,
        "SupplierId": item => item.dimension !== field
    };

    const removeOldData = array.filter(item => conditions[field] ? conditions[field](item) : true);

    return removeOldData.concat(object);
}

class DrilldownStategyPattern {
    strategies: Record<string, Strategy> = {};

    use(name: any, strategy: Strategy) {
        this.strategies[name] = strategy;
    }

    handler(name: DrillDownName, action: DrilldownAction, ...args: any) {
        if (!Object.values(DrillDownName)?.includes(name)) {
            console.error("Please input right Drilldown Stategy Name");

            return false;
        }

        if (Object.values(DrilldownAction)?.includes(action)) {
            return this.strategies[`${name}_${action}`].handler.apply(null, args);
        } else {

            console.error("Please input right Drilldown Stategy Action");
            return false;
        }

    }
}

class OpenProductCategoryIdStrategy implements Strategy {
    handler(args: any) {
        const { groupBySelected, isLastChild, groupBy, name, list, value, filterTag, t } = args;
        const findItem = list.data.find(item => item.id === value);

        let renewGroupBy = [...groupBySelected];
        const findItemRemoveIndex = renewGroupBy.findIndex(y => y.groupPropertyField === "ProductCategoryId");
        const findItemAddIndex = groupBy.findIndex(y => y.groupPropertyField === "ProductId");

        if (isLastChild) {
            // * Nếu là lastchild => change view groupby
            renewGroupBy.splice(findItemRemoveIndex, 1, groupBy[findItemAddIndex]);
        }

        const currentProductCategoryId = {
            conjunction: "and",
            dimension: "ProductCategoryId",
            // dimension: !isLastChild ? "ProductCategoryId" : "ProductByCategoryId",
            query: [{
                conjunction: 'or',
                symbol: "is",
                displayText: `${t(name)} ${t("is")} ${findItem.name}`,
                query: findItem.name,
                value: findItem.value,
            }]
        };

        const newFilter = addOrUpdateObjectInArray(filterTag, currentProductCategoryId, "ProductCategoryId");

        return { groupByNew: renewGroupBy, filters: newFilter, currentItem: findItem, rename: "ProductCategoryId" };
    }
}

class OpenSupplierIdStrategy implements Strategy {
    handler(args: any) {
        const {
            name,
            list,
            value,
            groupBy,
            groupBySelected,
            t,
            filterTag
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        const findItem = list.data.find(item => item.id === value);

        // ! Ẩn group by Nhà cung cấp
        let findItemRemove = renewGroupBy.findIndex(y => y.groupPropertyField === "SupplierId");

        // ! Hiện group by Sản phẩm
        let findItemAddProductId = groupBy.findIndex(y => y.groupPropertyField === "ProductId");

        // ! Hiện group by Biển thể
        let findItemAddVariantId = groupBy.findIndex(y => y.groupPropertyField === "VariantId");

        renewGroupBy.splice(findItemRemove, 1);
        renewGroupBy.push(groupBy[findItemAddProductId]);
        renewGroupBy.push(groupBy[findItemAddVariantId]);

        let currentSupplierId: any = {
            conjunction: "and",
            dimension: name,
            query: []
        }

        currentSupplierId.query = [{
            conjunction: 'or',
            symbol: "is",
            displayText: `${t(name)} ${t("is")} ${findItem.name}`,
            query: findItem.name,
            value: findItem.value,
        }];

        const newFilter = addOrUpdateObjectInArray(filterTag, currentSupplierId, name);

        const newName = name;

        return {
            groupByNew: renewGroupBy,
            filters: newFilter,
            currentItem: findItem,
            rename: newName
        };
    }
}

class BackProductCategoryId implements Strategy {
    handler(args: any) {
        const {
            groupBySelected,
            groupBy,
            list,
            filterTag,
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewFilterTag: any = JSON.parse(JSON.stringify([...filterTag]));
        let newQuery: any = [];

        // & [GroupBy] Delete all GroupBy Add add new field ProductCategoryId
        renewGroupBy = renewGroupBy.filter(y => y.groupPropertyField === "ProductCategoryId");

        let indexFilterProductCategoryId = renewFilterTag.findIndex(y => y.dimension === "ProductCategoryId");
        // let indexFilterProductCategoryId = renewFilterTag.findIndex(y => y.dimension === "ProductCategoryId" || y.dimension === 'ProductByCategoryId');

        const childId = renewFilterTag[indexFilterProductCategoryId].query[0].value;

        // & [Filter] Find parentId by childId
        let parentId = list.data.find(item => {
            return item.id === (childId)
        }).specialInfo.parentId;


        /**
         * Khi trở lại sẽ có 2 TH
         * TH1: Trường hợp từ SP thuộc + ngành hàng khác > Show sản phẩm => Khi back lại thì parentId sẽ là childrenId
         * (SP thuộc trực tiếp ngành hàng ABC) <Click> |
         * Ngành hàng 1                                |
         * Ngành hàng 2                                |
         *
         * TH2: Trường hợp từ ngành hàng (Không còn ngành hàng con) > Show sản phẩm => Khi back lại thì parentId sẽ là parentId
         * (SP thuộc trực tiếp ngành hàng ABC) |
         * Ngành hàng 1 <Click>                |
         * Ngành hàng 2                        |
         */

        // (Nếu back ra mà đang đứng ở view "ProductByCategoryId" và là Sản phẩm thuộc (isLastChild === true) thì gán lại parentId là childId)
        // if (name === "ProductByCategoryId" && isLastChild) {
        //     parentId = childId
        // }

        if (parentId !== 0) {
            // & [Filter]: If ProductByCategoryId when back haven't parentId (parentId === 0), we use childId for that.
            const queryId = parentId === 0 ? parseInt(childId) : parseInt(parentId);

            let parentQueryIndex = list.data.findIndex(item => parseInt(item.id) === queryId)

            const { displayname_vi, name: dimension, value } = list.data[parentQueryIndex];

            // & [Filter] Assign new Query to filter
            newQuery = [{
                conjunction: "or",
                displayText: displayname_vi,
                query: dimension,
                symbol: 'is',
                value
            }];
        };



        if (!Boolean(parseInt(childId)) && !Boolean(parseInt(parentId))) {
            // TH: tên ngành hàng là "--"
            renewFilterTag.splice(indexFilterProductCategoryId, 1)
        } else {
            if (newQuery.length) {
                renewFilterTag[indexFilterProductCategoryId].dimension = "ProductCategoryId"
                renewFilterTag[indexFilterProductCategoryId].query = newQuery;
            } else {
                renewFilterTag.splice(indexFilterProductCategoryId, 1)
            }
        }

        if (!renewGroupBy.length) {
            let findItemAdd = groupBy.findIndex(y => y.groupPropertyField === "ProductCategoryId")

            renewGroupBy.push(groupBy[findItemAdd])
        };

        return {
            parentId,
            groupByNew: renewGroupBy,
            filters: renewFilterTag,
            currentItem: newQuery,
            rename: "ProductCategoryId"
        };
    }
}

class BackSupplierId implements Strategy {
    handler(args: any) {
        const {
            groupBySelected,
            groupBy,
            filterTag,
        } = args;

        let renewGroupBy: any = JSON.parse(JSON.stringify([...groupBySelected]));
        let renewFilterTag: any = JSON.parse(JSON.stringify([...filterTag]));
        let newQuery: any = [];

        // ! Hiện group by Nhà cung cấp
        let findItemAdd = groupBy.findIndex(y => y.groupPropertyField === "SupplierId");

        // ! Ẩn group by Sản phẩm
        let findItemRemoveProductId = renewGroupBy.findIndex(y => y.groupPropertyField === "VariantId")

        // ! Ẩn group by Biến thể
        let findItemRemoveVariantId = renewGroupBy.findIndex(y => y.groupPropertyField === "ProductId")

        renewGroupBy.splice(findItemRemoveProductId, 1);
        renewGroupBy.splice(findItemRemoveVariantId, 1);
        renewGroupBy.push(groupBy[findItemAdd]);

        return {
            parentId: 0,
            groupByNew: renewGroupBy,
            filters: renewFilterTag,
            currentItem: newQuery,
            rename: "ProductCategoryId"
        };
    }
}

const DrilldownStategy = new DrilldownStategyPattern();

// Mở Drilldown cho ngành hàng
DrilldownStategy.use(DrilldownNameAction.ProductCategoryId_open, new OpenProductCategoryIdStrategy());

// Mở Drilldown cho nhà cung cấp
DrilldownStategy.use(DrilldownNameAction.SupplierId_open, new OpenSupplierIdStrategy());

// Back Drilldown cho ngành hàng
DrilldownStategy.use(DrilldownNameAction.ProductCategoryId_back, new BackProductCategoryId())

// Back Drilldown cho nhà cung cấp
DrilldownStategy.use(DrilldownNameAction.SupplierId_back, new BackSupplierId())

export function drilldownHandler(mode: DrillDownName, action: DrilldownAction, args: any) {

    return DrilldownStategy.handler(mode, action, args)
}
