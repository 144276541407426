/**
 * @Created_by Mạnh Đạt
 * @Docs Container bao tất cả các items với logic map() mục đích cho tất cả các đối tượng được hiển thị trên màn hình sẽ render theo màn hình
 * VD: Có 100000 items nhưng màn hình chỉ hiển thị được 100 thì sẽ delay loading 100 items theo scroll (Scroll tới đâu DOM sẽ render tới đó không render 1 lần hết 10000 items)
 * @Core Sử dụng thư viện Virtuoso 
 */

import { Fragment, useDeferredValue } from "react";
import { Virtuoso } from "react-virtuoso";

type PropsType = {
    list: Array<any>;
    renderItems: Function;
    maxHeight: number | string;
    limit: number;
    customClass?: string;
    emptyContent?: any;
    onScroll?: Function
}

// * Nếu list truyền vào có độ dài nhỏ hơn limit thì sẽ hiển thị virtualized list vì virtualized phục vụ cho việc list quá lớn không cần thiết phải sử dụng khi list nhỏ
export default function VirtualizedContainer(props: PropsType) {
    const { list, limit, renderItems, maxHeight, customClass, emptyContent, onScroll } = props;

    const defferedList = useDeferredValue(list);

    return (
        <>
            {defferedList.length ? <>
                {defferedList.length < limit ? <div
                    style={{
                        maxHeight,
                        overflowY: "auto"
                    }}
                    className={customClass}>
                    {defferedList?.map((item, idx) => {
                        return <Fragment key={idx}>
                            {renderItems(item)}
                        </Fragment>
                    })}
                </div> :
                    <Virtuoso
                        style={{
                            height: maxHeight,
                        }}
                        data={defferedList}
                        className={customClass}
                        itemContent={(_, option) => {
                            return renderItems(option)
                        }}
                        onScroll={(e) => {
                            onScroll && onScroll(e)
                        }}
                    />
                }
            </> : emptyContent()}
        </>
    )
}