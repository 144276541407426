import { memo } from "react";

// ! Components
import SummaryLine from "./SummaryLine";
import BodyLine from "./BodyLine";

import { queryString } from "helpers/helpers";

function BodyTableDefault(props) {
    const {
        summary,
        dataRows,
        headingRows,
        canReadMACost,
        headers,
        subHeadingRows,
        dataLink,
        isReportPNL,
        displaySummary,
        dependdata
    } = props;

    const shop: any = queryString.shop;

    return (
        <>
            <tbody>
                <SummaryLine displaySummary={displaySummary} data={summary} />

                <BodyLine
                    data={dataRows}
                    headers={headers}
                    headingRows={headingRows}
                    subHeadingRows={subHeadingRows}
                    canReadMACost={canReadMACost}
                    dataLink={dataLink}
                    shop={shop}
                    isReportPNL={isReportPNL}
                    dependdata={dependdata}
                />
            </tbody>
        </>
    );
}

export default memo(BodyTableDefault);
