import {
  DEFAULT_ACTION,
  DUPLICATE_ACTION,
  DUPLICATE_ACTION_ERROR,
  DUPLICATE_ACTION_SUCCESS,
  DELETE_ACTION,
  DELETE_ACTION_ERROR,
  DELETE_ACTION_SUCCESS,
  EXPORT_ACTION,
  EXPORT_ACTION_ERROR,
  EXPORT_ACTION_SUCCESS,
  FETCH_INFO,
  FETCH_INFO_ERROR,
  FETCH_INFO_SUCCESS,
  FETCH_STATISTIC,
  FETCH_STATISTIC_ERROR,
  FETCH_STATISTIC_SUCCESS,
  FETCH_SUMMARY,
  FETCH_SUMMARY_ERROR,
  FETCH_SUMMARY_SUCCESS,
  SET_DATE,
  SET_DIMEMSION_SELECTED,
  SET_FETCH_DATA_PAYLOAD,
  SET_FILTER_SELECTED,
  SET_GROUP_BY,
  SET_GROUP_BY_SELECTED,
  SET_MEASURE,
  SET_MEASURE_SELECTED,
  SET_OPERATOR_SELECTED,
  SET_SORT_TABLE,
  SET_HIDDEN_PAGINATION,
  SET_OFFSET,
  CHOOSE_COUNPON,
  SET_DATE_COMPARE,
  SET_OPTION_COMPARE,
  FETCH_COMPARE_STATISTIC,
  FETCH_COMPARE_STATISTIC_SUCCESS,
  FETCH_COMPARE_STATISTIC_ERROR,
  FETCH_COMPARE_SUMMARY,
  FETCH_COMPARE_SUMMARY_SUCCESS,
  FETCH_COMPARE_SUMMARY_ERROR,
  SET_MEASURE_Y_AXIS,
  GET_AUTO_COMPLETE_DEFAULT_SUCCESS,
  SET_DEFAULT_GROUP_BY,
  SET_DEFAULT_MEASURE,
  SET_KEYWORD_SEARCH,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_SUCCESS,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_ERROR,
  SET_HEATMAP_OPTIONS,
  SET_GROUP_BY_FILTER_PANEL,
  SET_QUERY_STRING_BY_AUTOCOMPLETE,
  SET_PARAMS_FROM_LISTING,
  SET_PARAMS_FROM_DASHBOARD,
  SET_EXPORT_ID,
  GET_DATA_PRINT,
  GET_DATA_PRINT_ERROR,
  GET_DATA_PRINT_SUCCESS,
  TOGGLE_DISPLAY_ANNOUCE_EXPORT,
  TOGGLE_IS_FAVOURITE,
  SET_REPORT_NAME,
  SET_ONBOARDING_DATA,
  GET_DATA_EXPORT_ERROR,
  GET_DATA_EXPORT_SUCCESS,
  GET_DATA_EXPORT,
  TOGGLE_VISIBLE_DRILLDOWN,
  SET_DRILLDOWN_STATE,
  CHANGE_STATUS_REFRESH,
  SET_MESURE_DRAW_LIST,
  GET_AUTO_COMPLETE,
  GET_AUTO_COMPLETE_SUCCESS,
  GET_AUTO_COMPLETE_ERROR,
  SET_MODAL_TYPE,
  SET_DUPLICATE_NAME,
  SET_ABOUT_CONTENT
} from "./constants";

export const setDefaultAction = (payload: any) => ({
  type: DEFAULT_ACTION,
  payload,
});

export const fetchInfo = () => ({
  type: FETCH_INFO,
});

export const fetchInfoSuccess = (payload: any) => ({
  type: FETCH_INFO_SUCCESS,
  payload,
});

export const fetchInfoError = () => ({
  type: FETCH_INFO_ERROR,
});

export const fetchStatistic = () => ({
  type: FETCH_STATISTIC,
});

export const fetchStatisticSuccess = (payload: any) => ({
  type: FETCH_STATISTIC_SUCCESS,
  payload,
});

export const fetchStatisticError = () => ({
  type: FETCH_STATISTIC_ERROR,
});

export const fetchSummary = () => ({
  type: FETCH_SUMMARY,
});

export const fetchSummarySuccess = (payload: any) => ({
  type: FETCH_SUMMARY_SUCCESS,
  payload,
});

export const fetchSummaryError = (payload: any) => ({
  type: FETCH_SUMMARY_ERROR,
  payload,
});
export const setDate = (payload: any) => ({
  type: SET_DATE,
  payload,
});

export const setGroupBy = (payload: any) => ({
  type: SET_GROUP_BY,
  payload,
});

export const setMeasure = (payload: any) => ({
  type: SET_MEASURE,
  payload,
});
export const setGroupBySelected = (payload: any) => ({
  type: SET_GROUP_BY_SELECTED,
  payload,
});

export const setFilterSelected = (payload: any) => ({
  type: SET_FILTER_SELECTED,
  payload,
});

export const setMeasureSelected = (payload: any) => ({
  type: SET_MEASURE_SELECTED,
  payload,
});

export const setFetchDataTable = (payload: any) => ({
  type: SET_FETCH_DATA_PAYLOAD,
  payload,
});

export const setSortHeader = (payload: any) => ({
  type: SET_SORT_TABLE,
  payload,
});

export const setDimensionSelected = (payload: any) => ({
  type: SET_DIMEMSION_SELECTED,
  payload,
});

export const setOperatorSelected = (payload: any) => ({
  type: SET_OPERATOR_SELECTED,
  payload,
});

export const exportAction = () => ({
  type: EXPORT_ACTION,
});

export const exportActionSuccess = () => ({
  type: EXPORT_ACTION_SUCCESS,
});

export const exportActionError = () => ({
  type: EXPORT_ACTION_ERROR,
});

export const duplicateAction = () => ({
  type: DUPLICATE_ACTION,
});

export const duplicateActionSuccess = () => ({
  type: DUPLICATE_ACTION_SUCCESS,
});

export const duplicateActionError = () => ({
  type: DUPLICATE_ACTION_ERROR,
});

export const deleteAction = () => ({
  type: DELETE_ACTION,
});

export const deleteActionSuccess = () => ({
  type: DELETE_ACTION_SUCCESS,
});

export const deleteActionError = () => ({
  type: DELETE_ACTION_ERROR,
});
export const setHiddenPagination = (payload: boolean) => ({
  type: SET_HIDDEN_PAGINATION,
  payload,
});

export const setOffset = (payload: number) => ({
  type: SET_OFFSET,
  payload,
});

export const chooseCoupon = (payload: any) => ({
  type: CHOOSE_COUNPON,
  payload,
});

export const setDateCompare = (payload: any) => ({
  type: SET_DATE_COMPARE,
  payload,
});

export const setOptionCompare = (payload: any) => ({
  type: SET_OPTION_COMPARE,
  payload,
});

export const fetchCompareStatistic = () => ({
  type: FETCH_COMPARE_STATISTIC,
});

export const fetchCompareStatisticSuccess = (payload: any) => ({
  type: FETCH_COMPARE_STATISTIC_SUCCESS,
  payload,
});

export const fetchCompareStatisticError = () => ({
  type: FETCH_COMPARE_STATISTIC_ERROR,
});

export const fetchCompareSummary = () => ({
  type: FETCH_COMPARE_SUMMARY,
});

export const fetchCompareSummarySuccess = (payload: any) => ({
  type: FETCH_COMPARE_SUMMARY_SUCCESS,
  payload,
});

export const fetchCompareSummaryError = (payload: any) => ({
  type: FETCH_COMPARE_SUMMARY_ERROR,
  payload,
});

export const setMeasureYAxis = (payload: any) => ({
  type: SET_MEASURE_Y_AXIS,
  payload,
});


export const getAutoCompleteDefault = (payload: any) => ({
  type: GET_AUTO_COMPLETE_DEFAULT_SUCCESS,
  payload,
});

export const setDefaultGroupBy = (payload: any) => ({
  type: SET_DEFAULT_GROUP_BY,
  payload,
});

export const setDefaultMeasure = (payload: any) => ({
  type: SET_DEFAULT_MEASURE,
  payload,
});

export const setKeywordSearch = (payload: any) => ({
  type: SET_KEYWORD_SEARCH,
  payload,
});

export const getAutoCompleteFilterPanel = (payload: any) => ({
  type: GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL,
  payload,
});

export const getAutoCompleteFilterPanelSuccess = (payload: any) => ({
  type: GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_SUCCESS,
  payload,
});

export const getAutoCompleteFilterPanelError = () => ({
  type: GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_ERROR,
});

export const setHeatMapOptions = (payload: any) => ({
  type: SET_HEATMAP_OPTIONS,
  payload,
});

export const setGroupByFilterPanel = (payload: any) => ({
  type: SET_GROUP_BY_FILTER_PANEL,
  payload,
});
export const setQueryStringByAutoComplete = (payload: any) => ({
  type: SET_QUERY_STRING_BY_AUTOCOMPLETE,
  payload,
});

export const setTypeFromListing = (payload: any) => ({
  type: SET_PARAMS_FROM_LISTING,
  payload,
});
export const setTypeFromDashboard = (payload: any) => ({
  type: SET_PARAMS_FROM_DASHBOARD,
  payload,
});

export const setExportId = (payload: any) => ({
  type: SET_EXPORT_ID,
  payload,
});

export const getDataPrint = () => ({
  type: GET_DATA_PRINT,
});

export const getDataPrintError = () => ({
  type: GET_DATA_PRINT_ERROR,
});

export const getDataPrintSuccess = (payload: any) => ({
  type: GET_DATA_PRINT_SUCCESS,
  payload,
});

export const setIsDisplayAnnouceExport = (payload: any) => ({
  type: TOGGLE_DISPLAY_ANNOUCE_EXPORT,
  payload,
});

export const toggleIsFavourite = (payload: any) => ({
  type: TOGGLE_IS_FAVOURITE,
  payload,
});
export const setReportName = (payload: any) => ({
  type: SET_REPORT_NAME,
  payload,
});
export const setOnboardingData = (payload: any) => ({
  type: SET_ONBOARDING_DATA,
  payload,
});

export const getDataExport = (payload: any) => ({
  type: GET_DATA_EXPORT,
  payload,
});

export const getDataExportError = (payload: any) => ({
  type: GET_DATA_EXPORT_ERROR,
  payload,
});

export const getDataExportSuccess = (payload: any) => ({
  type: GET_DATA_EXPORT_SUCCESS,
  payload,
});

export const toggleVisibleDrilldown = (payload: any) => ({
  type: TOGGLE_VISIBLE_DRILLDOWN,
  payload,
});

export const setDrilldownState = (payload: any) => ({
  type: SET_DRILLDOWN_STATE,
  payload,
});

export const changeStatusRefresh = (payload: any) => ({
  type: CHANGE_STATUS_REFRESH,
  payload,
});

export const setMetricsDraw = (payload: any) => ({
  type: SET_MESURE_DRAW_LIST,
  payload,
});


export const getAutoComplete = () => ({
  type: GET_AUTO_COMPLETE,
});

export const getAutoCompleteSuccess = (payload: any) => ({
  type: GET_AUTO_COMPLETE_SUCCESS,
  payload,
});

export const getAutoCompleteError = () => ({
  type: GET_AUTO_COMPLETE_ERROR,
});

// * New modal handler
export const setModalType = (payload) => ({
  type: SET_MODAL_TYPE,
  payload,
});

export const setDuplicateName = (payload) => ({
  type: SET_DUPLICATE_NAME,
  payload,
});

export const setAboutContent = (payload) => ({
  type: SET_ABOUT_CONTENT,
  payload,
});
