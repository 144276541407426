/**
 * Created_by: Mạnh Đạt Võ
 * Created_at: 04/10/2024
 * From: Report Team
 * Component: Date Range Picker
 * Component used dayjs for handle time
 * TODO: (1): implement single select when user choose 1 day (Not important)
 * TODO: (2): handle case Mobile device only have 1 date picker in the left side (important)
 * TODO: (3): perfect if it will have start and end click not by rule start first end later (Not important)
 */

import { useState, useEffect, useRef, useCallback, EffectCallback } from 'react';
import { useClickOutside } from './config/useClickOutside';
import { createPortal } from "react-dom";

// ! Library
import dayjs, { FlexibleMonthValid, getClientRect, isEmpty } from './config';
import { IDatepicker } from './types';

// ! Component
import Dropdowns from './components/Dropdowns';

import "./style.css";

const DateRangePicker = ({
    startDateDefault = dayjs().startOf('day'),
    endDateDefault = dayjs().endOf('day'),
    minDate,
    maxDate,
    minYear = dayjs().subtract(100, 'year').format('YYYY'),
    maxYear = dayjs().add(100, 'year').format('YYYY'),
    singleDatePicker = false,
    showWeekNumbers = false,
    ranges = {},
    applyButtonClasses = 'btn-primary',
    cancelButtonClasses = 'btn-default',
    locale = {
        format: 'MM/DD/YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: dayjs.weekdaysMin(),
        monthNames: dayjs.monthsShort(),
        firstDay: dayjs.localeData().firstDayOfWeek()
    },
    linkedCalendars = true,
    onChange,
    isInvalidDate = (date) => {
        return false
    },
    isCustomDate = (date) => {
        return false
    },
    children,
    onShow,
    onHide,
    showDropdowns = true,
    threshold = 0,
    showRangeDateBlock = true,
    singleCalendar = false
}: IDatepicker) => {
    /** Ref handler */
    const refParentPicker = useRef<any>(null);
    const containerRef = useRef<any>(null);

    /** State handler */
    const [startDate, setStartDate] = useState<any>(startDateDefault);
    const [endDate, setEndDate] = useState<any>(endDateDefault);
    const [hoverDate, setHoverDate] = useState<any>(null);
    const [leftCalendar, setLeftCalendar] = useState<any>({});
    const [rightCalendar, setRightCalendar] = useState<any>({});
    const [isOpen, setIsOpen] = useState(false);

    // ! Trả lại mặc định view khi user click ra ngoài
    const resetViewDate = () => {
        setStartDate(startDateDefault);
        setEndDate(endDateDefault);

        // * Set lại view (Đưa ngày kết thúc qua bên tay phải)
        let endM = endDateDefault.month();

        if (!singleCalendar) {
            endM = endDateDefault.month() - 1
        }

        const { month, year } = FlexibleMonthValid(endM, endDateDefault.year());
        handleMonthOrYearChanged(month, year);
    }

    // ! Xử lý khi user Click ra ngoài phạm vi Datepicker
    const handleClickOutside = (event) => {
        if (isOpen) {
            if (event && refParentPicker.current && !refParentPicker.current?.contains(event.target)) {
                resetViewDate();
                handleHide();
            }
        }
    }

    useClickOutside(containerRef, handleClickOutside)

    const updateMonthsInView = () => {
        const leftCalendarNew: any = {}
        const rightCalendarNew: any = {}

        // ! Trường hợp start và end là undefined thì sẽ lấy ngày hiện tại
        if (typeof startDate === "undefined" && typeof endDate === "undefined") return

        if (endDate) {
            // ! Trường hợp 2 ngày leftCalendar và rightCalendar có giá trị như nhau thì giữ nguyên
            if (!singleDatePicker && leftCalendar?.month && rightCalendar?.month && (startDate.format('YYYY-MM') == leftCalendar.month.format('YYYY-MM') || startDate.format('YYYY-MM') == rightCalendar.month.format('YYYY-MM')) && (endDate.format('YYYY-MM') == leftCalendar.month.format('YYYY-MM') || endDate.format('YYYY-MM') == rightCalendar.month.format('YYYY-MM'))
            ) {
                return;
            }

            // ! Gán giá trị cho leftCalendar và rightCalendar
            if (singleCalendar) {
                leftCalendarNew.month = endDate.clone().date(2);
            } else {
                leftCalendarNew.month = endDate.clone().date(2).subtract(1, 'month');
            }

            if (!linkedCalendars && (endDate.month() != startDate.month() || endDate.year() != startDate.year())) {
                rightCalendarNew.month = endDate.clone().date(2);
            } else {

                if (singleCalendar) {
                    rightCalendarNew.month = endDate.clone().date(2).add(1, 'month');
                } else {
                    rightCalendarNew.month = endDate.clone().date(2);
                }
            }

        } else {
            if (leftCalendar.month.format('YYYY-MM') != startDate.format('YYYY-MM') && rightCalendar.month.format('YYYY-MM') != startDate.format('YYYY-MM')) {

                if (singleCalendar) {
                    leftCalendarNew.month = endDate.clone().date(2);
                    rightCalendarNew.month = endDate.clone().date(2).add(1, 'month');
                } else {
                    leftCalendarNew.month = endDate.clone().date(2).subtract(1, 'month');
                    rightCalendarNew.month = endDate.clone().date(2);
                }
            }
        }

        if (maxDate && linkedCalendars && !singleDatePicker && rightCalendar.month > maxDate) {
            rightCalendarNew.month = maxDate.clone().date(2);
            leftCalendarNew.month = maxDate.clone().date(2).subtract(1, 'month');
        };

        setLeftCalendar(leftCalendarNew)
        setRightCalendar(rightCalendarNew)
    };

    const useEffectDidMount = (callback: EffectCallback) => {
        return useEffect(callback, [])
    }

    useEffectDidMount(() => {
        updateMonthsInView();
    });

    const handleMonthOrYearChanged = (month, year) => {
        let monthOutput = parseInt(month);
        let yearOutput = parseInt(year);

        const leftMonth = leftCalendar.month.month(monthOutput).year(yearOutput);

        setLeftCalendar({ month: leftMonth });
        setRightCalendar({ month: leftMonth.clone().add(1, 'month') });
    }

    // * Click chọn ngày
    const handleDateClick = (date) => {
        if (endDate || date.isBefore(startDate, 'day')) {
            setEndDate(null)
            setStartDate(date.clone());
        } else if (!endDate && date.isBefore(startDate)) {
            //special case: clicking the same date for start/end,
            //but the time of the end date is before the start date
            setEndDate(startDate.clone());
        } else {
            setEndDate(date.clone());
        }
    };

    /**
     * ! Start Actions Với button bên trong DatePicker
     */
    const handleApply = () => {
        // * Tìm xem endDate, startDate có nằm trong range hay không ?
        const inRange = findRangeActive();
        const label = inRange.length ? inRange[0] : locale.customRangeLabel;
        const isUndefinedChosen = typeof startDate === 'undefined' && typeof endDate === 'undefined';

        if (isUndefinedChosen) {
            onChange(undefined, undefined, label)
        } else {
            onChange(dayjs(startDate).startOf('day'), dayjs(endDate).endOf('day'), label);

            let endM = endDate.month();

            if (!singleCalendar) {
                endM = endDate.month() - 1
            }

            // * Set lại view (Đưa ngày kết thúc qua bên tay phải) (Trường hợp undefined cả 2 input start và end thì không cần set lại) 
            const { month, year } = FlexibleMonthValid(endM, endDate.year());

            handleMonthOrYearChanged(month, year);
        }

        setIsOpen(false);
        onHide && onHide(false)
    };

    const handleCancel = () => {
        resetViewDate();
        setIsOpen(false);
        onHide && onHide(false)
    };

    const handleNext = (month, year) => {
        handleMonthOrYearChanged(month, year)
    }

    const handlePrev = (month, year) => {
        handleMonthOrYearChanged(month, year)
    }

    const handleShow = () => {
        if (isOpen) {
            onHide && onHide(false)
        } else {
            onShow && onShow(true)
        }

        setIsOpen(!isOpen);
    }

    const handleHide = () => {
        setIsOpen(false);
        onHide && onHide(false)
    }

    // ! Cập nhật lịch khi chọn range
    const updateCalendars = (start, end) => {
        const isUndefinedChosen = typeof start === 'undefined' && typeof end === 'undefined'

        if (isUndefinedChosen) return;

        let endM = end.month()

        if (!singleCalendar) {
            // ! Trường hợp linked date thì range được chọn luôn luôn nằm tay phải
            endM = end.month() - 1;
        }

        const { month, year } = FlexibleMonthValid(endM, end.year());
        handleMonthOrYearChanged(month, year);
    };

    /**
    * ! End Actions Với button bên trong DatePicker
    */

    // ! xử lý việc class nào sẽ sử dụng với trạng thái nào trên từng ngày
    const classesHandler = (date, startDateOfMonth) => {
        let classes = "";

        //* Highlight Ngày hôm nay
        if (date.isSame(new Date(), "day")) {
            classes += "today "
        }

        //* Highlight Ngày cuối tuần
        if (date.isoWeekday() > 5) {
            classes += "weekend "
        }

        //* Tô xám những ngày không thuộc ngày trong tháng trên lịch
        if (date.month() != startDateOfMonth.month()) {
            classes += "off ends "
        }

        //* Không cho phép chọn những ngày nhỏ hơn minDate
        if (minDate && date.isBefore(minDate, 'day')) {
            classes += 'off disabled ';
        }

        //* Không cho phép chọn những ngày nhỏ hơn maxDate
        if (maxDate && date.isAfter(maxDate, 'day')) {
            classes += 'off disabled ';
        }

        //* Không cho phép chọn những ngày Nếu có 1 ngày nào đó không hợp lệ
        if (isInvalidDate(date)) {
            classes += 'off disabled ';
        }

        //* Highlight Ngày bắt đầu active
        if (date.format('YYYY-MM-DD') == startDate?.format('YYYY-MM-DD')) {
            classes += 'active start-date ';
        }

        //* Highlight Ngày kết thúc active
        if (endDate != null && date.format('YYYY-MM-DD') == endDate?.format('YYYY-MM-DD')) {
            classes += 'active end-date ';
        }

        //* Highlight những ngày nằm giữa 2 ngày đang chọn
        if (endDate != null && date > startDate && date < endDate) {
            classes += 'in-range ';
        }

        //* Apply custom classes for this date
        let isCustom = isCustomDate(date);

        if (isCustom !== false) {
            if (typeof isCustom === 'string') {
                classes += isCustom;
            }
        }

        let isDisabled = classes.includes("disabled");

        if (!isDisabled) {
            classes += 'available'
        }


        return classes
    }

    // ! Render Selection chọn ngày và năm trên Datepicker
    const renderDropdowns = (currentMonth, currentYear) => {
        if (showDropdowns) {
            return <Dropdowns
                defaultMonth={currentMonth}
                defaultYear={currentYear}
                minDate={minDate}
                maxDate={maxDate}
                minYear={minYear}
                maxYear={maxYear}
                locale={locale}
                startDate={startDate}
                onChange={(month, year) => {
                    handleMonthOrYearChanged(month, year)
                }}
                onClickNext={handleNext}
                onClickPrev={handlePrev}
                singleCalendar={singleCalendar}
            />
        } else {
            return null
        }
    }

    // ! Render Lịch
    const renderCalendar = (side) => {
        let calendarInput: any = {};

        const newLeftCalendar = { ...leftCalendar };
        const newRightCalendar = { ...rightCalendar };

        if (isEmpty(leftCalendar) || !isEmpty(rightCalendar)) {
            calendarInput = side == 'left' ? leftCalendar : rightCalendar
        }

        const date = calendarInput?.month

        const month = date.month();
        const year = date.year();
        const hour = date.hour();
        const minute = date.minute();
        const second = date.second();
        // const daysInMonth = date.daysInMonth();
        const firstDay = dayjs([year, month, 1]);
        // const lastDay = dayjs([year, month, daysInMonth]);
        const lastMonth = dayjs(firstDay).subtract(1, 'month').month();
        const lastYear = dayjs(firstDay).subtract(1, 'month').year();
        const daysInLastMonth = dayjs([lastYear, lastMonth]).daysInMonth();
        const dayOfWeek = firstDay.day();

        const calendarDates: any = [];


        //initialize a 6 rows x 7 columns array for the calendar
        // calendarDates.firstDay = firstDay;
        // calendarDates.lastDay = lastDay;

        for (var i = 0; i < 6; i++) {
            calendarDates[i] = [];
        }

        let startDay = daysInLastMonth - dayOfWeek + locale?.firstDay + 1;

        if (startDay > daysInLastMonth) {
            startDay -= 7;
        }

        if (dayOfWeek == locale.firstDay) {
            startDay = daysInLastMonth - 6;
        }

        let curDate = dayjs([lastYear, lastMonth, startDay, 12, minute, second]);

        for (
            let i = 0,
            col = 0,
            row = 0;
            i < 42;
            i++,
            col++,
            curDate = dayjs(curDate).add(24, 'hour')
        ) {
            if (i > 0 && col % 7 === 0) {
                col = 0;
                row++;
            };

            calendarDates[row][col] = curDate.clone().hour(hour).minute(minute).second(second);

            curDate.hour(12);

            if (minDate && calendarDates[row][col].format('YYYY-MM-DD') == minDate.format('YYYY-MM-DD') && calendarDates[row][col].isBefore(minDate) && side == 'left') {
                calendarDates[row][col] = minDate.clone();
            }

            if (maxDate && calendarDates[row][col].format('YYYY-MM-DD') == maxDate?.format('YYYY-MM-DD') && calendarDates[row][col].isAfter(maxDate) && side == 'right') {
                calendarDates[row][col] = maxDate.clone();
            }
        }

        if (side == 'left') {
            newLeftCalendar.calendar = calendarDates;
        } else {
            newRightCalendar.calendar = calendarDates;
        }

        // ! Handle Class for Date
        return (
            <div className={`drp-calendar ${side}`}>
                <div className="calendar-table">
                    <table className="table-condensed">
                        <thead>
                            <tr>
                                {showWeekNumbers && <th></th>}

                                {locale.daysOfWeek.map((day, index) => (
                                    <th key={index}>{day}</th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {Array.from({ length: 6 }).map((_, weekIndex) => {
                                return <tr key={weekIndex}>
                                    {calendarDates[weekIndex].map((day, dayIndex) => {
                                        const startDateOfMonth = calendarDates[1][1];
                                        let classNames = classesHandler(day, startDateOfMonth);
                                        let isActiveHover = false;

                                        if (!hoverDate) {
                                            isActiveHover = false
                                        }

                                        if (typeof startDate === 'undefined' && typeof endDate === 'undefined') {
                                            isActiveHover = false
                                        } else if ((day.isAfter(startDate) && day.isBefore(hoverDate)) || day.isSame(hoverDate, 'day')) {
                                            isActiveHover = true
                                        }

                                        return <td
                                            key={dayIndex}
                                            data-title={`r${weekIndex}c${dayIndex}`}
                                            className={`${classNames} ${isActiveHover ? "in-range" : ""}`}
                                            onClick={() => {
                                                // ! Những ngày nào "bị dislabed mặc định" hoặc ngày "của tháng trước nhưng hiển trị trong tháng này" thì không active onClick
                                                const isDisabled = classNames.includes("disabled") || classNames.includes("off ends");
                                                !isDisabled && handleDateClick(day)
                                            }}
                                            onMouseEnter={() => {
                                                const isAvailable = classNames.includes("available");

                                                if (endDate) {
                                                    setHoverDate(null);
                                                    return;
                                                }

                                                if (isAvailable) {
                                                    setHoverDate(day)
                                                }
                                            }}
                                        >
                                            {day.format("D")}
                                        </td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    // ! Hiển thị từ ngày đến ngày block
    const renderRangeBlock = () => {
        const dayLeft = startDate ? startDate.format("DD/MM/YYYY") : "DD/MM/YYYY";
        const dayRight = startDate ? endDate ? endDate?.format("DD/MM/YYYY") : startDate?.format("DD/MM/YYYY") : "DD/MM/YYYY";

        return <div className='hrv-blockrange-container'>
            <div className='hrv-blockrange-container__input'>
                <p>{dayLeft}</p>
            </div>

            <div className='hrv-blockrange-container__arrow'>
                {ArrowFromToSvg}
            </div>

            <div className='hrv-blockrange-container__input'>
                <p>{dayRight}</p>
            </div>
        </div>
    }

    // ! Hiển thị tháng và năm
    const renderMonthAndYear = useCallback(() => {
        // ! Lấy giá trị tháng
        const monthLeft = locale.monthNames[leftCalendar.month.month()];
        const monthRight = locale.monthNames[rightCalendar.month.month()];

        // ! Lấy giá trị năm
        const yearLeft = leftCalendar.month.year();
        const yearRight = rightCalendar.month.year();

        return <div className='hrv-dateRange-container'>
            <span className='hrv-dateRange'>
                {monthLeft}{locale.separator}{yearLeft}
            </span>

            {!singleCalendar && <span className='hrv-dateRange'>
                {monthRight}{locale.separator}{yearRight}
            </span>}
        </div>

    }, [leftCalendar, rightCalendar]);

    // ! Tìm range nào đang trạng thái active theo endDate và startDate
    const findRangeActive = useCallback(() => {
        const rangeArray = Object.entries(ranges);

        let result = rangeArray.find(([key, range]) => {
            const [start, end] = range as any;

            // * Active trường hợp start và end undefined
            if (typeof start === 'undefined' && typeof end === 'undefined' && typeof startDate === 'undefined' && typeof endDate === 'undefined') {
                return [key, range]
            }

            return start?.isSame(startDate, 'day') && end?.isSame(endDate, 'day');
        });

        return result || []
    }, [ranges, startDate, endDate]);

    const getRectParent = getClientRect(refParentPicker?.current);

    return (
        <>
            <div ref={refParentPicker}>
                {children ?
                    <div onClick={handleShow}>
                        {children}
                    </div> :
                    <input
                        type="text"
                        value={`${startDate?.format(locale.format)}${locale.separator}${endDate?.format(locale.format)}`}
                        readOnly
                        onClick={handleShow}
                    />
                }
            </div>

            {isOpen
                ? createPortal(
                    <div
                        className="hrv-calendar-wrapper daterangepicker ltr show-ranges show-calendar opensright hrv-report-custom-datepicker"
                        ref={containerRef}
                        style={{
                            display: "block",
                            top: getRectParent?.top + refParentPicker.current.offsetHeight + threshold + window.scrollY
                                + 'px',
                            left: getRectParent?.left,
                        }}
                    >
                        <div className={`hrv-calendar ${singleCalendar ? "hrv-calendar-single" : ""}`}>
                            {!singleCalendar && <div className="hrv-range-picker">
                                {Object.entries({ ...ranges, [locale.customRangeLabel]: [] }).map(([label, [start, end]]: any) => {
                                    // ! isActive chỉ trong phạm vi range chưa bao gồm: "Tuỳ chọn". Nếu có active thì tuỳ chọn sẽ unactive và ngược lại nếu không có range nào được active thì tuỳ chọn sẽ active.
                                    const isActive = findRangeActive().length && findRangeActive()[0] === label;
                                    // * Nếu active là true thì trả về "active"
                                    // * Nếu active là false và !findRangeActive().length và label === 'Tuỳ chọn' thì active "Tuỳ chọn"

                                    return <button
                                        key={label}
                                        className={`${isActive ? "active" : (label === locale.customRangeLabel && !findRangeActive().length) ? "active" : ""}`}
                                        onClick={() => {
                                            if (label === locale.customRangeLabel || isActive) return;

                                            setStartDate(start);
                                            setEndDate(end);
                                            updateCalendars(start, end);
                                        }}
                                    >
                                        {label}
                                    </button>
                                })}
                            </div>}

                            <div style={{
                                width: singleCalendar ? "100%" : "unset"
                            }}>

                                {showRangeDateBlock && renderRangeBlock()}

                                {singleCalendar && <div className="hrv-range-picker">
                                    {Object.entries({ ...ranges, [locale.customRangeLabel]: [] }).map(([label, [start, end]]: any) => {
                                        // ! isActive chỉ trong phạm vi range chưa bao gồm: "Tuỳ chọn". Nếu có active thì tuỳ chọn sẽ unactive và ngược lại nếu không có range nào được active thì tuỳ chọn sẽ active.
                                        const isActive = findRangeActive().length && findRangeActive()[0] === label;
                                        // * Nếu active là true thì trả về "active"
                                        // * Nếu active là false và !findRangeActive().length và label === 'Tuỳ chọn' thì active "Tuỳ chọn"

                                        return <button
                                            key={label}
                                            className={`${isActive ? "active" : (label === locale.customRangeLabel && !findRangeActive().length) ? "active" : ""}`}
                                            onClick={() => {
                                                if (label === locale.customRangeLabel || isActive) return;

                                                setStartDate(start);
                                                setEndDate(end);
                                                updateCalendars(start, end);
                                            }}
                                        >
                                            {label}
                                        </button>
                                    })}
                                </div>}

                                {renderDropdowns(leftCalendar.month.month(), leftCalendar.month.year())}

                                {renderMonthAndYear()}

                                {renderCalendar('left')}

                                {!singleCalendar && renderCalendar('right')}
                            </div>
                        </div>

                        <div className="hrv-calendar-button drp-buttons">
                            <span className="drp-selected">
                                {startDate?.format(locale.format)} - {endDate?.format(locale.format)}
                            </span>

                            <button
                                className={`hrv-btn-datepicker hrv-btn-default ${cancelButtonClasses}`}
                                onClick={handleCancel}
                            >
                                {locale.cancelLabel}
                            </button>

                            <button
                                className={`hrv-btn-datepicker hrv-btn-primary ${applyButtonClasses}`}
                                disabled={endDate === null && startDate !== null}
                                onClick={handleApply}
                            >
                                {locale.applyLabel}
                            </button>
                        </div>
                    </div >,
                    document.body)
                : null}
        </>
    );
};

export default DateRangePicker;

const ArrowFromToSvg = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fillRule="evenodd" d="M1.5 8a.75.75 0 0 1 .75-.75h9.69l-2.72-2.72a.749.749 0 1 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.749.749 0 1 1-1.06-1.06l2.72-2.72h-9.69a.75.75 0 0 1-.75-.75"></path></svg>
