import { memo } from "react";

import SummaryLine from "./SummaryLine";
import BodyLine from "./BodyLine";

import {
  queryString
} from "helpers/helpers";

function BodyTableDefault(props) {
  const {
    displaySummary,
    summary,
    dataRows,
    headingRows,
    canReadMACost,
    headers,
    subHeadingRows,
    heatmapMode,
    dataLink,
    isReportPNL,
    benchmark,
    onClickDetailDrilldown,
    dependdata
  } = props;

  const shop: any = queryString.shop;

  return (
    <>
      <tbody>
        <SummaryLine displaySummary={displaySummary} data={summary} />

        <BodyLine
          data={dataRows}
          headers={headers}
          dependdata={dependdata}
          headingRows={headingRows}
          subHeadingRows={subHeadingRows}
          canReadMACost={canReadMACost}
          dataLink={dataLink}
          shop={shop}
          isReportPNL={isReportPNL}
          heatmapMode={heatmapMode}
          benchmark={benchmark}
          onClickDetailDrilldown={onClickDetailDrilldown}
        />
      </tbody>

      {/* {heatmapMode && (
        <HeatmapTooltip isShow={tooltip.level !== 0}>
          <div>
            <p
              className="hrv-report-mb-4 hrv-report-fw-600 hrv-report-fs-12"
              style={{
                color: tooltip.level < 3 ? "#DC2828" : "#178F51",
              }}
            >
              {tooltip.level < 3
                ? t("Chậm hơn")
                : tooltip.level > 3
                  ? t("Cao hơn")
                  : t("Tương đương")}{" "}
              {t(tooltipHeatmap[tooltip.name]?.report)}
            </p>

            <p className="hrv-report-m-0 hrv-report-fs-12 hrv-report-text-color-gray-9">
              {capitalizeFirstLetter(
                t(tooltipHeatmap[tooltip.name]?.report) || "",
              )}
              : XX {t(tooltipHeatmap[tooltip.name]?.unit)}
            </p>
          </div>
        </HeatmapTooltip>
      )} */}
    </>
  );
}

export default memo(BodyTableDefault);
