
const EmptyColorSVG = <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
  <rect width="88" height="88" rx="44" fill="#F9FAFB" />
  <path d="M50.2581 47.7583H48.9415L48.4748 47.3083C50.1081 45.4083 51.0915 42.9417 51.0915 40.2583C51.0915 34.275 46.2415 29.425 40.2581 29.425C34.2748 29.425 29.4248 34.275 29.4248 40.2583C29.4248 46.2417 34.2748 51.0917 40.2581 51.0917C42.9415 51.0917 45.4081 50.1083 47.3081 48.475L47.7581 48.9417V50.2583L56.0915 58.575L58.5748 56.0917L50.2581 47.7583ZM40.2581 47.7583C36.1081 47.7583 32.7581 44.4083 32.7581 40.2583C32.7581 36.1083 36.1081 32.7583 40.2581 32.7583C44.4081 32.7583 47.7581 36.1083 47.7581 40.2583C47.7581 44.4083 44.4081 47.7583 40.2581 47.7583Z" fill="#9CA3B0" />
</svg>

const EmptySVG = <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="88" height="88" rx="44" fill="#F9FAFB" />
  <path d="M31.0834 41.5C29.7 41.5 28.5834 42.6167 28.5834 44C28.5834 45.3833 29.7 46.5 31.0834 46.5C32.4667 46.5 33.5834 45.3833 33.5834 44C33.5834 42.6167 32.4667 41.5 31.0834 41.5ZM31.0834 31.5C29.7 31.5 28.5834 32.6167 28.5834 34C28.5834 35.3833 29.7 36.5 31.0834 36.5C32.4667 36.5 33.5834 35.3833 33.5834 34C33.5834 32.6167 32.4667 31.5 31.0834 31.5ZM31.0834 51.5C29.7 51.5 28.5834 52.6333 28.5834 54C28.5834 55.3667 29.7167 56.5 31.0834 56.5C32.45 56.5 33.5834 55.3667 33.5834 54C33.5834 52.6333 32.4667 51.5 31.0834 51.5ZM36.0834 55.6667H59.4167V52.3333H36.0834V55.6667ZM36.0834 45.6667H59.4167V42.3333H36.0834V45.6667ZM36.0834 32.3333V35.6667H59.4167V32.3333H36.0834Z" fill="#9CA3B0" />
</svg>


interface IProps {
  text: string;
  subText?: string;
  isHaveColor?: boolean;
  isUnsetMinHeight?: boolean;
  isShowIcon?: boolean;
}

export default function EmptyTemplate(props: IProps) {
  const {
    text,
    subText,
    isHaveColor = false,
    isUnsetMinHeight = false,
    isShowIcon = true,
  } = props;

  return (
    <>
      {isShowIcon ? (
        <div
          className="hrv-report-d-flex hrv-report-flex-col hrv-report-justify-center hrv-report-items-center"
          style={{
            minHeight: isUnsetMinHeight ? "unset" : "240px",
          }}
        >
          {isShowIcon && (
            <div>{isHaveColor ? EmptyColorSVG : EmptySVG}</div>
          )}

          {subText !== undefined ? (
            <div className="hrv-report-text-center">
              <p className="hrv-report-text-center hrv-report-mb-0 hrv-report-mt-16 hrv-report-fs-16 hrv-report-fw-600 hrv-report-text-color-black-6">
                {text}
              </p>
              <p className="hrv-report-text-center hrv-report-mb-0 hrv-report-mt-4 hrv-report-fs-14 hrv-report-text-color-black-5">
                {subText}
              </p>
            </div>
          ) : (
            <p className="hrv-report-text-center hrv-report-mb-0 hrv-report-mt-16 hrv-report-fs-14 hrv-report-text-color-black-5">
              {text}
            </p>
          )}
        </div>
      ) : (
        <p className="hrv-report-text-center hrv-report-mb-0 hrv-report-fs-14 hrv-report-text-color-black-5">
          {text}
        </p>
      )}
    </>
  );
}
