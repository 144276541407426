import { useEffect } from "react";

const ScrollReset = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (null);
}

export default ScrollReset;
