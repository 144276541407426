import { useMemo } from "react";
import { useLocation } from "react-router-dom";



export const useQueryString = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchParamsObject = Object.fromEntries([...queryParams]);

  return searchParamsObject;
};

export const useDataSearch = () => {
  const location = useLocation();
  const params = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location?.search);
    const paramsClean = ["locale", "timestamp", "shop", "hmac", "orgid"];
    paramsClean.map((i) => urlSearchParams.delete(i));

    return urlSearchParams;
  }, [location?.search]);

  const searchData: any = [];

  params.forEach((value, key) => {
    searchData[key] = {
      code: key,
      key,
      value,
      encodeValue: encodeURIComponent(value),
    };
  });



  return {
    searchData,
    searchDataObj: Object.fromEntries([...params]),
    searchDataString: params?.toString() !== "" ? `?${params?.toString()}` : "",
  };
};
