/**
 * Author: Mạnh Đạt
 * Created at: 21/02/2024
 */

/*
    Phases:
    1. Initial render: Unconditionally render all tabs. When used with server-side
       rendering, this produces complete static HTML that is still usable in the event
       that JavaScript fails to load on the client.
    2. componentDidMount: Trigger a "measuring" render.
    3. Measuring render: Unconditionally render all tabs, as well as the menu button.
    4. Measure DOM elements: After the measuring render, determine which tabs can fit
       without overflowing and save these as `visibleTabIndices`.
    5. Final render: Render visible tabs and shift the rest into the overflow menu.`

    ** When the window is resized or the tabs themselves change, trigger step 3.
*/

// * Hooks
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useTrans } from '@haravan/reactapp';

// * Components
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { TabContainer } from './components'
import { uniq } from 'helpers/helpers';
import TooltipWithTouch from 'components/TooltipWithTouch';
import { svgAddDashboard } from 'pages/ReportDashboardV2/tools/Header';
import { LIMIT_TAB } from 'pages/ReportDashboardV2/context/store/constants';

function DynamicTabs({ children, components = {}, ...props }) {
    const { onHandleChangeList, onHandleClickAdd, tabList } = props;

    const [measuringRender, setMeasuringRender] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const [visibleTabIndices, setVisibleTabIndices]: any = useState([]);

    const containerRef: any = useRef();
    const buttonRef: any = useRef();

    const t = useTrans();

    // * 2. componentDidMount
    useEffectOnce(() => {
        setIsMounted(true);
        setMeasuringRender(true);
    });


    // * 3. Measure DOM elements
    useLayoutEffect(() => {
        if (measuringRender) {
            if (containerRef !== null) {
                // Get the child tab elements
                const tabElements: any = Array.from(containerRef.current.children);

                let stopWidth = 0;
                let visible: any = [];
                const containerWidth = containerRef.current.offsetWidth

                tabElements.forEach((tab: any, index) => {
                    // Không tính width của nút xem thêm trừ khi nào nó được phép hiện
                    if (visible.length === tabElements.length - 1) {
                        stopWidth -= buttonRef.current.offsetWidth;
                    }

                    stopWidth += tab.offsetWidth;

                    if (containerWidth >= stopWidth) {
                        visible.push(index);
                    } else {
                        if (tabElements) {
                            let tabList = tabElements.map((y, index) => {
                                return {
                                    id: index,
                                    width: y.offsetWidth,
                                    active: y.getAttribute("attr-active") === "1"
                                }
                            });

                            const activeTab = tabList.find(y => y.active);
                            let widthOfActiveTab = 0;
                            let widthOfButton = 0;

                            widthOfActiveTab = activeTab?.width;
                            widthOfButton = buttonRef.current.offsetWidth;

                            const result = tabList.reduce((acc: any, curr: any) => {
                                const totalWidth = acc.reduce((sum, item) => sum + item.width, widthOfActiveTab + widthOfButton);

                                if (totalWidth <= containerWidth - widthOfButton) {
                                    acc.push(curr);
                                };

                                return acc;
                            }, []);

                            result.push(activeTab);

                            let pushActive = result.map(y => y?.id);

                            visible = uniq(pushActive)
                        }
                    }
                });

                setVisibleTabIndices(visible);

                setMeasuringRender(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measuringRender]);

    // * Trigger a measuringRender when the window is resized
    useEffectOnce(() => {
        function handleResize() {
            setMeasuringRender(true);
        }

        window.addEventListener('resize', handleResize);

        return function cleanUp() {
            window.removeEventListener('resize', handleResize);
        };
    });

    // * Trigger a measuringRender when the tabs (children) prop changes
    useEffect(() => {
        setMeasuringRender(true);
    }, [children]);

    const allTabs = React.Children.map(children, (tab, index) => {
        return React.cloneElement(tab, {
            key: index,
        });
    });

    let visibleTabs: any = [];

    const overflowTabs: any = [];

    if (!isMounted || measuringRender) {
        visibleTabs = allTabs;
    } else {
        // * Tạo 1 element import thêm 1 chức năng khi click vào nó
        allTabs.forEach((tab, index) => {
            if (visibleTabIndices.includes(index)) {
                const visible = React.cloneElement(tab, {
                    onClick: () => {
                        onHandleChangeList && onHandleChangeList(tabList[index]);
                    }
                });

                visibleTabs.push(visible);
            } else {
                const cloneHiddenTab = React.cloneElement(<MenuItem key={index}>
                    {tab}
                </MenuItem>, {
                    style: {
                        width: "100%"
                    },
                    className: 'hrv-report-dashboard-item-in-tray',
                    onClick: () => {
                        onHandleChangeList && onHandleChangeList(tabList[index])
                    }
                });

                overflowTabs.push(cloneHiddenTab);
            }
        });
    }

    return (
        <>
            <Box>
                <Box display="flex" maxHeight={"36px"} alignItems={'center'}>
                    <TabContainer innerRef={containerRef}>
                        {visibleTabs}
                    </TabContainer>

                    {(measuringRender || overflowTabs.length > 0) && (
                        <Menu placement="bottom-end">
                            <MenuButton
                                as={Button}
                                variant="ghost-default"
                                rightIcon={svgArrow}
                                ref={buttonRef}
                                ml={10}
                                flexShrink={0}
                            >
                                {t("See_more")}
                            </MenuButton>

                            <MenuList>
                                {overflowTabs}
                            </MenuList>
                        </Menu>
                    )}

                    <div className="hrv-report-d-none hrv-report-md-d-flex hrv-report-md-items-end">
                        {tabList.length < LIMIT_TAB && (
                            <>
                                <span className="hrv-report-divider hrv-report-mb-4"></span>

                                <TooltipWithTouch label={t("Add_new")} hasArrow>
                                    <span
                                        className="hrv-report-add-dashboard-button hrv-report-d-flex hrv-report-items-center hrv-report-cursor-pointer"
                                        onClick={() => {
                                            onHandleClickAdd && onHandleClickAdd();
                                        }}
                                    >
                                        {svgAddDashboard}
                                    </span>
                                </TooltipWithTouch>
                            </>
                        )}
                    </div>
                </Box>

                <div className="hrv-report-border-bottom-gray-8"></div>
            </Box>
        </>
    )
}

export default DynamicTabs


const svgArrow = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <g id="Icon">
            <path
                id="Vector"
                d="M6.175 6.9126L10 10.7293L13.825 6.9126L15 8.0876L10 13.0876L5 8.0876L6.175 6.9126Z"
                fill="#4B5563"
            />
        </g>
    </svg>
);