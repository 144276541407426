
import React, {
  useContext, useEffect,
  useState
} from "react";

import {
  useEmbedApp,
  useFetchJson
} from "@haravan/reactapp";

import useHistory from "hooks/useHistory";
import { useTrans } from "locales/hook";
import { useShopInfo } from "models/swr/shop";
import useSWR from "swr";

import {
  SetAccessFeatures,
  SetCurrencyCode,
  SetDaysOfWeek,
  SetEnableCanary,
  SetHourSuffix,
  encodeUrlParams,
} from "helpers/helpers";

export interface AppContextState {
  roles: Array<string>;
  locations: Array<any>;
  history: any;
  plan_name: string;
  plan_status: string;
  generalSettings: any;
  updateBreadcrumb: Function;
  redirectAdminPath: Function;
  toogleFullScreen: Function;
  shopFeatures: Array<any>;
  isLoading: boolean;
  isFullScreen: boolean;
  userId: number;
  betaFeatures: Array<any>;
  isWebview: boolean;
  locale: string
  user: any,
  shopInfo: any,
  setMenuActive: Function;
  isDisplaySummary: boolean;
  setIsDisplaySummary: Function
}

export interface AppProviderProps {
  children?: any;
}

export const AppContext = React.createContext<AppContextState>({
  roles: [],
  locations: [],
  plan_name: "",
  plan_status: "",
  generalSettings: {},
  history: {},
  updateBreadcrumb: () => { },
  redirectAdminPath: () => { },
  toogleFullScreen: () => { },
  shopFeatures: [],
  isLoading: true,
  isFullScreen: false,
  userId: 0,
  betaFeatures: [],
  isWebview: false,
  locale: 'vi',
  user: {},
  shopInfo: {},
  setMenuActive: () => { },
  isDisplaySummary: false,
  setIsDisplaySummary: () => { }
});

export const TYPE_APP = "APP::NAVIGATION::REDIRECT::APP";

export const AppProvider = function (props: AppProviderProps) {
  const {
    shop,
    setFullScreen,
    setFrameInitialized,
    locale,
    setBreadcrumb,
    setMenuActive,
    history,
    redirectToUrl,
  } = useEmbedApp();

  const t = useTrans();
  const { pushInApp } = useHistory();
  const fetchJson = useFetchJson();

  const { data, isLoading } = useSWR(
    `${import.meta.env.VITE_APP_API_SERVICES}/status`,
    fetchJson,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    },
  );

  const { data: shopInfo } = useShopInfo();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isWebview, setIsWebview] = useState(false);
  const [isDisplaySummary, setIsDisplaySummary] = useState(false);

  const _document = document as any;
  const dataLayer = (window as any)?.dataLayer || [];

  let betaFeatures = data?.data?.betaFeatures || [];
  let planName = shopInfo?.data?.shop?.planName?.toLowerCase() ?? "";
  let planStatus = shopInfo?.data?.shop?.planStatus || "";
  let userId = data?.data?.userId || 0;

  useEffect(() => {
    if (data !== undefined && data.data !== undefined) {
      var title = document.title;
      if (!title.includes(data.data.orgName)) {
        document.title = `${data.data.orgName} ~ ${title}`;
      }
      dataLayer.push({
        event: "identify",
        user_id: data.data.userId,
        org_id: data.data.orgId,
      });
    }
  }, [data]);

  const updateBreadcrumb = (
    title: string,
    label: string = t("report"),
    path: string = "/dashboard",
  ) => {
    setBreadcrumb(label, path, title);
  };

  //! Redirect to a relative path in Haravan admin
  const redirectAdminPath = (path) => {
    redirectToUrl(path, "admin_path");
  };

  const handleChangeMenu = (e) => {
    const data = e?.data;
    const payload = data?.payload;

    if (
      data &&
      data.type === "dispatch" &&
      payload?.payload &&
      payload?.group === "Navigation"
    ) {
      const path = payload.payload.path;
      const type = payload.type;

      if (type === TYPE_APP) {
        pushInApp(encodeUrlParams(path));
      }
    }
  };

  // ! Listen event khi click menu từ Omni
  useEffect(() => {
    window.addEventListener("message", handleChangeMenu);

    setFrameInitialized();

    return () => {
      window.removeEventListener("message", handleChangeMenu);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // ! get params in app
    const querySearch = new URLSearchParams(location.search);

    let source = querySearch.get('source')

    if (source === 'mobile') {
      setIsWebview(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let roles = data?.data.roles || [];
  let locations = data?.data.locations || [
    {
      id: -1,
      location_name: "",
    },
  ];

  if (
    shopInfo !== undefined &&
    shopInfo.data !== undefined &&
    shopInfo.data.shop !== undefined
  ) {
    const shopData = shopInfo.data.shop as any;
    const user = {
      OrgId: shopData.orgId,
      OrgName: shopData.orgName,
    };

    _document.user = user;
    SetEnableCanary(Number(user.OrgId));
  }

  if (shopInfo !== undefined && shopInfo.data !== undefined) {
    _document.opcmode = shopInfo.data.opcMode;
  }

  if (shop !== undefined) {
    _document.seller_domain = shop;
  }

  const toogleFullScreen = (isActive: boolean) => {
    setFullScreen(isActive);
    setIsFullScreen(isActive);
  };

  const appContextState = {
    roles,
    locations,
    plan_name: planName,
    plan_status: planStatus,
    generalSettings: shopInfo?.data?.shop || {},
    history,
    updateBreadcrumb: updateBreadcrumb,
    redirectAdminPath,
    toogleFullScreen: toogleFullScreen,
    shopFeatures: data?.data?.shopFeatures,
    isLoading,
    isFullScreen,
    userId,
    betaFeatures,
    isWebview,
    locale,
    user: data?.data,
    shopInfo,
    setMenuActive,
    isDisplaySummary,
    setIsDisplaySummary
  } as AppContextState;

  SetAccessFeatures(data?.data?.shopFeatures ?? []);

  SetDaysOfWeek({
    1: t("Sunday"),
    2: t("Monday"),
    3: t("Tuesday"),
    4: t("Wednesday"),
    5: t("Thursday"),
    6: t("Friday"),
    7: t("Saturday"),
    8: t("Sunday"),
  });

  SetHourSuffix({
    AM: t("AM"),
    PM: t("PM"),
  });

  SetCurrencyCode(appContextState.generalSettings.currency);

  return (
    <AppContext.Provider value={appContextState}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppProvider = () => {
  const context = useContext(AppContext);

  return context;
};
