interface IProps {
    enabled: boolean;
    variables: Record<string, any>
};

const DETECT_CLASS = "user-tracker";
const DEFAULT_GTM_PROVIDERS = {
    attributes: {},
    detectClass: ""
};

export const useGTM = (props: IProps) => {
    const { enabled, variables } = props;

    if (!enabled) {
        return DEFAULT_GTM_PROVIDERS;
    } else {
        return {
            ...DEFAULT_GTM_PROVIDERS,
            detectClass: DETECT_CLASS,
            attributes: variables
        }
    };
}