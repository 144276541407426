/**
 * Vẽ Chart cho báo cáo liên quan đến bán hàng
 * ngoại trừ: Báo cáo sàn thương mại điện tử, hiệu quả khuyến mãi, Báo cáo VAT
 * Role: => Hiển thị Trục ngang - Groupby đầu tiên có type !== 'timestamp'
 * Role: => Hiển thị Trục dọc - Groupby đầu tiên có type === 'timestamp'
 */

// ! Hooks
import { EffectCallback, useCallback, useEffect, useState } from "react";
import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
// ! Components
import { Box } from "@chakra-ui/react";
import WrapperSpinner from "components/Spinner";
import { BarChart } from "components/Chart";
import { EmptyChart } from "./components/EmptyChart";
//! Helpers
import { ShowDateRange, implementLongStringInChart, isEmpty, valueHandler } from "helpers/helpers";
import { GetDateShowType } from "helpers/date";

// & Chỉ vẽ theo doanh thu thuần
const NET_AMOUNT_VALUE = ["NetSaleAmount", "NetSaleAmountByLocAssigned"];
// & Độ cao chart
const HEIGHT_CHART = 440;
// & Độ cao legend
const HEIGHT_LEGEND = 36;
// & Số items được vẽ nếu chart vẽ theo chiều ngang
const LIMIT_ROWS_OF_INDEX_AXIS_Y = 10;

const ACTIVE_INDEX_AXIS_FEATURE = true

export const SalesMetrics = () => {
    const t = useTrans();
    const { state } = useStore();
    const {
        isLoadingTable: isLoading,
        statistical_table_data,
        startdate,
        enddate
    } = state;
    const { headerreport: headers, data } = statistical_table_data;
    const { type } = GetDateShowType(startdate, enddate);
    const [typeHorizontal, setTypeHorizontal] = useState("");
    const [labels, setLabels] = useState([]);
    const [dataChart, setDataChart] = useState<any>([]);

    const findIndexAxis = useCallback(() => {
        if (ACTIVE_INDEX_AXIS_FEATURE) {
            return headers[0]?.dataFormat === 'timestamp' ? 'x' : 'y';
        } else {
            return 'x'
        }
    }, [headers]);

    const useEffectToBuildData = (callback: EffectCallback) => {
        return useEffect(callback, [statistical_table_data])
    }

    useEffectToBuildData(() => {
        if (!isEmpty(statistical_table_data)) {
            let renderData = [] as Array<any>;

            // Tìm vị trí của cột "Doanh thu thuần"
            let findIndexOfNetAmount = headers?.findIndex((h) => NET_AMOUNT_VALUE.includes(h.name));

            const typeOfVertical = headers[0].dataFormat;
            const nameOfVertical = headers[0].name;

            const labels = data.map((row) => {
                let value = row[0];

                if (nameOfVertical === "CustomerId" && value === 'guest') {
                    value = t(value);
                }

                if (nameOfVertical === "CustomerAgeGroup") {
                    value = t(`CustomerAgeGroup_value_${value}`)
                }

                if (nameOfVertical === "CustomerGender") {
                    value = t(value)
                }

                return valueHandler(typeOfVertical, nameOfVertical, value, type);
            });

            if (findIndexOfNetAmount !== -1) {
                const typeHorizon = headers[findIndexOfNetAmount].dataFormat;

                setTypeHorizontal(typeHorizon);

                if (data.length > 5 && data.length <= 12) {
                    const newLabels = data.map((row) =>
                        implementLongStringInChart(valueHandler(typeOfVertical, nameOfVertical, row[0], type).split(" "))
                    );

                    setLabels(newLabels);
                };

                renderData = data.map((row) =>
                    typeHorizon === "percent" ? row[findIndexOfNetAmount] * 100 : row[findIndexOfNetAmount]
                );
            }

            if (findIndexAxis() === "y") {
                setLabels(labels.slice(0, LIMIT_ROWS_OF_INDEX_AXIS_Y));
                renderData = renderData.slice(0, LIMIT_ROWS_OF_INDEX_AXIS_Y);
            } else {
                setLabels(labels);
            }

            setDataChart(renderData)
        }
    });

    const renderChart = () => {
        const isRenderChart = !!labels?.length || !!data?.length;

        return (
            <>
                <div className="hrv-report-detail-chart">
                    {isRenderChart ? (
                        <div style={{
                            height: HEIGHT_CHART
                        }}>
                            <BarChart
                                proData={{
                                    labels,
                                    data: dataChart,
                                    namefield: `${ShowDateRange(startdate, enddate)}`,
                                }}
                                colorBar="#3C83F6"
                                hoverColorBar="#61A6FA"
                                typeHorizonal={typeHorizontal}
                                configHeight={HEIGHT_CHART}
                                typeVertical={headers[0]?.name.toLowerCase()}
                                indexAxis={findIndexAxis()}
                            />
                        </div>
                    ) : (
                        <EmptyChart
                            chartHeight={HEIGHT_CHART}
                            legendHeight={HEIGHT_LEGEND}
                        />
                    )}
                </div>
            </>
        );
    }

    const renderLegend = () => {
        const isRenderChart = !!labels?.length || !!data?.length;

        return (
            <>
                {isRenderChart && <div
                    className="hrv-report-row hrv-report-items-center hrv-report-justify-center hrv-report-mt-16"
                >
                    {findIndexAxis() === "y" && data?.length >= LIMIT_ROWS_OF_INDEX_AXIS_Y && <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-my-16" style={{
                        width: "100%"
                    }}>
                        <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                            {t("Hiển thị top 10 giá trị cao nhất")}
                        </p>
                    </div>}


                    <div
                        className="hrv-report-legend-square"
                        style={{ background: "#3C83F6" }}
                    ></div>
                    <div>{ShowDateRange(startdate, enddate)}</div>
                </div>}
            </>
        );
    };

    return (
        <div className="hrv-report-whitebox-no-bs hrv-report-mt-16 hrv-report-pb-16">
            <Box textStyle='h4' className="hrv-report-mb-16">{t(NET_AMOUNT_VALUE[0])}</Box>

            {isLoading ? (
                <div
                    className="hrv-report-d-flex hrv-report-items-center"
                    style={{
                        minHeight: `${HEIGHT_CHART + HEIGHT_LEGEND}px`
                    }}
                >
                    <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-w-100" style={{
                        height: '100%'
                    }}>
                        <WrapperSpinner />
                    </div>
                </div>
            ) : (
                <>
                    {renderChart()}
                    {renderLegend()}
                </>
            )}
        </div>
    );
}

