import dayjs from "components/Dayjs"

import { useTrans } from "locales/hook";
import useWindowDimensions from "hooks/useWindowDimension";
import { useStore } from "pages/ReportStandard/context/store";

import HCheckbox from "components/HCheckbox";
import { ReportDatePicker, ReportGroupBy } from "../components";

import TiktokIcon from "assets/images/icons/tiktok.png";
import LazadaIcon from "assets/images/icons/lazada.png";
import ShopeeIcon from "assets/images/icons/shopee.png";

import { DATE_RANGES_RFM, DATE_RANGES_STOCK_LOTS } from "components/Dayjs/constants";

interface IRenderFilterByDefaultProps {
  reportNameSystem: string;
  groupBySelected: any;
  handleChangeGroupBy: Function;
  groupBy: any;
  onChangeDate: Function;
  dateStart: string;
  dateEnd: string;
  autoCompleteFilterPanel?: any;
  isLoadingAutoCompleteFilterPanel?: boolean;
  onHandleChangeFilter?: any;
  filterTag?: any;
  onHandleChangeCheckboxInFilterTypeSingle: Function;
  groupByFilterPanel: any;
}

function RenderFilterByDefault(props: IRenderFilterByDefaultProps) {
  const {
    reportNameSystem,
    groupBySelected,
    handleChangeGroupBy,
    groupBy,
    onChangeDate,
    dateStart,
    dateEnd,
    autoCompleteFilterPanel,
    isLoadingAutoCompleteFilterPanel,
    onHandleChangeFilter,
    filterTag,
    onHandleChangeCheckboxInFilterTypeSingle,
    groupByFilterPanel,
  } = props;

  const { dispatch, actions, state } = useStore();

  const { isVisibleDrilldown } = state;

  const t = useTrans();
  const { width } = useWindowDimensions();

  const handleOnClickCheckbox = (dimension, value) => {
    onHandleChangeFilter && onHandleChangeFilter(dimension, value);
  };
  /**
   *
   * @param type: Loại của checkbox khi click: groupby hay measure
   * @param groupAreaName: Tên của cái group cần thay đổi (Chỉ aply cho type === groupname) đối với Measure thì thay đổi trực tiếp trong field luôn
   * @param value: Value gửi qua cho hàm này
   */
  const handleClickSingle = ({ type, groupAreaName, value }: any) => {
    onHandleChangeCheckboxInFilterTypeSingle &&
      onHandleChangeCheckboxInFilterTypeSingle({
        type,
        groupAreaName,
        value,
      });
  };

  return (
    <div className="hrv-report-row hrv-report-items-center">
      {!["customers_by_rfm", "cas_transaction_pnl", "stock_lots", "stock_remaining_days"].includes(
        reportNameSystem,
      ) && (
          <div className="report_detail_header__item hrv-report-d-flex hrv-report-md-pr-8 hrv-report-flex-col hrv-report-md-flex-row">
            <ReportDatePicker
              onCallback={onChangeDate}
              dateStart={dateStart}
              dateEnd={dateEnd}
              autoApply={width < 856}
            />

            {(reportNameSystem === "shipments_carriers_status_by_time" ||
              reportNameSystem === "shipments_carriers_status_by_location") && (
                <div className="hrv-report-md-w-auto hrv-report-w-full hrv-report-mt-8 hrv-report-md-mt-0 hrv-report-ml-0 hrv-report-md-ml-16">
                  <HCheckbox
                    isMultiple={true}
                    name="displayname_vi"
                    valueName="value"
                    defaultValue={
                      filterTag
                        ?.find((y) => y.dimension === "CarrierId")
                        ?.query?.map((y) => y.value) || []
                    }
                    showButtonAll={true}
                    onChange={(value) => {
                      handleOnClickCheckbox("CarrierId", value);
                    }}
                    options={autoCompleteFilterPanel["CarrierId"]}
                    useEmptyForGetAll={true}
                    nameField={t("nhà vận chuyển")}
                    isLoading={isLoadingAutoCompleteFilterPanel}
                    setDimension={() => {
                      dispatch(actions.setDimensionSelected("CarrierId"));
                    }}
                    showApplyButton
                    size="m"
                  />
                </div>
              )}
          </div>
        )}

      {[
        "order_shipments_detail_carriers_internal_speed",
        "order_shipments_overview_carriers_internal_speed",
      ].includes(reportNameSystem) && (
          <div className="hrv-report-wrapper-shipments-panel">
            <div
              key={`anc_Time`}
              className="hrv-report-wrapper-shipments-panel__date"
            >
              <HCheckbox
                isMultiple={false}
                name="groupPropertyName"
                valueName="dataFormat"
                defaultValue={[
                  groupBySelected.find((y) => y.groupAreaName === "Time")
                    .dataFormat,
                ]}
                showButtonAll={false}
                onChange={(value) => {
                  handleClickSingle({
                    type: "groupby",
                    groupAreaName: "Time",
                    value,
                  });
                }}
                options={groupByFilterPanel["Time"]}
                nameField={`${t("view_by")}`}
                fullWidth
                isShowNameField={true}
                size='m'
              />
            </div>

            <div
              className="hrv-report-wrapper-shipments-panel__locId"
              key={`anc_FromLocId`}
            >
              <HCheckbox
                isMultiple={true}
                name="displayname_vi"
                valueName="value"
                defaultValue={
                  filterTag
                    ?.find((y) => y.dimension === "FromLocId")
                    ?.query?.map((y) => y.value) || []
                }
                showButtonAll={true}
                onChange={(value) => {
                  handleOnClickCheckbox("FromLocId", value);
                }}
                options={autoCompleteFilterPanel["FromLocId"]}
                useEmptyForGetAll={true}
                nameField={t("Kho xuất hàng")}
                isLoading={isLoadingAutoCompleteFilterPanel}
                setDimension={() => {
                  dispatch(actions.setDimensionSelected("FromLocId"));
                }}
                fullWidth
                showApplyButton
                size='m'
              />
            </div>
          </div>
        )}

      {reportNameSystem === "stock_lots" && (
        <div className="report_detail_header__item hrv-report-d-flex hrv-report-md-pr-8 hrv-report-flex-col hrv-report-md-flex-row">
          <ReportDatePicker
            onCallback={onChangeDate}
            dateStart={dateStart}
            dateEnd={dateEnd}
            ranges={{
              ...DATE_RANGES_STOCK_LOTS(t)
            }}
            maxDate={dayjs().add(dayjs.duration({ 'years': 10 })).endOf('year')}
            autoApply={width < 856}
            startView={"months"}
            minViewMode={"months"}
            isStockLot={true}
          />
        </div>
      )}

      {reportNameSystem === "cas_transaction_pnl" && (
        <>
          <div className="report_detail_header__item hrv-report-md-pr-8">
            <ReportDatePicker
              onChangeDate={onChangeDate}
              isYearPicker
              customText={t("Thời gian:")}
              isShowIcon={false}
              fullWidth
            />
          </div>

          <div className="report_detail_header__item hrv-report-md-pl-8">
            <ReportGroupBy
              selected={groupBySelected[0].dataFormat}
              items={groupBy}
              handleChangeGroupBy={handleChangeGroupBy}
            />
          </div>
        </>
      )}

      {reportNameSystem === "stock_remaining_days" && (
        <div className="hrv-report-wrapper-shipments-panel">
          <div
            key={`option_inventory`}
            className="hrv-report-wrapper-shipments-panel__date"
          >
            <HCheckbox
              isMultiple={false}
              name="displayname_vi"
              valueName="value"
              defaultValue={
                filterTag
                  ?.find((y) => y.dimension === "LocId")
                  ?.query?.map((y) => y.value) || []
              }
              showButtonAll={true}
              onChange={(value) => {
                handleClickSingle({
                  type: "measure",
                  groupAreaName: "LocId",
                  value,
                });
              }}
              options={autoCompleteFilterPanel["LocId"]}
              nameField={`${t("SaleLocId")}`}
              fullWidth
              setDimension={() => {
                dispatch(actions.setDimensionSelected("LocId"));
              }}
              isShowNameField={false}
              size='m'
            />
          </div>
        </div>
      )}

      {reportNameSystem === "sales_orders_salechannel_branch" && (
        <div
          className="hrv-report-wrapper-shipments-panel__locId"
          key={`anc_SalesChannelBranchName`}
        >
          <HCheckbox
            isMultiple={true}
            name="displayname_vi"
            valueName="value"
            defaultValue={
              filterTag
                ?.find((y) => y.dimension === "SalesChannelBranchName")
                ?.query?.map((y) => y.value) || []
            }
            showButtonAll={true}
            onChange={(value) => {
              handleOnClickCheckbox("SalesChannelBranchName", value);
            }}
            options={autoCompleteFilterPanel["SalesChannelBranchName"]?.map(
              (v) => {
                return {
                  ...v,
                  icon:
                    v.specialInfo === "shopee"
                      ? ShopeeIcon
                      : v.specialInfo === "tiktokshop"
                        ? TiktokIcon
                        : LazadaIcon,
                };
              },
            )}
            useEmptyForGetAll={true}
            nameField={t("Gian hàng")}
            isLoading={isLoadingAutoCompleteFilterPanel}
            setDimension={() => {
              dispatch(actions.setDimensionSelected("SalesChannelBranchName"));
            }}
            fullWidth
            isShowIcon
            showApplyButton
            size='m'
          />
        </div>
      )}

      {reportNameSystem === "stock_invreceive_by_suppliers" && <div
        className="hrv-report-wrapper-shipments-panel__locId"
        key={`anc_SalesChannelBranchName`}
      >
        <HCheckbox
          isMultiple={true}
          name="displayname_vi"
          valueName="value"
          defaultValue={
            filterTag
              ?.find((y) => y.dimension === "SupplierId")
              ?.query?.map((y) => y.value) || []
          }
          showButtonAll={true}
          onChange={(value) => {
            handleOnClickCheckbox("SupplierId", value);
          }}
          options={autoCompleteFilterPanel["SupplierId"]}
          useEmptyForGetAll={true}
          nameField={t("ProductVendorName")}
          isLoading={isLoadingAutoCompleteFilterPanel}
          setDimension={() => {
            dispatch(actions.setDimensionSelected("SupplierId"));
          }}
          fullWidth
          showApplyButton
          isDisabled={isVisibleDrilldown}
          size="m"
        />
      </div>}

      {reportNameSystem === "customers_by_rfm" && <div
        key={`anc_SalesChannelBranchName`}
      >
        <ReportDatePicker
          onCallback={onChangeDate}
          dateStart={dateStart}
          dateEnd={dateEnd}
          autoApply={width < 856}
          ranges={{
            ...DATE_RANGES_RFM(t)
          }}
          alwaysShowCalendars={false}
          showCustomRangeLabel={false}
          isRFM={true}
        />
      </div>}
    </div>
  );
}

export default RenderFilterByDefault;
