import { ShowDateRange } from "helpers/helpers";

function Legend(props) {
  const {
    startdate,
    enddate,
    compareDateEnd,
    compareDateStart,
    isShowCompared,
  } = props;

  return (
    <>
      <div className="hrv-report-xs-d-none hrv-report-lg-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-w-100 hrv-report-my-6">
        <div className="hrv-report-d-flex hrv-report-items-center">
          <div className="hrv-report-border-blue-custom"></div>
          <p className="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">
            {ShowDateRange(startdate, enddate)}
          </p>
        </div>

        {isShowCompared && (
          <div className="hrv-report-d-flex hrv-report-items-center hrv-report-ml-24">
            <div
              className="hrv-report-border-dashed-blue-custom"
              style={{ height: 1 }}
            ></div>

            <p className="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400">
              {ShowDateRange(compareDateStart, compareDateEnd)}
            </p>
          </div>
        )}
      </div>

      <div className="hrv-report-justify-center hrv-report-items-center hrv-report-mt-6 hrv-report-xs-d-flex hrv-report-lg-d-none">
        <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-mr-12">
          <div className="hrv-report-border-blue-custom"></div>
          <p className="hrv-report-mb-0 hrv-report-fs-12 hrv-report-xs-fs-14 hrv-report-fw-400">
            {ShowDateRange(startdate, enddate)}
          </p>
        </div>

        {isShowCompared && (
          <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center">
            <div
              className="hrv-report-border-dashed-blue-custom"
              style={{ height: 1 }}
            ></div>

            <p className="hrv-report-mb-0 hrv-report-fs-12 hrv-report-xs-fs-14 hrv-report-fw-400">
              {ShowDateRange(compareDateStart, compareDateEnd)}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default Legend;
