import { memo, useEffect, useState } from "react";
import { useTrans } from "locales/hook";
import { Box, Button } from "@chakra-ui/react";

import "./styles.scss";

function DashboardTopbar(props) {
  const t = useTrans();

  const { inConfigMode, onClickCancel, onClickSave, isLoadingSave } = props;

  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(inConfigMode);
    }, 100);
  }, [inConfigMode]);

  return (
    <div
      className={`hrv-report-dashboard-topbar ${animation ? "hrv-report-dashboard-topbar-show" : ""
        }`}
    >
      <div
        className="hrv-report-items-center hrv-report-justify-between hrv-report-d-flex"
        style={{
          width: "100%",
        }}
      >
        <Box className="hrv-report-fw-600 hrv-report-fs-16 hrv-report-text-color-gray-9" textStyle={"paragraph"}>
          {t("customizations_have_not_been_saved")}
        </Box>

        <div>
          <Button
            variant="default-default"
            className="hrv-report-mr-8"
            onClick={() => {
              onClickCancel && onClickCancel();
            }}
          >
            {t("cancel")}
          </Button>

          <Button
            variant="default-primary"
            isLoading={isLoadingSave}
            onClick={() => {
              onClickSave && onClickSave();
            }}
            className="user-tracker"
            ut-screen={"dashboard"}
            ut-name={'save_adjust'}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(DashboardTopbar);
