interface IProps {
  title: string;
  color: string;
  status: string;
}

function LegendChart(props: IProps) {
  const { title, color, status } = props;

  return (
    <div className="hrv-report-legend-mobile-item hrv-report-d-flex hrv-report-items-center">
      <div
        className="hrv-report-border-blue-custom"
        style={{
          backgroundColor: status === "line" ? color : "transparent",
          border: status === "dashed" ? `1px ${status} ${color}` : "unset",
        }}
      ></div>

      <p className="hrv-report-text-color-gray-9 hrv-report-mb-0 hrv-report-fs-12 hrv-report-fw-400">
        {title}
      </p>
    </div>
  );
}

export default LegendChart;
