import { memo } from 'react';

import { Checkbox } from '@chakra-ui/react'

import { useTrans } from "locales/hook";
import TooltipWithTouch from 'components/TooltipWithTouch';
import { limitString } from 'helpers/helpers';

function HRVCheckbox({
  title,
  checked,
  onChange,
  disabled,
  customTip,
  className,
  children,
  value,
  id,
  variant
}: any) {
  const t = useTrans();

  const onHandleChange = (value) => {
    onChange && onChange(value);
  };

  return (
    <Checkbox
      id={id}
      defaultChecked={checked}
      isChecked={checked}
      onChange={(event) => {
        const check = event.target.checked;

        onHandleChange({ check, name: title, id, value });
      }}
      isDisabled={disabled}
      className={`${className}`}
      variant={variant}
    >
      <>
        {children ? children : <div className="hrv-report-d-flex hrv-report-items-center">
          <span>{!title.includes(" ") ? limitString(t(title), 0, 40) : t(title)}</span>


          {customTip ? (
            <span className="hrv-report-ml-4">
              <TooltipWithTouch placement="bottom" label={customTip} hasArrow>
                {svgInfo}
              </TooltipWithTouch>
            </span>
          ) : (
            null
          )}
        </div>}
      </>
    </Checkbox>
  );
}

export default memo(HRVCheckbox);

const svgInfo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="info">
      <path
        id="Vector"
        d="M9.16699 5.83366H10.8337V7.50033H9.16699V5.83366ZM9.16699 9.16699H10.8337V14.167H9.16699V9.16699ZM10.0003 1.66699C5.40033 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.40033 18.3337 10.0003 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 10.0003 1.66699ZM10.0003 16.667C6.32533 16.667 3.33366 13.6753 3.33366 10.0003C3.33366 6.32533 6.32533 3.33366 10.0003 3.33366C13.6753 3.33366 16.667 6.32533 16.667 10.0003C16.667 13.6753 13.6753 16.667 10.0003 16.667Z"
        fill="#9CA3B0"
      />
    </g>
  </svg>
);
