// ! Hooks
import { memo, useEffect, useRef, useState } from "react";
import { Button, useOutsideClick } from "@chakra-ui/react";
import { useTrans } from "locales/hook";

// ! Components
import WrapperSpinner from "components/Spinner";
import VirtualizedContainer from "components/VirtualizedContainer";
import Popup from "components/HSearch/components/Popup";
import DiscountCard from "components/HSearch/components/DiscountCard";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import CouponRender from "./CouponRender";
import useDebouncedCallback from "hooks/useDebounceCallback";

type TProps = {
    placeholder: string;
    onChange: Function;
    isLoading: boolean;
    result: any[];
    discount: any;
    currentPage: number;
    size?: string;
    onSubmit?: Function
};

const LIMIT_VIRTUALIZED_ACTIVE = 20

function CouponApply(props: TProps) {
    const t = useTrans();

    const {
        placeholder,
        onChange,
        isLoading,
        result,
        discount,
        currentPage,
        size = "l",
        onSubmit
    } = props;

    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>([]);
    const clickRef = useRef<any>();
    const [keyword, setKeyword] = useState('');

    const handleChangeDebounced = (words) => {
        onChange && onChange(words, 1, true);
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

    useEffect(() => {
        discount.length && setSelectedItem(discount);
    }, [discount]);

    const isAtBottom = (e): boolean => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1;

        return bottom;
    };

    const emptyContent = () => {
        return <div className="hrv-report-text-center hrv-report-py-8 hrv-report-px-16">
            <p className="hrv-report-fs-14 hrv-report-fw-600 hrv-report-mb-4 hrv-report-text-color-black-6">{`${t(
                `Không tìm thấy kết quả ${keyword?.length ? "cho" : ""}`
            )} ${keyword?.length ? `"${keyword}"` : ""}`}</p>

            <p className="hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-color-gray-9 hrv-report-mb-0">
                {t("Vui lòng tìm kiếm khuyến mãi với từ khóa khác")}
            </p>
        </div>
    }

    const handleChangeCheckbox = (item) => {
        let selected = [...selectedItem];

        if (item.check) {
            const reItem = {
                query: item.name,
                symbol: "is",
                value: item.value
            }

            selected.push(reItem);
        } else {
            const itemIndex = selected.findIndex((y) => y['value'] === item['value']);

            if (itemIndex !== -1) {
                selected.splice(itemIndex, 1);
            }
        };

        setSelectedItem(selected);
    }

    const content = () => {
        const heightOfWrapper = 470;
        const heightOfButton = 64;
        const heightOfInputSearch = 48;
        const heightOfContentCheckbox = heightOfWrapper - heightOfButton - heightOfInputSearch - 8;

        return (
            <div
                className="hrv-report-discount-search hrv-report-scrollbar_custom hrv-report-py-8"
                style={{
                    maxWidth: "376px",
                    width: clickRef?.current?.clientWidth,
                    paddingRight: 4,
                    maxHeight: heightOfWrapper
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        height: heightOfInputSearch,
                        width: "100%",
                        background: "#fff",
                        borderBottom: "1px solid #f3f4f6",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px"
                    }}
                    className="hrv-report-p-8"
                >
                    <InputGroup>
                        <InputLeftElement h="full" w={10}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g id="search">
                                    <path id="Vector" d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill="#4B5563" />
                                </g>
                            </svg>
                        </InputLeftElement>

                        <Input
                            autoComplete={"off"}
                            size="m"
                            placeholder={t("search")}
                            pl={16}
                            value={keyword}
                            onChange={(e) => {
                                const key = e.target.value;

                                setKeyword(key);
                                debouncedCallback(key);
                            }}
                        />
                    </InputGroup>
                </div>

                <div style={{
                    marginTop: heightOfInputSearch,
                    marginBottom: heightOfButton
                }}>
                    {isLoading && currentPage !== 1 && (
                        <div
                            className="hrv-report-absolute hrv-report-d-flex hrv-report-items-center hrv-report-justify-center"
                            style={{
                                height: "100%",
                                width: "100%",
                                background: "rgba(255,255,255,0.7)",
                                borderRadius: "16px",
                                top: 0,
                                zIndex: 9
                            }}
                        >
                            <WrapperSpinner />
                        </div>
                    )}

                    {isLoading && currentPage === 1 ? (
                        <div
                            className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-px-8"
                            style={{
                                height: "200px",
                            }}
                        >
                            <WrapperSpinner />
                        </div>
                    ) : (
                        <VirtualizedContainer
                            onScroll={(event) => {
                                if (isAtBottom(event)) {
                                    const page = currentPage + 1;

                                    onChange && onChange(keyword, page);
                                }
                            }}
                            customClass="hrv-report-scrollbar_custom"
                            maxHeight={heightOfContentCheckbox}
                            list={result}
                            emptyContent={emptyContent}
                            limit={LIMIT_VIRTUALIZED_ACTIVE}
                            renderItems={(item) => {
                                const isChecked = selectedItem.map(y => y["value"]).includes(item.value);

                                return <DiscountCard
                                    isCheckbox={true}
                                    isChecked={isChecked}
                                    onCheck={handleChangeCheckbox}
                                    {...item}
                                />
                            }}
                        />
                    )}
                </div>

                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        height: heightOfButton,
                        width: "100%",
                        borderTop: "1px solid #E5E7EB",
                        background: "#fff",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }}
                    className="hrv-report-px-16 hrv-report-d-flex hrv-report-justify-between hrv-report-items-center"
                >
                    <Button variant="default-default"
                        onClick={() => {
                            handleReset()
                        }}
                        w={"50%"}
                        mr={8}
                    >
                        {t("cancelLabel")}
                    </Button>

                    <Button
                        variant="default-primary"
                        onClick={() => {
                            onSubmit && onSubmit(selectedItem);

                            setKeyword('');
                            handleChangeDebounced("")
                            setVisible(false);
                        }}
                        w={"50%"}
                    >
                        {t("applyLabel")}
                    </Button>
                </div>
            </div >
        );
    };

    const handleReset = () => {
        setSelectedItem(discount);
        setVisible(false);
    }

    const onClickOutside = () => {
        handleReset()
    };

    useOutsideClick(
        {
            ref: clickRef,
            handler: (e: any, ...restProps) => {
                onClickOutside()
            },
        }
    );

    const sizeClasses = {
        m: "hrv-report-custom_datepicker__sizeM",
        l: "hrv-report-custom_datepicker__sizeL"
    }

    return (
        <div ref={clickRef} className="hrv-report-relative" style={{
            width: "100%"
        }}>
            <div
                className={`
                    hrv-report-daterangepicker 
                    hrv-report-d-flex 
                    hrv-report-justify-between 
                    hrv-report-items-center
                    hrv-report-custom_datepicker
                    hrv-report-custom_applycoupon
                    ${visible ? "hrv-report-custom_datepicker__active" : ""}
                    ${sizeClasses[size]}
                `}
                onClick={() => {
                    setVisible(!visible);
                }}
            >
                {selectedItem.length ? (
                    <CouponRender coupons={selectedItem} width={clickRef.current.offsetWidth} />
                ) : (
                    <span>
                        <span className="hrv-report-mr-8 hrv-report-fs-14">
                            {placeholder}
                        </span>
                    </span>
                )}

                <span
                    className={`hrv-report-trail-icon ${visible ? "hrv-report-trail-icon__active" : ""}`}
                >
                    {arrowDownSvg}
                </span>
            </div>

            <Popup
                visible={visible}
            >
                {content()}
            </Popup>
        </div>
    );
}

export default memo(CouponApply);

const arrowDownSvg = (
    <svg
        width="12"
        height="8"
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.87461 1.00002L5.99461 4.88002L2.11461 1.00002C1.72461 0.61002 1.09461 0.61002 0.704609 1.00002C0.314609 1.39002 0.314609 2.02002 0.704609 2.41002L5.29461 7.00002C5.68461 7.39002 6.31461 7.39002 6.70461 7.00002L11.2946 2.41002C11.6846 2.02002 11.6846 1.39002 11.2946 1.00002C10.9046 0.62002 10.2646 0.61002 9.87461 1.00002Z"
            fill="#9CA3B0"
        />
    </svg>
);
