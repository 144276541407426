import { useEffect } from "react";

export const useDetectPage = (title: string) => {
  useEffect(() => {
    const _document: any = document;
    if (title !== '' && _document.user !== undefined &&  _document?.user?.OrgName !== undefined && _document?.user?.OrgName !== '') {
      document.title = `${_document.user.OrgName} ~ ${title}`;
      pushToDataLayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};

const pushToDataLayer = (): void => {
  const dataLayer = (window as any).dataLayer;
  if (Array.isArray(dataLayer)) {
    dataLayer.push({
      event: "page_view",
    });
  }
};