import React, { useEffect, useState } from 'react';
import dayjs from 'components/Dayjs';

import { Box, Button, Textarea } from '@chakra-ui/react';
import Modal from 'components/Modal';

import { useTrans } from "locales/hook";
import useApp from 'hooks/useApp';
import useFeedback from 'hooks/useFeedback';

import { create } from 'firebase/config';

import { formatDate } from 'helpers/helpers';

import "./styles.scss";

interface IProps {
    type: 'star' | "emotion"
    classNames?: string;
    visible?: boolean;
    reportName: string;
    reportId: string | number;
}

const HModal: any = Modal;

export const Rating: React.FC<IProps> = (props: IProps) => {
    const { type, visible = true, reportName, reportId } = props;
    const t = useTrans();
    const { shopInfo, user } = useApp();

    const [value, setValue] = useState(2);
    const [comment, setComment] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleDone, setIsVisibleDone] = useState(false);
    const { fetchApiFeedback } = useFeedback(reportId);

    // * Delay 30s sau sẽ hiện 
    const TIMING = 30 * 1000;

    useEffect(() => {
        let timer: any = null;

        if (visible) {
            setTimeout(() => setIsVisible(true), TIMING);
        }

        return () => {
            timer !== null && clearTimeout(timer);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])


    const RATING_EMOTION = [
        {
            id: 1,
            emotionIcon: EmotionSadSvg,
            value: 1,
        },
        {
            id: 2,
            emotionIcon: EmotionNormalSvg,
            value: 2,
        },
        {
            id: 3,
            emotionIcon: EmotionHappySvg,
            value: 3,
        }
    ]

    const headerContentRating = () => {
        return (
            <div className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-between">
                <Box textStyle="h4">
                    {t("Are_you_satisfied_with_this_application")}
                </Box>
            </div>
        );
    };

    const bodyContentRating = () => {
        return (
            <div
                className="hrv-report-d-flex hrv-report-items-center hrv-report-flex-col hrv-report-fs-14 hrv-report-mb-0"
            >
                <div className={`hrv-report-d-flex hrv-report-justify-between hrv-report-mb-20 hrv-report-w-70 hrv-report-mx-auto`}>
                    {type === "emotion" && <>
                        {RATING_EMOTION.map((emo, idx) => {
                            return <div key={`${idx}`} style={{
                                width: 72,
                                height: 72,
                                cursor: "pointer",
                                borderRadius: "50%",
                                background: value === emo.value ? '#F0F6FF' : "transparent",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                transition: "all 0.3s"
                            }}
                                className={`${value === emo.value ? "hrv-report-rating__active" : ""}`}
                                onClick={() => {
                                    setValue(emo.value)
                                }}
                            >
                                {emo.emotionIcon}
                            </div>
                        })}
                    </>}
                </div>

                <Textarea
                    placeholder={t("Is_this_application_useful_for_you")}
                    variant={"base"}
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                />
            </div>
        );
    };

    const footerContentRating = () => {
        return (
            <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                <span></span>

                <div className="hrv-report-d-flex">

                    <Button
                        onClick={() => { submit() }}
                        variant="default-primary"
                    >
                        {t("send")}
                    </Button>
                </div>
            </div>
        );
    };

    const headerContentThankyou = () => {
        return (
            <div className="hrv-report-text-center">
                <Box textStyle="h4">
                    {t("Thank_for_your_contribution")}
                </Box>
            </div>
        );
    }

    const bodyContentThankyou = () => {
        return (
            <div className="hrv-report-text-center">
                <Box textStyle="paragraph">
                    {t("Haravan_acknowledges_all_your_contributions")}
                </Box>
            </div>
        );
    }

    const footerContentThankyou = () => {
        return (
            <div style={{
                width: "100%"
            }}>
                <Button
                    variant={"outline-default"}
                    w={'100%'}
                    onClick={() => {
                        setIsVisibleDone(false);
                    }}
                >
                    {t("Đóng")}
                </Button>
            </div>
        );
    }

    const convertRating = (value) => {
        const rate = {
            1: "Không tốt",
            2: "Bình thường",
            3: "Rất hài lòng"
        };

        return rate[value]
    }

    const submit = () => {
        create("report-rating", {
            orgId: String(shopInfo.data.shop.orgId) || "",
            buyerDomain: shopInfo.data.shop.buyerDomain || '',
            email: user.email || "",
            userId: String(user.userId) || "",
            rating: convertRating(value),
            comment,
            reportName,
            reportId,
            createAt: formatDate(dayjs(), "DD/MM/YYYY HH:mm"),
            timestamp: String(Date.now())
        })

        fetchApiFeedback();
        setIsVisible(false);
        setIsVisibleDone(true)
    }

    const close = () => {
        create("report-rating", {
            orgId: String(shopInfo.data.shop.orgId) || "",
            buyerDomain: shopInfo.data.shop.buyerDomain || '',
            email: user.email || "",
            userId: String(user.userId) || "",
            rating: "Chưa đánh giá",
            comment: "",
            reportName,
            reportId,
            createAt: formatDate(dayjs(), "DD/MM/YYYY HH:mm"),
            timestamp: String(Date.now())
        });

        fetchApiFeedback();

        setIsVisible(false);
    }

    return (
        <>
            <HModal
                renderHeader={headerContentRating}
                renderBody={bodyContentRating}
                renderFooter={footerContentRating}
                onClose={() => {
                    close()
                }}
                visible={isVisible}
                closable={true}
                isCentered
                variant="rating"
                size="sm"
                maskClosable={false}
            ></HModal>

            <HModal
                renderHeader={headerContentThankyou}
                renderBody={bodyContentThankyou}
                renderFooter={footerContentThankyou}
                onClose={() => {
                    setIsVisibleDone(false)
                }}
                visible={isVisibleDone}
                closable={false}
                isCentered
                variant="rating"
                size="sm"
            ></HModal>
        </>

    )
}

const EmotionSadSvg = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M25.8333 18.3333C27.214 18.3333 28.3333 17.214 28.3333 15.8333C28.3333 14.4525 27.214 13.3333 25.8333 13.3333C24.4525 13.3333 23.3333 14.4525 23.3333 15.8333C23.3333 17.214 24.4525 18.3333 25.8333 18.3333Z" fill="#9CA3B0" />
    <path d="M14.1666 18.3333C15.5473 18.3333 16.6666 17.214 16.6666 15.8333C16.6666 14.4525 15.5473 13.3333 14.1666 13.3333C12.7859 13.3333 11.6666 14.4525 11.6666 15.8333C11.6666 17.214 12.7859 18.3333 14.1666 18.3333Z" fill="#9CA3B0" />
    <path d="M19.9999 23.3333C16.1166 23.3333 12.7999 25.7499 11.4666 29.1666H14.2499C15.3999 27.1833 17.5333 25.8333 19.9999 25.8333C22.4666 25.8333 24.5833 27.1833 25.7499 29.1666H28.5333C27.1999 25.7499 23.8833 23.3333 19.9999 23.3333ZM19.9833 3.33325C10.7833 3.33325 3.33325 10.7999 3.33325 19.9999C3.33325 29.1999 10.7833 36.6666 19.9833 36.6666C29.1999 36.6666 36.6666 29.1999 36.6666 19.9999C36.6666 10.7999 29.1999 3.33325 19.9833 3.33325ZM19.9999 33.3333C12.6333 33.3333 6.66659 27.3666 6.66659 19.9999C6.66659 12.6333 12.6333 6.66659 19.9999 6.66659C27.3666 6.66659 33.3333 12.6333 33.3333 19.9999C33.3333 27.3666 27.3666 33.3333 19.9999 33.3333Z" fill="#9CA3B0" />
</svg>

const EmotionNormalSvg = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M14.9999 23.3333H24.9999V25.8333H14.9999V23.3333Z" fill="#9CA3B0" />
    <path d="M25.8333 18.3333C27.214 18.3333 28.3333 17.214 28.3333 15.8333C28.3333 14.4525 27.214 13.3333 25.8333 13.3333C24.4525 13.3333 23.3333 14.4525 23.3333 15.8333C23.3333 17.214 24.4525 18.3333 25.8333 18.3333Z" fill="#9CA3B0" />
    <path d="M14.1666 18.3333C15.5473 18.3333 16.6666 17.214 16.6666 15.8333C16.6666 14.4525 15.5473 13.3333 14.1666 13.3333C12.7859 13.3333 11.6666 14.4525 11.6666 15.8333C11.6666 17.214 12.7859 18.3333 14.1666 18.3333Z" fill="#9CA3B0" />
    <path d="M19.9833 3.33325C10.7833 3.33325 3.33325 10.7999 3.33325 19.9999C3.33325 29.1999 10.7833 36.6666 19.9833 36.6666C29.1999 36.6666 36.6666 29.1999 36.6666 19.9999C36.6666 10.7999 29.1999 3.33325 19.9833 3.33325ZM19.9999 33.3333C12.6333 33.3333 6.66659 27.3666 6.66659 19.9999C6.66659 12.6333 12.6333 6.66659 19.9999 6.66659C27.3666 6.66659 33.3333 12.6333 33.3333 19.9999C33.3333 27.3666 27.3666 33.3333 19.9999 33.3333Z" fill="#9CA3B0" />
</svg>

const EmotionHappySvg = <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path d="M25.8333 18.3333C27.214 18.3333 28.3333 17.214 28.3333 15.8333C28.3333 14.4525 27.214 13.3333 25.8333 13.3333C24.4525 13.3333 23.3333 14.4525 23.3333 15.8333C23.3333 17.214 24.4525 18.3333 25.8333 18.3333Z" fill="#9CA3B0" />
    <path d="M14.1666 18.3333C15.5473 18.3333 16.6666 17.214 16.6666 15.8333C16.6666 14.4525 15.5473 13.3333 14.1666 13.3333C12.7859 13.3333 11.6666 14.4525 11.6666 15.8333C11.6666 17.214 12.7859 18.3333 14.1666 18.3333Z" fill="#9CA3B0" />
    <path d="M19.9999 26.6666C17.5333 26.6666 15.4166 25.3166 14.2499 23.3333H11.4666C12.7999 26.7499 16.1166 29.1666 19.9999 29.1666C23.8833 29.1666 27.1999 26.7499 28.5333 23.3333H25.7499C24.5999 25.3166 22.4666 26.6666 19.9999 26.6666ZM19.9833 3.33325C10.7833 3.33325 3.33325 10.7999 3.33325 19.9999C3.33325 29.1999 10.7833 36.6666 19.9833 36.6666C29.1999 36.6666 36.6666 29.1999 36.6666 19.9999C36.6666 10.7999 29.1999 3.33325 19.9833 3.33325ZM19.9999 33.3333C12.6333 33.3333 6.66659 27.3666 6.66659 19.9999C6.66659 12.6333 12.6333 6.66659 19.9999 6.66659C27.3666 6.66659 33.3333 12.6333 33.3333 19.9999C33.3333 27.3666 27.3666 33.3333 19.9999 33.3333Z" fill="#9CA3B0" />
</svg>