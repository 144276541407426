import { useEffect, useRef, useState } from "react";

import Popup from "./components/Popup";
import Counting from "./components/Counting";
import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import WrapperSpinner from "components/Spinner";
import HRVCheckbox from "components/Checkbox";
import RenderTags from "./components/Render";

import useDebouncedCallback from "hooks/useDebounceCallback";
import { useClickOutside } from "hooks/useClickOutside";
import { useTrans } from "locales/hook";

import { capitalizeFirstLetter, slugify } from "helpers/helpers";

import "./styles.scss";

function HCheckbox(props: any) {
  const {
    isMultiple,
    valueName,
    name,
    options,
    defaultValue,
    showButtonAll,
    onChange,
    nameField,
    useEmptyForGetAll,
    isLoading,
    setDimension,
    fullWidth,
    isShowNameField,
    flexibleHeight,
    showApplyButton,
    widthFixed,
    isShowIcon = false,
    isDisabled = false,
    showSearch = false,
    size = 'l',
    multipleType = 'tag'// hiển thị layout cho checkbox multiple
  } = props;

  const t = useTrans();
  const clickRef = useRef<any>(null);

  const [visible, setVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [keyword, setKeyword] = useState("")
  const [displayOptions, setDisplayOptions] = useState<Array<any>>([]);

  useEffect(() => {
    if (typeof options !== "undefined" && options.length) {
      setDisplayOptions(options);
      sortedCheckedValue()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (typeof options !== "undefined" && options.length) {
      sortedCheckedValue()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    setSelectedItem(defaultValue);
  }, [defaultValue]);

  const handleChangeCheckbox = (check, item) => {
    let selected = [...selectedItem];

    if (check) {
      selected.push(item[valueName]);
    } else {
      const itemIndex = selected.findIndex((y) => y === item[valueName]);

      if (itemIndex !== -1) {
        selected.splice(itemIndex, 1);
      }
    }

    setSelectedItem(selected);

    if (showApplyButton) return;

    onChange && onChange(selected);
  };

  const handleChangeCheckboxAll = (check) => {
    if (check) {
      const valueOptions = displayOptions?.map((y) => y[valueName]);

      setSelectedItem([...valueOptions]);
      if (showApplyButton) return;

      onChange && onChange([...valueOptions]);
    } else {
      setSelectedItem([]);

      if (showApplyButton) return;
      onChange && onChange([]);
    }
  };

  const handleSingleClick = (value) => {
    if (showApplyButton) return;
    onChange && onChange(value);
  };

  const handleChangeDebounced = (words) => {
    let result = [];

    result = options.filter((y) => {
      if (y[name] && y[name].length && typeof words !== 'undefined') {
        return slugify(y[name]?.toLowerCase()).includes(
          slugify(words?.toLowerCase())
        );
      } else {
        return y
      }
    });

    setDisplayOptions(result);
  };

  const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

  const layoutMultipleNameField = () => {
    const getDefaultList = selectedItem.map((id) => {
      return options.find(y => y.id === id)
    });

    return <RenderTags
      list={getDefaultList}
      width={300}
      filterName={nameField}
      useEmptyForGetAll={useEmptyForGetAll}
      isAll={selectedItem?.length === options?.length}
    />
  }

  const layoutMultipleWithCount = () => {
    return <>
      <div className="hrv-report-mr-8">
        {selectedItem.length === displayOptions.length ? (
          <p className="hrv-report-m-0 hrv-report-fs-14 hrv-report-text-color-black-6" >
            {t("Tất cả")} {nameField}
          </p>
        ) : (
          <p className={`${isDisabled ? "hrv-report-text-color-gray-12" : "hrv-report-text-color-black-6"} hrv-report-m-0 hrv-report-fs-14  hrv-report-text`}>
            {useEmptyForGetAll && !selectedItem.length
              ? `${t("Tất cả")} ${nameField}`
              : !selectedItem.length
                ? `${t("Chưa chọn")} ${nameField}`
                : capitalizeFirstLetter(nameField)}
          </p>
        )}
      </div>

      {selectedItem.length ? (
        <Counting count={selectedItem.length} />
      ) : ""}
    </>
  }

  const content = () => {
    const heightCheckAll = showButtonAll ? 48 : 0;
    const heightOfButton = showApplyButton ? 64 : 0;
    const heightOfSearch = showSearch ? 48 : 0;
    const heightOfWrapper = 400;
    const heightEmptyContent = 40;
    const heightOfContentCheckbox = heightOfWrapper - heightOfButton - heightCheckAll - heightOfSearch;
    const widthOfWrapper = widthFixed ? widthFixed : 243;

    return (
      <div
        className="hrv-report-scrollbar_custom"
        style={{
          width: widthOfWrapper,
          minWidth: widthOfWrapper,
          maxWidth: 375,
          maxHeight: heightOfWrapper,
          height: `${(displayOptions.length ? displayOptions.length * 33 : heightEmptyContent) + heightCheckAll + heightOfButton + heightOfSearch + 16 + 8}px`,
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <>
          {isMultiple ? (
            <>
              <div
                style={{
                  overflowX: "hidden",
                }}
                className="hrv-report-scrollbar_custom"
              >
                {showSearch && <div
                  style={{
                    overflowY: "auto",
                    width: "100%",
                    height: heightOfSearch,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16,
                    paddingRight: '8px',
                    paddingLeft: '8px',
                    paddingTop: '4px',
                    borderBottom: "1px solid #e5e7eb",
                  }}
                >
                  <InputGroup mt={2}>
                    <InputLeftElement h="full" w={10}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g id="search">
                          <path id="Vector" d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill="#4B5563" />
                        </g>
                      </svg>
                    </InputLeftElement>

                    <Input
                      autoComplete={"off"}
                      size="m"
                      placeholder={t("search")}
                      pl={16}
                      value={keyword}
                      onChange={(e) => {
                        const key = e.target.value;

                        setKeyword(key);
                        debouncedCallback(key);
                      }}
                    />
                  </InputGroup>
                </div>}

                {showButtonAll && (
                  <div
                    style={{
                      overflowY: "auto",
                      width: "100%",
                      top: heightOfSearch ? heightOfSearch : 0,
                      left: 0,
                      height: heightCheckAll,
                      borderTopLeftRadius: heightOfSearch ? 0 : 16,
                      borderTopRightRadius: heightOfSearch ? 0 : 16,
                      paddingRight: '8px',
                      paddingLeft: '8px',
                      paddingTop: '4px',
                      borderBottom: "1px solid #e5e7eb",
                    }}
                  >
                    <HRVCheckbox
                      className={`hrv-report-selection_box-item ${selectedItem.length === options?.length
                        ? "hrv-report-selection_box-item__active"
                        : ""
                        }`}
                      onChange={(value) => handleChangeCheckboxAll(value.check)}
                      checked={selectedItem.length === options?.length}
                      title={`${t("Tất cả")}`}
                      disable={isDisabled}
                    >
                    </HRVCheckbox>


                  </div>
                )}

                <div
                  style={{
                    height: `${(displayOptions.length ? displayOptions.length * 33 : heightEmptyContent) + 16 + 8}px`,
                    maxHeight: heightOfContentCheckbox,
                    overflowY: "auto",
                    padding: "6px 8px"
                  }}
                >
                  {displayOptions.length ? displayOptions.map((item, idx) => {
                    const isActive = selectedItem.some(
                      (value) => value === item[valueName],
                    );

                    return (
                      <HRVCheckbox
                        className={`hrv-report-selection_box-item ${isActive ? "hrv-report-selection_box-item__active" : ""
                          }`}
                        key={idx}
                        title={item[name]}
                        checked={isActive}
                        onChange={(value) => {
                          handleChangeCheckbox(value.check, item)
                        }}
                        disable={isDisabled}
                      >
                        <div className="hrv-report-d-flex">
                          {isShowIcon && (
                            <span
                              className="hrv-report-mr-16 hrv-report-ml-8"
                              style={{
                                width: 18,
                                height: 18,
                                display: "flex",
                              }}
                            >
                              <img src={item.icon} alt="icon" />
                            </span>
                          )}

                          <span
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              textOverflow: "ellipsis",
                              WebkitBoxOrient: "vertical",
                              maxHeight: 23,
                              overflow: "hidden",
                              width: `${isShowIcon ? "calc(100% - 34px)" : "unset"
                                }`,
                            }}
                          >
                            {t(item[name])}
                          </span>
                        </div>
                      </HRVCheckbox>
                    );
                  }) : <div className="hrv-report-d-flex hrv-report-items-center hrv-report-w-100" style={{
                    height: 'auto'
                  }}>
                    <p className="hrv-report-w-100 hrv-report-text-center hrv-report-mt-15">
                      {t("no_data")}
                    </p>
                  </div>}
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                padding: "8px",
                minWidth: widthOfWrapper,
                maxHeight: heightOfWrapper - heightOfButton,
                overflowY: "auto",
              }}
            >
              {showButtonAll && (
                <div
                  className={`hrv-report-single-checkbox ${!selectedItem.length
                    ? "hrv-report-single-checkbox_active"
                    : ""
                    }`}
                  onClick={() => {
                    handleSingleClick({
                      displayname_vi: "Tất cả kho bán",
                      id: "",
                      name: "Tất cả kho bán",
                      specialInfo: null,
                      value: "",
                    });
                    setVisible(false);
                  }}
                >
                  <p className="hrv-report-mb-0">
                    {t("Tất cả")} {nameField}
                  </p>
                </div>
              )}

              {displayOptions.map((item, idx) => {
                const isActive = selectedItem.some(
                  (value) => value === item[valueName],
                );

                return (
                  <div
                    key={idx}
                    className={`hrv-report-single-checkbox ${isActive ? "hrv-report-single-checkbox_active" : ""
                      }`}
                    onClick={() => {
                      handleSingleClick(item);
                      setVisible(false);
                    }}
                  >
                    {isShowIcon && (
                      <span
                        className="hrv-report-mr-16 hrv-report-ml-8"
                        style={{
                          width: 18,
                          height: 18,
                          display: "flex",
                        }}
                      >
                        <img src={item.icon} alt="icon" />
                      </span>
                    )}

                    <p
                      className="hrv-report-mb-0"
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        WebkitBoxOrient: "vertical",
                        maxHeight: 23,
                        overflow: "hidden",
                        width: `${isShowIcon ? "calc(100% - 34px)" : "unset"}`,
                      }}
                    >
                      {t(item[name])}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </>

        {showApplyButton && (
          <div
            style={{
              height: heightOfButton,
              width: "100%",
              borderTop: "1px solid #E5E7EB",
            }}
            className="hrv-report-px-16 hrv-report-d-flex hrv-report-justify-between hrv-report-items-center"
          >
            <Button variant="default-default" onClick={() => {
              setSelectedItem(defaultValue);
              setVisible(false);
            }} w={"50%"} mr={8}>
              {t("cancelLabel")}
            </Button>

            <Button
              variant="default-primary"
              onClick={() => {
                onChange && onChange(selectedItem);
                setVisible(false);

                // ! Auto sort checkbox đã chọn lên trên
                sortedCheckedValue();
              }}
              w={"50%"}
            >
              {t("applyLabel")}
            </Button>

          </div>
        )}
      </div >
    );
  };

  const sortedCheckedValue = () => {
    const itemClone = [...options];
    let result = itemClone.sort((a, b) => {
      if (selectedItem.includes(a[valueName])) {
        return -1;
      } else {
        return 1;
      }
    });

    setDisplayOptions(result);
  }

  const onClickOutside = () => {
    setVisible(false);

    if (showApplyButton) {
      setSelectedItem(defaultValue);
    }
  };

  const getSingleDataXML = () => {
    let selected = selectedItem[0] || "";
    let findItem = options?.find((y) => y[valueName] === selected);
    let result = "";
    if (findItem && typeof findItem !== "undefined") {
      result = findItem[name] || "";
    }

    return t(result);
  };

  useClickOutside(clickRef, onClickOutside);

  const sizeClasses = {
    m: "hrv-report-selection_box__sizeM",
    l: 'hrv-report-selection_box__sizeL'
  };

  return (
    <div
      ref={clickRef}
      className="hrv-report-relative"
      style={{
        width: fullWidth ? "100%" : "max-content",
      }}
    >
      {isLoading && displayOptions?.length ? (
        <div className="hrv-report-ml-12">
          <WrapperSpinner />
        </div>
      ) : (
        <div
          onClick={() => {
            if (isDisabled) {
              return
            }

            setVisible(!visible);

            setDimension && setDimension();
          }}
          className={`
            hrv-report-selection_box 
            ${sizeClasses[size]}
            ${fullWidth ? "hrv-report-d-flex hrv-report-justify-between" : ""} 
            ${visible ? "hrv-report-selection_box__active" : ""} 
            ${isDisabled ? "hrv-report-selection_box__disabled" : ""}`
          }
          style={{
            maxHeight: flexibleHeight,
          }}
        >
          <div className="hrv-report-d-flex hrv-report-items-center">
            {isMultiple ? (
              <>
                {multipleType === "tag" && layoutMultipleWithCount()}
                {multipleType === "label" && layoutMultipleNameField()}
              </>

            ) : (
              <>
                <div className="hrv-report-d-flex">
                  {isShowNameField && nameField && nameField.length ? (
                    <p className="hrv-report-m-0 hrv-report-mr-4 hrv-report-fs-14 hrv-report-text-color-gray-12">
                      {`${capitalizeFirstLetter(nameField)}:`}
                    </p>
                  ) : (
                    ""
                  )}

                  <p className="hrv-report-m-0 hrv-report-fs-14 hrv-report-text-color-black-6">
                    {getSingleDataXML()}
                  </p>
                </div>

                <p className="hrv-report-m-0 hrv-report-fs-14 hrv-report-text-color-black-6">
                  {!selectedItem.length ? `${t("Tất cả")} ${nameField}` : ""}
                </p>
              </>
            )}
          </div>

          <span
            className={`hrv-report-ml-6 hrv-report-trail-icon ${visible ? "hrv-report-trail-icon__active" : ""
              }`}
          >
            {svgTrail}
          </span>
        </div>
      )}

      <Popup visible={visible}>{content()}</Popup>
    </div>
  );
}

export default HCheckbox;

const svgTrail = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87461 1.00002L5.99461 4.88002L2.11461 1.00002C1.72461 0.61002 1.09461 0.61002 0.704609 1.00002C0.314609 1.39002 0.314609 2.02002 0.704609 2.41002L5.29461 7.00002C5.68461 7.39002 6.31461 7.39002 6.70461 7.00002L11.2946 2.41002C11.6846 2.02002 11.6846 1.39002 11.2946 1.00002C10.9046 0.62002 10.2646 0.61002 9.87461 1.00002Z"
      fill="#9CA3B0"
    />
  </svg>
);