// ! Hooks
import React, { forwardRef, useRef } from "react";
import { useTrans } from "locales/hook";
import { useTableUtils } from "helpers/tableutils";

// ! Components
import EmptyTemplate from "components/EmptyTemplate";
import HeaderTableDefault from "./header";
import BodyTableDefault from "./body";
import { useGroupTable } from "pages/ReportStandard/context/store/constants";


export const TablePrint = forwardRef((props: any, ref: any) => {
    const t = useTrans();
    const containerRef = useRef(null);
    const tableRef = useRef<any>(null);

    const {
        data,
        reportNameSystem,
        canReadMACost,
        headers,
        sortValue,
        summary,
        groupBySelected,
        dataLink,
        isLoadingTable,
        onHandleSortTable,
        dependdata
    } = props;

    const {
        dataHeaders,
        dataRows,
        isReportPNL,
        sortable,
        headingRows,
        subHeadingRows,
        displaySummary
    } = useTableUtils(headers, data, reportNameSystem, groupBySelected);

    React.useEffect(() => {
        const { current }: any = containerRef;
        current?.removeEventListener("scroll", handleTableScroll);
        current?.addEventListener("scroll", handleTableScroll);
        return () => {
            current?.removeEventListener("scroll", handleTableScroll);
        };
    }, [containerRef, data]);

    const handleTableScroll = (e) => {
        let table = e.target.getElementsByTagName("table")[0];
        if (e.target.scrollLeft > 0) {
            table.classList.add("hrv-report-table-container__scrolling");
        } else {
            table.classList.remove("hrv-report-table-container__scrolling");
        }
    };

    return (
        <div
            id="hrv-report-table-container"
            style={{
                borderLeft: 0,
                borderRight: 0,
            }}
        >
            <div
                ref={tableRef}
            >
                {data !== undefined && data?.length > 0 ? (
                    <> <div
                        className={`hrv-report-table-container hrv-report-relative`}
                        ref={containerRef}
                    >
                        <table
                            className={`${useGroupTable.includes(reportNameSystem)
                                ? "hrv-report-table-bordered"
                                : ""
                                }`}
                        >
                            <HeaderTableDefault
                                reportNameSystem={reportNameSystem}
                                canReadMACost={canReadMACost}
                                dataHeaders={dataHeaders}
                                sortValue={sortValue}
                                sortable={sortable}
                                onHandleSortTable={onHandleSortTable}
                            />

                            <BodyTableDefault
                                summary={summary}
                                dataRows={dataRows}
                                headingRows={headingRows}
                                canReadMACost={canReadMACost}
                                headers={headers}
                                subHeadingRows={subHeadingRows}
                                dataLink={dataLink}
                                isReportPNL={isReportPNL}
                                reportNameSystem={reportNameSystem}
                                displaySummary={displaySummary}
                                dependdata={dependdata}
                            />
                        </table>
                    </div>
                    </>
                ) : (
                    !isLoadingTable &&
                    data?.length === 0 && (
                        <EmptyTemplate
                            text={t("no_data")}
                            subText={t("change_fitler_hint")}
                        />
                    )
                )}
            </div>
        </div>
    );
});