import { memo } from "react";
// import { useParams } from "react-router-dom";
import { useStore } from "../../context/store";
import useHistory from "hooks/useHistory";
import useApp from "hooks/useApp";

import { ReportListPopular, ReportListAll } from "..";
import ButtonSupport from "components/ButtonSupport";

import {
  buildQueryString,
  // isOpenForFeaturesPackage
} from "helpers/helpers";
import { useTrans } from "locales/hook";
import { useDetectPage } from "hooks/useDetectPage";
// import useTour, { TypeTour } from "hooks/useTour";

const REPORT_DISCOUNT = ["sales_promotion"]; // Các báo cáo liên quan khuyến mãi

const Main = () => {
  // const { name }: any = useParams();
  const {
    // shopFeatures,
    // betaFeatures,
    updateBreadcrumb,
    setMenuActive
  }: any = useApp();
  const t = useTrans();
  const { push } = useHistory();

  const { state } = useStore();

  // const ONBOARDING_TOUR = [
  //   {
  //     tourName: "exportId_tour",
  //     target: ".hrv-report-tour-by-exportId",
  //     position: EPosition.TopLeft,
  //     steps: [
  //       {
  //         content: t('exportId_tour_description'),
  //       },
  //     ],
  //     iframeURL: "https://demo.arcade.software/Hag3a7vSc4eTgzAoMsni?embed"
  //   }
  // ]

  const {
    isFavorite,
    reportTabSelected,
    searchReportName,
    authors,
    // limitFeatures
  } = state;

  useDetectPage(t("all_reports"));
  updateBreadcrumb(t("all_reports"), t("report"), "/listing");

  setMenuActive({
    label: "Danh sách báo cáo",
    destination: '/listing'
  });

  // const { data, postTourDone } = useTour(TypeTour.introduce);

  // // ! Chặn gói Shipments
  // const useEffectPreventGoToConfigFeature = (effect: EffectCallback) => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   return useEffect(effect, [name, shopFeatures]);
  // };

  // useEffectPreventGoToConfigFeature(() => {
  //   if (shopFeatures?.length) {
  //     const isActivePackage = isOpenForFeaturesPackage(
  //       ["pcode_addon_smart_fulfillment_072023", "addonfulfillment"],
  //       shopFeatures,
  //     );

  //     if (!isActivePackage) {
  //       limitFeatures.push("shipments");
  //       dispatch(actions.setLimitFeatures(limitFeatures));
  //     }

  //     if (name === "shipments" && !isActivePackage) {
  //       push(`/dashboard`);
  //     };

  //   }
  // });

  const onClickHandler = (tab, id, reportNameSystem) => {
    const queryParam = buildQueryString({
      ...(reportTabSelected.length && { type: reportTabSelected }),
      ...(searchReportName.length && { s: searchReportName }),
      isFavorite: isFavorite ? 1 : 0,
      authors
    });

    if (REPORT_DISCOUNT.includes(reportNameSystem)) {
      push(`/sales/promotion/${id}${queryParam}`)
    } else {
      push(`/${tab}/${id}${queryParam}`)
    }
  };

  return (
    <div className="">
      <div className="hrv-report-row">
        <div className="hrv-report-col-12">
          <ReportListPopular onClickDetail={onClickHandler} />

          <ReportListAll onClickDetail={onClickHandler} />
        </div>
      </div>

      <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-py-52">
        <ButtonSupport
          url={
            "https://support.haravan.com/support/solutions/articles/42000081037-t%E1%BB%95ng-quan-v%E1%BB%81-b%C3%A1o-c%C3%A1o"
          }
          title={`${t(`support_about_report`)}`}
        />
      </div>

      <div className="hrv-report-tour-by-exportId" style={{
        position: 'fixed',
        right: 10,
        bottom: 140
      }}></div>
    </div>
  );
};

export default memo(Main);