import { useTrans } from "@haravan/reactapp"
import { Button } from "@chakra-ui/react"
import { useGTM } from "hooks/useGTM";

function ButtonFilter(props) {
    const { isDisplay, onClick } = props
    const t = useTrans();
    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "detail",
            "ut-name": 'filter'
        }
    });

    const handleClick = () => {
        onClick && onClick()
    }

    return (
        <>
            {isDisplay ? <Button
                variant="default-default"
                leftIcon={svgFilter}
                onClick={handleClick}
                iconSpacing={0}
                className={`hrv-report-tour-in-filter ${detectClass}`}
                {...attributes}
            >
                {t("filter_tour")}
            </Button> : ""}
        </>

    )
}

export default ButtonFilter


// ! SVG Imports
const svgFilter = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.33333 15H11.6667V13.3333H8.33333V15ZM2.5 5V6.66667H17.5V5H2.5ZM5 10.8333H15V9.16667H5V10.8333Z"
            fill="#4B5563"
        />
    </svg>
);
