import { useEffect, useRef, useState } from "react";

import { Input } from "@chakra-ui/react";

function HEditable(props) {
    const { isEdit, className, placeholder, value, onHandleClickOutside, onHandleClickInText, onValidate, errorMgs } = props;
    const [text, setText] = useState(value);
    const [mode, setMode] = useState(isEdit);
    const [width, setWidth] = useState(0);
    const span: any = useRef();

    const handleChangeText = (value) => {
        setText(value)
    };

    useEffect(() => {
        const getWidth = span?.current?.getBoundingClientRect().width

        setWidth(getWidth);
    }, [text, value]);

    useEffect(() => {
        setText(value);
    }, [value]);


    return (
        <>
            {mode ? (
                <div className={className} style={{
                    maxWidth: "100%"
                }}>
                    <span style={{
                        position: "fixed",
                        fontWeight: 600,
                        fontSize: 18,
                        padding: "8px",
                        opacity: 0,
                        overflow: "hidden",
                        height: 0,
                        maxWidth: "55vw"
                    }} ref={span}
                        content={text}
                        className="textSpan"
                    >
                    </span>

                    <Input
                        size={"l"}
                        placeholder={placeholder}
                        value={text}
                        onChange={(event) => {
                            const searchKey = event.target.value;

                            handleChangeText(searchKey);
                            onValidate && onValidate(searchKey);
                        }}
                        onBlur={() => {
                            // ! Check valid Input
                            if (!errorMgs.length) {
                                onHandleClickOutside && onHandleClickOutside(text);

                                setMode(false);
                            }
                        }}
                        style={{
                            fontWeight: 600,
                            fontSize: 18,
                            minWidth: `${width}px`,
                            width: `${width}px`,
                            position: "relative",
                            maxWidth: "100%"
                        }}
                        variant="autocomplete"
                        className={`${errorMgs.length ? "has-error" : ""}`} />

                </div>
            ) : (
                <div className={`hrv-report-d-flex hrv-report-items-center hrv-report-dashboard-name ${className}`} style={{
                    width: "fit-content",
                    maxWidth: "55vw"
                }} onClick={() => {
                    onHandleClickInText && onHandleClickInText();

                    setMode(true);
                    handleChangeText(value);
                }}>
                    <span
                        className="hrv-report-mb-0 hrv-report-fs-18 hrv-report-fw-600 hrv-report-text-color-black-6"
                        style={{
                            lineHeight: "40px",
                            overflow: 'hidden',
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            textOverflow: "ellipsis",
                            WebkitBoxOrient: "vertical",
                        }}
                        ref={span}
                    >
                        {text}
                    </span>

                    <span className="hrv-report-ml-8">{svgEdit}</span>
                </div >
            )
            }

        </>
    )
}

export default HEditable;

const svgEdit = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
    >
        <path
            d="M11.7167 7.51667L12.4833 8.28333L4.93333 15.8333H4.16667V15.0667L11.7167 7.51667ZM14.7167 2.5C14.5083 2.5 14.2917 2.58333 14.1333 2.74167L12.6083 4.26667L15.7333 7.39167L17.2583 5.86667C17.5833 5.54167 17.5833 5.01667 17.2583 4.69167L15.3083 2.74167C15.1417 2.575 14.9333 2.5 14.7167 2.5ZM11.7167 5.15833L2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833Z"
            fill="#4B5563"
        />
    </svg>
);