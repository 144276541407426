import { useEffect, useRef, useState } from "react";
import { useTrans } from "locales/hook";
import useDebouncedCallback from "hooks/useDebounceCallback";

// ! Components
import { Box, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Portal, useDisclosure, useMediaQuery, useOutsideClick } from "@chakra-ui/react";
import TreeView from "../TreeView";
import WrapperSpinner from "components/Spinner";

// ! Helpers
import { slugify } from "helpers/helpers";

const HMenuButton: any = MenuButton;

interface TreeNode {
    id: string;
    name: string;
    displayname_vi: string;
    value: string;
    children?: TreeNode[];
    checked: boolean,
    indeterminate: boolean;
    specialInfo: any
}

function TreeList(props) {
    const ref = useRef<any>();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery('(min-width: 556px)');
    const t = useTrans();
    const {
        label,
        placeholder,
        options,
        isLoading,
        onChoose,
        chooseList,
        isMultiple = false,
        handleBeforeClose,
        isDisabled = false,
        onOpenSearch,
    } = props;

    const [keyword, setKeyword] = useState('');
    const [result, setResult] = useState<any>([]);

    useEffect(() => {
        setResult(options)
    }, [options]);

    useOutsideClick({
        ref,
        handler: (e: any) => {
            // ! Tìm trong Nodelist có tồn tại đối tượng "input" hay không? Nếu có là đang click vào input
            const nodeListToArray = [...e.target.attributes];
            const findSubjectIndex = nodeListToArray.findIndex(y => y.nodeName === "input");

            if (findSubjectIndex !== -1) {
                // * Không active với input
                const inputName = nodeListToArray[findSubjectIndex].value
                if (label !== inputName) {
                    if (isOpen) {
                        handleBeforeClose && handleBeforeClose();
                        onClose();

                        onOpenSearch && onOpenSearch(false)
                    }
                }
                return;
            } else {
                // * Nếu đang open thì đóng lại
                if (isOpen) {
                    handleBeforeClose && handleBeforeClose();
                    onClose();

                    onOpenSearch && onOpenSearch(false)
                }
            }
        }
    });

    const handleChangeDebounced = (value: string) => {
        handleSearch(value)
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

    const handleChangeSearch = (e) => {
        setKeyword(e);
    };

    function buildTree(items: any[]): TreeNode[] {
        const itemMap: { [key: string]: TreeNode } = {};
        // Create nodes
        items.forEach(item => {
            itemMap[item.id] = {
                ...item,
                children: [],
            };
        });

        // Build tree structure
        const rootItems: TreeNode[] = [];

        items.forEach(item => {
            const parentId = item?.specialInfo?.parentId?.toString() || "0";

            if (parentId === "0") {
                rootItems.push(itemMap[item.id]);
            } else {
                itemMap[parentId]?.children?.push(itemMap[item.id]);
            }
        });

        return rootItems;
    }

    function handleSearch(value: string) {
        if (!value.length) {
            setResult(options);
        } else {
            const filteredTree = searchTree(buildTree(options), value);

            const flatten = data => {
                return data.reduce((r, { children, ...rest }) => {
                    r.push(rest);
                    if (children) r.push(...flatten(children));
                    return r;
                }, [])
            }

            setResult(flatten(filteredTree))
        }
    }

    function searchTree(nodes: TreeNode[], term: string): TreeNode[] {
        return nodes.reduce((acc: TreeNode[], node) => {

            if (slugify(node.name.toLowerCase()).includes(slugify(term.toLowerCase()))) {
                acc.push(node);
            }

            else if (node.children && node.children.length > 0) {
                const matchingChildren = searchTree(node.children, term);
                if (matchingChildren.length > 0) {
                    acc.push({ ...node, children: matchingChildren });
                }
            }

            return acc;
        }, []);
    }

    return (
        <div
            ref={ref}
            style={{
                position: "relative"
            }}
        >
            <Menu
                matchWidth
                variant="selection"
                isOpen={isOpen}
                autoSelect={false}
                flip={isDesktop}
                isLazy
                placement={'bottom'}
            >
                <HMenuButton
                    input={label}
                    as={Box}
                    onClick={(e) => {
                        if (isDisabled) {
                            return;
                        }

                        const findInput = document.getElementById(label);

                        findInput && findInput.focus();

                        if (!isOpen) {
                            onOpen();
                            onOpenSearch && onOpenSearch(true);

                            return;
                        }
                    }}
                >
                    <InputGroup>
                        <InputLeftElement h="full" w={10}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g id="search">
                                    <path id="Vector" d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill={`${isDisabled ? "#9CA3B0" : "#4B5563"}`} />
                                </g>
                            </svg>
                        </InputLeftElement>

                        <Input
                            isDisabled={isDisabled}
                            id={label}
                            value={keyword}
                            autoComplete={"off"}
                            onChange={(event) => {
                                handleChangeSearch(event.target.value);
                                debouncedCallback(event.target.value)

                            }}
                            size="m"
                            placeholder={placeholder}
                            pl={16}
                        />
                    </InputGroup>
                </HMenuButton>

                {isOpen ?
                    <Portal>
                        <MenuList
                            ref={ref}
                            onFocus={() => {
                                const findInput = document.getElementById(label);
                                findInput && findInput.focus();
                            }}
                            h={isLoading || !result?.length ? 600 : 'unset'}
                            maxH={300}
                        >
                            {isLoading ? <MenuItem
                                style={{
                                    height: "100%",
                                    overflow: "hidden",
                                }}
                                value="loading_component"
                                p={0}
                                m={0}
                                background="#ffffff"
                                _hover={{
                                    bg: '#ffffff'
                                }}
                            >
                                <WrapperSpinner />
                            </MenuItem> : <>
                                {!result.length ?
                                    <MenuItem
                                        value="empty_component"
                                        style={{
                                            height: "100%",
                                            overflow: "hidden",
                                        }}
                                        p={0}
                                        m={0}
                                        background="#ffffff"
                                        _hover={{
                                            bg: '#ffffff'
                                        }}>
                                        <div className="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                                            <p className="hrv-report-w-100 hrv-report-text-center hrv-report-mt-15">
                                                {t("no_data")}
                                            </p>
                                        </div>
                                    </MenuItem> :
                                    <div
                                        className="hrv-report-scrollbar_custom hrv-report-pr-0"
                                        style={{
                                            maxHeight: "280px",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <TreeView
                                            options={result}
                                            chooseList={chooseList}
                                            allList={options}
                                            onChangeCheckbox={onChoose}
                                            isMultiple={isMultiple}
                                        />
                                    </div>
                                }
                            </>}
                        </MenuList>
                    </Portal> : null}
            </Menu >
        </div>
    )
}

export default TreeList