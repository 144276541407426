import { memo } from "react";
import { Provider } from "./context";

import Main from "./components/Main";
import ScrollReset from "components/ScrollReset";

const DashboardV2 = () => {
  return (
    <Provider>
      <ScrollReset />

      <Main />
    </Provider>
  );
};

export default memo(DashboardV2);
