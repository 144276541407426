import { memo } from "react";
import { Box } from "@chakra-ui/react";

import LineChart from "./line";
import EmptyTemplate from "components/EmptyTemplate";

import { useTrans } from "locales/hook";

function ReportPromotionChart(props: any) {
  const t = useTrans();
  const { query, queryPeriod, isLoading, measureName } = props;

  return (
    <div>
      {!query?.data?.length && !queryPeriod?.data?.length ? (
        <EmptyTemplate
          isHaveColor={false}
          text={t("no_data")}
          subText={t("change_fitler_hint")}
        />
      ) : (
        <>
          <div className="hrv-report-d-flex hrv-report-mb-16 hrv-report-justify-between hrv-report-items-center">
            <Box textStyle='h4'> {t(measureName)}</Box>
          </div>

          <div style={{
            maxHeight: 420
          }} className="hrv-report-relative">
            <LineChart
              data={{
                Day: {
                  current: {
                    data: query.data || [],
                    headerreport: query.headerreport
                  },
                  previous: {
                    data: queryPeriod.data || [],
                    headerreport: queryPeriod.headerreport

                  }
                }
              }}
              isLoading={isLoading}
              configHeight={420}
              isAutoSetDateType={true}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default memo(ReportPromotionChart);
