import ConfigSVG from "assets/images/template/config-template.svg"

import { StoreManager } from "./templates/store-manager.template"
import { SaleTemplate } from "./templates/sale.template"
import { MarketingTemplate } from "./templates/marketing.template"
import { AcccountantTemplate } from "./templates/accountant.template"
import { InventoryTemplate } from "./templates/inventory.template"

export const TEMPLATE_EXAMPLE = [
    {
        id: 0,
        name: "Tuỳ chỉnh bảng",
        description: "Bạn có thể tự tạo bảng riêng theo nhu cầu của mình, lựa chọn biểu đồ và chỉ số phù hợp mục tiêu.",
        actions: ["config"],
        icon: <ConfigSVG />,
        template: []
    },
    StoreManager,
    SaleTemplate,
    MarketingTemplate,
    AcccountantTemplate,
    InventoryTemplate
]