export const colors = [
  {
    color: "#3C83F6", // Xanh dương
    hover: "#61A6FA",
  },
  {
    color: "#F97415", // Cam
    hover: "#FB923C",
  },
  {
    color: "#14B8A5", // Teal
    hover: "#2BD4BD",
  },
  {
    color: "#6366F2", // indigo
    hover: "#828DF8",
  },
  {
    color: "#EC4699", // pink
    hover: "#F471B5",
  },
  {
    color: "#21C45D", // green
    hover: "#4ADE80",
  },
  {
    color: "#07B6D5", // cyan
    hover: "#20D3EE",
  },
  {
    color: "#EF4343", // red
    hover: "#F87272",
  },
  {
    color: "#6B7280", // coolgray
    hover: "#9CA3B0",
  },
  {
    color: "#F59F0A", // amber
    hover: "#FBBD23",
  },
  {
    color: "#F59F0A", // amber
    hover: "#FBBD23",
  },
  {
    color: "#F59F0A", // amber
    hover: "#FBBD23",
  },
  {
    color: "#F59F0A", // amber
    hover: "#FBBD23",
  },
] as any;

export const colorsForShipments = {
  order_shipments_detail_carriers_internal_speed: {
    OrderToConfirmedTimeSpan: {
      color: "#DCEBFE",
      hover: "#DCEBFE30",
    },
    ConfirmedToAssignedLocationTimeSpan: {
      color: "#BEDBFE",
      hover: "#BEDBFE30",
    },
    AssignedLocationToInstockConfirmedTimeSpan: {
      color: "#91C3FD",
      hover: "#91C3FD",
    },
    InstockConfirmedToExportTimeSpan: {
      color: "#61A6FA",
      hover: "#61A6FA",
    },
    ExportToShipmentTimeSpan: {
      color: "#3C83F6",
      hover: "#3C83F6",
    },
    ShipmentToHandOverTimeSpan: {
      color: "#2463EB",
      hover: "#2463EB",
    },
    ConfirmedToShipmentTimeSpan: {
      color: "#61A6FA",
      hover: "#61A6FA",
    },
  },
  order_shipments_overview_carriers_internal_speed: {
    OrderToShipmentTimeSpan: {
      color: "#91C3FD",
      hover: "#91C3FD",
    },
    ShipmentToHandOverTimeSpan: {
      color: "#3C83F6",
      hover: "#3C83F6",
    },
  },
};

export const colorsForPageViews = {
  web_pageviews_month: {
    Mobile: {
      color: "#3C83F6", // Xanh dương
      hover: "#61A6FA",
    },
    Other: {
      color: "#F97415", // Cam
      hover: "#FB923C",
    },
  },
};
