import { memo, useState, useEffect, useRef } from "react";

import Popup from "components/HCheckbox/components/Popup";

import { useClickOutside } from "hooks/useClickOutside";

import "./styles.scss";

function HRVYearPicker(props) {
  const {
    minYear = 2017,
    maxYear = new Date().getFullYear(),
    pageSize = 12,
    defaultYear = new Date().getFullYear(),
    fullWidth,
    isShowIcon,
    customText,
    onChange,
    size = 'm'
  } = props;

  const [visible, setVisible] = useState(false);

  const [options, setOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const clickRef = useRef<any>(null);

  useEffect(() => {
    const totalOptions: any = [];
    const minFromDate = minYear - (minYear % pageSize) + 1;
    const maxFromDate = maxYear + pageSize - (maxYear % pageSize);

    for (let i = minFromDate; i <= maxFromDate; i++) {
      const year = i;

      totalOptions.push(year);
    }

    const indexOfYear = totalOptions.findIndex((y) => y === defaultYear);
    const current = Math.ceil(indexOfYear / pageSize);

    const totalPage = Math.ceil(totalOptions.length / pageSize);

    setTotalPage(totalPage); // Tổng số page dựa trên tổng số phần tử và limit
    setCurrentPage(current); // Page hiện tại
    setOptions(totalOptions); // Tổng phần tử render ra UI

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeNext = () => {
    const result = currentPage + 1;

    setCurrentPage(result);
  };

  const onChangePrevious = () => {
    const result = currentPage - 1;

    setCurrentPage(result);
  };

  const handleOnChange = (value) => {
    const started = `${value}-01-01`;
    const ended = `${value}-12-31`;

    onChange && onChange(started, ended);
    setVisible(false);
  };

  const content = () => {
    const arrayRenderYear = options.slice(
      (currentPage - 1) * pageSize,
      (currentPage - 1) * pageSize
        ? (currentPage - 1) * pageSize + pageSize
        : pageSize
    );
    return (
      <div
        className="hrv-report-scrollbar_custom hrv-report-p-16"
        style={{
          width: clickRef?.current?.clientWidth,
          minWidth: "260px",
          maxHeight: "263px",
          overflowY: "auto",
        }}
      >
        <>
          <div className="hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
            <div
              onClick={() => {
                if (currentPage !== 1) {
                  onChangePrevious();
                }
              }}
              style={{
                width: 40,
              }}
              className="hrv-report-p-10"
            >
              {currentPage !== 1 && (
                <span className="hrv-report-cursor-pointer">
                  {svgChervoLeft}
                </span>
              )}
            </div>

            <div className="hrv-report-d-flex">
              <p className="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-500">
                {arrayRenderYear[0]} -{" "}
                {arrayRenderYear[arrayRenderYear.length - 1]}
              </p>
            </div>

            <div
              onClick={() => {
                if (currentPage < totalPage) {
                  onChangeNext();
                }
              }}
              className="hrv-report-p-10"
              style={{
                width: 40,
              }}
            >
              {currentPage < totalPage && (
                <span className="hrv-report-cursor-pointer">
                  {svgChervoRight}
                </span>
              )}
            </div>
          </div>

          <div className="hrv-report-row">
            {arrayRenderYear.map((year, idx) => {
              return (
                <div
                  key={idx}
                  className={`hrv-report-col-3 hrv-report-my-4`}
                  onClick={() => {
                    if (year > maxYear || year < minYear) return;

                    setSelectedYear(year);
                    handleOnChange(year);
                  }}
                >
                  <p
                    className={`${year === selectedYear
                      ? "hrv-report-year-picker__active"
                      : ""
                      } ${year > maxYear ? "hrv-report-year-picker__off" : ""} ${year < minYear ? "hrv-report-year-picker__off" : ""
                      } hrv-report-year-picker hrv-report-text-center hrv-report-cursor-pointer hrv-report-mb-0 hrv-report-px-4 hrv-report-py-8`}
                  >
                    {year}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      </div>
    );
  };

  const onClickOutside = () => {
    setVisible(false);
  };

  useClickOutside(clickRef, onClickOutside);

  const sizeClasses = {
    m: "hrv-report-selection_box__sizeM",
    l: "hrv-report-selection_box__sizeL"
  }

  return (
    <div
      ref={clickRef}
      className="hrv-report-relative"
      style={{
        width: fullWidth ? "100%" : "max-content",
      }}
    >
      <div
        onClick={() => {
          setVisible(!visible);
        }}
        className={`
        ${fullWidth ? "hrv-report-d-flex hrv-report-justify-between" : ""}
        ${sizeClasses[size]}
        hrv-report-selection_box ${visible ? "hrv-report-selection_box__active" : ""
          }`}
      >
        <span>
          <span className="hrv-report-mr-8 hrv-report-text-color-gray-12">
            {isShowIcon ? calendarSvg : customText}
          </span>
          <span className="hrv-report-mr-8">{selectedYear}</span>
        </span>

        <span
          className={`hrv-report-trail-icon ${visible ? "hrv-report-trail-icon__active" : ""
            }`}
        >
          {svgTrail}
        </span>
      </div>

      <Popup visible={visible}>{content()}</Popup>
    </div>
  );
}

export default memo(HRVYearPicker);

const svgChervoLeft = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M13.0871 13.825L9.27044 10L13.0871 6.175L11.9121 5L6.91211 10L11.9121 15L13.0871 13.825Z"
        fill="#4B5563"
      />
    </g>
  </svg>
);

const svgChervoRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M8.08711 5L6.91211 6.175L10.7288 10L6.91211 13.825L8.08711 15L13.0871 10L8.08711 5Z"
        fill="#4B5563"
      />
    </g>
  </svg>
);

const calendarSvg = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 2.33341H13V0.666748H11.3333V2.33341H4.66667V0.666748H3V2.33341H2.16667C1.24167 2.33341 0.508333 3.08341 0.508333 4.00008L0.5 15.6667C0.5 16.5834 1.24167 17.3334 2.16667 17.3334H13.8333C14.75 17.3334 15.5 16.5834 15.5 15.6667V4.00008C15.5 3.08341 14.75 2.33341 13.8333 2.33341ZM13.8333 15.6667H2.16667V7.33342H13.8333V15.6667ZM13.8333 5.66675H2.16667V4.00008H13.8333V5.66675ZM5.5 10.6667H3.83333V9.00008H5.5V10.6667ZM8.83333 10.6667H7.16667V9.00008H8.83333V10.6667ZM12.1667 10.6667H10.5V9.00008H12.1667V10.6667ZM5.5 14.0001H3.83333V12.3334H5.5V14.0001ZM8.83333 14.0001H7.16667V12.3334H8.83333V14.0001ZM12.1667 14.0001H10.5V12.3334H12.1667V14.0001Z"
      fill="#4B5563"
    />
  </svg>
);

const svgTrail = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.175 6.9126L10 10.7293L13.825 6.9126L15 8.0876L10 13.0876L5 8.0876L6.175 6.9126Z"
      fill="#9CA3B0"
    />
  </svg>
);
