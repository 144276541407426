import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  FormatDateTimeCustom,
  GetCurrencyFormater,
  formatCurrency,
  formatNumber,
  valueHandler,
} from "helpers/helpers";
import { Bar } from "react-chartjs-2";
import * as ChartUtils from "helpers/chartutils";

import { useTrans } from "locales/hook";
import useWindowDimensions from "hooks/useWindowDimension";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartUtils.RegisterTooltipPositioner();

// interface IData {
//   labels: Array<string>;
//   data: Array<any>;
//   namefield: string;
// }

interface IProps {
  isDisplayTitle?: boolean;
  title?: string;
  proData: any;
  colorBar: string;
  hoverColorBar: string;
  typeHorizonal: string;
  configHeight: number;
  typeVertical?: string;
}

function StackedBar(props: IProps) {
  const {
    isDisplayTitle,
    title,
    proData,
    typeHorizonal,
    configHeight,
    typeVertical = "day",
  } = props;
  let formater = GetCurrencyFormater();
  const t = useTrans();
  const { width } = useWindowDimensions();

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(
      "div.tooltip-container",
    );

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("tooltip-container");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "12px 12px 2px 12px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      table.style.width = "100%";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];

      const tableHead = document.createElement("thead");
      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = "0px";

        const th = document.createElement("th");
        th.style.borderWidth = "0px";
        const text = document.createTextNode(title);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map((b) => b.lines);

        const tableHead = document.createElement("thead");
        titleLines.forEach((title) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = "0px";

          const th = document.createElement("th");
          th.style.borderWidth = "0px";
          th.style.paddingBottom = "8px";

          let format = "DD/MM/YYYY";

          if (typeVertical === "Hour") {
            format = "DD/MM/YYYY HH:mm";
          }
          if (typeVertical === "Day") {
            format = "DD/MM";
          }
          if (typeVertical === "Month") {
            format = "MM/YYYY";
          }

          if (typeVertical === "Year") {
            format = "YYYY";
          }

          if (typeVertical === "Month") {
            title = valueHandler("timestamp", typeVertical, title);
          } else if (typeVertical === "DayOfWeek") {
            title = valueHandler("dayofweek", "", title);
          } else {
            title = FormatDateTimeCustom(title, format);
          }

          let text = document.createTextNode(title);

          text = document.createTextNode(title.replaceAll(",", " "));

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement("tbody");
        bodyLines.forEach((_, i) => {
          const colors = tooltip.labelColors[i];
          const dataPoint = tooltip.dataPoints[i];

          const span = document.createElement("span");
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.marginRight = "10px";
          span.style.height = "12px";
          span.style.width = "12px";
          span.style.borderRadius = "2px";
          span.style.display = "inline-block";

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = "0px";

          const td = document.createElement("td");
          td.style.borderWidth = "0px";

          let value = dataPoint.raw;

          if (typeHorizonal === "money") {
            value = formater.format(value);
          }

          if (typeHorizonal === "percent") {
            value = formatNumber(value, false) + "%";
          };

          if (typeHorizonal === "numberhour") {
            value = formatNumber(value) + " " + t("giờ");
          }

          const labelCurrentContainer = document.createElement("div");
          labelCurrentContainer.classList.add("hrv-report-d-flex");
          labelCurrentContainer.classList.add("hrv-report-mb-10");
          labelCurrentContainer.classList.add("hrv-report-w-100");
          labelCurrentContainer.innerHTML = `
              <div class="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                ${span.outerHTML}
                <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-mr-20">${proData.data[i].name}</div>
                <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-ml-auto">${value}</div>
              </div>
            `;

          td.appendChild(labelCurrentContainer);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector("table");

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);



        const { offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;

        const { caretY } = chart.tooltip;

        const { chartArea } = chart;

        const { x } = chart.tooltip._active[0].element;

        if (width > 758) {
          if (tooltip.xAlign === "right") {
            // Bên phải
            tooltipEl.style.left = x - tooltipEl.clientWidth / 2 + "px";
          } else {
            // Bên trái
            tooltipEl.style.left = x + tooltipEl.clientWidth / 2 + "px";
          }

          if (tooltip.caretY > chartArea.bottom) {
            tooltipEl.style.opacity = 0;
          }

          if (caretY + tooltipEl.clientHeight > configHeight) {
            tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + "px";
          } else {
            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;
          }
        } else {
          tooltipEl.style.left = width / 2 + "px";
          tooltipEl.style.top = 30 + "px";
        }
      }
    }
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: true,
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: isDisplayTitle,
        text: title,
      },
      tooltip: {
        enabled: false,
        position: "cursor",
        external: externalTooltipHandler,
      },
    },

    scales: {
      x: {
        stacked: true,
        border: {
          dash: [1, 1],
        },
        grid: {
          display: true,
          drawTicks: true,
          offset: false,
        },
        ticks: {
          color: "#111827",
          callback: function (value, index, values) {
            let render = value;
            let format = "DD/MM/YYYY";

            if (typeVertical === "Hour") {
              format = "DD/MM/YYYY HH:mm";
            }
            if (typeVertical === "Day") {
              format = "DD/MM";
            }
            if (typeVertical === "Month") {
              format = "MM/YYYY";
            }

            if (typeVertical === "Year") {
              format = "YYYY";
            }

            if (typeVertical === "Month") {
              render = valueHandler("timestamp", typeVertical, labels[index]);
            } else if (typeVertical === "DayOfWeek") {
              render = valueHandler("dayofweek", "", labels[index]);
            } else {
              render = FormatDateTimeCustom(labels[index], format);
            }

            return render;
          },
        },

      },
      y: {
        stacked: true,
        border: {
          dash: [1, 1],
        },
        ticks: {
          // Sử dụng callback để thêm đơn vị vào giá trị nhãn trục Y
          callback: function (value, index, values) {
            let render = value;
            if (typeHorizonal === "money") {
              render = formatCurrency(value);
            }

            if (typeHorizonal === "percent") {
              render = formatNumber(value);
            }

            return render;
          },
          color: "#111827",
        },
      },
    },
  } as any;

  const labels = proData.labels;

  const data = {
    labels,
    datasets: [] as any,
  };

  proData.data.forEach((row, index) => {
    data.datasets.push({
      label: row.name,
      data: row.data,
      backgroundColor: row.color,
      hoverBackgroundColor: row.hover,
      maxBarThickness: 76,
      borderRadius: 2,
    });
  });

  return (
    <div
      style={{
        height: configHeight,
      }}
    >
      <Bar options={options} data={data} />
    </div>
  );
}

export default StackedBar;