import { EffectCallback, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragPlaceholder from "./DragPlaceholder";
import { isEmpty } from "helpers/helpers";
import usePrevious from "hooks/usePrevious";

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // Thêm 1 số stying cho Items
  userSelect: "none",
  marginBottom: `${grid}px`,
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  // Thêm 1 số stying cho Listing
  position: "relative"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";

const DragZone = (props) => {
  const [placeholderProps, setPlaceholderProps] = useState<any>({});
  const { editable, renderList, renderItem, onHandleDragEnd, activeScroll } = props;
  const prevResult: any = usePrevious(renderList);
  const refScroll: any = useRef(null)

  // & When RenderList changed will catch bottom element of list to scroll
  const useEffectAutoScrollToElement = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, [renderList])
  }

  useEffectAutoScrollToElement(() => {
    if (prevResult && renderList.length > prevResult.length) {
      if (refScroll && refScroll !== null) {
        refScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  })

  const onDragEnd = result => {
    setPlaceholderProps({})

    if (!result.destination) {
      return;
    }

    onHandleDragEnd && onHandleDragEnd(result);
  };

  // ! Drag update cho phép thêm placeholdr vào vị trí còn trống khi item đó được move ra khỏi vị trí ban đầu
  const onDragUpdate = update => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM: any = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }
    const { clientHeight, clientWidth } = draggedDOM;

    const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
      .slice(0, destinationIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
    });
  };

  const length = renderList.length;

  const isActiveScroll = (index) => {
    return length > prevResult?.length && activeScroll && renderList?.length - 1 === index
  }

  return <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {renderList.map((item, index) => (
            <Draggable
              key={String(item.id)}
              draggableId={String(item.id)}
              index={index}
              isDragDisabled={!editable}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <div ref={isActiveScroll(index) ? refScroll : null} >
                    {renderItem(item, (length - 1) === index, isActiveScroll(index) ? "hrv-report-column-active" : "")}
                  </div>
                </div>

              )}
            </Draggable>
          ))}

          {/* Cho phép placeholder xuất hiện khi placeholderProps khác {} */}
          {provided.placeholder}
          {!isEmpty(placeholderProps) ? <DragPlaceholder snapshot={placeholderProps} /> : ""}

        </div>
      )}
    </Droppable>
  </DragDropContext>
};

export default DragZone;