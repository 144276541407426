import { useTrans } from "locales/hook";
import TooltipWithTouch from "components/TooltipWithTouch";

export default function Permission({ allowed, placement = "top", children }) {
  const t = useTrans();

  if (allowed) {
    return <>{children}</>;
  }

  return (
    <TooltipWithTouch
      label={t('not_permission_to_perform')}
      placement={placement}
      hasArrow
    >
      {children}{" "}
    </TooltipWithTouch>
  );
}
