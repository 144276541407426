import { DateShowType } from "./constants";
import { formatCurrency, formatNumber } from "./helpers";
import { Tooltip } from "chart.js";
import dayjs from "dayjs";

// Hàm điền các giá trị còn thiếu cho current và previous
export const map2Chart = (current, previous) => {
  current.forEach((c1) => {
    let dt = previous.find((c2) => c2.date.getTime() === c1.date.getTime());
    if (!dt) previous.push({ date: c1.date, total: 0, order: 0 });
  });
  previous.forEach((c2) => {
    let dt = current.find((c1) => c1.date.getTime() === c2.date.getTime());
    if (!dt) current.push({ date: c2.date, total: 0, order: 0 });
  });

  current.sort((a, b) => (a.date > b.date ? 1 : -1));
  previous.sort((a, b) => (a.date > b.date ? 1 : -1));
};

export const dayAdd = (
  date,
  diffCounts,
  type: DateShowType = DateShowType.day,
): Date => {
  let changedDate = new Date(+date);
  switch (type) {
    case DateShowType.month:
      let mmCount = diffCounts % 12;
      let yyCount = Number.parseInt(diffCounts / 12 + "");
      let dd = changedDate.getDate();
      let mm = changedDate.getMonth() + mmCount + 1;
      let yy = changedDate.getFullYear() + yyCount;
      changedDate.setFullYear(yy, mm, 0);
      if (changedDate.getDate() > dd) {
        changedDate.setDate(dd);
      }
      break;
    case DateShowType.year:
      changedDate.setFullYear(changedDate.getFullYear() + diffCounts);
      break;
    case DateShowType.day:
    default:
      changedDate.setTime(changedDate.getTime() + diffCounts * 86400000);
      break;
  }
  return changedDate;
};

export const formatData = (data, startDate, endDate, chartShowType) => {
  let first = data[0]?.date;
  let last = data[data.length - 1]?.date;
  let result: Array<any> = [];
  let pattern: Array<any> = [];
  let startH, endH;

  switch (chartShowType) {
    case DateShowType.hour:
      if (data.length === 1) {
        result.push({
          date: new Date(dayjs(first).add(dayjs.duration({ 'hours': -1 })).format()),
          total: 0,
          order: 0,
        });
      } else {
        startH = dayjs(first);
        endH = dayjs(last);

        for (startH; startH.unix() <= endH.unix(); startH = startH.add(1, "h")) {
          let found = data.find((n) => {
            return dayjs(n.date).unix() === startH.unix();
          });

          if (!found)
            result.push({
              date: startH.format(),
              total: 0,
              order: 0,
            });
        }
      }
      for (
        let i = dayjs(startDate).startOf("day");
        i.unix() <= dayjs(endDate).endOf("day").unix();
        i = i.add(1, "h")
      ) {
        pattern.push({
          date: i.format(),
          total: 0,
          order: 0,
        });
      }

      break;
    case DateShowType.day:
      for (
        let i = dayjs(startDate);
        i.unix() <= dayjs(endDate).unix();
        i = i.add(1, 'day')
      ) {
        pattern.push({
          date: i.format(),
          total: 0,
          order: 0,
        });
      }
      break;
    case DateShowType.month:

      for (
        let i = dayjs(startDate);
        i.unix() <= dayjs(endDate).unix();
        i = i.add(1, 'month')
      ) {
        pattern.push({
          date: i.format(),
          total: 0,
          order: 0,
        });
      }
      break;
    case DateShowType.year:
      for (
        let i = dayjs(startDate);
        i.unix() <= dayjs(endDate).unix();
        i = i.add(1, 'year')
      ) {
        pattern.push({
          date: i.format(),
          total: 0,
          order: 0,
        });
      }
      break;
  }

  result = result.concat(data);

  result.sort((a, b) => (a.date > b.date ? 1 : -1));

  if (chartShowType === DateShowType.hour) {
    for (let obj of pattern) {
      let found = result.find((n) => {
        return dayjs(n.date).unix() === dayjs(obj.date).unix();
      });

      if (found) {
        obj.order = found.order;
        obj.total = found.total;
      }
    }

    return pattern;
  } else {
    for (let obj of pattern) {
      let found;

      if (chartShowType === "month") {
        found = result.find((n) => {
          return (
            dayjs(n.date).month() === dayjs(obj.date).month() &&
            dayjs(n.date).year() === dayjs(obj.date).year()
          );
        });
      } else if (chartShowType === "year") {
        found = result.find((n) => {
          return dayjs(n.date).year() === dayjs(obj.date).year();
        });
      } else {
        found = result.find((n) => {
          const isSame = dayjs(n.date).isSame(dayjs(obj.date), 'day');

          return isSame;
        });
      }

      if (found) {
        obj.order = found.order;
        obj.total = found.total;
      }
    }

    pattern.sort((a, b) => (a.date > b.date ? 1 : -1));
    return pattern;
  }
};

export const getDataByKey = (key: string, data: any, isPercentage = false) => {
  let newData: Array<any> = [];
  data.forEach((item: any) =>
    newData.push(isPercentage ? item[key] * 100 : item[key]),
  );
  return newData;
};

export const hoverLine = {
  id: "hoverLine",
  afterDatasetDraw(chart, args, plugins) {
    if (chart.tooltip?._active?.length) {
      let x = chart.tooltip._active[0].element.x;
      let yAxis = chart.scales.y;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#9CA3B0";
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const addGradientBgToDataset = (dsIndex) => {
  return {
    id: "grarientBg",
    beforeInit: (chart, args, options) => {
      const ctx = chart.ctx;
      const bg = ctx.createLinearGradient(0, 0, 0, 500);
      bg.addColorStop(0, "rgba(60, 131, 246, 0.5)");
      bg.addColorStop(0.42, "rgba(255, 255, 255, 0)");
      if (chart.data.datasets[dsIndex]) {
        chart.data.datasets[dsIndex].backgroundColor = bg;
        chart.data.datasets[dsIndex].fill = "origin";
        chart.update();
      }
    },
  };
};

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector(
    "div.tooltip-container",
  );

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("tooltip-container");
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const GetLineConfig = (
  dataChart,
  externalTooltipHandler,
  isCurrency = true,
  xAxisElementLimit = 7,
) => {
  return {
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      axis: "x",
      intersect: false,
    },
    plugins: {
      filter: {
        propagate: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "cursor",
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          color: "#111827",
          callback: function (val, index) {
            if (dataChart.labels.length <= xAxisElementLimit) {
              return dataChart.labels[index];
            }
            // Hide every 2nd tick label
            return index % 2 === 0 ? dataChart.labels[index] : "";
          },
        },
      },
      y: {
        border: {
          dash: [1, 1],
        },
        ticks: {
          min: 0,
          beginAtZero: true,
          scaleBeginAtZero: true,
          // Sử dụng callback để thêm đơn vị vào giá trị nhãn trục Y
          callback: function (value, index, values) {
            let render = formatCurrency(value);
            if (!isCurrency) {
              if (!Number.isInteger(value)) {
                return null;
              }
              render = formatNumber(value, true, 1);
            }
            return render;
          },
          color: "#111827",
        },
      },
    },
  } as any;
};

export const RegisterTooltipPositioner = () => {
  // @ts-ignore:next-line
  Tooltip.positioners.cursor = (_, coordinates) => {
    // @ts-ignore:next-line

    const { chart } = coordinates;

    if (
      typeof chart !== "undefined" &&
      chart.config._config.type !== "doughnut"
    ) {
      const {
        scales: { x, y },
      } = chart;
      return {
        x: x.getPixelForValue(x.getValueForPixel(coordinates.x)),
        y: y.getPixelForValue(y.getValueForPixel(coordinates.y)),
      };
    } else {
      return {
        x: coordinates.x,
        y: coordinates.y,
      };
    }
  };
};

export const Expand24h = (date, items) => {
  let result24h: Array<any> = [];
  for (var hour = 0; hour < 24; hour++) {
    const newDate = new Date(date.toISOString());
    newDate.setHours(hour, 0, 0, 0);
    let findIndex = items.findIndex(
      (r) => new Date(r.date).getHours() === newDate.getHours(),
    );
    if (findIndex !== -1) {
      result24h.push(items[findIndex]);
    } else {
      let formated = { date: newDate, total: 0, order: 0 };
      result24h.push(formated);
    }
  }

  return result24h;
};

export const breakLabel = (label, numOfCharacter = 2) => {
  let newLabel = [] as any;
  const length = label.length;
  if (length > 10) {
    const parts = label.split(" ");
    let currentIndex = 0;
    let counter = 0;
    parts.forEach((part) => {
      if (newLabel[currentIndex] === undefined) {
        newLabel[currentIndex] = "";
      }
      if (counter < numOfCharacter) {
        newLabel[currentIndex] += part + " ";
        ++counter;
      } else {
        newLabel[currentIndex] = newLabel[currentIndex].trim();
        counter = 0;
        ++currentIndex;
      }
    });
  } else {
    newLabel.push(label);
  }

  return newLabel;
};

export const RefineDataToCompare = (
  response,
  groupBySelected,
  measureSelected,
  started,
  ended,
) => {
  const newResponse = {
    ...response,
  };
  const items = [...response.data];
  const newItems = [] as any;
  let date = started.clone();
  const diff = ended.diff(started, "days");
  for (var i = 0; i <= diff; i++) {
    if (i > 0) {
      date = date.add(1, "day");
    }
    var newItem = [] as any;
    var dateFormated = date.utcOffset(0).format("YYYY-MM-DDTHH:mm:ss[Z]");
    newItem.push(dateFormated);
    const indexFound = findItemIndex(items, dateFormated);
    if (indexFound === -1) {
      if (groupBySelected > 1) {
        for (var d = 0; d < groupBySelected.length - 1; d++) {
          newItem.push("--");
        }
      }
      for (var j = 0; j < measureSelected.length; j++) {
        newItem.push(0);
      }
      newItems.push(newItem);
    } else {
      newItems.push(items[indexFound]);
    }
  }
  newResponse.data = newItems;
  return newResponse;
};

export const findItemIndex = (items, date) => {
  return items.findIndex((item) => item[0] === date);
};


export const addDataDayOfWeek = (data, index) => {
  const pattern = [2, 3, 4, 5, 6, 7, 1];

  let final: any = [];

  pattern.forEach((dayOfWeek) => {
    if (data && data?.length) {
      let rootData = data?.findIndex((y: any) => y[index] === dayOfWeek);
      let result: any = []

      if (rootData === -1) {
        result = [dayOfWeek, 0]
      } else {
        result = data[rootData]
      }
      final.push(result)
    }
  })

  return final
}

export const addDataHourOfDay = (data, index) => {
  let final: any = [];

  for (let i = 0; i <= 23; i++) {
    if (data && data?.length) {
      let rootData = data?.findIndex((y: any) => y[index] === i);
      let result: any = []

      if (rootData === -1) {
        result = [i, 0]
      } else {
        result = data[rootData]
      }


      final.push(result)
    }
  }

  return final
}