// ! Hooks
import { useRef, useState, EffectCallback, useEffect, useDeferredValue } from "react";
import { useTrans } from "locales/hook";
import useDebouncedCallback from "hooks/useDebounceCallback";

// ! Components Import
import { Box, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Portal, useDisclosure, useMediaQuery, useOutsideClick } from "@chakra-ui/react";
import WrapperSpinner from "components/Spinner";
import HLimitLongString from "components/LimitLongString";
import HRVCheckbox from "components/Checkbox";
import VirtualizedContainer from "components/VirtualizedContainer";

import { slugify } from "helpers/helpers";

const LIMIT_VIRTUALIZED_ACTIVE = 20

function SelectionMulti(props) {
    const t = useTrans();

    const {
        label,
        placeholder,
        options,
        isLoading,
        name,
        onChoose,
        valueName,
        withFetchAPI,
        chooseList,
        onChangeSearch,
        isMultiple = false,
        handleBeforeClose,
        isDisabled = false,
        onOpenSearch,
        // index
    } = props;

    const [keyword, setKeyword] = useState('');
    const [defaultOptions, setDefaultOptions] = useState(options);

    const [isDesktop] = useMediaQuery('(min-width: 556px)');

    const ref = useRef<any>();
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

    useOutsideClick({
        ref,
        handler: (e: any) => {
            // ! Tìm trong Nodelist có tồn tại đối tượng "input" hay không? Nếu có là đang click vào input
            const nodeListToArray = [...e.target.attributes];
            const findSubjectIndex = nodeListToArray.findIndex(y => y.nodeName === "input");

            if (findSubjectIndex !== -1) {
                // * Không active với input
                const inputName = nodeListToArray[findSubjectIndex].value
                if (label !== inputName) {
                    if (isOpen) {
                        handleBeforeClose && handleBeforeClose();
                        onClose();

                        onOpenSearch && onOpenSearch(false)
                    }
                }
                return;
            } else {
                // * Nếu đang open thì đóng lại
                if (isOpen) {
                    handleBeforeClose && handleBeforeClose();
                    onClose();

                    onOpenSearch && onOpenSearch(false)
                }
            }
        }
    });

    const handleChangeDebounced = (value: string) => {
        if (withFetchAPI) {
            onChangeSearch && onChangeSearch(value);
        } else {
            let result = [];

            result = options.filter((y) => {
                if (y[name] && y[name].length && typeof value !== 'undefined') {
                    return slugify(y[name]?.toLowerCase()).includes(
                        slugify(value?.toLowerCase())
                    );
                } else {
                    return y
                }
            });

            setDefaultOptions(result);
        }
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

    const useEffectSetOptions = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(effect, [options])
    }

    useEffectSetOptions(() => {
        setDefaultOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const handleChangeSearch = (e) => {
        setKeyword(e);
    };

    const handleChoosen = (item) => {
        if (!isMultiple) {
            setKeyword("");
            onChoose && onChoose(item);
        } else {
            onChoose && onChoose(item);
        }
    };

    const renderList = useDeferredValue(chooseList);

    const isDisabledCondition = (option) => {
        // ! With isMultipleProps = true => Button not disabled
        if (isMultiple) return false;

        return renderList?.map(y => y[valueName]).includes(option[valueName])
    }

    const HMenuButton: any = MenuButton;

    return (
        <div ref={ref} style={{
            position: "relative"
        }}>
            <Menu matchWidth variant="selection" isOpen={isOpen} autoSelect={false} flip={isDesktop} isLazy placement={'bottom'}>
                <HMenuButton
                    input={label}
                    as={Box}
                    onClick={(e) => {
                        if (isDisabled) {
                            return;
                        }

                        const findInput = document.getElementById(label);

                        findInput && findInput.focus();

                        if (!isOpen) {
                            onOpen();
                            onOpenSearch && onOpenSearch(true);

                            return;
                        }
                    }}
                >
                    <InputGroup>
                        <InputLeftElement h="full" w={10}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g id="search">
                                    <path id="Vector" d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill={`${isDisabled ? "#9CA3B0" : "#4B5563"}`} />
                                </g>
                            </svg>
                        </InputLeftElement>

                        <Input
                            isDisabled={isDisabled}
                            id={label}
                            value={keyword}
                            autoComplete={"off"}
                            onChange={(event) => {
                                handleChangeSearch(event.target.value);
                                debouncedCallback(event.target.value);
                            }}
                            size="m"
                            placeholder={placeholder}
                            pl={16}
                        />
                    </InputGroup>
                </HMenuButton>

                {isOpen ? <Portal>
                    <MenuList
                        ref={ref}
                        onFocus={() => {
                            const findInput = document.getElementById(label);
                            findInput && findInput.focus();
                        }}
                        h={isLoading || !defaultOptions?.length ? 240 : 'unset'}
                    >
                        {isLoading ? <MenuItem
                            style={{
                                height: "100%",
                                overflow: "hidden",
                            }}
                            value="loading_component"
                            p={0}
                            m={0}
                            background="#ffffff"
                            _hover={{
                                bg: '#ffffff'
                            }}
                        >
                            <WrapperSpinner />
                        </MenuItem> : <>
                            <VirtualizedContainer
                                customClass="hrv-report-scrollbar_custom"
                                maxHeight={222}
                                list={defaultOptions}
                                emptyContent={() => {
                                    return <MenuItem value="empty_component" style={{
                                        height: "100%",
                                        overflow: "hidden",
                                    }}
                                        p={0}
                                        m={0}
                                        background="#ffffff"
                                        _hover={{
                                            bg: '#ffffff'
                                        }}>
                                        <div className="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                                            <p className="hrv-report-w-100 hrv-report-text-center hrv-report-mt-15">
                                                {t("no_data")}
                                            </p>
                                        </div>
                                    </MenuItem>
                                }}
                                limit={LIMIT_VIRTUALIZED_ACTIVE}
                                renderItems={(option) => {
                                    const isChecked = renderList.some(y => y[valueName] === option[valueName]);

                                    return <>
                                        {isMultiple ? <>
                                            <HRVCheckbox
                                                className={`hrv-report-selection_box-item ${isChecked ? "hrv-report-selection_box-item__active" : ""}`}
                                                title={option[name]}
                                                checked={isChecked}
                                                onChange={(value) => {
                                                    handleChoosen(value);
                                                }}
                                                value={option[valueName]}
                                            />
                                        </>
                                            :
                                            <MenuItem
                                                value={option[valueName]}
                                                onClick={() => {
                                                    handleChoosen({
                                                        check: true,
                                                        id: option.id,
                                                        name: option.name,
                                                        value: option.value
                                                    });
                                                    onToggle();
                                                }}
                                                px={4}
                                                isDisabled={isDisabledCondition(option)}
                                            >
                                                <HLimitLongString
                                                    placement="right"
                                                    lengthText={60}
                                                    customClass="hrv-report-m-0"
                                                    text={option[name].trim() !== "" ? option[name] : "--"}
                                                />
                                            </MenuItem>}
                                    </>
                                }}
                            />
                        </>}
                    </MenuList>
                </Portal> : null}
            </Menu >
        </div >
    )
}

export default (SelectionMulti);

