import { useTrans } from "locales/hook";
import TooltipWithTouch from "components/TooltipWithTouch";
import HeaderTooltips from "helpers/headertooltips";

export default function TableReportHeader({ reportNameSystem, name }) {
  const t = useTrans();
  const headerTooltip = HeaderTooltips({ reportNameSystem });

  if (headerTooltip.has(name)) {
    return (
      <TooltipWithTouch label={headerTooltip.get(name)} hasArrow>
        <span className="hrv-report-relative hrv-report-fs-12">
          <span style={{ borderBottom: "1px dashed rgb(0, 0, 0)", }} >
            {t(headerTooltip.getName(name))}
          </span>
        </span>
      </TooltipWithTouch>
    );
  }

  return <>{t(name)}</>;
}
