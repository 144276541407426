import { memo } from "react";
// import Dialog from "rc-dialog";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'

interface IModal {
  visible: boolean;
  onClose?: any;
  renderHeader?: any;
  renderBody?: any;
  renderFooter?: any;
  afterClose?: any;
  destroyOnClose?: boolean;
  closable?: boolean;
  maskClosable?: boolean;
  className?: string;
  isCentered?: boolean
}

const HModal = (props: IModal) => {
  const {
    visible,
    onClose,
    closable,
    maskClosable,
    renderHeader,
    renderBody,
    afterClose,
    // className,
    renderFooter,
    isCentered,
    ...restProps
  } = props;

  return (
    <Modal
      isOpen={visible}
      autoFocus={false}
      onClose={onClose}
      closeOnOverlayClick={maskClosable}
      onCloseComplete={afterClose}
      isCentered={isCentered}
      {...restProps}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {renderHeader()}
        </ModalHeader>

        {closable ? <ModalCloseButton /> : null}

        <ModalBody>
          {renderBody()}
        </ModalBody>

        {renderFooter && <ModalFooter>
          {renderFooter()}
        </ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default memo(HModal);
