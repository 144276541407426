import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FormatDateTimeCustom, valueHandler } from "helpers/helpers";
import { Bar } from "react-chartjs-2";
import * as ChartUtils from "helpers/chartutils";
import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";
import { useRef } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartUtils.RegisterTooltipPositioner();

interface IData {
  labels: Array<string>;
  data: Array<any>;
  namefield: string;
}

interface IProps {
  isDisplayTitle?: boolean;
  title?: string;
  proData: IData;
  typeHorizonal: string;
  configHeight: number;
  unit?: string;
  width?: number
}

function StackedHorizontalBar(props: IProps) {
  const { isDisplayTitle, title, proData, typeHorizonal, configHeight = 0, unit = "" } = props;
  const { width: widthScreen } = useWindowDimensions();
  const t = useTrans();
  const refChart = useRef<any>();
  // const [hoverElem, setHoverElem] = useState(-1);

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(
      "div.tooltip-container",
    );

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("tooltip-container");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "12px 12px 2px 12px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      table.style.width = "100%";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    const { dataPoints } = tooltip;

    if (dataPoints?.length) {
      const { dataset } = dataPoints[0];

      if (tooltip.body && dataPoints.length) {
        const titleLines = tooltip.title || [];

        const tableHead = document.createElement("thead");
        titleLines.forEach((title) => {
          const tr = document.createElement("tr");
          tr.style.borderWidth = "0px";

          const th = document.createElement("th");
          th.style.borderWidth = "0px";
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        // Set Text
        if (tooltip.body) {
          const titleLines = tooltip.title || [];

          const tableHead = document.createElement("thead");
          titleLines.forEach((title) => {
            let render = title;

            if (typeHorizonal === "Month") {
              render = valueHandler("timestamp", typeHorizonal, title);
            } else if (typeHorizonal === "DayOfWeek") {
              render = valueHandler("dayofweek", "", title);
            } else {
              render = FormatDateTimeCustom(title, "DD/MM");
            }

            const tr = document.createElement("tr");
            tr.style.borderWidth = "0px";

            const th = document.createElement("th");
            th.style.borderWidth = "0px";
            th.style.paddingBottom = "8px";
            const text = document.createTextNode(render);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
          });

          const tableBody = document.createElement("tbody");

          const colors = dataset.backgroundColor;
          const dataPoint = tooltip.dataPoints[0];

          const span = document.createElement("span");
          span.style.background = colors;
          span.style.marginRight = "10px";
          span.style.height = "12px";
          span.style.width = "12px";
          span.style.borderRadius = "2px";
          span.style.display = "inline-block";

          const tr = document.createElement("tr");
          tr.style.backgroundColor = "inherit";
          tr.style.borderWidth = "0px";

          const td = document.createElement("td");
          td.style.borderWidth = "0px";

          let value = dataPoint.raw;

          if (typeHorizonal === "numberhour") {
            value = value + " " + t("giờ");
          }

          const labelCurrentContainer = document.createElement("div");
          labelCurrentContainer.classList.add("hrv-report-d-flex");
          labelCurrentContainer.classList.add("hrv-report-mb-10");
          labelCurrentContainer.classList.add("hrv-report-w-100");
          labelCurrentContainer.innerHTML = `
                <div class="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                  ${span.outerHTML}
                  <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-mr-20">${dataset.label}</div>
                  <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-ml-auto">${value}</div>
                </div>
              `;

          td.appendChild(labelCurrentContainer);
          tr.appendChild(td);
          tableBody.appendChild(tr);

          const tableRoot = tooltipEl.querySelector("table");

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);

          const { chartArea } = chart;
          const { caretX, caretY } = chart.tooltip;
          const { offsetTop: positionY } = chart.canvas;

          tooltipEl.style.opacity = 1;

          if (caretX < chartArea.left) {
            tooltipEl.style.opacity = 0;
          }
          const { x } = chart.tooltip._eventPosition;

          if (widthScreen > 758) {
            const widthOfTooltip = tooltipEl.offsetWidth;
            let widthOfWrapperChart = 0;

            if (refChart && refChart.current) {
              widthOfWrapperChart = refChart.current.clientWidth;
            }

            const isFloatRight = x + widthOfTooltip > widthOfWrapperChart - x / 2 + 240;

            if (isFloatRight) {
              // Bên phải
              tooltipEl.style.left =
                x - tooltipEl.clientWidth / 2 + "px";
            } else {
              // Bên trái
              tooltipEl.style.left =
                x + tooltipEl.clientWidth / 2 + "px";
            }

            tooltipEl.style.top = positionY + tooltip.caretY + "px";
            tooltipEl.style.font = tooltip.options.bodyFont.string;

            if (caretY + tooltipEl.clientHeight > configHeight) {
              tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + "px";
            } else {
              tooltipEl.style.top = positionY + tooltip.caretY + "px";
              tooltipEl.style.font = tooltip.options.bodyFont.string;
            }
          } else {
            tooltipEl.style.left = tooltipEl.clientWidth / 2 + "px";

            if (caretY < configHeight / 2) {
              tooltipEl.style.top = configHeight / 2 + "px";

            } else {
              tooltipEl.style.top = tooltipEl.clientHeight + "px";
            }
          }
        }
      }
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    type: "horizontalBar",
    interaction: {
      mode: "point",
      intersect: true,
    },
    // onHover: (event, chartElement) => {
    //   if (chartElement.length > 0) {
    //     setHoverElem(chartElement[0].datasetIndex);
    //   } else {
    //     setHoverElem(-1);
    //   }
    // },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: isDisplayTitle,
        text: title,
      },
      tooltip: {
        enabled: false,
        position: "cursor",
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        stacked: true,
        border: {
          dash: [1, 1],
        },
        grid: {
          display: true,
          drawTicks: true,
          offset: false,
        },
        ticks: {
          color: "#111827",
          callback: function (value) {
            let final = "";

            if (typeHorizonal === 'numberhour') {
              final = t("giờ")
            }

            if (unit.length) {
              final = unit
            }
            return value + " " + final;
          },
        },
      },
      y: {
        stacked: true,
        border: {
          dash: [1, 1],
        },
        ticks: {
          // Sử dụng callback để thêm đơn vị vào giá trị nhãn trục Y
          callback: function (value, index, values) {
            let render = value;

            if (typeHorizonal === "Month") {
              render = valueHandler("timestamp", typeHorizonal, labels[index]);
            } else if (typeHorizonal === "DayOfWeek") {
              render = valueHandler("dayofweek", "", labels[index]);
            } else {
              render = FormatDateTimeCustom(labels[index], "DD/MM");
            }

            return render;
          },
          color: "#111827",
        },
        grid: {
          display: false, // Hide the Y-axis grid lines as well
        },
      },
    },
  } as any;

  const labels = proData.labels;

  const data = {
    labels,
    datasets: [] as any,
  };

  proData.data.forEach((row, idx) => {
    data.datasets.push({
      label: row.name,
      data: row.data,
      backgroundColor: row.color,
      hoverBackgroundColor: row.hover,
      maxBarThickness: 32,
      // barThickness: 32,
      borderRadius: 2,
    });
  });

  return (
    <div
      style={{
        width: "100%",
        height: configHeight,
      }}
      ref={refChart}
    >
      <Bar options={options} data={data} />
    </div>
  );
}

export default StackedHorizontalBar