import NotfoundSVG from "assets/images/notfound/notfound.svg";

export default function NotFound() {
  return (
    <div className="wrapper-container-embbed">
      <div className="hrv-report-row">
        <div className="hrv-report-col-12">
          <div
            className="hrv-report-mg-auto hrv-report-mt-90"
            style={{ width: "300px", minWidth: "300px" }}
          >
            <NotfoundSVG />
          </div>

          <div className="hrv-report-mg-auto hrv-report-text-center">
            <h4 className="hrv-report-fs-50 hrv-report-fw-bold hrv-report-mt-10">
              404
            </h4>
            <p className="hrv-report-fs-16 hrv-report-text-color-gray-1">
              Trang bạn đang tìm kiếm <br /> không tồn tại trong hệ thống, vui
              lòng kiểm tra lại
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
