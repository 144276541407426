import { useTrans } from "locales/hook";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import useDebouncedCallback from "hooks/useDebounceCallback";
import useWindowDimensions from "hooks/useWindowDimension";
import { useGTM } from "hooks/useGTM";

import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, Tabs } from "@chakra-ui/react";
import ButtonColumnShow from "components/ColumnShow/components/Button";
import CheckBoxContainerV2 from "components/ColumnShow/components/CheckboxV2";
import DragContainerV2 from "components/ColumnShow/components/DragAreaV2";
import EmptyTemplate from "components/EmptyTemplate";
import HRVInputSearch from "components/InputSearch";
import WrapperSpinner from "components/Spinner";

import { getDisabledItems, getPermissionColumn, requiredSelectAtLeastOneItemInGroup } from "helpers/helpers";


function DesktopColumnShow(props) {
    const t = useTrans();

    const {
        groupAreas,
        groupBy,
        reportNameSystem,
        measure,
        measureSelected,
        groupBySelected,
        groupPropertyInteractions,
        measureInteractions,
        onHandleApply,
        onHandleReset,
        defaultGroupBy,
        defaultMeasure,
        optionYAxis,
        onChangeYAxis,
        useMetricToDrawChart = true,
        useMetricToDrawHeatMap = false,
        optionsHeatMap,
        onChangeHeatmaps,
        isDisplay
    } = props;

    const tabList = [
        {
            index: 0,
            name: t("Nhóm theo"),
            id: "0",
        },
        {
            index: 1,
            name: t("Chỉ số"),
            id: '1',
        }
    ];

    const disabledGroupItems = getDisabledItems(reportNameSystem); // ! disabled 1 items trong group
    const disabledAtLeastOneItemInGroup = requiredSelectAtLeastOneItemInGroup(reportNameSystem) // ! chọn ít nhất 1 item được require trong group

    const [tabActive, setTabActive] = useState(0);
    const [groupBySelectedItems, setGroupBySelectedItems] = useState([]);
    const [measureSelectedItems, setMeasureSelectedItems] = useState([]);
    const [isEmptyMeasure, setIsEmptyMeasure] = useState(false);
    const [isEmptyGroupBy, setIsEmptyGroupBy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [yAxis, setYAxis] = useState(optionYAxis);
    const [heatmaps, setHeatmaps] = useState(optionsHeatMap);
    const [inOnScroll, setInOnScroll] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const { width } = useWindowDimensions();

    const [keyword, setKeyword] = useState("");

    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "detail",
            "ut-name": 'apply_column_show'
        }
    });

    const refTab: any = useRef();
    const refCheckboxGroupBy: any = useRef();
    const refCheckboxMeasure: any = useRef();

    let isReadOnly = false;

    let groupByDisplay = getPermissionColumn("groupBy", "hide", reportNameSystem);
    let groupByDrag = groupPropertyInteractions.includes("Drag");
    let groupByDelete = groupPropertyInteractions.includes("Delete");
    let groupByAdd = groupPropertyInteractions.includes("Add");

    let metricDisplay = getPermissionColumn("measure", "hide", reportNameSystem);
    let metricDrag = measureInteractions.includes("Drag");
    let metricDelete = measureInteractions.includes("Delete");
    let metricAdd = measureInteractions.includes("Add");

    const [refTabGroupBy, groupByInView] = useInView({
        threshold: 0.1,
        skip: !!keyword.length
    });

    const [refTabMetrics, metricsInView] = useInView({
        threshold: 0.1,
        skip: !!keyword.length
    });

    useEffect(() => {
        setGroupBySelectedItems(groupBySelected);
    }, [groupBySelected]);

    useEffect(() => {
        // * TH: Popup close thì sẽ hiện default các option
        if (!isVisible) {
            setIsEmptyMeasure(false);
            setIsEmptyGroupBy(false);
        }
    }, [isVisible]);

    useEffect(() => {
        setMeasureSelectedItems(measureSelected);
    }, [measureSelected]);

    // & UseEffect to listener scroll and catching when item inView will active
    useEffect(() => {
        if (!inOnScroll) {
            if (!groupByInView && !metricsInView) return;
            if (groupByInView && metricsInView) return;

            if (groupByInView) {
                setTabActive(0)

            } else if (!groupByInView && metricsInView) {
                setTabActive(1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupByInView, metricsInView])

    // & UseEffect when click setTab this will be scroll to element action
    useEffect(() => {
        setInOnScroll(true);

        const element: any = document.getElementById(`div_${tabActive}`);

        if (element !== null) {
            element.scrollIntoView(
                {
                    behavior: "smooth",
                    block: "start"
                }
            );
        }

        setInOnScroll(false)
    }, [tabActive]);

    const handleBuildCheckboxGroupBy = () => {
        return groupBy.filter(y => y.isDisplay).map((y) => {
            let foundIndex = groupBySelectedItems.findIndex(
                (si: any) => si.groupPropertyName === y.groupPropertyName,
            );

            return {
                group: y.groupAreaName,
                key: y.groupPropertyName,
                value: foundIndex !== -1,
                id: y.id
            };
        });
    }

    const handleBuildCheckboxMeasure = useCallback((list) => {
        return list.filter(y => y.isDisplay).map((y) => {
            let foundIndex = measureSelectedItems.findIndex(
                (si: any) => si.id === y.id,
            );

            return {
                key: y.measureField,
                value: foundIndex !== -1,
                id: y.id
            };
        });
    }, [measureSelectedItems]);

    const onHandleChange = (data) => {
        const { check, type, id } = data;

        if (type === "group_by") {
            const newResult = [...groupBy];

            const updateIndex = newResult.findIndex(
                (obj) => obj.id === id,
            );


            newResult[updateIndex].isSelected = check;

            if (check) {
                const currentList = [...groupBySelectedItems] as any;
                currentList.push(newResult[updateIndex]);
                setGroupBySelectedItems(currentList);

                // * Scroll to item just scroll
            } else {
                const currentList = [...groupBySelectedItems] as any;
                const index = currentList.findIndex(
                    (obj) => obj.id === id,
                );
                currentList.splice(index, 1);

                setGroupBySelectedItems(currentList);
            }
        } else if (type === "measure") {
            const newResult = [...measure];

            const updateIndex = newResult.findIndex(
                (obj) => obj.id === id,
            );

            newResult[updateIndex].isSelected = check;

            if (check) {
                const currentList = [...measureSelectedItems] as any;
                currentList.push(newResult[updateIndex]);
                setMeasureSelectedItems(currentList);
            } else {
                const currentList = [...measureSelectedItems] as any;
                const index = currentList.findIndex((obj) => obj.id === id);
                currentList.splice(index, 1);
                setMeasureSelectedItems(currentList);
            }
        }
    };

    const handleApply = () => {
        onHandleApply && onHandleApply(groupBySelectedItems, measureSelectedItems);

        // * Sử dụng để vẽ trục Y
        if (useMetricToDrawChart) {
            onChangeYAxis && onChangeYAxis(yAxis);
        }

        // * Sử dụng để vẽ Heatmap
        if (useMetricToDrawHeatMap) {
            onChangeHeatmaps && onChangeHeatmaps(heatmaps);
        }

        setKeyword("");

        setIsVisible(false);
    };

    const handleReset = () => {
        setGroupBySelectedItems(defaultGroupBy);
        setMeasureSelectedItems(defaultMeasure);
        setKeyword("")

        onHandleReset && onHandleReset();
    };

    // ! Chỉnh sửa trục Y để vẽ chart
    const handleChangeYAxis = (value) => {
        setYAxis(value);
    };

    // ! Chỉnh sửa heatmap để vẽ
    const handleChangeHeatmap = (value, checked) => {
        let result = [...heatmaps];

        if (checked) {
            result.push(value["measureField"]);
        } else {
            const findIndex = heatmaps.findIndex((y) => y === value["measureField"]);

            if (findIndex !== -1) {
                result.splice(findIndex, 1);
            }
        }

        setHeatmaps(result);
    };

    const handleCloseModal = () => {
        // & Trả về mặc định những select đã áp dụng
        setGroupBySelectedItems(groupBySelected);
        setMeasureSelectedItems(measureSelected);
        setKeyword("")

        setIsVisible(false);
    }

    const handleOpenModal = () => {
        setIsVisible(true);
    }

    const handleChangeDebounced = (value) => {
        if (!value.length) {
            setIsEmptyGroupBy(false)
            setIsEmptyMeasure(false)
        } else {
            setTimeout(() => {
                if (value.length) {
                    const countingGroupby = refCheckboxGroupBy?.current?.getList() || 0;
                    const countingMeasure = refCheckboxMeasure?.current?.getList() || 0;

                    setIsEmptyGroupBy(!Boolean(countingGroupby.length));
                    setIsEmptyMeasure(!Boolean(countingMeasure.length));

                }
            }, 50);
        }

        setIsLoading(false);
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

    const headerModal = () => {
        return <div
            className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-justify-between"
        >
            <p
                className="
                hrv-report-mb-0
                hrv-report-text-color-black-6
                hrv-report-fs-16 
                hrv-report-fw-600
                "
            >{t("Quản lý cột")}</p>

            <span
                style={{
                    width: 18,
                }}
                className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-cursor-pointer
                hrv-report-justify-between
                "
                onClick={() => {
                    handleCloseModal()
                }}
            >
                {svgClose}
            </span>
        </div>
    }

    return (
        <>
            <ButtonColumnShow isDisplay={isDisplay} onClick={handleOpenModal} />

            <Modal isOpen={isVisible} onClose={handleCloseModal} size="md" autoFocus={false} motionPreset={"slideInBottom"} >
                <ModalOverlay />
                <ModalContent
                    minWidth="fit-content"
                    height="fit-content"
                >
                    <ModalHeader w={width} maxW='800px'>{headerModal()}</ModalHeader>

                    <ModalBody
                        w={width}
                        maxW='800px'
                        maxH="520px"
                        overflow={"hidden"}
                        className="hrv-report-scrollbar_custom"
                        p={0}
                    >
                        <Box display="flex" >
                            <Box w={"50%"}>
                                <Box>
                                    <Box pt={6} px={8}>
                                        <HRVInputSearch
                                            placeholder={t("Tìm tên cột")}
                                            isShowCloseButton
                                            value={keyword}
                                            onChange={(value) => {
                                                setIsLoading(true);
                                                setKeyword(value);
                                                debouncedCallback(value)
                                            }}
                                            prefixIcon={svgSearch}
                                            size="m"
                                        />
                                    </Box>


                                    {!keyword.length ? <Tabs index={tabActive} onChange={(index) => {
                                        setTabActive(index)
                                    }} size="md">
                                        <TabList>
                                            {tabList.map((tab, idx) => {
                                                return <Tab key={idx}>{tab.name}</Tab>
                                            })}
                                        </TabList>
                                    </Tabs> : <div className="hrv-report-p-8 " style={{
                                        borderBottom: "1px solid #E5E7EB"
                                    }}></div>}
                                </Box>

                                <Box style={{
                                    maxHeight: "420px",
                                    minHeight: "420px",
                                }} className="hrv-report-scrollbar_custom" overflowX={"hidden"}>
                                    {isLoading ? <Box h={"420px"} display="flex" justifyContent="center" alignItems="center">
                                        <WrapperSpinner />
                                    </Box> : <>
                                        <>
                                            {isEmptyMeasure && isEmptyGroupBy && <Box h={"420px"} display="flex" justifyContent="center" alignItems="center">
                                                <EmptyTemplate text={t("no_data")} />
                                            </Box>}

                                            {!isEmptyGroupBy ? <Box pl={8} ref={refTabGroupBy} id={"div_0"}>
                                                <div ref={tabActive === 0 ? refTab : null} data-section >
                                                    <h3 className="hrv-report-pt-16 hrv-report-mb-0 hrv-report-fs-16 hrv-report-fw-600">{t("Nhóm theo")}</h3>
                                                </div>
                                            </Box > : null}

                                            <Box pb={!isEmptyGroupBy ? 6 : 0} pl={!isEmptyGroupBy ? 8 : 0}>
                                                <CheckBoxContainerV2
                                                    optionGroups={groupAreas}
                                                    options={handleBuildCheckboxGroupBy()}
                                                    onHandleChange={onHandleChange}
                                                    type={"group_by"}
                                                    disabledItems={disabledGroupItems}
                                                    keyword={keyword}
                                                    isAllowAdd={groupByAdd}
                                                    ref={refCheckboxGroupBy}
                                                    disabledAtLeastOneItemInGroup={disabledAtLeastOneItemInGroup}
                                                />
                                            </Box>


                                            {!isEmptyMeasure ? <Box pl={8} ref={refTabMetrics} data-section id={"div_1"}>
                                                <div ref={tabActive === 1 ? refTab : null} >
                                                    <h3 className={`${isEmptyGroupBy ? "hrv-report-pt-16" : "hrv-report-pt-4"} hrv-report-mb-4 hrv-report-fs-16 hrv-report-fw-600`}>{t("Chỉ số")}</h3>
                                                </div>
                                            </Box > : null}

                                            <Box pb={!isEmptyMeasure ? 6 : 0} pl={!isEmptyMeasure ? 8 : 0}>
                                                <CheckBoxContainerV2
                                                    options={handleBuildCheckboxMeasure(measure)}
                                                    onHandleChange={onHandleChange}
                                                    type={"measure"}
                                                    disabledItems={[]}
                                                    keyword={keyword}
                                                    isAllowAdd={metricAdd}
                                                    ref={refCheckboxMeasure}
                                                />
                                            </Box>
                                        </>
                                    </>}
                                </Box>
                            </Box>

                            <Box py={6} px={12} bg={"#F3F4F6"} w={"50%"} style={{
                                overflow: "auto",
                                maxHeight: "520px",
                                minHeight: "520px",
                            }} className="hrv-report-scrollbar_custom">
                                <h3 className={`hrv-report-mb-24 hrv-report-fs-16 hrv-report-fw-600`}>{t("Cột hiển thị")}</h3>

                                <Box>
                                    <Box textTransform="uppercase" fontSize="12px" fontWeight="600" color="#4B5563" mb={4}>{t("Nhóm theo")}</Box>

                                    <DragContainerV2
                                        editable={groupByDisplay}
                                        readOnly={isReadOnly}
                                        list={groupBySelectedItems}
                                        onDelete={(item) => {
                                            const value = {
                                                check: false,
                                                type: "group_by",
                                                id: item.id
                                            }

                                            onHandleChange(value);
                                        }}
                                        type={"group_by"}
                                        onHandleDragEnd={(result) => {
                                            setGroupBySelectedItems(result);
                                        }}
                                        isDrag={groupByDrag}
                                        isDelete={groupByDelete}
                                        disabledGroupItems={disabledGroupItems}
                                        disabledAtLeastOneItemInGroup={disabledAtLeastOneItemInGroup}
                                    />
                                </Box>


                                <Box>
                                    <Box textTransform="uppercase" fontSize="12px" fontWeight="600" color="#4B5563" mt={6} mb={4}>{t("Chỉ số")}</Box>

                                    <DragContainerV2
                                        editable={metricDisplay}
                                        readOnly={isReadOnly}
                                        list={measureSelectedItems}
                                        onDelete={(item) => {
                                            const value = {
                                                check: false,
                                                type: "measure",
                                                id: item.id
                                            }

                                            onHandleChange(value);
                                        }}
                                        type={"measure"}
                                        onHandleDragEnd={(results) => {
                                            setMeasureSelectedItems(results)
                                        }}
                                        useMetricToDrawChart={useMetricToDrawChart}
                                        useMetricToDrawHeatMap={useMetricToDrawHeatMap}
                                        optionYAxis={yAxis}
                                        onChangeYAxis={handleChangeYAxis}
                                        optionsHeatMap={heatmaps}
                                        onChangeHeatMap={handleChangeHeatmap}
                                        isDrag={metricDrag}
                                        isDelete={metricDelete}
                                        disabledGroupItems={[]}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </ModalBody>

                    <ModalFooter maxW='800px' w={width} display="flex" justifyContent="space-between">
                        <Button variant='default-tertiary' mr={4} onClick={() => {
                            handleReset()
                        }}>
                            {t("Đặt lại")}
                        </Button>

                        <Box>
                            <Button variant='default-default' mr={4} onClick={handleCloseModal}>
                                {t("Huỷ")}
                            </Button>

                            <Button
                                variant='default-primary'
                                onClick={() => {
                                    handleApply();
                                }}
                                className={`buttonSubmit ${detectClass}`}
                                {...attributes}
                            >
                                {t("Áp dụng")}
                            </Button>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DesktopColumnShow;

const svgSearch = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g id="search">
        <path id="Vector" d="M13.1291 11.8791H12.4707L12.2374 11.6541C13.0541 10.7041 13.5457 9.47074 13.5457 8.12907C13.5457 5.1374 11.1207 2.7124 8.12907 2.7124C5.1374 2.7124 2.7124 5.1374 2.7124 8.12907C2.7124 11.1207 5.1374 13.5457 8.12907 13.5457C9.47074 13.5457 10.7041 13.0541 11.6541 12.2374L11.8791 12.4707V13.1291L16.0457 17.2874L17.2874 16.0457L13.1291 11.8791ZM8.12907 11.8791C6.05407 11.8791 4.37907 10.2041 4.37907 8.12907C4.37907 6.05407 6.05407 4.37907 8.12907 4.37907C10.2041 4.37907 11.8791 6.05407 11.8791 8.12907C11.8791 10.2041 10.2041 11.8791 8.12907 11.8791Z" fill="#4B5563" />
    </g>
</svg>


const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 5.34167L14.6577 4.16667L9.99935 8.82501L5.34102 4.16667L4.16602 5.34167L8.82435 10L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 10L15.8327 5.34167Z" fill="#9CA3B0" />
    </svg>
);