import { useEffect } from "react";
import useApp from "hooks/useApp";
import { Outlet } from "react-router-dom";

const MasterLayout = () => {
  const { isLoading, toogleFullScreen } = useApp();

  useEffect(() => {
    toogleFullScreen(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`wrapper-container-embbed__new hrv-report-container__new`}>
      {!isLoading ? <Outlet /> : <div className="hrv-report-loading-main"></div>}
    </div>
  );
};

export default MasterLayout;
