import {
  SET_FAVORITES,
  SET_LIMIT_FEATURES,
  SET_AUTHORS,
  TOGGLE_FAVORITE_BTN,
  TOGGLE_FAVORITE_BTN_SUCCESS,
  TOGGLE_FAVORITE_BTN_ERROR,
  FETCHING_REPORT_LIST,
  FETCHING_REPORT_LIST_SUCCESS,
  FETCHING_REPORT_LIST_ERROR,
  TOGGLE_POPUP_CHANGENAME,
  TOGGLE_POPUP_DELETE,
  SET_REPORT_TAB_SELECTED,
  FETCHING_REPORT_LIST_ALL,
  FETCHING_REPORT_LIST_ALL_SUCCESS,
  FETCHING_REPORT_LIST_ALL_ERROR,
  SET_SORT_VALUE,
} from "./constants";

export const setLimitFeatures = (payload: any) => ({
  type: SET_LIMIT_FEATURES,
  payload,
});

export const setFavorites = (payload: any) => ({
  type: SET_FAVORITES,
  payload,
});

export const setAuthors = (payload: any) => ({
  type: SET_AUTHORS,
  payload,
});

export const toggleFavoriteItem = (payload: any) => ({
  type: TOGGLE_FAVORITE_BTN,
  payload,
});

export const toggleFavoriteItemSuccess = (payload: any) => ({
  type: TOGGLE_FAVORITE_BTN_SUCCESS,
  payload,
});

export const toggleFavoriteItemError = (payload: any) => ({
  type: TOGGLE_FAVORITE_BTN_ERROR,
  payload,
});

export const fetchingReportListDefault = () => ({
  type: FETCHING_REPORT_LIST,
});

export const fetchingReportListDefaultSuccess = (payload: any) => ({
  type: FETCHING_REPORT_LIST_SUCCESS,
  payload,
});

export const fetchingReportListDefaultError = () => ({
  type: FETCHING_REPORT_LIST_ERROR,
});

export const fetchingReportListAll = () => ({
  type: FETCHING_REPORT_LIST_ALL,
});

export const fetchingReportListAllSuccess = (payload: any) => ({
  type: FETCHING_REPORT_LIST_ALL_SUCCESS,
  payload,
});

export const fetchingReportListAllError = () => ({
  type: FETCHING_REPORT_LIST_ALL_ERROR,
});

export const togglePopupChangeName = (payload: any) => ({
  type: TOGGLE_POPUP_CHANGENAME,
  payload,
});

export const togglePopupDelete = (payload: any) => ({
  type: TOGGLE_POPUP_DELETE,
  payload,
});

export const setReportTabSelected = (payload: any) => ({
  type: SET_REPORT_TAB_SELECTED,
  payload,
});

export const setSortValue = (payload: any) => ({
  type: SET_SORT_VALUE,
  payload,
});
