import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

function HRVInputSearch({
  placeholder,
  value,
  onChange,
  isShowCloseButton,
  classNames,
  prefixIcon,
  size = "l"
}: any) {
  const handleOnChange = (e) => {
    onChange && onChange(e);
  };

  return (
    <InputGroup>
      <InputLeftElement h={'full'} w={10} children={prefixIcon || svgSearch} />

      <Input
        size={size}
        placeholder={placeholder}
        className={classNames}
        value={value}
        onChange={(e) => {
          handleOnChange(e.target.value)
        }}
        pl={16}
        pr={16}
      />

      <InputRightElement h={'full'} w={isShowCloseButton ? 10 : 0}>
        <>
          {isShowCloseButton && value?.length ? (
            <div
              className="hrv-report-cursor-pointer"
              onClick={() => {
                handleOnChange("");
              }}
            >
              {svgClose}
            </div>
          ) : (
            ""
          )}
        </>
      </InputRightElement>
    </InputGroup>
  );
}

export default HRVInputSearch;

const svgSearch = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.0252 13.8475L18.5943 17.4158L17.4152 18.595L13.8469 15.0258C12.5191 16.0902 10.8677 16.6691 9.16602 16.6667C5.02602 16.6667 1.66602 13.3067 1.66602 9.16667C1.66602 5.02667 5.02602 1.66667 9.16602 1.66667C13.306 1.66667 16.666 5.02667 16.666 9.16667C16.6684 10.8683 16.0895 12.5198 15.0252 13.8475ZM13.3535 13.2292C14.4111 12.1416 15.0017 10.6837 14.9993 9.16667C14.9993 5.94334 12.3885 3.33334 9.16602 3.33334C5.94268 3.33334 3.33268 5.94334 3.33268 9.16667C3.33268 12.3892 5.94268 15 9.16602 15C10.683 15.0024 12.1409 14.4118 13.2285 13.3542L13.3535 13.2292Z"
      fill="#4B5563"
    />
  </svg>
);

const svgClose = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 1.66663C5.39199 1.66663 1.66699 5.39163 1.66699 9.99996C1.66699 14.6083 5.39199 18.3333 10.0003 18.3333C14.6087 18.3333 18.3337 14.6083 18.3337 9.99996C18.3337 5.39163 14.6087 1.66663 10.0003 1.66663ZM13.5837 13.5833C13.2587 13.9083 12.7337 13.9083 12.4087 13.5833L10.0003 11.175L7.59199 13.5833C7.26699 13.9083 6.74199 13.9083 6.41699 13.5833C6.09199 13.2583 6.09199 12.7333 6.41699 12.4083L8.82533 9.99996L6.41699 7.59163C6.09199 7.26663 6.09199 6.74163 6.41699 6.41663C6.74199 6.09163 7.26699 6.09163 7.59199 6.41663L10.0003 8.82496L12.4087 6.41663C12.7337 6.09163 13.2587 6.09163 13.5837 6.41663C13.9087 6.74163 13.9087 7.26663 13.5837 7.59163L11.1753 9.99996L13.5837 12.4083C13.9003 12.725 13.9003 13.2583 13.5837 13.5833Z"
      fill="#D1D5DB"
    />
  </svg>
);
