import "./styles.scss"

function HTable(props) {
    const { className, renderTableHeader, renderTableBody, style } = props;

    return (
        <div className="hrv-table-container hrv-report-bg-white hrv-report-scrollbar hrv-report-border-4 hrv-report-custom-product-status-table">
            <table className={`hrv-report-table ${className}`} style={{
                ...style
            }}>
                <thead>
                    {renderTableHeader}
                </thead>
                <tbody>
                    {renderTableBody}
                </tbody>
            </table>
        </div>

    )
}

export default HTable