import { useRef, useState } from "react";
import { useTrans } from "locales/hook";

import { formatCurrency, valueFromCurrency } from "helpers/helpers";
import { POINT_SCALE_COLORS_RFM, POINT_SCALE_OPERATORS_RFM } from "./constants";

type TPercentages = {
    value: number,
    proportion: number,
    index: number, // * index càng nhỏ thì càng nằm lên trên đầu mảng
    color: string,
}

interface IProps {
    options: Array<TPercentages>
    description: string
    isShowTick: boolean
    isReverse: boolean
    maxValue: number,
    isMoney?: boolean,
    content: any
}

const HEIGHT_PROCESS_AREA = 30;

function Process(props: IProps) {
    const {
        options,
        description,
        isShowTick,
        isReverse,
        maxValue,
        isMoney = false,
        content
    } = props;

    const t = useTrans();
    const refLine = useRef<any>(null);
    const [activeId, setActiveId] = useState<any>(null);


    const pointBlock = ({ point, bgColor, borderColor }) => {
        return <div
            className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-mb-8"
            style={{
                background: bgColor,
                border: `1px solid ${borderColor}`,
                width: "46px",
                height: "24px",
                borderRadius: "3px"
            }}
        >
            <span className="hrv-report-fs-12 hrv-report-text-color-black-6 hrv-report-fw-400">{point} điểm</span>
        </div>
    }


    const operators = (key, point) => {
        let before = "";
        let after = "";
        const getOperator = POINT_SCALE_OPERATORS_RFM[key][point];

        switch (getOperator) {
            case "morethan":
                before = "from"
                after = 'more'
                break;

            case "fromto":
                before = "is"
                after = "to"
                break;

            case "fromtobelow":
                before = 'from'
                after = 'to_below'
                break;

            case "lowerthan":
                before = "below"
                break;

            default:
                break;
        }

        return {
            before,
            after
        }
    };

    const unitBlock = (key, reversePoint?: any) => {
        let unit = "";

        switch (key) {
            case 'r':
                unit = "ngày"
                break;

            case 'f':
                unit = "unit_order"
                break;

            default:
                break;
        }

        if (reversePoint) {
            if (reversePoint === 5 && key === "f") {
                unit = ""
            }

            if (reversePoint === 1 && key === "r") {
                unit = ""
            }
        }

        return unit
    }

    return (
        <div className="hrv-report-mb-16 hrv-report-border-black-7 hrv-report-border-8 hrv-report-py-12 hrv-report-px-16"
            style={{
                background: "#f9fafb"
            }}
        >
            <p className="hrv-report-mb-10 hrv-report-fs-12 hrv-report-fw-500 hrv-report-text-color-gray-9">{description}</p>

            <div className="hrv-report-w-full hrv-report-relative">
                <div
                    className="hrv-report-relative"
                    style={{
                        bottom: 0,
                        height: HEIGHT_PROCESS_AREA,
                        width: "100%",
                        background: '#f9fafb',
                    }}
                    ref={refLine}
                >
                    {refLine?.current && options.map((option, idx) => {
                        const shouldAddProportion = (index, idx) => idx <= index;

                        const calcSumProportion = options.reduce((acc, curr, index) => {
                            return shouldAddProportion(index, idx) ? acc + curr.proportion : acc;
                        }, 0);

                        const calcSumProportionPrevious = options.reduce((acc, curr, index) => {
                            return idx <= index - 1 ? acc + curr.proportion : acc;
                        }, 0);

                        const calcLeft = (calcSumProportion * refLine?.current?.clientWidth) / 100;
                        const calcLeftPrevious = (calcSumProportionPrevious * refLine?.current?.clientWidth) / 100;

                        const position = [5, 4, 3, 2, 1];
                        const reversePoint = position[activeId] ?? 0;
                        const getColors = POINT_SCALE_COLORS_RFM[reversePoint];
                        const { before, after } = operators(content?.key, reversePoint);

                        const isLast = idx === 0;

                        const getInputValue = (number) => {
                            return content.value[reversePoint - number]
                        }

                        return <div key={idx}
                        >
                            <div
                                style={{
                                    bottom: 0,
                                    height: HEIGHT_PROCESS_AREA,
                                    zIndex: option.index,
                                    transition: "all 0.7s ease",
                                    left: 0,
                                    cursor: "pointer",
                                    width: `${calcSumProportion}%`,
                                }}
                                className="hrv-report-absolute"
                                onMouseMove={(event) => {
                                    setActiveId(idx);
                                }}
                                onMouseLeave={() => {
                                    setActiveId(null);
                                }}
                            />

                            <div
                                className="hrv-report-absolute"
                                style={{
                                    background: 'white',
                                    width: `${calcSumProportion}%`,
                                    height: 4,
                                    zIndex: option.index + 1,
                                    bottom: 0,
                                    opacity: activeId === null ? 0 : idx !== activeId ? 0.6 : 0,
                                    transition: "all 0.3s ease",
                                }}
                            />

                            <div
                                className="hrv-report-absolute"
                                style={{
                                    background: option.color,
                                    width: `${calcSumProportion}%`,
                                    height: 4,
                                    zIndex: option.index,
                                    bottom: 0,
                                    transition: "all 0.3s ease",
                                }}>

                                {<div className="hrv-report-relative hrv-report-p-8"
                                    style={{
                                        top: 10,
                                        left: calcLeftPrevious > refLine?.current?.clientWidth - 160 ? calcLeft - 160 : calcLeftPrevious,
                                        display: idx === activeId ? "block" : "none",
                                        width: 160,
                                        background: "#fff",
                                        borderRadius: 8,
                                        boxShadow: `0px 12px 24px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.24)`,
                                        zIndex: 9999
                                    }}
                                >
                                    <div>
                                        {pointBlock({
                                            point: reversePoint,
                                            bgColor: getColors?.bgColor,
                                            borderColor: getColors?.borderColor
                                        })}

                                        <span className="hrv-report-d-inline-block hrv-report-fs-12 hrv-report-fw-400 hrv-report-text-color-black-6" >
                                            {`
                                            ${t(`${content.property}_property`)} 
                                            ${t(before)}
                                            
                                            ${content.key === "r" ?
                                                    `${!getInputValue(0) ? 0 : getInputValue(0) + 1} 
                                                    ${reversePoint !== 1 ? t(after) : ""} 
                                                    ${reversePoint === 1 ? `${t(unitBlock(content.key))} ${t(after)}` : ""}
                                                    ${reversePoint !== 1 ? `${getInputValue(1)}` : ""}
                                                    ${t(unitBlock(content.key, reversePoint))}`
                                                    : ""}

                                            ${content.key === "f" ?
                                                    `${reversePoint !== 1 ? `${getInputValue(2) ? getInputValue(2) : 1}` : ""}
                                                    ${reversePoint === 5 ? `${t(unitBlock(content.key))}` : ""}
                                                    ${t(after)}
                                                    ${reversePoint !== 5 ? `${getInputValue(1)}` : ""}
                                                    ${t(unitBlock(content.key, reversePoint))}
                                                    ` : ""}

                                            ${content.key === "m" ? `
                                                    ${reversePoint !== 1 ? !getInputValue(2) ? 0 : valueFromCurrency(getInputValue(2)) : ""}
                                                    ${t(after)}
                                                    ${reversePoint !== 5 ? `${getInputValue(1) ? valueFromCurrency(getInputValue(1)) : 1}` : ""}
                                                    `: ""}
                                            `}
                                        </span>
                                    </div>
                                </div>}

                                {isShowTick && !isLast && <>
                                    <div className="hrv-report-absolute"
                                        style={{
                                            bottom: (refLine?.current?.clientHeight - HEIGHT_PROCESS_AREA),
                                            height: 10,
                                            width: 1,
                                            background: "#d1d5db",
                                            left: `${calcLeft - 0.5}px`,
                                            transition: "all 0.7s ease"
                                        }}>
                                    </div>

                                    <div className="hrv-report-absolute"
                                        style={{
                                            bottom: (refLine?.current?.clientHeight - HEIGHT_PROCESS_AREA) + 10,
                                            left: `${calcLeft - 6}px`,
                                            transition: "all 0.7s ease",
                                            width: 50
                                        }}
                                    >
                                        <p className="hrv-report-fs-12 hrv-report-fw-400 hrv-report-text-color-black-6 hrv-report-mb-0">
                                            {isReverse ? options[idx - 1].value :
                                                isMoney ? formatCurrency(Math.round(option.value), true, 0)
                                                    : option.value
                                            }
                                        </p>
                                    </div>
                                </>}
                            </div>
                        </div>
                    })}


                    <div className="hrv-report-absolute"
                        style={{
                            bottom: (refLine?.current?.clientHeight - HEIGHT_PROCESS_AREA) + 6,
                            left: `0px`,
                        }}
                    >
                        <p className="hrv-report-fs-12 hrv-report-fw-400 hrv-report-text-color-gray-12 hrv-report-mb-0">
                            {!isReverse ? 1 : isMoney ? maxValue === Infinity ? "∞" : formatCurrency(Math.round(maxValue), true, 0) : maxValue === Infinity ? "∞" : maxValue}
                        </p>
                    </div>


                    <div className="hrv-report-absolute"
                        style={{
                            bottom: (refLine?.current?.clientHeight - HEIGHT_PROCESS_AREA) + 6,
                            right: `0px`,
                        }}
                    >
                        <p className="hrv-report-fs-12 hrv-report-fw-400 hrv-report-text-color-gray-12 hrv-report-mb-0">
                            {isReverse ? 0 : isMoney ? maxValue === Infinity ? "∞" : formatCurrency(Math.round(maxValue), true, 0) : maxValue === Infinity ? "∞" : maxValue}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Process;