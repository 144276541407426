import { useState, useEffect, EffectCallback, useCallback } from "react";
import { useTrans } from "locales/hook";
import { useAutoCompleteV2 } from "components/Filter/swr/useAutocomplete";

// ! Components
import { Controller } from "react-hook-form";
import { Box, Button, Divider, Input } from "@chakra-ui/react";
import SearchableDropdown from "../searchableDropdown";
import HSelection from "components/Selection";
import SelectionMulti from "../selectionMulti";
import HChip from "components/Chip";
import TooltipWithTouch from "components/TooltipWithTouch";
import TreeContainer from "../TreeContainer/container";

// ! Helpers
import { FormatDecimalQuantity, middleEllipsisHandler, xmlInputWithFieldName } from "helpers/helpers";

interface IProps {
    onHandleRemove: Function;
    index: number;
    control?: any;
    dimensionList: any;
    defaultDimensionList: any;
    setValue: Function;
    getValue: Function;
    dateStart: any;
    dateEnd: any;
    reportId?: string;
    watch?: any;
    setDisabledSubmit?: any;
    disabled?: boolean;
    reportNameSystem: string
}

const DEFAULT_QUERY_TEMPLATE = {
    conjunction: "or",
    displayText: "",
    query: "",
    symbol: "is",
    value: "",
}

const DEFAULT_ERRORS = {
    from: "",
    to: ""
}

function FilterScore(props: IProps) {
    const t = useTrans();

    const {
        onHandleRemove,
        index,
        control,
        dimensionList,
        setValue,
        defaultDimensionList,
        dateStart,
        dateEnd,
        reportId,
        watch,
        setDisabledSubmit,
        disabled,
        reportNameSystem
    } = props;

    const getDimension = watch(`filter.${index}.dimension`);
    const getQuery = watch(`filter.${index}.query`);

    const [operators, setOperators] = useState<any>([]);
    const [operatorSelected, setOperatorSelected] = useState("is");
    const [keyword, setKeyword] = useState<any>("");
    const [valueInText, setValueInText] = useState("");
    const [valueInNumber, setValueInNumber] = useState<any>(0); // handle number and handle start in range
    const [valueInRangeTo, setValueInRangeTo] = useState(""); // handle end in range
    const [callApi, setCallApi] = useState(false);
    const [errorMgs, setErrorMgs] = useState(DEFAULT_ERRORS)

    const isCallApiByProductCategoryId = getDimension === "ProductCategoryId"
    const { data, isLoading } = useAutoCompleteV2({
        id: reportId,
        body: {
            enddate: dateEnd,
            startdate: dateStart,
            dimension: getDimension,
            keyword: keyword || "",
        },
        preventFetch: isCallApiByProductCategoryId ? false : !callApi
    });

    const useEffectGetOperators = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(effect, [getDimension])
    };

    const useEffectSetDefaultForInput = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(effect, [getDimension, operatorSelected])
    };

    useEffectGetOperators(() => {
        handleAutoChangeOperatorWhenChangeDimension(getDimension);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffectSetDefaultForInput(() => {
        if (xmlInputWithFieldName(getDimension) === "treeListWithSingle") {
            let cloneQuery = [...getQuery];

            cloneQuery = cloneQuery.map(query => {
                const findSelectedSingleTree = data.data.find(y => y.id === query.value)

                return {
                    ...query,
                    fullName: findSelectedSingleTree?.specialInfo?.fullName || ""
                }
            });

            setValue(`filter.${index}.query`, cloneQuery, true);
        }

        // & Chỉ set cho dimension là numberField
        if (xmlInputWithFieldName(getDimension) === "numberField") {
            if (getQuery[0]?.value?.length && getQuery[0]?.value.includes("|")) {
                let [from, to] = getQuery[0]?.value.split("|")

                setValueInNumber(from)
                setValueInRangeTo(to)

                buildQueryForInput(`${from}|${to}`, 'range');
            } else {
                setValueInNumber(getQuery[0]?.value);

                buildQueryForInput(getQuery[0]?.value, 'number');
            }
        }
    });

    useEffect(() => {
        if (!errorMgs?.from?.length && !errorMgs?.to?.length) {
            setDisabledSubmit(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMgs])


    const handleAutoChangeOperatorWhenChangeDimension = (currentDimension) => {
        if (currentDimension && currentDimension.length) {
            const dimen = defaultDimensionList.find(
                (y) => y.fieldName === currentDimension
            );

            if (JSON.stringify(operators) !== JSON.stringify(dimen?.acceptOperators)) {
                setOperators(dimen.acceptOperators);

                if (getDimension.length && getQuery.length) {
                    //* TH đã chọn dimension thì lấy dimension default
                    setOperatorSelected(getQuery[0].symbol)
                } else {
                    //* TH chưa chọn dimension thì lấy dimension đc accpet option đầu tiên
                    setOperatorSelected(dimen.acceptOperators[0].symbol);
                }
            } else {
                setOperatorSelected(dimen.acceptOperators[0].symbol);
            }
        }
    };

    const handleChooseMultipleQuery = (query) => {
        const { check, name, value } = query;
        const cloneQuery = [...getQuery];

        let final: any = [];

        if (check) {
            const findItem = data.data.find(y => y.value === value);

            cloneQuery.push({
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${findItem.displayname_vi}`,
                query: name,
                value: findItem.value,
            })


            final = cloneQuery
        } else {
            const findIndex = getQuery.findIndex(y => y.query === name);

            cloneQuery.splice(findIndex, 1);

            final = cloneQuery;
        }

        setValue(`filter.${index}.query`, final, true);
    }

    const handleChooseSingleQuery = (query) => {
        const { name, value } = query;

        const findItem = data.data.find(y => y.value === value);

        const final = [
            {
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${findItem.displayname_vi}`,
                query: name,
                value: findItem.value,
            }
        ]

        setValue(`filter.${index}.query`, final, true);
    }

    const handleChooseTreeViewCheckbox = (list) => {
        let final = list.map(item => {
            return {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${item.displayname_vi}`,
                query: item.name,
                value: item.value,
                fullName: item.specialInfo.fullName
            }
        });

        setValue(`filter.${index}.query`, final, true);
    }

    const handleChangeOperator = (operator) => {
        const changeOperatorInQuery = [...getQuery];

        if (operator !== 'range') {
            setValueInRangeTo("")
        }
        let final = changeOperatorInQuery.map(query => {
            let fixValue = query.query.includes("|") && operator === 'range' ? query.query : query.value.split("|")[0]

            return {
                ...query,
                symbol: operator,
                displayText: `${t(getDimension)} ${t(operator)} ${fixValue}`,
                conjunction: operator === 'is' ? 'or' : "and",
                value: fixValue
            }
        });

        setValue(`filter.${index}.query`, final, true);

        setValueInText("");

        setErrorMgs(DEFAULT_ERRORS);
    }

    // & Mỗi lần input onchange thì function này được gọi lại chưa tối ưu lắm
    const buildQueryForInput = useCallback((value, type) => {
        const cloneQuery = [...getQuery];

        if (type === 'string') {
            // ! Xử lý khi dữ liệu input vào thuộc type là String (thêm query đó vào)
            cloneQuery.push({
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${value}`,
                query: value,
                value,
            })
        }

        if (type === 'number') {
            // ! Xử lý khi dữ liệu input vào thuộc type là Number (thay thế query đó)
            cloneQuery[0] = {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${value || "0"}`,
                query: value || "0",
                value: value === 0 ? 0 : `${FormatDecimalQuantity(value || 0)}`,
            }
        }

        if (type === 'range') {
            // ! Xử lý khi dữ liệu input vào thuộc type là Range (Biến đổi kiểu dữ liệu)
            cloneQuery[0] = {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: 'range',
                displayText: `${t(getDimension)} ${t(operatorSelected)} ${value}`,
                query: value,
                value: value,
            }
        }

        setValue(`filter.${index}.query`, cloneQuery);

        setTimeout(() => {
            setValueInText("");
        }, 60);
    }, [getDimension, getQuery, index, operatorSelected, setValue, t])

    const handleOpenSearchBox = (isOpen) => {
        // ! Khi searchbox open thì mới gọi API autocomplete
        if (isOpen) {
            setCallApi(true)
        }
    }

    // & Listener input is pressed enter key
    const handlePressedEnterKey = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (valueInText.length) {
                buildQueryForInput(valueInText, 'string');
            }
        }
    }

    const handleCheckValidData = () => {
        let messages: any = {};

        if (valueInNumber === null || typeof valueInNumber === 'undefined' || !valueInNumber.length) {
            messages = {
                ...errorMgs,
                from: t("Not_be_empty")
            }

            setDisabledSubmit(true)
        }
        if (valueInRangeTo === null || typeof valueInRangeTo === 'undefined' || !valueInRangeTo.length) {
            messages = {
                ...errorMgs,
                to: t("Not_be_empty")
            }

            setDisabledSubmit(true)

        }

        if (valueInNumber > parseInt(valueInRangeTo)) {
            messages = {
                ...errorMgs,
                to: t("not_less_than_from")
            }

            setDisabledSubmit(true)
        }

        setErrorMgs(messages)
    }

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box flex="1" >
                        {/* Dimensions */}
                        <Controller
                            name={`filter.${index}`}
                            control={control}
                            render={({ field }) => {
                                return <SearchableDropdown
                                    defaultSelect={field.value.dimension}
                                    options={dimensionList}
                                    inputName={`filter.${index}`}
                                    name="filterName"
                                    value="fieldName"
                                    onHandleChange={(value) => {
                                        field.onChange({
                                            ...field.value,
                                            dimension: value,
                                            query: []
                                        });

                                        setValue(`filter.${index}.dimension`, value);

                                        setErrorMgs(DEFAULT_ERRORS);
                                    }}
                                    disabled={disabled}
                                />
                            }}
                        />
                    </Box>

                    <Box px="4">
                        {/* Operators */}
                        <Controller
                            name={`filter.${index}.dimension`}
                            control={control}
                            render={({ field }) => {
                                return <HSelection
                                    key={`symbol_${field.value.dimension}`}
                                    dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                                    isOptGroup={false}
                                    options={operators}
                                    selectKey={field.value.dimension}
                                    name="symbol"
                                    value="symbol"
                                    defaultValue={operatorSelected}
                                    disabled={!operators.length || disabled}
                                    onChange={(value) => {
                                        setOperatorSelected(value);

                                        handleChangeOperator(value);

                                        if (value === 'range') {
                                            handleCheckValidData()
                                        }
                                    }}
                                    matchWidth={false}
                                    minW={150}
                                />
                            }}
                        />
                    </Box>

                    <Box>
                        <Button
                            variant="default-default"
                            onClick={() => {
                                onHandleRemove && onHandleRemove(index)
                            }}
                            rightIcon={svgDelete}
                            iconSpacing={0}
                            w={"32px"}
                        />
                    </Box>
                </Box>

                <Box mt='4' display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box flex="1" mr={4}>
                        <Controller
                            name={`filter.${index}`}
                            control={control}
                            render={({ field }) => {
                                return <>
                                    {/** Gọi API */}
                                    {xmlInputWithFieldName(field.value.dimension) === "inputMultipleSearchDB" ? (
                                        <SelectionMulti
                                            label={`${index}_autocomplete`}
                                            options={data ? data?.data : []}
                                            placeholder={t("search")}
                                            name="displayname_vi"
                                            valueName="value"
                                            onChangeSearch={(keyword) => {
                                                setKeyword(keyword);
                                            }}
                                            isLoading={isLoading}
                                            withFetchAPI={true}
                                            isMultiple={true}
                                            chooseList={getQuery}
                                            onChoose={handleChooseMultipleQuery}
                                            index={index}
                                            onOpenSearch={handleOpenSearchBox}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/** Gọi API */}
                                    {xmlInputWithFieldName(field.value.dimension) === "inputSingleSearchDB" ? (
                                        <SelectionMulti
                                            label={`${index}_autocomplete`}
                                            options={data ? data?.data : []}
                                            placeholder={t("search")}
                                            name="displayname_vi"
                                            valueName="value"
                                            onChangeSearch={(keyword) => {
                                                setKeyword(keyword);
                                            }}
                                            isLoading={isLoading}
                                            withFetchAPI={true}
                                            isMultiple={true}
                                            chooseList={getQuery}
                                            onChoose={handleChooseSingleQuery}
                                            index={index}
                                            onOpenSearch={handleOpenSearchBox}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/** Không gọi API */}
                                    {xmlInputWithFieldName(field.value.dimension) === "inputMultipleSearchLocal" ? (
                                        <SelectionMulti
                                            label={`${index}_autocomplete`}
                                            options={data ? data?.data : []}
                                            placeholder={t("search")}
                                            name="displayname_vi"
                                            valueName="value"
                                            onChangeSearch={(keyword) => {
                                                setKeyword(keyword);
                                            }}
                                            isLoading={isLoading}
                                            withFetchAPI={false}
                                            isMultiple={true}
                                            onChoose={handleChooseMultipleQuery}
                                            chooseList={getQuery}
                                            index={index}
                                            onOpenSearch={handleOpenSearchBox}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/** Không gọi API */}
                                    {xmlInputWithFieldName(field.value.dimension) === "inputSingleSearchLocal" ? (
                                        <SelectionMulti
                                            label={`${index}_autocomplete`}
                                            options={data ? data?.data : []}
                                            placeholder={t("search")}
                                            name="displayname_vi"
                                            valueName="value"
                                            onChangeSearch={(keyword) => {
                                                setKeyword(keyword);
                                            }}
                                            isLoading={isLoading}
                                            withFetchAPI={false}
                                            isMultiple={false}
                                            onChoose={handleChooseSingleQuery}
                                            chooseList={getQuery}
                                            index={index}
                                            onOpenSearch={handleOpenSearchBox}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/* Input text */}
                                    {xmlInputWithFieldName(field.value.dimension) ===
                                        "inputField" ? (
                                        <Input
                                            size={"m"}
                                            placeholder={t("enter_value")}
                                            value={valueInText}
                                            onChange={(e: any) => {
                                                setValueInText(e.target.value);
                                            }}
                                            onKeyDown={handlePressedEnterKey}
                                            onBlur={() => {
                                                if (valueInText.length) {
                                                    buildQueryForInput(valueInText, 'string')
                                                }
                                            }}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/* Input number */}
                                    {xmlInputWithFieldName(field.value.dimension) ===
                                        "numberField" && operatorSelected !== 'range' ? (
                                        <Input
                                            size={"m"}
                                            type="number"
                                            placeholder={t("enter_value")}
                                            value={valueInNumber}
                                            onChange={(e: any) => {
                                                let value = e.target.value;
                                                setValueInNumber(value);
                                                buildQueryForInput(`${FormatDecimalQuantity(value)}`, 'number')
                                            }}
                                            isDisabled={disabled}
                                        />
                                    ) : null}

                                    {/* Input range */}
                                    {xmlInputWithFieldName(field.value.dimension) ===
                                        "numberField" && operatorSelected === 'range' ? (
                                        <Box display={'flex'} alignItems={"flex-start"} w={"100%"}>
                                            <Box w={"50%"} display={'flex'} alignItems={"flex-start"} >
                                                <Box mx={4} pt={5}>{t("from")}</Box>

                                                <Box flex={1} >
                                                    <Input
                                                        size={"m"}
                                                        errorBorderColor="red"
                                                        isInvalid={Boolean(errorMgs?.from?.length)}
                                                        type="number"
                                                        placeholder={t("enter_value")}
                                                        value={valueInNumber}
                                                        onChange={(e: any) => {
                                                            let value = e.target.value;

                                                            setValueInNumber(value)

                                                            buildQueryForInput(`${FormatDecimalQuantity(value)}|${FormatDecimalQuantity(valueInRangeTo)}`, 'range');

                                                            setErrorMgs({
                                                                ...errorMgs,
                                                                from: ""
                                                            })
                                                        }}
                                                        onBlur={() => {
                                                            handleCheckValidData()
                                                        }}
                                                        disabled={disabled}
                                                    />

                                                    {Boolean(errorMgs?.from?.length) ? <Box textColor={"#DC2828"} mt={3}>
                                                        {errorMgs?.from}
                                                    </Box> : ""}
                                                </Box>
                                            </Box>

                                            <Box w={"50%"} display={'flex'} alignItems={"flex-start"} >
                                                <Box mx={4} pt={5}>{t("to")}</Box>

                                                <Box flex={1} position={'relative'}>
                                                    <Input
                                                        size={"m"}
                                                        isInvalid={Boolean(errorMgs?.to?.length)}
                                                        variant="autocomplete"
                                                        type="number"
                                                        placeholder={t("enter_value")}
                                                        value={valueInRangeTo}
                                                        onChange={(e: any) => {
                                                            setValueInRangeTo(e.target.value);

                                                            buildQueryForInput(`${FormatDecimalQuantity(valueInNumber)}|${FormatDecimalQuantity(e.target.value)}`, 'range');

                                                            setErrorMgs({
                                                                ...errorMgs,
                                                                to: ""
                                                            })
                                                        }}
                                                        onBlur={() => {
                                                            handleCheckValidData()
                                                        }}
                                                        disabled={disabled}
                                                    />

                                                    {Boolean(errorMgs?.to?.length) ? <Box textColor={"#DC2828"} mt={3}>
                                                        {errorMgs?.to}
                                                    </Box> : ""}
                                                </Box>

                                            </Box>
                                        </Box>

                                    ) : null}

                                    {/* Input treelist */}
                                    {xmlInputWithFieldName(field.value.dimension) ===
                                        "treeListWithSingle" ? (
                                        <TreeContainer
                                            label={`${index}_treelist`}
                                            options={data ? data?.data : []}
                                            placeholder={t("search")}
                                            name="displayname_vi"
                                            valueName="value"
                                            isLoading={isLoading}
                                            isMultiple={false}
                                            onChoose={handleChooseTreeViewCheckbox}
                                            chooseList={getQuery}
                                            isDisabled={disabled}
                                            onOpenSearch={handleOpenSearchBox}
                                        />
                                    ) : null}


                                    {!field.value.dimension.length ? <SelectionMulti
                                        label={`${index}_autocomplete`}
                                        options={[]}
                                        placeholder={t("search")}
                                        name="displayname_vi"
                                        isDisabled
                                    /> : null}

                                    {
                                        !!getQuery.length && xmlInputWithFieldName(getDimension) !== "numberField" && (
                                            <div className="hrv-report-row">
                                                {getQuery.map((filter, idx) => {
                                                    const isTreeview = xmlInputWithFieldName(field.value.dimension) === "treeListWithSingle"
                                                    const isShowTooltip = isTreeview && filter?.fullName?.trim().split(">").length > 2;

                                                    return <HChip
                                                        size="md"
                                                        key={idx}
                                                        content={
                                                            isTreeview ?
                                                                middleEllipsisHandler(filter?.fullName?.trim() || "") :
                                                                t(filter.query.trim())
                                                        }
                                                        onHandleClose={() => {
                                                            handleChooseMultipleQuery({
                                                                check: false,
                                                                name: filter.query,
                                                            });
                                                        }}
                                                        tooltipLabel={isShowTooltip ? filter?.fullName?.trim() || "" : ""}
                                                        showTooltip={isShowTooltip}
                                                    />
                                                })}
                                            </div>
                                        )
                                    }
                                </>
                            }}
                        />
                    </Box>

                    <Box w={"32px"} h={'32px'} display={'flex'} alignItems={"center"} justifyContent={'center'}>
                        {getDimension === 'CollectionIds' && reportNameSystem !== "stock_onhand" && <TooltipWithTouch label={t("Chỉ hiển thị 500 sản phẩm doanh thu cao nhất (áp dụng nhóm <= 10,000 sản phẩm)")} hasArrow>
                            {svgInfo}
                        </TooltipWithTouch>}
                    </Box>
                </Box>
            </Box>


            <Divider orientation='horizontal' borderColor="#E5E7EB" />
        </>
    )
}

export default FilterScore;

const svgDelete = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M13.3332 7.5V15.8333H6.6665V7.5H13.3332ZM12.0832 2.5H7.9165L7.08317 3.33333H4.1665V5H15.8332V3.33333H12.9165L12.0832 2.5ZM14.9998 5.83333H4.99984V15.8333C4.99984 16.75 5.74984 17.5 6.6665 17.5H13.3332C14.2498 17.5 14.9998 16.75 14.9998 15.8333V5.83333Z" fill="#4B5563" />
</svg>

const svgInfo = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
</svg>