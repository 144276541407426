import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { useMousePosition } from "hooks/useMousePosition";
// import useWindowDimensions from "hooks/useWindowDimension";

function HeatmapTooltip(props) {
  const { isShow, children } = props;
  const refContentTooltip = useRef<any>(null);
  const { x, y }: any = useMousePosition();
  // const { width } = useWindowDimensions();

  // let byBigScreen = width > 1245 ? 280 : 0;

  const [distanceTop, setDistanceTop] = useState(0);

  const getTable: any = document.querySelector(".hrv-report-table-container");
  let fromTop: any = getTable?.getBoundingClientRect().top;
  let heightOfTable: any = getTable?.getBoundingClientRect().height;
  let widthOfTable: any = getTable?.getBoundingClientRect().width;
  // let fromScroll: any = getTable?.scrollLeft;
  let windowScrollDistance: any = document.documentElement.scrollTop;

  const getContentWidth = refContentTooltip?.current?.clientWidth;
  const getContentHeight = refContentTooltip?.current?.clientHeight;
  const DISTANCE_OEFFECIENT = 30; // ! Hệ số khoảng cách

  useEffect(() => {
    setDistanceTop(fromTop);
  }, [fromTop]);

  const conditionShowTooltip = () => {
    const isBottom = heightOfTable + fromTop;

    return isShow && fromTop < y && y < isBottom;
  };

  const positionContent = () => {
    let position = "";

    // ! Nếu con mouse scroll tới 1 nửa màn hình thì nhảy sang phải
    // ! Nếu con mouse scroll tới 1 nửa cái table thì nhảy lên trên còn lại ở dưới
    const hoverInTableWithHeight = y - distanceTop;

    let xAxis = 0;
    let yAxis = 0;

    if (x > Math.abs(widthOfTable - getContentWidth)) {
      xAxis = 1;
    } else {
      xAxis = -1;
    }

    if (
      hoverInTableWithHeight >
      Math.abs(heightOfTable - getContentHeight - DISTANCE_OEFFECIENT) &&
      y > 60
    ) {
      yAxis = 1;
    } else {
      yAxis = -1;
    }

    position = `${xAxis >= 1 ? "right" : "left"}${yAxis >= 1 ? "bottom" : "top"
      }`;

    return position;
  };

  const keyDom: any = document.getElementById("portal-tooltip")
  // const browserZoomLevel = Math.round(window.devicePixelRatio * 100);

  return (
    <>
      {createPortal(
        <>
          {conditionShowTooltip() ? (
            <div
              style={{
                background: "#ffffff",
                color: "#333333",
                position: "absolute",
                top:
                  positionContent() === "lefttop" ||
                    positionContent() === "righttop"
                    ? 0
                    : positionContent() === "leftbottom" ||
                      positionContent() === "rightbottom"
                      ? -getContentHeight - DISTANCE_OEFFECIENT
                      : 0,
                left:
                  positionContent() === "lefttop" ||
                    positionContent() === "leftbottom"
                    ? 0
                    : positionContent() === "righttop" ||
                      positionContent() === "rightbottom"
                      ? -getContentWidth - DISTANCE_OEFFECIENT
                      : 0,
                zIndex: 2,
                transform: `translate(${
                  // ((x + fromScroll - byBigScreen) * browserZoomLevel) / 100
                  x + DISTANCE_OEFFECIENT
                  }px, ${y + windowScrollDistance + DISTANCE_OEFFECIENT}px)`,
                transition: "transform 0.1s",
                opacity: isShow ? 1 : 0,
                boxShadow:
                  "0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24)",
                borderRadius: 4,
              }}
              ref={refContentTooltip}
            >
              <div className="hrv-report-p-12">
                <>{children}</>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>,
        keyDom
      )}
    </>
  );
}

export default memo(HeatmapTooltip);
