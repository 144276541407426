import { Box, Button, PlacementWithLogical } from "@chakra-ui/react";
import TooltipWithTouch from "components/TooltipWithTouch";
import { middleEllipsisHandler } from "helpers/helpers";

interface IProps {
  text: string;
  customClass?: string;
  isLink?: boolean;
  link?: string;
  lengthText?: number;
  placement?: PlacementWithLogical;
  isLinkSecondary?: boolean; // * Cũng là link nhưng dành cho shipping (styling tối màu)
  tooltipSecondary?: string; // * tooltip cho shipping khi hover
  notDisplayTooltip?: boolean; // * tooltip có cần show hay không
  showButton?: boolean;
  onHandleClickButton?: Function;
  middleEllipsis?: boolean
}

function HLimitLongString(props: IProps) {
  const {
    text,
    customClass,
    link,
    isLink,
    lengthText = 30,
    placement = "top-start",
    isLinkSecondary,
    tooltipSecondary,
    notDisplayTooltip = false,
    showButton = false,
    onHandleClickButton,
    middleEllipsis
  } = props;

  const isLongText = (text) => {
    if (text === undefined || text === null) {
      return false;
    }
    if (text.length > lengthText) {
      return true;
    } else {
      return false;
    }
  };

  const renderXMLLink = () => {
    return isLongText(text) ? (
      <p className={customClass}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={`${isLinkSecondary ? "hrv-report-link_secondary" : "hrv-report-link"
            }`}
        >
          {sliceText(lengthText, text)}
        </a>
      </p>
    ) : (
      <p className={customClass}>
        {isLinkSecondary ? (
          <TooltipWithTouch
            label={tooltipSecondary}
            placement={placement}
            hasArrow
          >
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="hrv-report-link_secondary"
            >
              <span className="hrv-report-link_secondary_text hrv-report-mr-12">
                {text}
              </span>

              <span className="hrv-report-link_secondary_icon">{svgArrow}</span>
            </a>
          </TooltipWithTouch>
        ) : (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="hrv-report-link"
          >
            {text}
          </a>
        )}
      </p>
    );
  };

  const sliceText = (lengthText, text) => {
    if (middleEllipsis) {
      return middleEllipsisHandler(text)
    } else {
      return text.slice(0, lengthText) + "..."
    }
  }

  const renderXMLNormalText = () => {
    return <Box display={showButton ? "flex" : "block"} alignItems={'center'} justifyContent={showButton ? "space-between" : "unset"}>
      {isLongText(text) ? (
        <p className={customClass}>
          <>{sliceText(lengthText, text)}</>
        </p>
      ) : (
        <p
          className={`${customClass} ${isLinkSecondary ? "hrv-report-mr-18 hrv-report-fs-14" : ""
            }`}
        >
          {text === "infin" ? <>&infin;</> : <>{text}</>}
        </p>
      )}

      {showButton ? <TooltipWithTouch
        label={tooltipSecondary}
        placement={placement}
        hasArrow
      >
        <Button
          ml={8}
          variant={"default-tertiary"}
          w={"32px"}
          onClick={() => {
            onHandleClickButton && onHandleClickButton()
          }}
          rightIcon={svgArrowButton}
          iconSpacing={0}
        />
      </TooltipWithTouch> : null}


    </Box>
  };

  const Component = (props) => {
    return isLongText(text) ? (
      <TooltipWithTouch {...props} hasArrow>{props.children}</TooltipWithTouch>
    ) : (
      <>{props.children}</>
    );
  };

  return (
    <>
      <Component
        label={notDisplayTooltip ? "" : text}
        placement={placement}
        hasArrow
      >
        {isLink ? renderXMLLink() : renderXMLNormalText()}
      </Component>
    </>
  );
}

export default HLimitLongString;

const svgArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
  >
    <path
      d="M0.530273 7.06L3.58361 4L0.530273 0.94L1.47027 0L5.47027 4L1.47027 8L0.530273 7.06Z"
      fill="#4B5563"
    />
  </svg>
);

const svgArrowButton = <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
  <path d="M0.566895 8.825L4.19273 5L0.566895 1.175L1.68314 0L6.43314 5L1.68314 10L0.566895 8.825Z" fill="#4B5563" />
</svg>