import { memo } from "react";
import { useTrans } from "locales/hook";

import { Tooltip } from "@chakra-ui/react";

import "./styles.scss";

function WidgetNavItem(props) {
  const {
    reportName,
    icon,
    description,
    onHandleToggleWidget,
    typeName,
    widgetId,
  } = props;

  const t = useTrans();

  return (
    <>
      <Tooltip
        className="hrv-report-d-flex"
        hasArrow
        placement="right"
        label={
          description?.length ? (
            <div className="hrv-report-d-flex">
              <div
                className="hrv-report-mr-8 hrv-report-tooltip-icon hrv-report-d-inline"
                dangerouslySetInnerHTML={{ __html: icon }}
              ></div>

              <div>
                <p className="hrv-report-fs-12 hrv-report-fw-500 hrv-report-text-color-black-6 hrv-report-mb-4">
                  {t(reportName)}
                </p>

                <p className="hrv-report-mb-0 hrv-report-fs-12 hrv-report-fw-400 hrv-report-text-color-gray-9">
                  {t(description)}
                </p>
              </div>
            </div>
          ) : (
            ""
          )
        }
        variant='light'
      >
        <li
          className={`hrv-report-widget hrv-report-cursor-pointer hrv-report-d-flex hrv-report-justify-between hrv-report-items-center hrv-report-py-8 hrv-report-px-4 `}
          onClick={() => {
            onHandleToggleWidget &&
              onHandleToggleWidget(typeName, widgetId, true);
          }}
        >
          <div className="hrv-report-d-flex hrv-report-items-center">
            <span
              className="hrv-report-mr-8"
              dangerouslySetInnerHTML={{ __html: icon }}
            ></span>

            <span className="hrv-report-fs-12 hrv-report-fw-500 hrv-report-text-color-black-6">
              {t(reportName)}
            </span>
          </div>

          <span className="hrv-report-widget-icon-add">{svgAddCircle}</span>
        </li>
      </Tooltip>
    </>
  );
}

export default memo(WidgetNavItem);

const svgAddCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM14.166 10.8334H10.8327V14.1667H9.16602V10.8334H5.83268V9.16675H9.16602V5.83342H10.8327V9.16675H14.166V10.8334Z"
      fill="#2463EB"
    />
  </svg>
);
