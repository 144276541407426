import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import duration from "dayjs/plugin/duration";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from 'dayjs/plugin/updateLocale';
import calendar from 'dayjs/plugin/calendar';

let thresholds = [
    { l: 's', r: 60 },
    { l: 'm', r: 60 },
    { l: 'h', r: 24 },
];

const config = {
    thresholds,
};

dayjs.extend(duration)
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime, config);
dayjs.extend(updateLocale);
dayjs.extend(calendar);

export default dayjs;