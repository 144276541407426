import { RouterProvider } from "react-router-dom";
import { AppState, useEmbedApp } from "@haravan/reactapp";

import { router } from "routes";

function App() {
  const { state, gotoInstall } = useEmbedApp();

  if (state === AppState.NotInstalled) {
    gotoInstall !== undefined && gotoInstall();
  }

  // & if App not installed => trigger gotoInstall() and on the other hande run RouterProvider
  return (
    <>
      {!state ? (
        <></>
      ) : (
        <div className="App">
          <RouterProvider router={router} />
        </div >
      )
      }
    </>
  );
}

export default App;
