// ! thay đổi ngày, ngày so sánh, trục y, filter, mã hoặc CTKM
/**
 * @Author Mạnh Đạt
 * @Created_at 27-6-2024
 * @Param reportScreenId, reportNameSystem, dataToFetchChart, optionCompare, discount
 * @Phrase Detect optionCompare to response data chart right format 
 * @Return Chart Data (Current and Previous)
 */

import { useQueryV2 } from "models/swr/report";
// import { GetDateShowType } from "helpers/date";
import { isEmpty } from "helpers/helpers";
import { useTrans } from "locales/hook";
import { REPORT_OPEN_COMPARE } from "../constants";
import { useCallback } from "react";
import { useRef, useEffect } from "react";

enum STATUS_FETCHING {
    SINGLE = 'single', // Gọi 1 API
    DOU = 'dou', // Gọi song song 2 APIs
    NONE = 'none' // Không gọi API
}



// middleware return the previous result until the new data has loaded.
const laggy = (useSWRNext) => {
    return (key, fetcher, config) => {
        const laggyDataRef = useRef();

        const swr = useSWRNext(key, fetcher, config);

        useEffect(() => {
            if (swr.data !== undefined) {
                laggyDataRef.current = swr.data;
            }
        }, [swr.data]);

        const resetLaggy = useCallback(() => {
            laggyDataRef.current = undefined;
        }, []);

        const dataOrLaggyData =
            swr.data === undefined ? laggyDataRef.current : swr.data;

        const isLagging =
            swr.data === undefined && laggyDataRef.current !== undefined;

        return Object.assign({}, swr, {
            data: dataOrLaggyData,
            isLagging,
            resetLaggy,
        });
    };
};

export const useComparision = ({
    reportScreenId,
    reportNameSystem,
    dataToFetchChart,
    optionCompare,
    discount,
    preventFetch
}) => {
    const t = useTrans();

    const condition = useCallback((): STATUS_FETCHING | undefined => {
        let shouldCallBoth = STATUS_FETCHING.NONE;

        if (isEmpty(dataToFetchChart)) {
            shouldCallBoth = STATUS_FETCHING.NONE
        };

        // * Báo cáo có sử dụng tính năng kỳ so sánh
        const isUseCompare = REPORT_OPEN_COMPARE.includes(reportNameSystem)

        const isSalesPromotion = reportNameSystem === "sales_promotion";
        const isSalesOverviewPromotion = reportNameSystem === "sales_overview_promotion";

        const hasDiscount = !isEmpty(discount);

        const hasComparisonOption = optionCompare.length && optionCompare !== t("noCompare");

        if (isUseCompare) {
            if (isSalesPromotion) {
                if (hasDiscount) {
                    shouldCallBoth = hasComparisonOption ? STATUS_FETCHING.DOU : STATUS_FETCHING.SINGLE;
                } else {
                    shouldCallBoth = STATUS_FETCHING.NONE
                }
            } else if (isSalesOverviewPromotion) {

                shouldCallBoth = hasComparisonOption ? STATUS_FETCHING.DOU : STATUS_FETCHING.SINGLE;
            }
        } else {
            shouldCallBoth = STATUS_FETCHING.SINGLE
        }

        return shouldCallBoth;
    }, [optionCompare])

    const cloneDataFetch = { ...dataToFetchChart };

    const createPayload = (startdate: string, enddate: string) => ({
        daterange: { startdate, enddate },
        filters: cloneDataFetch.filters,
        groupby: cloneDataFetch.groupby,
        metrics: cloneDataFetch.metrics,
        order_by: cloneDataFetch.order_by,
        pagination: cloneDataFetch.pagination,
    });

    const currentBody = createPayload(dataToFetchChart.startdate, dataToFetchChart.enddate);
    const previousBody = createPayload(dataToFetchChart.compareDateStart, dataToFetchChart.compareDateEnd);

    const isPreventCurrent = condition() === STATUS_FETCHING.NONE || preventFetch;
    const isPreventPrevious = condition() === STATUS_FETCHING.NONE || condition() === STATUS_FETCHING.SINGLE || preventFetch;

    const {
        data: current,
        isLoading: isLoadingCurrent,
        mutate: mutateCurrent,
        isValidating: isValidatingCurrent
    } = useQueryV2(reportScreenId, currentBody, isPreventCurrent, true, [laggy]);

    const {
        data: previous,
        isLoading: isLoadingPrevious,
        mutate: mutatePrevious,
        isValidating: isValidatingPrevious
    } = useQueryV2(reportScreenId, previousBody, isPreventPrevious, true, [laggy]);

    const mutateBoth = () => {
        const key = Date.now();
        mutateCurrent(key);
        mutatePrevious(key);
    };

    return {
        current: current?.data || [],
        previous: previous?.data || [],
        isLoadingCurrent,
        isLoadingPrevious,
        isValidatingPrevious,
        isValidatingCurrent,
        mutate: mutateBoth,
    };

}

