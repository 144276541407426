
import { useState } from "react";
import { useTrans } from "locales/hook";
import { useGTM } from "hooks/useGTM";

// ! Components
import { Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import FilterScore from "components/Filter/components/filterScore";

import { deepClone, removeFlatArray } from "helpers/helpers";
import ButtonFilter from "components/Filter/components/Button";

function MobileFilter(props) {
    const {
        defaultVisible,
        handleSubmit,
        fields,
        watch,
        dateEnd,
        remove,
        setValue,
        control,
        optionsDimension,
        reportId,
        append,
        getValues,
        dateStart,
        defaultFilter = {},
        reset,
        filterDefault,
        disableFilterField,
        reportNameSystem
    } = props;

    const [isVisible, setIsVisible] = useState(defaultVisible)

    const t = useTrans();
    const [isDisabledButton, setIsDisabledButton] = useState(false)
    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "detail",
            "ut-name": 'apply_filter'
        }
    });

    const getFilter = getValues('filter');

    const checkKeyDown = (e) => {
        if (e.key === 'Enter' && e.target.className.includes("chakra-input")) {
            e.preventDefault()
        } else if (e.key === 'Enter') {
            e.stopPropagation()

        }
    };

    const resignDimension = (selected: any) => {
        const cloneObject = deepClone(getFilter);

        const getCurrentDimension = cloneObject.map(y => y.dimension);
        const findIndexDimension = getCurrentDimension.findIndex(y => y === selected);

        getCurrentDimension.splice(findIndexDimension, 1);

        let result = removeFlatArray(getCurrentDimension, optionsDimension, "fieldName");

        return result;
    };


    const emptyFilterState = () => {
        return <>
            <p>Chưa có bộ lọc nào được áp dụng</p>

            <Divider orientation='horizontal' borderColor="#E5E7EB" />
        </>
    }

    const addFilterItem = () => {
        return <Button variant='default-default'
            onKeyDown={(event: any) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                }
            }}
            onClick={(e: any) => {
                append({
                    ...defaultFilter,
                    conjunction: "and"
                });
            }}
        >
            {t("Thêm bộ lọc")}
        </Button>
    }

    const handleClose = () => {
        // reset({ filter: filterDefault });

        setTimeout(() => {
            setIsVisible(false)
        }, 10)
    }

    const handleOpenDrawer = () => {
        setIsVisible(true)
        reset({ filter: filterDefault });
    }

    return (
        <>
            <ButtonFilter isDisplay onClick={handleOpenDrawer} />

            <Drawer isOpen={isVisible} onClose={handleClose} size="full" >
                <DrawerOverlay width="full" h="full" />

                <DrawerContent
                    minWidth="100vw"
                    height="100vh"
                >
                    <DrawerHeader px={8} w='100%' h="60px" fontSize="18px" fontWeight="500" borderBottom="1px solid #E5E7EB" lineHeight="44px">Quản lý bộ lọc</DrawerHeader>

                    <DrawerCloseButton
                        fontSize='10px'
                        p='0'
                        h='40px'
                        w='40px'
                        borderRadius='md'
                        mt="6px"
                        _focus={{
                            outline: "none",
                        }}
                    />

                    <form onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)}>
                        <DrawerBody
                            w='100%'
                            maxH={`calc(100vh - ${120}px)`}
                            minH={`calc(100vh - ${120}px)`}
                            overflowY="auto"
                            className="hrv-report-scrollbar_custom"
                            p={8}
                        >
                            {!fields.filter(field => !disableFilterField.includes(field.dimension)).length ? emptyFilterState() : null}

                            {fields?.map((_: any, index) => {
                                const disabled = disableFilterField.includes(getValues(`filter.${index}.dimension`));

                                return !disableFilterField.includes(fields[index].dimension) ? <div key={_.id}>
                                    <FilterScore
                                        watch={watch}
                                        setValue={setValue}
                                        index={index}
                                        onHandleRemove={remove}
                                        control={control}
                                        dimensionList={resignDimension(getValues(`filter.${index}.dimension`))}
                                        getValue={getValues}
                                        defaultDimensionList={optionsDimension}
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        reportId={reportId}
                                        setDisabledSubmit={setIsDisabledButton}
                                        disabled={disabled}
                                        reportNameSystem={reportNameSystem}
                                    />
                                </div> : null
                            })}


                            {addFilterItem()}
                        </DrawerBody>

                        <DrawerFooter w='100%' h="64px" position="fixed" bottom="0" borderTop="1px solid #E5E7EB" bg="#ffffff" display={'flex'} justifyContent={"start"}>
                            <Button
                                variant='default-default'
                                mr={4}
                                onClick={handleClose}
                            >
                                {t("Huỷ")}
                            </Button>

                            <Button
                                variant='default-primary'
                                type="submit"
                                className={`buttonSubmit ${detectClass}`}
                                isDisabled={isDisabledButton}
                                onClick={handleClose}
                                {...attributes}
                            >
                                {t("Áp dụng")}
                            </Button>
                        </DrawerFooter>
                    </form>
                </DrawerContent>
            </Drawer>
        </>

    )
}

export default MobileFilter
