import dayjs from "components/Dayjs";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
import useWindowDimensions from "hooks/useWindowDimension";

import HSearchInput from "components/HSearch";
import { ReportDatePicker } from "../components";

import { dayAdd } from "helpers/chartutils";
import { GetDateShowType } from "helpers/date";
import { getAdjustedComparisonDates, isEmpty } from "helpers/helpers";
import { useLoadMoreCoupon } from "components/CouponApply/swr/useLoadMoreCoupon";

interface IRenderFilterByDiscountProps {
  onChangeDate: Function;
  dateStart: string;
  dateEnd: string;
  compareDateEnd?: string;
  compareDateStart?: string;
  onChangeCompareDate?: Function;
}

function RenderFilterByDiscount(props: IRenderFilterByDiscountProps) {
  const { id } = useParams<string>();
  const t = useTrans();

  const { state, actions, dispatch } = useStore();

  const { width } = useWindowDimensions();

  const { discount, optionCompare, info } = state;
  const { isSystemReport } = info;
  const {
    onChangeDate,
    dateStart,
    dateEnd,
    compareDateStart,
    compareDateEnd,
    onChangeCompareDate,
  } = props;

  let { duration: diffCounts, type: dateShowType } = GetDateShowType(
    dateStart,
    dateEnd
  );

  const [keyword, setKeyword] = useState('');
  const [ranges, setRanges] = useState({
    [t("noCompare")]: [undefined, undefined],
    [t("previousPeriod")]: [
      dayjs(dayAdd(dateStart, diffCounts * -1, dateShowType)),
      dayjs(dayAdd(dateEnd, diffCounts * -1, dateShowType)),
    ],
    [t("previousYear")]: [
      dayjs(dateStart).subtract(1, "year"),
      dayjs(dateEnd).subtract(1, "year"),
    ],
  });

  const { isValidating, data, setSize, size } = useLoadMoreCoupon({
    preventFetch: false,
    dimension: "DiscountId",
    reportId: id,
    keyword,
  });

  // Danh sách các khuyến mãi
  let coupons = [];

  if (data && data.length) {
    for (let index = 0; index <= size - 1; index++) {
      const elements = data[index]?.data || [];
      coupons = coupons.concat(elements);
    }
  } else {
    coupons = []
  }


  useEffect(() => {
    const rangesPrevious: any = handlePreviousPeriodByRanges(dateStart, dateEnd);

    // ! Thay đổi lại bộ range bởi vì khi ngày thay đổi thì bộ option range của kỳ sẽ thay đổi => không cố định mà sẽ flexible theo bộ lọc ngày chuẩn
    setRanges(rangesPrevious);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd]);

  useEffect(() => {
    dispatch(actions.setOptionCompare(t("previousPeriod")));

    const getPreviousPeriod = getAdjustedComparisonDates(dateStart, dateEnd, diffCounts, dateShowType)

    onChangeCompareDate &&
      onChangeCompareDate(
        dayjs(getPreviousPeriod["start"]),
        dayjs(getPreviousPeriod["end"]),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let keyword = "";

  //   if (discount !== undefined && discount.displayname_vi !== undefined) {
  //     keyword = discount.displayname_vi;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleChangeInput = (keyword, page) => {
    setSize(page);
    setKeyword(keyword);
  };

  const handleOnChangeDate = (start, end) => {
    // ! Thay đổi ngày lọc
    onChangeDate && onChangeDate(start, end);

    if (!optionCompare.length) return;

    if (optionCompare === t("custom") || optionCompare === t("noCompare")) {
      return;
    } else {
      dispatch(actions.setOptionCompare(t("previousPeriod")));

      const rangesPrevious = handlePreviousPeriodByRanges(start, end)

      onChangeCompareDate &&
        onChangeCompareDate(
          rangesPrevious[t("previousPeriod")][0],
          rangesPrevious[t("previousPeriod")][1],
        );
    }
  };

  const handleChooseCoupon = (value) => {
    dispatch(actions.chooseCoupon(value));

    handleChangeInput(value.displayname_vi, 1);
  };

  const handlePreviousPeriodByRanges = (newStartDate, newEndDate) => {
    let { duration: diffCounts, type: dateShowType } = GetDateShowType(
      newStartDate,
      newEndDate,
    );

    let newRangesPrevious = {
      [t("noCompare")]: [undefined, undefined],
      [t("previousPeriod")]: [
        dayjs(dayAdd(newStartDate, diffCounts * -1, dateShowType)),
        dayjs(dayAdd(newEndDate, diffCounts * -1, dateShowType)),
      ],
      [t("previousYear")]: [
        dayjs(newStartDate).subtract(1, "year"),
        dayjs(newEndDate).subtract(1, "year"),
      ],
    };

    const { start, end } = getAdjustedComparisonDates(newStartDate, newEndDate, diffCounts, dateShowType)

    newRangesPrevious[t("previousPeriod")] = [
      start,
      end
    ]


    return newRangesPrevious;

  }

  const isChooseDiscount = !isEmpty(discount);

  return (
    <div
      className="hrv-report-items-center hrv-report-responsive-wrapper-filter hrv-report-d-flex hrv-report-flex-wrap"
      style={{
        width: "100%",
      }}
    >
      <div className="hrv-report-responsive-item-filter hrv-report-mr-8">
        <HSearchInput
          placeholder={t("Chọn khuyến mãi")}
          onChange={handleChangeInput}
          isLoading={isValidating}
          result={coupons}
          onHandleOnClick={handleChooseCoupon}
          isChooseDiscount={isChooseDiscount}
          discount={discount}
          isSystemReport={isSystemReport}
          size="m"
          currentPage={size}
        />
      </div>

      <div className="hrv-report-responsive-item-filter hrv-report-mr-8">
        <ReportDatePicker
          onCallback={handleOnChangeDate}
          dateStart={dateStart}
          dateEnd={dateEnd}
          isShowIcon={true}
          placement="center"
          autoApply={width < 856}
        />
      </div>

      <div className="hrv-report-responsive-item-filter">
        <ReportDatePicker
          dateStart={compareDateStart}
          dateEnd={compareDateEnd}
          customText={`${t("So sánh")}: `}
          ranges={ranges}
          isComparePeriod={true}
          placement="left"
          onCallback={(start, end, label) => {
            if (label !== undefined) {
              dispatch(actions.setOptionCompare(label));
            }

            if (label === t("noCompare")) {
              onChangeCompareDate &&
                onChangeCompareDate(undefined, undefined, label);
            } else {
              onChangeCompareDate && onChangeCompareDate(start, end, label);
            }
          }}
          isShowIcon={false}
          autoApply={width < 856}
        />
      </div>
    </div>
  );
}

export default RenderFilterByDiscount;
