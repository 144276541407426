// ! React Hooks
import { useEffect, useState } from "react";
import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useRFMPointApi, useSubmitRFM } from "pages/ReportStandard/hooks";
import useDebouncedCallback from "hooks/useDebounceCallback";

// ! Components
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useMediaQuery
} from "@chakra-ui/react";
import SwitchTabs from "components/SwitchTabs";
import MoneyWithLocale from "components/Money/locale";
import Process from "./Process";

// ! Constants
import { DEFAULT_TABS, POINT_SCALE_COLORS_RFM, POINT_SCALE_OPERATORS_RFM, defaultValue } from "./constants";
import { GetCurrencyCode, formatNumber, isEmpty } from "helpers/helpers";

interface IProps {
    isDisplay: boolean
    allowed: boolean
    onHandleRefresh: Function
};

function ConfigRFM(props: IProps) {
    const { isDisplay, allowed, onHandleRefresh } = props;
    const t = useTrans();
    const { width } = useWindowDimensions();
    const currencyCode = GetCurrencyCode();

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [tab, setTab] = useState(0);
    const [option, setOption] = useState([]);
    const [formData, setFormData] = useState<any>([]);
    const [maxValue, setMaxValue] = useState<any>(0);
    const [isDesktop] = useMediaQuery('(min-width: 556px)');

    const { data, isLoading } = useRFMPointApi();

    const { trigger: submitRFM, isMutating } = useSubmitRFM({
        onSuccess: (params) => {
            if (!params.hasError) {
                setIsOpenModal(false);
                setTab(0)
                onHandleRefresh && onHandleRefresh()
            }
        }
    });

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        trigger
    } = useForm({
        values: {
            rfm: formData,
        },
        mode: "onChange",
    });

    const { fields } = useFieldArray<any>({
        control,
        name: "rfm",
    });

    const watchForm = watch()
    const errorsMgs: any = errors;

    useEffect(() => {
        if (!isLoading && typeof data !== "undefined") {
            const result = [...defaultValue];

            function getValue(point, indicate) {
                const isIdOne = point.id === 1;

                switch (indicate.key) {
                    case 'r':
                        return isIdOne ? point.minR : point.maxR;
                    case 'f':
                        return point.maxF;
                    case 'm':
                        return point.maxM;
                    default:
                        return null;
                }
            }

            data.forEach(point => {
                result.map(indicate => {
                    const value = getValue(point, indicate);

                    return {
                        ...indicate,
                        value: indicate.value.splice(point.id - 1, 1, value)
                    };
                });
            });
            drawScale(0, data)
            setFormData(result)
        }
    }, [isLoading]);

    const handleChangeDebounced = (value) => {
        if (isEmpty(errorsMgs)) {
            const buildForm = handleBuildData(watchForm.rfm);
            const sortOptionByIndex = buildForm.sort((a, b) => b.id - a.id); // sort id 1 - 5 reverse to 5 - 1
            drawScale(tab, sortOptionByIndex)
        }
    };

    const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [watchForm.rfm]);

    const drawScale = (tab, data) => {
        const findMaxValue = data.find(line => {
            if (tab !== 0) {
                return line.id === 4
            } else {
                return line.id === 5
            }
        }); // * Đối với F và M: Lấy chốt cuối là điểm 4 từ điểm 5 trở đi sẽ tới vô cùng

        const PERCENTAGE_OF_VALUE_WITH_LASTING = 90;

        const optionalLine = data.map((line) => {
            switch (tab) {
                case 0:
                    const MAX = 365; // số max khi RFM là R
                    const sum = MAX;
                    setMaxValue(MAX);
                    let percentage: any = 0;
                    const isLast = line.id === 1
                    const position = [5, 4, 3, 2, 1];
                    const reversePoint = position[line.id - 1];

                    let valueCalcPercent = (line.maxR + 1) - line.minR
                    let value = isLast ? line.minR : line.maxR;

                    if (isLast) {
                        const arraySumWithoutLast = data.slice(0, -1);

                        const sumWithoutLast = arraySumWithoutLast.reduce((acc, curr) => {
                            let range = (curr.maxR + 1) - curr.minR;
                            const percentageValue = (range * 100) / sum;

                            return acc + percentageValue
                        }, 0);

                        percentage = 100 - sumWithoutLast;
                    } else {
                        percentage = (valueCalcPercent * 100) / sum
                    };

                    return {
                        value: value,
                        proportion: percentage,
                        index: reversePoint,
                        color: POINT_SCALE_COLORS_RFM[line.id]?.borderColor
                    }
                case 1: {
                    const isLast = line.id === 5;
                    const MAX = findMaxValue.maxF; // số max khi RFM là F
                    setMaxValue(Infinity);

                    const sum = MAX - 1;

                    let percentage: any = 0;
                    let valueCalcPercent = isLast ? sum - line.minF : line.maxF - line.minF;
                    let value = isLast ? 0 : line.maxF;
                    const position = [5, 4, 3, 2, 1];
                    const reversePoint = position[line.id - 1];

                    if (!isLast) {
                        percentage = (valueCalcPercent * PERCENTAGE_OF_VALUE_WITH_LASTING) / sum
                    }

                    return {
                        value: value,
                        proportion: percentage,
                        index: reversePoint,
                        color: POINT_SCALE_COLORS_RFM[line.id]?.borderColor
                    }
                }
                case 2: {
                    const isLast = line.id === 5
                    const MAX = findMaxValue.maxM - 1; // số max khi RFM là F
                    setMaxValue(Infinity)
                    const sum = MAX;
                    let percentage: any = 0;
                    let valueCalcPercent = isLast ? sum - line.minM : line.maxM - line.minM;
                    let value = isLast ? line.minM : line.maxM;
                    const position = [5, 4, 3, 2, 1];
                    const reversePoint = position[line.id - 1];

                    if (!isLast) {
                        percentage = (valueCalcPercent * PERCENTAGE_OF_VALUE_WITH_LASTING) / sum
                    }

                    return {
                        value: value,
                        proportion: percentage,
                        index: reversePoint,
                        color: POINT_SCALE_COLORS_RFM[line.id]?.borderColor
                    }
                }

                default:
                    break;
            }
        });

        let sortOptionByIndex = optionalLine.sort((a, b) => a.index - b.index);

        if (tab !== 0) {
            let lastingOptionIndex = sortOptionByIndex.findIndex(y => y.index === 1);

            sortOptionByIndex[lastingOptionIndex] = {
                ...sortOptionByIndex[lastingOptionIndex],
                proportion: 100 - PERCENTAGE_OF_VALUE_WITH_LASTING
            };
        }

        setOption(sortOptionByIndex)
    }

    const contentFooter = () => {
        return (

            <div
                className="
                            hrv-report-d-flex 
                            hrv-report-justify-between
                            hrv-report-w-full
                            "
            >
                <a href={'https://support.haravan.com/support/solutions/articles/42000109124-b%C3%A1o-c%C3%A1o-kh%C3%A1ch-h%C3%A0ng-theo-ph%C3%A2n-kh%C3%BAc-rfm'} target="_blank" rel="noreferrer"
                    className='hrv-report-d-flex hrv-report-items-center hrv-report-link' style={{
                        lineHeight: "20px"
                    }}>
                    <span className="hrv-report-mr-4">{svgInfo}</span>

                    <span>{t("learn_more_about_rfm")}</span>
                </a>

                <div className="hrv-report-d-flex">
                    <Button
                        variant="default-default"
                        className="hrv-report-mr-8"
                        onClick={() => {
                            setIsOpenModal(false)
                        }}
                    >
                        {t("cancelLabel")}
                    </Button>

                    <Button
                        type="submit"
                        onClick={() => {
                            trigger();
                        }}
                        variant="default-primary"
                        isLoading={isMutating}
                        isDisabled={!isEmpty(errorsMgs) || !allowed || isMutating}
                    >
                        {t("applyLabel")}
                    </Button>
                </div>
            </div>
        );
    };

    const handleChangeTab = (id) => {
        if (!isEmpty(errorsMgs)) return;

        setTab(id);

        const buildForm = handleBuildData(watchForm.rfm);
        const sortOptionByIndex = buildForm.sort((a, b) => b.id - a.id); // sort id 1 - 5 reverse to 5 - 1

        setTimeout(() => {
            drawScale(id, sortOptionByIndex)
        }, 100);
    }

    const pointBlock = ({ point, bgColor, borderColor }) => {
        return <div
            className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-border-8 hrv-report-mr-16"
            style={{
                background: bgColor,
                border: `1px solid ${borderColor}`,
                minWidth: "44px",
                minHeight: "32px",
                width: "44px",
                height: "32px"
            }}
        >
            <span>{point}</span>
        </div>
    }

    const unitBlock = (key, reversePoint?: any) => {
        let unit = "";

        switch (key) {
            case 'r':
                unit = "ngày"
                break;

            case 'f':
                unit = "unit_order"
                break;

            default:
                break;
        }

        if (reversePoint) {
            if (reversePoint === 5 && key === "f") {
                unit = ""
            }

            if (reversePoint === 1 && key === "r") {
                unit = ""
            }
        }

        return unit
    }

    const operators = (key, point) => {
        let before = "";
        let after = "";
        const getOperator = POINT_SCALE_OPERATORS_RFM[key][point];

        switch (getOperator) {
            case "morethan":
                before = "from"
                after = 'more'
                break;

            case "fromto":
                before = "is"
                after = "to"
                break;

            case "fromtobelow":
                before = 'from'
                after = 'to_below'
                break;

            case "lowerthan":
                before = "below"
                break;

            default:
                break;
        }

        return {
            before,
            after
        }
    };

    const errorContent = (value, dataformat, type) => {
        return <div className="hrv-report-absolute" style={{
            width: "max-content",
            bottom: -24,
            left: '50%',
            transform: "translateX(-50%)"
        }}>
            {dataformat === "number" && < div className="hrv-report-fs-12 hrv-report-fw-400 hrv-report-mb-0 hrv-report-text-color-red-2">
                <span className="hrv-report-mr-3">
                    {type === "compare_lower_value" && t("value_must_be_greater")}
                    {type === "compare_higher_value" && t("value_must_be_lower")}
                </span>
                <span>
                    {value}
                </span>
            </div>}

            {dataformat === "money" && <div className="hrv-report-d-flex hrv-report-fs-12 hrv-report-fw-400 hrv-report-mb-0 hrv-report-text-color-red-2">
                <span className="hrv-report-mr-3">
                    {type === "compare_lower_value" && t("value_must_be_greater")}
                    {type === "compare_higher_value" && t("value_must_be_lower")}
                </span>
                <MoneyWithLocale value={value} currencyCode={currencyCode} />
            </div>}
        </div>
    }

    const handleBuildData = (values) => {
        const pointLength = 5; // có 5 điểm
        const arr = Array.from(Array(pointLength), (_, index) => index + 1);

        const result = arr.map((point) => {
            let item = {
                id: point,
                minR: 0,
                maxR: 0,
                minF: 0,
                maxF: 0,
                minM: 0,
                maxM: 0
            };

            values?.forEach((y: any) => {
                const from = point - 1;
                const to = point;

                if (y.key === 'r') {
                    item.maxR = 365; // mặc định max 365 ngày

                    if (point === 1) {
                        item.minR = y.value[to] + 1
                    } else {
                        item.minR = y.value[to] ? y.value[to] + 1 : 1
                        item.maxR = y.value[from]
                    }
                }

                if (["f"].includes(y.key)) {
                    item.minF = 1; // mặc định min 1 đơn

                    if (point === 1) {
                        item.maxF = y.value[from]
                    } else {
                        item.minF = y.value[from - 1];
                        item.maxF = y.value[from];
                    }
                }

                if (y.key === 'm') {
                    item.minM = 1; // mặc định min 1đ

                    if (point === 1) {
                        item.maxM = y.value[from]
                    } else {
                        item.minM = y.value[from - 1];
                        item.maxM = y.value[from];
                    }
                }
            })

            return item
        })

        return result
    }

    const handleOnSubmitForm = (values) => {
        const payload = handleBuildData(values?.rfm);
        submitRFM(payload);
    }

    const contentBody = () => {
        return <>
            {!isLoading && formData.length && <div>
                <p className="hrv-report-text-color-black-6 hrv-report-fw-500 hrv-report-fs-14 hrv-report-mb-8">
                    {t("change_RFM_metrics_to_suit_your_business_model")}
                </p>

                <div className="hrv-report-mb-8">
                    <SwitchTabs
                        options={DEFAULT_TABS}
                        defaultTab={tab}
                        onHandleChangeTab={handleChangeTab}
                    />
                </div>

                <div className="hrv-report-mb-16">
                    <p className="hrv-report-text-color-black-6 hrv-report-fw-500 hrv-report-fs-14 hrv-report-mb-4">
                        {DEFAULT_TABS[tab].heading}
                    </p>

                    <ul className="hrv-report-ml-16">
                        {DEFAULT_TABS[tab].description.map((line, id) => {
                            return <li className="hrv-report-text-color-black-6" key={id}>
                                {t(line)}
                            </li>
                        })}
                    </ul>
                </div>

                <Process
                    description={
                        tab === 0 ? t("last_purchase_time") :
                            tab === 1 ? t("purchase_frequency_with_unit") :
                                t("amount")
                    }
                    options={option}
                    isShowTick={true}
                    isReverse={tab === 0}
                    maxValue={maxValue}
                    isMoney={tab === 2}
                    content={watchForm.rfm[tab]}
                />

                {/* Form */}
                <div>
                    <div
                        style={{
                            backgroundColor: "#f3f4f6",
                            margin: "0 -16px",
                            borderBottom: "1px solid #e5e7eb"
                        }}
                        className="hrv-report-px-16 hrv-report-py-6"
                    >
                        <span
                            className="
                        hrv-report-fs-12 
                        hrv-report-mr-4 
                        hrv-report-fw-500 
                        hrv-report-text-color-black-6
                        "
                        >
                            {t('scale')}
                        </span>

                        <span
                            className="hrv-report-fs-12 hrv-report-text-color-gray-12">
                            {`(${t('low')}: 1 - ${t('high')}: 5)`}
                        </span>
                    </div>

                    {fields.map((input: any, id: any) => {
                        return <div
                            key={id}
                            style={{
                                margin: "0 -16px",
                                opacity: id === tab ? 1 : 0,
                                height: id !== tab ? 0 : 'auto'
                            }}
                        >
                            {input?.value?.map((val, inputId) => {
                                // Defined position of field index
                                // Values From BE is from 1 to 5
                                // So, if we want display from 5 to 1 we should reverse index
                                const position = [5, 4, 3, 2, 1];
                                const reversePoint = position[inputId];
                                const getColors = POINT_SCALE_COLORS_RFM[reversePoint];
                                const { before, after } = operators(input.key, reversePoint);

                                const getInputValue = (number) => {
                                    return watch(`rfm.${id}.value.${reversePoint - number}`);
                                }

                                const errorMessage = isEmpty(errorsMgs) ? "" : errorsMgs?.rfm[id]?.value[reversePoint - 1]?.message || "";
                                const errorType = isEmpty(errorsMgs) ? "" : errorsMgs?.rfm[id]?.value[reversePoint - 1]?.type || "";

                                return <div
                                    key={inputId}
                                    className={`
                                    hrv-report-d-flex
                                    hrv-report-items-center
                                   ${id !== tab ? "" : 'hrv-report-py-8'} 
                                   ${id !== tab ? "" : 'hrv-report-px-16'} 
                                    hrv-report-flex-wrap
                                   ${errorMessage.length ? "hrv-report-pb-26" : ""}`
                                    }
                                    style={{
                                        borderBottom: `1px solid ${inputId === input?.value.length - 1 ? "transparent" : "#e5e7eb"}`,
                                        height: id !== tab ? 0 : 'auto'
                                    }}
                                >
                                    {pointBlock({
                                        point: reversePoint,
                                        bgColor: getColors.bgColor,
                                        borderColor: getColors.borderColor
                                    })}

                                    <div className="hrv-report-d-flex hrv-report-items-center hrv-report-flex-row hrv-report-wrap">
                                        <div className="hrv-report-mr-3" style={{
                                            minWidth: "fit-content"
                                        }}>
                                            {t(`${input.property}_property`)}
                                        </div>

                                        <div style={{
                                            minWidth: "fit-content"
                                        }}>
                                            {t(before)}
                                        </div>

                                        {
                                            input.key === 'r' && <>
                                                <div className="hrv-report-mx-3">{!getInputValue(0) ? 0 : getInputValue(0) + 1}</div>

                                                <div style={{
                                                    minWidth: "fit-content"
                                                }}>
                                                    {reversePoint !== 1 && t(after)}
                                                </div>

                                                {reversePoint === 1 && <div style={{
                                                    minWidth: "fit-content"
                                                }}>
                                                    {t(unitBlock(input.key))}
                                                    {" "}
                                                    {t(after)}
                                                </div>}

                                                {reversePoint !== 1 &&
                                                    <Controller
                                                        name={`rfm.${id}.value.${reversePoint - 1}`}
                                                        control={control}
                                                        rules={{
                                                            validate: {
                                                                compare_lower_value: (value) => {
                                                                    const getFromValue = getInputValue(0) + 1 || 0;
                                                                    return getFromValue < value || String(getFromValue);
                                                                },
                                                                compare_higher_value: (value) => {
                                                                    if (tab === id) {
                                                                        return maxValue > value || String(maxValue);
                                                                    }
                                                                }
                                                            },
                                                        }}

                                                        render={({ field }) => {
                                                            return <div className="hrv-report-relative">
                                                                <Input
                                                                    size={'m'}
                                                                    w={input.dataformat === "money" ? "120px" : '70px'}
                                                                    className={"hrv-report-mx-8"}
                                                                    value={field?.value}
                                                                    onChange={(event: any) => {
                                                                        const getValue = event.target.value;
                                                                        const value = getValue ? getValue : "0";

                                                                        field.onChange(parseInt(value.replace(/[^\d]/g, "")));
                                                                        trigger();
                                                                        debouncedCallback()
                                                                    }}
                                                                    isInvalid={errorMessage}
                                                                    disabled={!allowed}
                                                                />

                                                                {
                                                                    !!errorMessage.length && (
                                                                        errorContent(errorMessage, "number", errorType)
                                                                    )
                                                                }
                                                            </div>
                                                        }}

                                                    />
                                                }

                                                {t(unitBlock(input.key, reversePoint))}
                                            </>
                                        }

                                        {
                                            input.key === 'f' && <>
                                                {reversePoint !== 1 &&
                                                    <div className="hrv-report-mx-3">
                                                        {getInputValue(2) ? getInputValue(2) : 1}
                                                    </div>}


                                                {reversePoint === 5 && <div className="hrv-report-mr-4">{t(unitBlock(input.key))}</div>}

                                                <div className="hrv-report-mr-3" style={{
                                                    minWidth: "fit-content"
                                                }}>
                                                    {t(after)}
                                                </div>

                                                {reversePoint !== 5 &&
                                                    <Controller
                                                        name={`rfm.${id}.value.${reversePoint - 1}`}
                                                        control={control}
                                                        rules={{
                                                            validate: {
                                                                compare_lower_value: (value) => {
                                                                    const getFromValue = getInputValue(2) || 1;
                                                                    return getFromValue < value || String(getFromValue);
                                                                },
                                                                compare_higher_value: (value) => {
                                                                    if (tab === id) {
                                                                        return maxValue > value || String(maxValue);
                                                                    }
                                                                }
                                                            },
                                                        }}
                                                        render={({ field }) => {
                                                            return <div className="hrv-report-relative">
                                                                <Input
                                                                    size={'m'}
                                                                    w={input.dataformat === "money" ? "120px" : '70px'}
                                                                    className={"hrv-report-mx-8"}
                                                                    value={field?.value}
                                                                    onChange={(event: any) => {
                                                                        const getValue = event.target.value;
                                                                        const value = getValue ? getValue : "0";

                                                                        field.onChange(parseInt(value.replace(/[^\d]/g, "")));
                                                                        trigger()
                                                                        debouncedCallback()
                                                                    }}
                                                                    isInvalid={errorMessage}
                                                                    disabled={!allowed}
                                                                />

                                                                {
                                                                    !!errorMessage.length && (
                                                                        errorContent(errorMessage, "number", errorType)
                                                                    )
                                                                }
                                                            </div>
                                                        }}
                                                    />
                                                }

                                                <div
                                                    style={{
                                                        minWidth: "fit-content"
                                                    }}
                                                >
                                                    {t(unitBlock(input.key, reversePoint))}
                                                </div>
                                            </>
                                        }

                                        {
                                            input.key === "m" && <>
                                                {reversePoint !== 1 &&
                                                    <>
                                                        {
                                                            !getInputValue(2) ? <div className="hrv-report-mx-3">0</div> :
                                                                <MoneyWithLocale
                                                                    value={getInputValue(2)}
                                                                    currencyCode={currencyCode}
                                                                    className="hrv-report-mx-3"
                                                                />
                                                        }
                                                    </>
                                                }

                                                {t(after)}

                                                {reversePoint !== 5 &&
                                                    <Controller
                                                        name={`rfm.${id}.value.${reversePoint - 1}`}
                                                        control={control}
                                                        rules={{
                                                            validate: {
                                                                compare_lower_value: (value) => {
                                                                    const getFromValue = getInputValue(2) || 0;
                                                                    return getFromValue < value || String(getFromValue);
                                                                },
                                                                compare_higher_value: (value) => {
                                                                    if (tab === id) {
                                                                        return maxValue > value || String(maxValue);
                                                                    }
                                                                }
                                                            },
                                                        }}
                                                        render={({ field }) => {
                                                            return <div className="hrv-report-relative">
                                                                <InputGroup w={input.dataformat === "money" ? "150px" : '70px'}>
                                                                    <Input
                                                                        autoComplete={"off"}
                                                                        size="m"
                                                                        className="hrv-report-mx-8"
                                                                        value={
                                                                            !isNaN(parseInt(field?.value.toString()))
                                                                                ? formatNumber(field?.value)
                                                                                : 0
                                                                        }
                                                                        onChange={(event: any) => {
                                                                            const getValue = event.target.value;
                                                                            const value = getValue ? getValue : "0";

                                                                            field.onChange(parseInt(value.replace(/[^\d]/g, "")));
                                                                            trigger()
                                                                            debouncedCallback()
                                                                        }}
                                                                        isInvalid={errorMessage}
                                                                        disabled={!allowed}
                                                                    />

                                                                    <InputRightElement h="full" w={10}>
                                                                        đ
                                                                    </InputRightElement>
                                                                </InputGroup>


                                                                {
                                                                    !!errorMessage.length && (
                                                                        errorContent(errorMessage, "money", errorType)
                                                                    )
                                                                }
                                                            </div>
                                                        }}
                                                    />}
                                            </>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                    })}
                </div>
            </div >
            }
        </>
    };

    const headerModal = () => {
        return <div
            className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-justify-between"
        >
            <p
                className="
                hrv-report-mb-0
                hrv-report-text-color-black-6
                hrv-report-fs-16 
                hrv-report-fw-600
                "
            >{t("config_rfm")}</p>

            <span
                style={{
                    width: 18,
                }}
                className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-cursor-pointer
                hrv-report-justify-between
                "
                onClick={() => {
                    setIsOpenModal(false);
                    setTab(0);
                }}
            >
                {svgClose}
            </span>
        </div>
    }

    return (
        <>
            {!isDisplay ? <></> :
                <>
                    <Button
                        mr={4}
                        variant={width < 768 ? "ghost-default" : "default-default"}
                        onClick={() => {
                            setIsOpenModal(true);
                            debouncedCallback()
                        }}
                        style={{
                            justifyContent: "start"
                        }}
                        w={width < 768 ? "100%" : "fit-content"}
                    >
                        {t("config_rfm")}
                    </Button>

                    {isDesktop &&
                        <Modal
                            isOpen={isOpenModal} onClose={() => {
                                setIsOpenModal(false);
                                setTab(0);
                            }}
                            size="md2"
                            autoFocus={false} motionPreset={"slideInBottom"}
                        >
                            <ModalOverlay />

                            <ModalContent
                                minWidth="fit-content"
                            >
                                <ModalHeader>
                                    {headerModal()}
                                </ModalHeader>

                                <form onSubmit={handleSubmit((values) => handleOnSubmitForm(values))}>
                                    <ModalBody
                                        w='700px'
                                        className="hrv-report-scrollbar_custom hrv-report-pb-0"
                                    >
                                        {contentBody()}
                                    </ModalBody>

                                    <ModalFooter w='700'>
                                        {contentFooter()}
                                    </ModalFooter>
                                </form>
                            </ModalContent>
                        </Modal>}

                    {!isDesktop && <Drawer
                        isOpen={isOpenModal}
                        onClose={() => {
                            setIsOpenModal(false);
                            setTab(0);
                        }} size="full"
                    >
                        <DrawerOverlay width="full" h="full" />

                        <DrawerContent>
                            <DrawerHeader
                                px={8}
                                w='100%'
                                h="60px"
                                fontSize="18px"
                                fontWeight="500"
                                borderBottom="1px solid #E5E7EB"
                                lineHeight="44px"
                            >
                                {t("config_rfm")}
                            </DrawerHeader>

                            <DrawerCloseButton
                                fontSize='10px'
                                p='0'
                                h='40px'
                                w='40px'
                                borderRadius='md'
                                mt="6px"
                                _focus={{
                                    outline: "none",
                                }}
                            />
                            <form onSubmit={handleSubmit((values) => handleOnSubmitForm(values))}>
                                <DrawerBody
                                    w='100%'
                                    maxH={`calc(100vh - ${120}px)`}
                                    minH={`calc(100vh - ${120}px)`}
                                    overflowY="auto"
                                    className="hrv-report-scrollbar_custom"
                                    p={8}
                                >
                                    {contentBody()}
                                </DrawerBody>


                                <DrawerFooter
                                    w='100%'
                                    h="64px"
                                    position="fixed"
                                    bottom="0"
                                    borderTop="1px solid #E5E7EB"
                                    bg="#ffffff"
                                    display={'flex'}
                                    justifyContent={"start"}
                                >
                                    {contentFooter()}
                                </DrawerFooter>
                            </form>
                        </DrawerContent>
                    </Drawer>}
                </>
            }
        </>
    )
}

export default ConfigRFM;

const svgInfo = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.16669 5.83335H10.8334V7.50002H9.16669V5.83335ZM9.16669 9.16669H10.8334V14.1667H9.16669V9.16669ZM10 1.66669C5.40002 1.66669 1.66669 5.40002 1.66669 10C1.66669 14.6 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6 1.66669 10 1.66669ZM10 16.6667C6.32502 16.6667 3.33335 13.675 3.33335 10C3.33335 6.32502 6.32502 3.33335 10 3.33335C13.675 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.675 16.6667 10 16.6667Z" fill="#2463EB" />
</svg>)

const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 5.34167L14.6577 4.16667L9.99935 8.82501L5.34102 4.16667L4.16602 5.34167L8.82435 10L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 10L15.8327 5.34167Z" fill="#9CA3B0" />
    </svg>
);