import { Box, Spinner } from '@chakra-ui/react'

function WrapperSpinner({ ...restProps }) {
    return (
        <Box display='flex' alignItems='center' justifyContent="center" w="100%" h="fit-content">
            <Spinner variant="app" {...restProps} />
        </Box>
    )
}

export default WrapperSpinner