import { useTrans } from '@haravan/reactapp';
import { memo } from 'react';
import { Box } from '@chakra-ui/react';

import MoneyWithLocale from 'components/Money/locale';

import { COLORS_SEGMENT, SEGMENTS, SUGGEST_SEGMMENT } from './rfm';
import { FormatDecimalQuantity, GetCurrencyCode, formatNumber, formatPercentage, valueHandler } from 'helpers/helpers';

function TooltipRFM(props) {
    const t = useTrans();
    const { hoverId, data, labels } = props;
    const currencyCode = GetCurrencyCode();

    const renderData = (value, keyObject, segmentColumnIndex) => {
        let num = formatNumber(value, false, 0);
        let unit = "";
        let percentage: any = "";

        if (keyObject.dataFormat === "number") {
            switch (keyObject.name) {
                case "DayDiffPurchase":
                    num = valueHandler('number', "DayDiffPurchase", value)
                    unit = t("unit_days_ago");
                    break;

                case "OrderCountSpecial":
                    unit = t("unit_order");
                    break;

                case "CustomerCountSpecial": {
                    const customerCountSpecialColumnIndex = labels?.findIndex(line => line?.name === "CustomerCountSpecial");
                    const sum = data.reduce((acc, curr) => acc + curr[customerCountSpecialColumnIndex], 0);
                    const isLast = hoverId === data[data.length - 1][segmentColumnIndex];

                    if (isLast) {
                        if (data.length > 1) {
                            const arraySumWithoutLast = data.slice(0, -1);
                            const sumWithoutLast = arraySumWithoutLast.reduce((acc, curr) => {
                                const percentageValue = formatPercentage(curr[customerCountSpecialColumnIndex], sum);
                                return acc + FormatDecimalQuantity(percentageValue.replace("%", ""));
                            }, 0);

                            percentage = `${FormatDecimalQuantity(100 - sumWithoutLast)}%`;
                        } else {
                            percentage = "100%";
                        }
                    } else {
                        percentage = formatPercentage(value, sum);
                    }
                    break;
                }

                default:
                    break;
            }

            return `${num} ${unit} ${percentage ? `(${percentage})` : ""}`;
        }

        if (keyObject.dataFormat === 'money') {
            return <MoneyWithLocale value={value} currencyCode={currencyCode} />;
        }

        return value;
    };

    const xmlRenderValue = (keyObject, index) => {
        const isCustomerSegment = keyObject.name === "CustomerSegment";
        if (isCustomerSegment) return null;

        const segmentColumnIndex = labels?.findIndex(line => line.name === "CustomerSegment");
        const dataSegmentIndex = data?.findIndex(line => line[segmentColumnIndex] === hoverId);

        return (
            <div className="hrv-report-mb-12">
                <div className="hrv-report-d-flex hrv-report-items-center">
                    <span className="hrv-report-d-flex hrv-report-mr-4">{svgCircle}</span>
                    <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-fw-400 hrv-report-text-color-black-6">
                        {t(keyObject?.name || "Không tồn tại")}
                    </p>
                </div>

                <div className="hrv-report-ml-18">
                    <p className="hrv-report-fw-500 hrv-report-fs-14 hrv-report-text-color-black-6 hrv-report-mb-0">
                        {renderData(dataSegmentIndex !== -1 ? data[dataSegmentIndex][index] : 0, keyObject, segmentColumnIndex)}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <Box maxWidth={["auto", 'auto', 256]} width={['100vw', 'auto']} margin={["0 -12px", '-12px', '-12px']} style={{

        }}>
            <Box className="hrv-report-treemap-rfm-description-wrapper">
                <div className="hrv-report-d-flex hrv-report-items-center">
                    <span className="hrv-report-d-flex hrv-report-mr-4" style={{ width: 12, height: 12, background: COLORS_SEGMENT[hoverId], borderRadius: "50%" }}></span>
                    <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-text-color-black-6">
                        {t(SEGMENTS[hoverId - 1]?.name)}
                    </p>
                </div>
                <p className="hrv-report-ml-16 hrv-report-text-color-gray-9 hrv-report-fw-400 hrv-report-mb-0 hrv-report-fs-12">
                    {t(`CustomerSegment_value_${hoverId}_Desc`)}
                </p>
            </Box>

            <div className="hrv-report-treemap-rfm-measure-wrapper">
                <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-12">
                    <span className="hrv-report-d-flex hrv-report-mr-4">{svgMeasure}</span>
                    <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-text-color-black-6">
                        {t("important_measure")}
                    </p>
                </div>

                <div className="hrv-report-ml-20">
                    {labels.map((key, labelId) => (
                        <div key={labelId}>{xmlRenderValue(key, labelId)}</div>
                    ))}
                </div>
            </div>

            <div className="hrv-report-treemap-rfm-suggest-wrapper">
                <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-8">
                    <span className="hrv-report-d-flex hrv-report-mr-4">{svgTip}</span>
                    <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-text-color-black-6">
                        {t("propose")}
                    </p>
                </div>

                <ul className="hrv-report-treemap-rfm-suggest-list">
                    {SUGGEST_SEGMMENT[hoverId]?.map((text, idx) => (
                        <li key={idx}>
                            <p className="hrv-report-fs-12 hrv-report-text-color-black-6 hrv-report-fw-400 hrv-report-mb-0">
                                {t(`CustomerSegment_value_${hoverId}_${text}`)}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </Box>
    )
}

export default memo(TooltipRFM);

const svgCircle = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M2 6C2 3.78728 3.78728 2 6 2C8.21272 2 10 3.78728 10 6C10 8.21272 8.21272 10 6 10C3.78728 10 2 8.21272 2 6Z" fill="#2463EB" stroke="#DCEBFE" strokeWidth="2" />
</svg>

const svgMeasure = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.99967 1.33337C4.31967 1.33337 1.33301 4.32004 1.33301 8.00004C1.33301 11.68 4.31967 14.6667 7.99967 14.6667H13.333C14.0663 14.6667 14.6663 14.0667 14.6663 13.3334V8.00004C14.6663 4.32004 11.6797 1.33337 7.99967 1.33337ZM7.99967 13.3334C6.07301 13.3334 4.37967 12.3067 3.44634 10.7734L5.41301 8.80671L7.60634 10.6667L10.6663 7.61337V8.66671H11.9997V5.33337H8.66634V6.66671H9.71968L7.53301 8.85337L5.33301 7.00004L2.87301 9.46671C2.73967 9.00004 2.66634 8.50671 2.66634 8.00004C2.66634 5.06004 5.05967 2.66671 7.99967 2.66671C10.9397 2.66671 13.333 5.06004 13.333 8.00004C13.333 10.94 10.9397 13.3334 7.99967 13.3334ZM12.9997 13.6667C12.633 13.6667 12.333 13.3667 12.333 13C12.333 12.6334 12.633 12.3334 12.9997 12.3334C13.3663 12.3334 13.6663 12.6334 13.6663 13C13.6663 13.3667 13.3663 13.6667 12.9997 13.6667Z" fill="#2463EB" />
</svg>

const svgTip = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_9702_83443)">
        <path d="M4.16667 14H6.83333C6.83333 14.7334 6.23333 15.3334 5.5 15.3334C4.76667 15.3334 4.16667 14.7334 4.16667 14ZM2.83333 13.3334H8.16667V12H2.83333V13.3334ZM10.5 7.00002C10.5 9.54669 8.72667 10.9067 7.98667 11.3334H3.01333C2.27333 10.9067 0.5 9.54669 0.5 7.00002C0.5 4.24002 2.74 2.00002 5.5 2.00002C8.26 2.00002 10.5 4.24002 10.5 7.00002ZM9.16667 7.00002C9.16667 4.98002 7.52 3.33335 5.5 3.33335C3.48 3.33335 1.83333 4.98002 1.83333 7.00002C1.83333 8.64669 2.82667 9.59335 3.4 10H7.6C8.17333 9.59335 9.16667 8.64669 9.16667 7.00002ZM13.7467 5.58002L12.8333 6.00002L13.7467 6.42002L14.1667 7.33335L14.5867 6.42002L15.5 6.00002L14.5867 5.58002L14.1667 4.66669L13.7467 5.58002ZM12.1667 4.66669L12.7933 3.29335L14.1667 2.66669L12.7933 2.04002L12.1667 0.666687L11.54 2.04002L10.1667 2.66669L11.54 3.29335L12.1667 4.66669Z" fill="#2463EB" />
    </g>
    <defs>
        <clipPath id="clip0_9702_83443">
            <rect width="16" height="16" fill="white" />
        </clipPath>
    </defs>
</svg>