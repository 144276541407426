import { memo } from "react";
import StatusPeriod from "components/StatusPeriod";
import {
    GetComparison,
    GetCurrencyFormater,
    PointsToCommas,
    formatNumber,
    isEmpty,
} from "helpers/helpers";

function NumberChart(props) {
    const { dataCurrent, dataPrevious, configHeight, isComparisonDate } = props;
    let formater = GetCurrencyFormater();

    const dataFormat = isEmpty(dataCurrent)
        ? "number"
        : dataCurrent?.data[0]?.dataFormat;

    const summaryComparison = () => {
        let currentValue = isEmpty(dataCurrent) ? 0 : dataCurrent?.data[0]?.value;
        let previousValue = isEmpty(dataPrevious)
            ? 0
            : dataPrevious?.data[0]?.value;

        return (
            isComparisonDate &&
            GetComparison(Number(currentValue), Number(previousValue))
        );
    };

    let isDisplayBehindText = false;

    return (
        <div
            style={{
                height: configHeight,
                overflowY: "auto",
            }}
        >
            <>
                <p className="hrv-report-mb-4 hrv-report-fs-20 hrv-report-fw-600 hrv-report-text-color-black-6">
                    {dataFormat === "money" &&
                        PointsToCommas(formater.format(dataCurrent?.data[0]?.value))}

                    {dataFormat === "number" &&
                        formatNumber(Number(dataCurrent?.data[0]?.value))}

                    {dataFormat === "percent" &&
                        formatNumber(Number(dataCurrent?.data[0]?.value) * 100) + "%"}
                </p>

                {isComparisonDate && (
                    <StatusPeriod
                        value={summaryComparison().value}
                        trend={summaryComparison().trend}
                        infinity={summaryComparison().infinity}
                        reverse={summaryComparison().reverse}
                        isDisplayBehindText={isDisplayBehindText}
                    />
                )}
            </>
        </div>
    );
}

export default memo(NumberChart);
