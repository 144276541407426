import { FormatDecimalQuantity, formatPercentage, valueHandler } from "helpers/helpers";
import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";
import { useEffect, useRef, useState } from "react";

import { Responsive, WidthProvider } from "react-grid-layout";
import TooltipWithTouch from "components/TooltipWithTouch";
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import TooltipRFM from "./tooltip";

import "./styles.scss";

const ResponsiveGridLayout = WidthProvider(Responsive);

const indexCols = 5; // Tổng số cột trong template
const SIZE_AXIS = 45; // Kích cỡ trục X (chiều rộng), trục Y (chiều cao)
const SPACING = 0; // Khoảng cách padding tính từ container ngoài vô trong chart
const GAP = 2; // Khoảng cách từ cột này đến cột khác
const SIZE_NAME_AXIS = 15;

export const COLORS_SEGMENT = {
    1: "#d1d5db",
    2: "#fb923c",
    3: "#fdba72",
    4: "#fed6a9",
    5: "#ffedd6",
    6: "#bae5fd",
    7: "#7ed4fc",
    8: "#3abff8",
    9: "#5dead5",
    10: "#2bd4bd",
}

export const SUGGEST_SEGMMENT = {
    1: [
        "propose_1",
    ],
    2: [
        "propose_1",
        "propose_2",
    ],
    3: [
        "propose_1",
        "propose_2",
    ],
    4: [
        "propose_1",
        "propose_2",
    ],
    5: [
        "propose_1",
        "propose_2",
    ],
    6: [
        "propose_1",
        "propose_2",
    ],
    7: [
        "propose_1",
        "propose_2",
    ],
    8: [
        "propose_1",
        "propose_2",
    ],
    9: [
        "propose_1",
        "propose_2",
    ],
    10: [
        "propose_1",
        "propose_2",
    ]
}

type SegmentType = {
    id: number;
    name: string;
    description: string;
    r: any;
    f: any;
    m: any;
    color: string;
};

export const SEGMENTS: Array<SegmentType> = [
    {
        id: 1,
        name: 'CustomerSegment_value_1',
        description: "Hibernating",
        r: {
            from: 1,
            to: 2,
        },
        f: {
            from: 1,
            to: 2,
        },
        m: {
            from: 1,
            to: 2,
        },
        color: "#d1d5db"
    },
    {
        id: 2,
        name: "CustomerSegment_value_2",
        description: "Cannot Lose",
        r: {
            from: 1,
            to: 2,
        },
        f: {
            from: 5,
            to: 5,
        },
        m: {
            from: 5,
            to: 5,
        },
        color: "#fb923c"
    },
    {
        id: 3,
        name: 'CustomerSegment_value_3',
        description: "At Risk",
        r: {
            from: 1,
            to: 2,
        },
        f: {
            from: 3,
            to: 4,
        },
        m: {
            from: 3,
            to: 4,
        },

        color: "#fdba72"
    },
    {
        id: 4,
        name: 'CustomerSegment_value_4',
        description: "About To Sleep",
        r: {
            from: 3,
            to: 3,
        },
        f: {
            from: 1,
            to: 2,
        },
        m: {
            from: 1,
            to: 2,
        },
        color: '#fed6a9'
    },
    {
        id: 5,
        name: 'CustomerSegment_value_5',
        description: "Need Attention",
        r: {
            from: 3,
            to: 3,
        },
        f: {
            from: 3,
            to: 3,
        },
        m: {
            from: 3,
            to: 3,
        },
        color: '#ffedd6'
    },
    {
        id: 6,
        name: 'CustomerSegment_value_6',
        description: "Promising",
        r: {
            from: 4,
            to: 4,
        },
        f: {
            from: 1,
            to: 1,
        },
        m: {
            from: 1,
            to: 1,
        },
        color: "#bae5fd"
    },
    {
        id: 7,
        name: 'CustomerSegment_value_7',
        description: "Recent Customers",

        r: {
            from: 5,
            to: 5,
        },
        f: {
            from: 1,
            to: 1,
        },
        m: {
            from: 1,
            to: 1,
        },
        color: '#7ed4fc'
    },
    {
        id: 8,
        name: 'CustomerSegment_value_8',
        description: "Potential Loyalist",
        r: {
            from: 4,
            to: 5,
        },
        f: {
            from: 2,
            to: 3,
        },
        m: {
            from: 2,
            to: 3,
        },

        color: '#3abff8'
    },
    {
        id: 9,
        name: "CustomerSegment_value_9",
        description: "Loyal",
        r: {
            from: 3,
            to: 4,
        },
        f: {
            from: 4,
            to: 5,
        },
        m: {
            from: 4,
            to: 5,
        },
        color: '#5dead5'
    },
    {
        id: 10,
        name: 'CustomerSegment_value_10',
        description: "Champions",
        r: {
            from: 5,
            to: 5,
        },
        f: {
            from: 4,
            to: 5,
        },
        m: {
            from: 4,
            to: 5,
        },
        color: "#2bd4bd"
    },
];

function TreemapRFM(props) {
    const { data, labels, optionMeasure, useConfig = false, configWidth, configHeight, threshold = 0 } = props;
    const t = useTrans();
    const { width } = useWindowDimensions();
    const useWidth = useConfig ? configWidth : width;
    const useHeight = useConfig ? configHeight / 6 : 60;

    const refChart: any = useRef();

    const [layoutsLg, setLayoutsLg] = useState<any>([]);
    const [hoverId, setHoverId] = useState<any>(0);
    const spacious = 64 + 64 + 12;
    const minHeight = useHeight;
    const widthCol = useConfig ? 0 : (useWidth - spacious) / indexCols; // Width của cột theo tỉ lệ màn hình

    const maxHeightCol = useConfig ? minHeight : Math.max(minHeight, widthCol / 3.5);

    useEffect(() => {
        const template = SEGMENTS.map((segment, index) => {
            let outputValue: any = "";

            // * Khi fieldname chọn để draw thay đổi thì sẽ tìm kiếm index fieldname đó để vẽ chart
            const customerCountSpecialColumnIndex = labels?.findIndex(line => line?.name === optionMeasure.measureField);
            const customFieldColumnIndex = labels?.findIndex(line => line?.name === "CustomerSegment");

            const value = data.find(y => y[customFieldColumnIndex] === segment.id.toString());

            if (["CustomerCountSpecial", "NetSaleAmount", "OrderCountSpecial"].includes(optionMeasure.measureField)) {
                const sum = data.reduce((acc, curr) => acc + curr[customerCountSpecialColumnIndex], 0);
                const isLast = segment.id.toString() === data[data.length - 1][customFieldColumnIndex];
                if (isLast) {
                    if (data.length > 1) {
                        const arraySumWithoutLast = data.slice(0, -1);
                        const sumWithoutLast = arraySumWithoutLast.reduce((acc, curr) => {
                            const percentageValue = formatPercentage(curr[customerCountSpecialColumnIndex], sum);
                            return acc + FormatDecimalQuantity(percentageValue.replace("%", ""));
                        }, 0);

                        outputValue = `${FormatDecimalQuantity(100 - sumWithoutLast)}%`;
                    } else {
                        outputValue = "100%";
                    }
                } else {
                    outputValue = formatPercentage(value ? value[customerCountSpecialColumnIndex] : 0, sum);
                }
            } else {
                outputValue = value ? valueHandler(labels[customerCountSpecialColumnIndex].dataFormat, labels[customerCountSpecialColumnIndex].name, value[customerCountSpecialColumnIndex]) : 0
            }

            return {
                h: ((segment.f.to - segment.f.from) + 1),
                w: (((segment.r.to - segment.r.from) + 1)),
                x: ((segment.r.from - 1)),
                y: Math.abs(indexCols - segment.f.to),
                static: true,
                name: segment.name,
                i: segment.id.toString(),
                v: outputValue,
                color: segment.color
            }
        });

        setLayoutsLg(template);
    }, [optionMeasure]);

    const scalesX = () => {
        return <div
            style={{
                position: "absolute",
                bottom: -SIZE_AXIS + SIZE_NAME_AXIS + GAP * 4,
                left: SPACING + SIZE_AXIS,
                display: "flex",
                height: 16,
            }}
        >
            {Array(5).fill(0).map((_, idx) => {
                return <div
                    key={idx}
                    className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center"
                    style={{
                        width: ((refChart?.current?.clientWidth) / indexCols) || 0,
                    }}
                >
                    <p className="hrv-report-fs-10 hrv-report-md-fs-12 hrv-report-mb-0">
                        {idx + 1}
                    </p>
                </div>
            })}
        </div>
    }

    const scalesY = () => {
        return <div
            style={{
                position: "absolute",
                top: SPACING + threshold,
                left: SPACING + SIZE_NAME_AXIS + GAP * indexCols,
                width: 16
            }}
        >
            {[5, 4, 3, 2, 1].map((_, idx) => {
                return <div
                    className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center"
                    style={{
                        height: maxHeightCol + 2,
                    }}
                    key={idx}
                >
                    <p className="
                    hrv-report-fs-10
                    hrv-report-md-fs-12
                    hrv-report-mb-0
                ">
                        {_}
                    </p>
                </div>
            })}
        </div>
    }

    const labelsX = () => {
        return <div
            style={{
                position: "absolute",
                bottom: -SIZE_AXIS,
                left: SPACING + SIZE_AXIS,
                width: refChart?.current?.clientWidth,
                textAlign: "center"
            }}
        >
            <p className="
            hrv-report-fs-10
            hrv-report-md-fs-12
            hrv-report-mb-0 hrv-report-text-color-black-6 hrv-report-fw-600">
                {t("latest_purchase_time")}
            </p>
        </div>

    }

    const labelsY = () => {
        return <div
            style={{
                position: "absolute",
                top: SPACING + threshold,
                left: SPACING,
                width: SIZE_NAME_AXIS,
                height: useConfig ? configHeight : '100%',
                textAlign: "center",
                display: "flex"

            }}
        >
            <p
                className="hrv-report-fs-10 hrv-report-md-fs-12 hrv-report-text-color-black-6 hrv-report-mb-0  hrv-report-fw-600"
                style={{
                    writingMode: "vertical-rl",
                    textOrientation: "mixed",
                    transform: "rotate(180deg)",
                }}>
                {t("OrderCountSpecialWithRFM")}
            </p>
        </div >

    }

    return (
        <>
            {scalesY()}
            {scalesX()}
            {labelsX()}
            {labelsY()}

            <Drawer
                isOpen={Boolean(hoverId) && width <= 480}
                placement='bottom'
                onClose={() => {
                    setHoverId(0)
                }}
                id='Modal_Tooltip_Rfm'
                autoFocus={false}
            >
                <DrawerOverlay />

                <DrawerContent>

                    <DrawerBody style={{
                        width: "100vw"
                    }}>
                        <TooltipRFM hoverId={hoverId} data={data} labels={labels} />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            <div
                style={{
                    marginLeft: SIZE_AXIS,
                    marginBottom: SIZE_AXIS,
                    borderLeft: '1px solid #e5e7eb',
                    borderBottom: '1px solid #e5e7eb',
                    height: indexCols * maxHeightCol + SIZE_NAME_AXIS
                }}
                ref={refChart}
                className="hrv-report-container-rfm"
            >
                <ResponsiveGridLayout
                    margin={[GAP, GAP]}
                    className={`layout`}
                    layouts={{
                        lg: layoutsLg,
                    }}
                    cols={{
                        lg: indexCols,
                    }}
                    breakpoints={{
                        lg: 1200,
                    }}
                    rowHeight={maxHeightCol}
                    isDraggable={false}
                    isResizable={false}
                    preventCollision={true}
                    style={{
                        right: 0
                    }}
                >
                    {layoutsLg.map((block) => {
                        return <div
                            key={block.i}
                            id={`hrv-report-widget-id-${block.i}`}
                            style={{
                                background: COLORS_SEGMENT[block.i],
                                borderRadius: 4,
                                display: "flex",
                            }}
                            className="hrv-report-treemap-rfm"
                            onMouseEnter={() => {
                                if (width > 480) {
                                    setHoverId(block.i)
                                }
                            }}
                            onMouseLeave={(e) => {
                                if (width > 480) {
                                    setHoverId(0)
                                }
                            }}
                            onClick={() => {
                                if (width <= 480) {
                                    setHoverId(block.i);
                                }
                            }}
                        >
                            <p className="
                                        hrv-report-fs-8
                                        hrv-report-d-block 
                                        hrv-report-sm-d-none
                                        hrv-report-fs-11
                                        hrv-report-fw-600
                                        hrv-report-text-color-black-6
                                        hrv-report-p-4
                                        "
                            >
                                {block.v}
                            </p>

                            <TooltipWithTouch
                                label={
                                    <TooltipRFM hoverId={hoverId} data={data} labels={labels} />
                                }
                                className="hrv-report-w-full"
                                placement="right"
                                offset={[180, 5]}
                                variant='rfm'
                                style={{
                                    display: width <= 480 ? "none" : "flex",
                                    width: "100%",
                                }}
                                hasArrow={false}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    className="
                                        hrv-report-p-4
                                        hrv-report-d-flex 
                                        hrv-report-flex-col
                                        hrv-report-w-full
                                    "
                                >
                                    <p className={`
                                        hrv-report-fw-600
                                        hrv-report-text-color-black-6
                                        hrv-report-mb-2
                                        ${useWidth < 700 ? "hrv-report-fs-9" : 'hrv-report-sm-fs-10 hrv-report-md-fs-12'}
                                        ${useWidth < 650 ? "hrv-report-d-none" : 'hrv-report-d-block'}
                                        `}
                                    >
                                        {t(block.name)}
                                    </p>

                                    <p className="
                                        hrv-report-fs-8
                                        hrv-report-sm-fs-10
                                        hrv-report-md-fs-12
                                        hrv-report-fw-600
                                        hrv-report-text-color-black-6"
                                    >
                                        {block.v}
                                    </p>
                                </div>
                            </TooltipWithTouch>
                        </div>
                    })}
                </ResponsiveGridLayout >
            </div>
        </>
    )
}

export default TreemapRFM;