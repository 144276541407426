import React, { useEffect, useState } from "react";
import { useTrans } from "locales/hook";
import useTranslate from "hooks/useTranslate";
import useWindowDimensions from "hooks/useWindowDimension";
import { useGTM } from "hooks/useGTM";
import { useMatch } from "react-router-dom";
import { useStickyCalendar } from "hooks/useStickyCalendar";
import dayjs from "components/Dayjs"

import HRVYearPicker from "../YearPicker";
import { ShowUIDate, ShowUIDateCompare } from "helpers/helpers";
import { Skeleton } from "@chakra-ui/react";


import "./styles.scss";
import DateRangePicker from "pages/DatePicker";

export default function ReportDatePicker({
  onChangeDate,
  dateStart,
  dateEnd,
  isShowIcon = true,
  customText,
  isComparePeriod = false,
  placement = "right",
  maxDate,
  isYearPicker,
  fullWidth,
  customContent,
  isStockLot = false,
  isRFM = false,
  size = 'm',
  threshold = 0,
  alwaysShowCalendars = true,
  ...restProps
}: any) {
  const { ranges, onCallback } = restProps;
  const t = useTrans();
  const { getLocaleDatePicker } = useTranslate();
  const [isReloadDatePicker, setIsReloadDatePicker] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const locale = getLocaleDatePicker();
  const { width } = useWindowDimensions();
  const matches = useMatch("/dashboard");
  const isDashboard = Boolean(matches);

  useStickyCalendar({ id: "report_detail_header", threshold })

  const rangeName = dateStart && dateEnd ? ShowUIDate(dateStart, dateEnd, isStockLot, isRFM) : "";
  const rangeNameCompare = ShowUIDateCompare(
    dateStart,
    dateEnd,
    ranges,
  );

  const { attributes, detectClass } = useGTM({
    enabled: isDashboard && isComparePeriod, // Datepicker so sánh và là datepicker trong trang dashboard
    variables: {
      'ut-name': "compare_period",
      'ut-screen': "dashboard"
    }
  });

  // ! set lại ranges của kì so sánh khi thay đổi theo ngày lọc
  React.useEffect(() => {
    if (isComparePeriod) {
      setIsReloadDatePicker(true);

      setTimeout(() => {
        setIsReloadDatePicker(false);
      }, 500);
    }
  }, [ranges, isComparePeriod]);

  useEffect(() => {
    // ! Add className
    const getDatepicker = document.querySelectorAll(".daterangepicker");

    for (let i = 0; i < getDatepicker.length; i++) {
      let datePickerClsList = getDatepicker[i].classList;

      datePickerClsList?.add("hrv-report-custom-datepicker");
    }
  }, [isReloadDatePicker]);

  // const positionPopupDatepicker = () => {
  //   const reportHeader = document.getElementById("report_detail_header");

  //   if (reportHeader) {
  //     let top = (reportHeader?.getBoundingClientRect().top || 0) + window.scrollY;

  //     let reportHeaderHeight = reportHeader?.clientHeight || 0;

  //     const calendar: any = document.body.querySelectorAll(".daterangepicker");

  //     if (calendar.length) {
  //       for (let i = 0; i < calendar.length; i++) {
  //         calendar[i].style.top = top + reportHeaderHeight - 16 + threshold + "px";
  //       }
  //     }
  //   } else {
  //     return;
  //   }
  // }

  const handleOnShow = () => {
    setIsShowCalendar(true);

    // width > 992 && positionPopupDatepicker()

    if (rangeName === t("thisMonth")) {
      const currentSelected = document.querySelector(
        `.daterangepicker .ranges .active`,
      );
      const currentName = currentSelected?.innerHTML || "";
      if (currentName !== rangeName) {
        const selected = document.querySelector(
          `.daterangepicker .ranges [data-range-key="${rangeName}"]`,
        );
        currentSelected?.classList.remove("active");
        selected?.classList.add("active");
      }
    }
  };

  const handleOnHide = () => {
    setIsShowCalendar(false);
  };

  const handleOnCallback = (start, end, label) => {
    onCallback && onCallback(start, end, label)
  }

  const sizeClasses = {
    m: "hrv-report-custom_datepicker__sizeM",
    l: "hrv-report-custom_datepicker__sizeL"
  }


  return (
    <div className="hrv-report-relative">
      {isYearPicker ? (
        <HRVYearPicker
          isShowIcon={isShowIcon}
          customText={customText}
          onChange={(startDate, endDate) => {
            onChangeDate && onChangeDate(startDate, endDate);
          }}
          fullWidth={fullWidth}
        />
      ) : (
        <>
          {isComparePeriod ?
            !isReloadDatePicker ? <>
              <DateRangePicker
                locale={locale}
                startDateDefault={dateStart ? dateStart : undefined}
                endDateDefault={dateEnd ? dateEnd : undefined}
                ranges={ranges}
                minDate={dayjs().year(2014).startOf("year")}
                maxDate={maxDate || dayjs().endOf("year")}
                minYear={2014}
                maxYear={parseInt(dayjs().format('YYYY'))}
                singleDatePicker={false}
                onHide={handleOnHide}
                onShow={handleOnShow}
                threshold={8}
                {...restProps}
                onChange={(start, end, label) => {
                  handleOnCallback(start, end, label)
                }}
                singleCalendar={width < 552}
              >
                <div
                  className={`
                            hrv-report-daterangepicker hrv-report-d-flex hrv-report-justify-between hrv-report-items-center hrv-report-custom_datepicker
                            ${isShowCalendar ? "hrv-report-custom_datepicker__active" : ""}
                            ${sizeClasses[size]}
                            ${detectClass}
                            `
                  }
                  {...attributes}
                >
                  {customContent ? (
                    customContent()
                  ) : (
                    <span>
                      <span className="hrv-report-mr-8 hrv-report-text-color-gray-12 hrv-report-fs-14">
                        {isShowIcon ? calendarSvg : customText}
                      </span>

                      <span className="hrv-report-mr-8 hrv-report-fs-14">
                        {rangeNameCompare}
                      </span>
                    </span>
                  )}

                  <span
                    className={`hrv-report-trail-icon ${isShowCalendar ? "hrv-report-trail-icon__active" : ""
                      }`}
                  >
                    {arrowDownSvg}
                  </span>
                </div>
              </DateRangePicker>
            </> :
              <Skeleton height={size === "m" ? "32px" : "40px"} minW={"200px"} />
            : <DateRangePicker
              locale={locale}
              startDateDefault={dateStart ? dateStart : undefined}
              endDateDefault={dateEnd ? dateEnd : undefined}
              ranges={locale.ranges}
              minDate={dayjs().year(2014).startOf("year")}
              maxDate={maxDate || dayjs().endOf("year")}
              minYear={2014}
              maxYear={parseInt(dayjs().format('YYYY'))}
              singleDatePicker={false}
              onHide={handleOnHide}
              onShow={handleOnShow}
              threshold={8}
              {...restProps}
              onChange={(start, end, label) => {
                handleOnCallback(start, end, label)
              }}
              singleCalendar={width < 552}
            >
              <div
                className={`
                hrv-report-daterangepicker hrv-report-d-flex hrv-report-justify-between hrv-report-items-center
        hrv-report-custom_datepicker ${isShowCalendar ? "hrv-report-custom_datepicker__active" : ""
                  }
                    ${sizeClasses[size]}
                    `}
              >
                {customContent ? (
                  customContent()
                ) : (
                  <span className="">
                    <span className="hrv-report-mr-8 hrv-report-text-color-gray-12 hrv-report-fs-14">
                      {isShowIcon ? calendarSvg : customText}
                    </span>

                    <span className="hrv-report-mr-8 hrv-report-fs-14">
                      {rangeName}
                    </span>
                  </span>
                )}

                <span
                  className={`hrv-report-trail-icon ${isShowCalendar ? "hrv-report-trail-icon__active" : ""
                    }`}
                >
                  {arrowDownSvg}
                </span>
              </div>
            </DateRangePicker>}
        </>
      )
      }
    </div >
  );
}

const calendarSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M15.8333 3.33329H15V1.66663H13.3333V3.33329H6.66667V1.66663H5V3.33329H4.16667C3.24167 3.33329 2.50833 4.08329 2.50833 4.99996L2.5 16.6666C2.5 17.5833 3.24167 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6666V4.99996C17.5 4.08329 16.75 3.33329 15.8333 3.33329ZM15.8333 16.6666H4.16667V8.33329H15.8333V16.6666ZM15.8333 6.66663H4.16667V4.99996H15.8333V6.66663ZM7.5 11.6666H5.83333V9.99996H7.5V11.6666ZM10.8333 11.6666H9.16667V9.99996H10.8333V11.6666ZM14.1667 11.6666H12.5V9.99996H14.1667V11.6666ZM7.5 15H5.83333V13.3333H7.5V15ZM10.8333 15H9.16667V13.3333H10.8333V15ZM14.1667 15H12.5V13.3333H14.1667V15Z" fill="#4B5563" />
  </svg>
);

const arrowDownSvg = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87461 1.00002L5.99461 4.88002L2.11461 1.00002C1.72461 0.61002 1.09461 0.61002 0.704609 1.00002C0.314609 1.39002 0.314609 2.02002 0.704609 2.41002L5.29461 7.00002C5.68461 7.39002 6.31461 7.39002 6.70461 7.00002L11.2946 2.41002C11.6846 2.02002 11.6846 1.39002 11.2946 1.00002C10.9046 0.62002 10.2646 0.61002 9.87461 1.00002Z"
      fill="#9CA3B0"
    />
  </svg>
);
