import dayjs from "components/Dayjs";

import { useEmbedApp, useFetchJson } from "@haravan/reactapp";
import { useTrans } from "locales/hook"
import useApp from "hooks/useApp";
import useHistory from "hooks/useHistory";
import { useQueryString } from "hooks/useQueryString";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useStore } from "../../context/store";

import HRVCheckbox from "components/Checkbox";
import MoneyWithLocale from "components/Money/locale";
import { ReportDatePicker } from "components/PanelFilter/components";
import { SkeletonText } from "@chakra-ui/react";
import TooltipWithTouch from "components/TooltipWithTouch";

import { formatNumber } from "helpers/helpers";
import { TEMPLATE_DASHBOARD_SHIPMENTS } from "./constants";

import { useDetectPage } from "hooks/useDetectPage";

import "./styles.scss";

export default function Main() {
  useDetectPage('Tổng quan vận chuyển');

  const t = useTrans();
  const { push } = useHistory();
  const fetchJson = useFetchJson();
  const { shop } = useEmbedApp();

  const { toogleFullScreen } = useApp();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [liveUpdate, setLiveUpdate] = useState(false);
  const { state, dispatch, actions } = useStore();

  const { startDate, endDate } = state;

  const queryString: { startDate?: string; endDate?: string } =
    useQueryString();

  let { data, isLoading } = useSWR(
    `${import.meta.env.VITE_APP_API_SERVICES + "/"
    }dashboards/shipments?endDate=${endDate
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}&startDate=${startDate
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}`,
    fetchJson,
    { refreshInterval: liveUpdate ? 30000 : 0 }
  );

  const isChooseToday =
    dayjs().isSame(startDate, "day") && dayjs().isSame(endDate, "day");

  useEffect(() => {
    // ? Start và End từ Query
    let startDateFromQr: any = dayjs(queryString.startDate);
    let endDateFromQr: any = dayjs(queryString.endDate);

    if (queryString.startDate && queryString.endDate) {
      dispatch(
        actions.setDateRange({
          startDate: startDateFromQr,
          endDate: endDateFromQr,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeDate = (start, end) => {
    const isChooseToday =
      dayjs().isSame(start, "day") && dayjs().isSame(end, "day");

    if (!isChooseToday) {
      setLiveUpdate(false);
    }

    const payload = {
      startDate: start,
      endDate: end,
    };

    dispatch(actions.setDateRange(payload));

    push(
      `/shipments/dashboard?startDate=${start.format(
        "YYYY-MM-DD"
      )}&endDate=${end.format("YYYY-MM-DD")}`
    );
  };

  const handleClickFullScreenBtn = () => {
    setIsFullScreen(!isFullScreen);
    toogleFullScreen(!isFullScreen);
  };

  const handleChangeUpdateParallel = () => {
    setLiveUpdate(!liveUpdate);
  };

  return (
    <div className="hrv-report-px-8 hrv-report-md-px-0 hrv-report-my-24">
      <div className=" hrv-report-d-flex hrv-report-justify-between hrv-report-mb-16">
        <div>
          <ReportDatePicker
            onCallback={handleOnChangeDate}
            dateStart={startDate}
            dateEnd={endDate}
          />
        </div>

        <div
          onClick={() => {
            handleClickFullScreenBtn();
          }}
          className="hrv-report-cursor-pointer hrv-report-d-flex hrv-report-items-center"
        >
          <span className="hrv-report-mr-8">
            {!isFullScreen ? svgFullScreen : svgSmallScreen}
          </span>

          <span className="hrv-report-d-none hrv-report-sm-d-block hrv-report-fs-16 hrv-report-fw-600 hrv-report-text-color-gray-11">
            {!isFullScreen ? t("fullScreen") : t("MinimizeScreen")}
          </span>
        </div>
      </div>

      <div>
        <HRVCheckbox
          title={t("constantly_updated")}
          checked={liveUpdate}
          onChange={handleChangeUpdateParallel}
          disabled={!isChooseToday}
          customTip={t("Continuous_update_only_when_set_time_to_today")}
          className={`${liveUpdate ? "hrv-checked" : "hrv-unchecked"}`}
        />
      </div>

      <div>
        {Object.keys(TEMPLATE_DASHBOARD_SHIPMENTS).map((key, idx) => {
          const { name, isShow, icon, itemsInclude } = TEMPLATE_DASHBOARD_SHIPMENTS[key];

          return (
            <>
              <div
                className={`${!isShow && "hrv-report-d-none"} hrv-report-whitebox hrv-report-border-8 hrv-report-p-16 hrv-report-my-16`}
                key={`${idx}_dashboard_shipments`}
                style={{
                  boxShadow: "unset",
                }}
              >
                {isShow && (
                  <div>
                    {isLoading ? <>
                      <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-8">
                        <span className="hrv-report-mr-8">{icon}</span>

                        <span className="hrv-report-fs-16 hrv-report-fw-600 hrv-report-text-color-black-6">
                          {t(name)}
                        </span>
                      </div>

                      <div className="hrv-report-row hrv-shipments-wrapper">
                        {Array(itemsInclude).fill(0).map((_, idx) => {
                          let md = 12 / itemsInclude;
                          return <div
                            key={`card_of_dashboardShipmentasd_${idx}`}
                            className={`hrv-report-col-12 hrv-report-md-col-${md}`}
                          >
                            <div className="hrv-shipments-card hrv-report-d-flex hrv-report-justify-center hrv-report">

                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <div className="hrv-report-mb-4"></div>

                                <SkeletonText noOfLines={3} skeletonHeight='2' />
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                    </> :
                      <>
                        <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mb-8">
                          <span className="hrv-report-mr-8">{icon}</span>

                          <span className="hrv-report-fs-16 hrv-report-fw-600 hrv-report-text-color-black-6">
                            {t(name)}
                          </span>
                        </div>

                        <div className="hrv-report-row hrv-shipments-wrapper">
                          <>
                            {data?.data[name].map((item, index) => {
                              let md = 12 / data?.data[name].length;

                              return (
                                <div
                                  key={`card_of_dashboardShipment_${index}`}
                                  className={`hrv-report-col-12 hrv-report-md-col-${md}`}
                                  onClick={() => {
                                    item?.link?.length &&
                                      window.open(
                                        `https://${shop}/admin/${item?.link}`
                                      );
                                  }}
                                >
                                  <div className="hrv-shipments-card hrv-report-d-flex hrv-report-justify-center hrv-report">
                                    <TooltipWithTouch
                                      label={t(item.name + "_Desc")}
                                      placement="top"
                                      hasArrow
                                    >
                                      <span className="hrv-shipments-card_tooltip">
                                        {t(item.name)}
                                      </span>
                                    </TooltipWithTouch>

                                    {isLoading ? (
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <div className="hrv-report-mb-4"></div>

                                        <SkeletonText noOfLines={3} skeletonHeight='2' />
                                      </div>
                                    ) : (
                                      <>
                                        <p
                                          className={`hrv-shipments-card_indicator ${!item?.isPositive
                                            ? "hrv-report-text-color-red-2"
                                            : ""
                                            }`}
                                        >
                                          {formatNumber(parseInt(item.total))}{" "}
                                          {item.unit && t(item.unit)}
                                          {!item?.isPositive && t("đơn")}
                                        </p>

                                        <p className="hrv-shipments-card_money">
                                          <MoneyWithLocale
                                            value={parseInt(item.amount)}
                                          />
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        </div>
                      </>}

                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

const svgFullScreen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="fullscreen">
      <path
        id="Vector"
        d="M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z"
        fill="#4B5563"
      />
    </g>
  </svg>
);

const svgSmallScreen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M5 16H8V19H10V14H5V16ZM8 8H5V10H10V5H8V8ZM14 19H16V16H19V14H14V19ZM16 8V5H14V10H19V8H16Z"
        fill="#4B5563"
      />
    </g>
  </svg>
);
