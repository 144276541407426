import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import TooltipWithTouch from "components/TooltipWithTouch";
import { limitString } from "helpers/helpers";

function HChip({
  content,
  onHandleClose,
  size,
  showTooltip = false,
  tooltipLabel = ""
}: any) {

  const handleOnClose = () => {
    onHandleClose && onHandleClose();
  };

  const WrapperComponent = ({ children }) => {
    if (showTooltip) {
      return <TooltipWithTouch label={tooltipLabel}>
        {children}
      </TooltipWithTouch>
    } else {
      return <>{children}</>
    }
  }

  return (
    <WrapperComponent>
      <Tag
        size={size}
        key={size}
        variant='solid'
        colorScheme='green'
      >
        <TagLabel>
          {!content.includes(" ") ? limitString(content, 0, 40) : content}
        </TagLabel>

        <TagCloseButton onClick={handleOnClose} _focusVisible={{
          outline: "none",
        }}
          _focus={{
            outline: "none",
          }} />
      </Tag>
    </WrapperComponent>


  );
}

export default HChip;