import { useTrans } from "locales/hook";
import { PanelCategory } from "./components";
import { Box } from "@chakra-ui/react";

interface IProps {
    options: Array<any>;
    onHandleSubmitFilter?: Function;
    reportId?: number | string;
}

function PanelStatus(props: IProps) {
    const { options, onHandleSubmitFilter, reportId } = props;
    const t = useTrans();

    const handleSubmitFilter = (filters) => {
        const buildFilter = filters?.map((filter) => {
            let final: any = [];
            filter.query.forEach((query) => {
                final.push({
                    dimension: filter.dimension,
                    searchKey: "",
                    value: query.value,
                    symbol: query.symbol
                })
            })

            return final;
        });

        const result = buildFilter.flat();

        onHandleSubmitFilter && onHandleSubmitFilter(result)
    }

    return (
        <div className="hrv-report-whitebox-no-bs hrv-report-px-0 hrv-report-mt-16 hrv-report-pb-8 hrv-report-lg-pb-16 hrv-report-pt-16">
            <div className="hrv-report-d-flex hrv-report-px-16 hrv-report-mb-16 hrv-report-justify-between hrv-report-items-center">
                <Box textStyle='h4'>{t("Inventory_status")}</Box>
            </div>

            <div className="hrv-report-row hrv-report-px-16" style={{
                marginRight: "-6px",
                marginLeft: "-6px"
            }}>
                {options.map((option, idx) => {
                    return <div className="hrv-report-col-6 hrv-report-md-col-4 hrv-report-lg-col-3 hrv-report-px-6 hrv-report-mb-8 hrv-report-lg-mb-0" key={`${idx}_panel_category`}>
                        <PanelCategory {...option} onHandleOnClickItem={handleSubmitFilter} reportId={reportId} />
                    </div>
                })}
            </div>
        </div>
    )
}

export default PanelStatus