import { memo } from "react";
import GroupHeader from "./GroupHeader";
import DefaultHeader from "./DefaultHeader";
import { useGroupTable } from "pages/ReportStandard/context/store/constants";

function HeaderTableDefault(props) {
  const {
    reportNameSystem,
    canReadMACost,
    dataHeaders,
    sortValue,
    sortable,
    heatmapMode,
    onHandleSortTable,
  } = props;

  return (
    <thead>
      {useGroupTable.includes(reportNameSystem) ? (
        <GroupHeader
          canReadMACost={canReadMACost}
          reportNameSystem={reportNameSystem}
          onHandleSortTable={onHandleSortTable}
          dataHeaders={dataHeaders}
        />
      ) : (
        <DefaultHeader
          dataHeaders={dataHeaders}
          sortValue={sortValue}
          sortable={sortable}
          reportNameSystem={reportNameSystem}
          heatmapMode={heatmapMode}
          onHandleSortTable={onHandleSortTable}
        />
      )}
    </thead>
  );
}

export default memo(HeaderTableDefault);
