export enum DateShowType {
    hour = 'hour',
    day = 'day',
    month = 'month',
    year = 'year',
    day_of_week = 'day_of_week',
    date = 'date',
    time = 'time',
    long_date = 'long_date',
    long_time = 'long_time',
    relative = 'relative',
    custom = 'custom',
    mixed_long_date = 'mixed_long_date',
}

export const CANARY_ORGS = [
    1000244594,
    200000040794,
    1000051569,
    1000360569,
];