import { memo } from "react";
import { useTrans } from "locales/hook";

import dayjs from "components/Dayjs";
import { formatDate } from "helpers/helpers";


function ReportItem(props) {
  const {
    isFavourite,
    name,
    lastViewed,
    isSystemReport,
    createdUser,
    typeName,
    id,
    onToggleFavoriteBtnItem,
    // createdUserId,
    // userId,
    // roles,
    // onClickEdit,
    // onClickDelete,
    createdDate,
    reportNameSystem,
    onClickDetail
  } = props;

  const t = useTrans();

  const onClickHandler = (tab, id) => {
    onClickDetail && onClickDetail(tab, id, reportNameSystem)
  };
  const renderLabelNewReport = (isSystemReport, createdDate) => {
    const isDisplayNewBadge = () => {
      if (isSystemReport) {
        const now = dayjs();
        const created = dayjs(createdDate);
        const diff = now.diff(created, "days");
        if (diff <= 14) {
          return true;
        }
      }

      return false;
    };

    return (
      <>
        {isDisplayNewBadge() ? (
          <span
            className="hrv-report-d-inline hrv-report-ml-4 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-white hrv-report-border-4 hrv-report-fw-400"
            style={{ backgroundColor: "#DC2828" }}
          >
            {t("new")}
          </span>
        ) : null}
      </>
    );
  };

  // const renderLabelBetaReport = () => {
  //   return (
  //     <span
  //       className="hrv-report-ml-4 hrv-report-px-6 hrv-report-fs-12 hrv-report-py-2 hrv-report-text-color-blue-6 hrv-report-border-4 hrv-report-fw-500"
  //       style={{ backgroundColor: "#E1F3FE" }}
  //     >
  //       {t("Beta")}
  //     </span>
  //   );
  // };
  return (
    <>
      <div className="hrv-report-pt-12 hrv-report-pl-16 hrv-report-pr-10 hrv-report-d-flex hrv-report-items-center">
        <div
          className="hrv-report-card__tag hrv-report-mb-0"
          style={{
            padding: "0 4px",
          }}
        >
          <span
            style={{
              fontSize: 10,
            }}
          >
            {t(typeName)}
          </span>
        </div>

        <span className="hrv-report-px-4">·</span>

        <span>
          <span className="hrv-report-fs-12 hrv-report-text-color-gray-9">
            {t("Xem gần nhất")}:
          </span>{" "}
          <span className="hrv-report-fs-12 hrv-report-text-color-black-6">
            {lastViewed !== "1970-01-01T17:00:00Z" ? formatDate(lastViewed, "DD/MM/YYYY") : "--"}
          </span>
        </span>
      </div>

      <div className="hrv-report-d-flex hrv-report-justify-between hrv-report-items-center hrv-report-pb-12 hrv-report-pl-16 hrv-report-pr-10 hrv-report-border-bottom-black-7">
        <div className="hrv-report-mr-10">
          <div
            className="hrv-report-mt-4"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            <span
              className="hrv-report-d-inline hrv-report-cursor-pointer hrv-report-mb-0 hrv-report-fs-14 hrv-report-text-color-blue-5"
              onClick={() => {
                onClickHandler && onClickHandler(typeName, id);
              }}
            >
              {name}
            </span>

            <>{renderLabelNewReport(isSystemReport, createdDate)}</>
          </div>

          <div className="hrv-report-mt-4">
            <span>
              <span className="hrv-report-fs-12 hrv-report-text-color-gray-9">
                {t("Người tạo")}:
              </span>{" "}
              <span className="hrv-report-fs-12 hrv-report-text-color-black-6">
                {isSystemReport ? "Haravan" : createdUser}
              </span>
            </span>
          </div>
        </div>

        <div
          className="hrv-report-d-flex hrv-report-items-center"
          style={{
            marginTop: -18,
          }}
        >
          <div
            className="hrv-report-ml-6 hrv-report-p-6 hrv-report-mr-4 hrv-report-cursor-pointer"
            onClick={() => {
              onToggleFavoriteBtnItem &&
                onToggleFavoriteBtnItem(id, isFavourite);
            }}
          >
            {isFavourite ? svgStarredActive : svgStarredDisable}
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ReportItem);

const svgStarredDisable = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3337 8.11683L12.342 7.60016L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085L15.1503 17.9168L13.792 12.0585L18.3337 8.11683ZM10.0003 13.2502L6.86699 15.1418L7.70033 11.5752L4.93366 9.17516L8.58366 8.8585L10.0003 5.50016L11.4253 8.86683L15.0753 9.1835L12.3087 11.5835L13.142 15.1502L10.0003 13.2502Z"
      fill="#4B5563"
    />
  </svg>
);

const svgStarredActive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 14.8085L15.1503 17.9168L13.7837 12.0585L18.3337 8.11683L12.342 7.6085L10.0003 2.0835L7.65866 7.6085L1.66699 8.11683L6.21699 12.0585L4.85033 17.9168L10.0003 14.8085Z"
      fill="#F59F0A"
    />
  </svg>
);