// ! Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTrans } from "locales/hook";
import { useGetDataToDrawChart } from "hooks/useGetDataToDrawChart";

// ! Components
import { Box } from "@chakra-ui/react";
import {
    StackedBar, StackedHorizontalBar, BarChart, Doughnut
} from "components/Chart";
import LineChart from "components/MetricsDiscount/components/chart/line";
import EmptyTemplate from "components/EmptyTemplate";
import HRVCheckbox from "components/Checkbox";
import WrapperSpinner from "components/Spinner";
import TreemapChart from "components/Chart/TreeMap";

// ! Helpers
import { GetDateShowType } from "helpers/date";
import { ShowDateRange, generatingColorForString, implementLongStringInChart, mapOrder, valueHandler, xmlRenderCheckbox } from "helpers/helpers";
import { colors, colorsForPageViews, colorsForShipments } from "components/Chart/constants";
import { IProps, HEIGHT_CHART, LIMIT_GROUPED_BY_DISCOUNT } from ".";
import md5 from "helpers/md5";
import { ALLOW_CHANGE_DISPLAY_CHART, DEFAULT_DATA_FETCHING } from "pages/ReportStandard/context/store/constants";
import { useStore } from "pages/ReportStandard/context/store";
import HSelection from "components/Selection";


const REPORT_WITH_METRIC = {
    // ! Còn lại tất cả các report còn lại vẽ theo 'NetSaleAmount'
    sales_transaction_by_vat: 'TotalTaxAmount', // Tổng doanh thu hàng hoá và thuế VAT vẽ theo Tổng VAT
    sales_order_assigned_location: 'NetSaleAmountByLocAssigned' // Báo cáo theo kho xuất vẽ theo doanh thu thuần
}

// ! Một số report sẽ vẽ theo trục Y (Cột ngang) với report được định nghĩa 
const DRAW_BY_Y_AXIS_FOR_SPECIFIC_REPORTS = [
    "sales_order_assigned_location", // * Báo cáo theo kho xuất
    "sales_overview_promotion", // * Báo cáo hiệu quả khuyến mãi
    'sales_transaction_by_vat' // * Báo cáo Tổng doanh thu hàng hoá và thuế VAT
];

const REVERSE_NEGATIVE_FIELD_NAME = [
    "DiscountAmount",
    "TotalDiscount"
]

export default function Metrics(props: IProps) {
    const {
        headers,
        data,
        displayChartMode,
        isLoading,
        reportNameSystem,
        onHandleCheckboxChart,
        query,
        queryPeriod,
        isLoadingChart,
        reportId,
        dataFetchingTable,
        optionCompare,
        compareDateStart,
        compareDateEnd,
        measureSelected,
        startdate,
        enddate,
        measure,
        measureDraw,
        optionMeasure
    } = props;

    const t = useTrans();

    const { state, dispatch, actions } = useStore();

    const {
        isRefresh,
    } = state;

    const [label, setLabel] = useState<any>([]);
    const [render, setRender] = useState([] as Array<any>);
    const [fieldName, setFieldName] = useState("");
    const [typehorizontal, setTypehorizontal] = useState("");
    const [doughnutLegends, setDoughnutLegends] = useState([]);
    const { type } = GetDateShowType(startdate, enddate);
    const [isGrouped, setIsGrouped] = useState(false);
    const [isCompare, setIsCompare] = useState(false);
    const [displayChart, setDisplayChart] = useState("");

    let specificChart = ["stackedhorizalbar", "doughnut", 'treemap'];

    // * id chart, report name, payload, condition;
    // * return data, isLoading
    const {
        data: dataSpecificChart,
        isLoading: isLoadingSpecificChart,
        mutate
    } = useGetDataToDrawChart(
        {
            id: reportId,
            name: reportNameSystem,
            body: {
                ...dataFetchingTable,
                startdate: startdate,
                enddate: enddate,
                optionMeasure
            },
            preventFetch: !displayChart.length || !specificChart.includes(displayChartMode) || JSON.stringify(dataFetchingTable) === JSON.stringify(DEFAULT_DATA_FETCHING)
        }
    );


    // * Listener khi nút refresh được gọi
    useEffect(() => {
        if (isRefresh) {
            dispatch(actions.changeStatusRefresh(false));
            mutate(Date.now())
        }
    }, [isRefresh])


    const chartContainerRef = useRef<any>();

    // * Nếu report được áp dụng để vẽ trục Y và chỉ số liên quan đến thời gian sẽ vẽ theo X
    const ruleDrawY = useCallback(() => {
        let axis = "x";

        if (!DRAW_BY_Y_AXIS_FOR_SPECIFIC_REPORTS.includes(reportNameSystem)) {
            axis = 'x'
        } else {
            if (headers[0]?.dataFormat === 'timestamp') {
                axis = 'x'
            } else {
                axis = 'y'
            }

        }

        return axis;
    }, [reportNameSystem, headers])

    useEffect(() => {
        if (typeof compareDateStart !== 'undefined') {
            setIsCompare(true);

            if (!queryPeriod?.data?.length) {
                setIsCompare(false);
            }
        } else {
            setIsCompare(false)
        }
    }, [compareDateStart, queryPeriod]);

    useEffect(() => {
        if (headers && headers.length) {
            if (["Day", "Month", "Year", "Hour"].includes(headers[0].name) && ALLOW_CHANGE_DISPLAY_CHART.includes(reportNameSystem)) {
                setDisplayChart("line")
            } else {
                setDisplayChart(displayChartMode)
            }
        }
    }, [headers])

    // * Build Normal Chart
    useEffect(() => {
        if (data && data?.length && !specificChart.includes(displayChart)) {
            const typeofVertical = headers[0].dataFormat;
            const nameofVertical = headers[0].name;

            // ! Trục X
            let labels = data.map((y) => {
                let value = y[0];

                if (nameofVertical === "CustomerId" && y[0] === 'guest') {
                    value = t(y[0]);
                }

                return valueHandler(typeofVertical, nameofVertical, value, type);
            }
            );

            // chọn cột cuối
            // const typeHorizon = headers[headers.length - 1].dataFormat;

            const DrawBy = REPORT_WITH_METRIC[reportNameSystem] || 'NetSaleAmount' // Chọn cột để vẽ
            let drawIndex = headers.findIndex((h) => DrawBy.includes(h.name));
            let renderData = [] as Array<any>;

            switch (displayChart) {
                case 'stackedbar': {
                    renderData = [];

                    labels = data?.map((y) => {
                        return y[0];
                    });

                    data.forEach((row, rowIndex) => {
                        let renderDataIndex = 0;

                        row.forEach((col, id) => {
                            const measure = measureSelected.find(
                                (m) => m.measureName === headers[id].name
                            );

                            if (measure !== undefined) {

                                if (measure?.isDraw) {
                                    if (rowIndex === 0) {
                                        renderData.push({
                                            name: t(measure.measureField),
                                            data: [col],
                                            hover: colorsForPageViews[reportNameSystem][headers[id].name].hover,
                                            color: colorsForPageViews[reportNameSystem][headers[id].name].color
                                        });
                                    } else {
                                        renderData[renderDataIndex].data.push(col);
                                    }
                                    renderDataIndex++;
                                }
                            }
                        });
                    });

                    setLabel(labels);

                    if (reportNameSystem === "web_pageviews_month") {
                        setFieldName(t("Lượt xem trang"))
                    }

                    break;
                }

                case "groupedcolumn": {
                    switch (reportNameSystem) {
                        case "sales_overview_promotion": // * Hiệu quả khuyến mãi
                            // ! Trục Y
                            const drawY = 'TotalDiscount'; // Vẽ Y theo chỉ số Giảm giá
                            let typeY = ""; // Kiểu dữ liệu trục Y ví dụ ("money", "number", "percentage")

                            // ! Trục X
                            const drawX = headers[0]?.fieldName // Vẽ theo X nhóm theo đầu tiên
                            const nameX = headers[0]?.name // Vẽ theo X nhóm theo đầu tiên
                            const typeX = headers[0].dataFormat; // Kiểu dữ liệu trục X ví dụ ("money", "number", "percentage")

                            let drawIndexInQuery = query?.headerreport?.findIndex((h) => h.fieldName === drawY);
                            let drawXInQuery = query?.headerreport?.findIndex((h) => h.fieldName === drawX);

                            drawIndex = headers?.findIndex((h) => h.name === drawY);

                            if (drawIndex !== -1) {
                                typeY = headers[drawIndex].dataFormat;
                                setTypehorizontal(typeY);

                                const labels = query?.data?.map((y) => {
                                    if (nameX === 'DiscountId') {
                                        return y[0];
                                    } else {
                                        return implementLongStringInChart(valueHandler(typeX, nameX, y[0], type)?.toString()?.split(" "));
                                    }
                                }
                                );

                                setLabel(labels);

                                if (queryPeriod?.data?.length && isCompare) {
                                    setIsGrouped(true);

                                    renderData = [
                                        {
                                            label: `${ShowDateRange(startdate, enddate)}`,
                                            data: query?.data?.map((y) => y[drawIndexInQuery] * (REVERSE_NEGATIVE_FIELD_NAME.includes(drawY) ? -1 : 1)) || [],
                                            backgroundColor: "#3C83F6",
                                            hoverBackgroundColor: "#3C83F6",
                                            borderRadius: 6,
                                            categoryPercentage: (query?.data?.length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT,
                                            barPercentage: 1.0
                                        },
                                        {
                                            label: `${ShowDateRange(compareDateStart, compareDateEnd)}`,
                                            data: query?.datalink?.map((y) => {
                                                const findInPeriod = queryPeriod?.datalink?.findIndex(x => {

                                                    return x[drawXInQuery] === y[drawXInQuery]
                                                })

                                                if (findInPeriod !== -1) {
                                                    return queryPeriod?.datalink[findInPeriod][drawIndexInQuery] * (REVERSE_NEGATIVE_FIELD_NAME.includes(drawY) ? -1 : 1)
                                                } else {
                                                    return "0"
                                                }
                                            }) || [],
                                            backgroundColor: "#7ED4FC",
                                            hoverBackgroundColor: "#7ED4FC",
                                            borderRadius: 6,
                                            categoryPercentage: (query?.data?.length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT,
                                            barPercentage: 1.0
                                        },
                                    ];
                                } else {
                                    setIsGrouped(false);

                                    if (drawIndex !== -1) {
                                        // ! Trục Y
                                        renderData = query?.data?.map((y) => y[drawIndexInQuery] * (REVERSE_NEGATIVE_FIELD_NAME.includes(drawY) ? -1 : 1));

                                        if (headers && headers?.length) {
                                            // setFieldName(t(headers[headers.length - 1].fieldName));
                                            setFieldName(t(headers[drawIndex].fieldName));
                                        }
                                    }
                                }

                                if (headers && headers?.length) {
                                    setFieldName(t(headers[drawIndex].fieldName));
                                }
                            } else {
                                setLabel([])
                            }

                            break;

                        default:
                            throw new Error(`The chart type: groupedcolumn done defined for ${reportNameSystem}`)
                    }
                    // ! TH: để xây được 1 chart đôi thì nhất thiết phải cấu hình lại 2 data thì hiện tại xài (query và queryPeriod)


                    setRender(renderData);

                    break;
                }

                case "groupedhorizontalcolumn": {
                    setIsGrouped(true);

                    const drawY = headers[0]?.fieldName; // Vẽ Y theo cột đầu tiên

                    const drawX1 = "ReceiveCostAmount"; // Vẽ X1 theo tổng tiền hàng nhập
                    const drawX2 = "ReturnCostAmount"; // Vẽ X2 theo tổng tiền hàng trả


                    let drawIndexInQuery = headers?.findIndex((h) => h.fieldName === drawY);

                    let drawX1InQuery = headers?.findIndex((h) => h.fieldName === drawX1);
                    let drawX2InQuery = headers?.findIndex((h) => h.fieldName === drawX2);

                    if (drawIndexInQuery !== -1) {
                        const typeHorizon = 'money'
                        setTypehorizontal(typeHorizon);

                        const labels = data.slice(0, LIMIT_GROUPED_BY_DISCOUNT)?.map((y) => {
                            return y[0] || "--"
                        }
                        );

                        setLabel(labels);

                        renderData = [
                            {
                                label: t("ReceiveCostAmount"),
                                data: data.slice(0, LIMIT_GROUPED_BY_DISCOUNT)?.map((y) => y[drawX1InQuery]) || [],
                                backgroundColor: "#3C83F6",
                                hoverBackgroundColor: "#3C83F6",
                                borderRadius: 6,
                                categoryPercentage: (data.slice(0, LIMIT_GROUPED_BY_DISCOUNT).length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT,
                                barPercentage: 1.0
                            },
                            {
                                label: t("ReturnCostAmount"),
                                data: data.slice(0, LIMIT_GROUPED_BY_DISCOUNT)?.map((y) => y[drawX2InQuery]) || [],
                                backgroundColor: "#F97415",
                                hoverBackgroundColor: "#F97415",
                                borderRadius: 6,
                                categoryPercentage: (data.slice(0, LIMIT_GROUPED_BY_DISCOUNT).length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT,
                                barPercentage: 1.0
                            },
                        ]

                        if (headers && headers?.length) {
                            setFieldName(t("value"));
                        }
                    } else {
                        setLabel([])
                    }

                    setRender(renderData);

                    return;
                }

                default: {
                    if (drawIndex !== -1) {
                        const typeHorizon = headers[drawIndex].dataFormat;
                        setTypehorizontal(typeHorizon);

                        if (data?.length <= 12 && data?.length > 5) {
                            const labels = data.map((y) => implementLongStringInChart(valueHandler(typeofVertical, nameofVertical, y[0], type).split(" "))
                            );

                            setLabel(labels);
                        }

                        // ! Trục Y
                        renderData = data.map((y) =>
                            // Chọn cột cuối
                            // typeHorizon === "percent" ? y[y.length - 1] * 100 : y[y.length - 1]
                            // Chọn cột doanh thu thuần
                            typeHorizon === "percent" ? y[drawIndex] * 100 : y[drawIndex]
                        );

                        if (headers && headers?.length) {
                            // setFieldName(t(headers[headers.length - 1].fieldName));
                            setFieldName(t(headers[drawIndex].fieldName));
                        }
                    }

                    // * Vẽ cột ngang thì sẽ limit
                    if (ruleDrawY() === "y") {
                        labels = labels.slice(0, LIMIT_GROUPED_BY_DISCOUNT);
                        renderData = renderData.slice(0, LIMIT_GROUPED_BY_DISCOUNT);
                    };

                    setLabel(labels);
                    break;
                }
            }

            setRender(renderData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers, data, query, queryPeriod, isCompare, displayChart]);

    // * Build Specific Chart
    useEffect(() => {
        if (!isLoadingSpecificChart && dataSpecificChart?.data) {
            let renderData = [] as Array<any>;

            const fromChart = dataSpecificChart?.data

            switch (displayChart) {
                case "stackedhorizalbar": {
                    renderData = [];

                    const dayOfWeek = [2, 3, 4, 5, 6, 7, 1];

                    let labels = fromChart?.data?.map((y) => {
                        return y[0];
                    });

                    const headerreport = fromChart?.headerreport || [];
                    const data = fromChart?.data || [];

                    if (headerreport[0]?.name === "DayOfWeek") {
                        // * TH Là ngày trong tuần
                        // labels = dayOfWeek;
                        let renewData: any = [...data];

                        let importDataFormDaysOfWeek = dayOfWeek.map((dow) => {
                            const findDayOfWeekInData = renewData?.findIndex(
                                (data) => data[0] === dow
                            );

                            if (findDayOfWeekInData === -1) {
                                if (fromChart?.data[0] && fromChart?.data[0]?.length) {
                                    let fillArray = Array(
                                        fromChart?.data[0].length - 2
                                    ).fill(0);

                                    return [dow, ...fillArray];
                                } else {
                                    return [dow]
                                }
                            } else {
                                return data[findDayOfWeekInData];
                            }
                        });

                        renderData = headerreport?.slice(1)?.map((item) => {
                            const index = headerreport.findIndex(
                                (headerItem) => headerItem.name === item.name
                            );

                            return {
                                name: t(item.name),
                                data: importDataFormDaysOfWeek.map((row) => row[index]),
                                color: colorsForShipments[reportNameSystem][item.name].color,
                            };
                        });
                    } else {
                        // * TH Không là ngày trong tuần
                        renderData = headerreport?.slice(1)?.map((item) => {
                            const index = headerreport.findIndex(
                                (headerItem) => headerItem.name === item.name
                            );

                            return {
                                fieldName: item.name,
                                name: t(item.name),
                                data: data.map((row) => row[index]),
                                color: colorsForShipments[reportNameSystem][item.name].color,
                            };
                        });
                    }

                    renderData = mapOrder(
                        renderData,
                        xmlRenderCheckbox[reportNameSystem],
                        "fieldName"
                    );

                    if (fromChart?.headerreport) {
                        setTypehorizontal(fromChart?.headerreport[0]?.name);
                    }

                    setLabel(labels);
                    setRender(renderData);

                    if (["order_shipments_overview_carriers_internal_speed", "order_shipments_detail_carriers_internal_speed"].includes(reportNameSystem)) {
                        setFieldName(t("Thời gian xử lý"))
                    }

                    break;
                }

                case "doughnut": {
                    const { headerreport, data } = dataSpecificChart?.data;

                    const NetAmountValue = [
                        'NetSaleAmountByLocAssigned',
                        'NetSaleAmount',
                        "CustomerCountSpecial",
                    ]; // Chọn cột doanh thu thuần

                    if (headerreport && data) {
                        let drawIndex = headerreport?.findIndex(
                            (h) => NetAmountValue.includes(h.name)
                        );

                        switch (reportNameSystem) {
                            case 'customers_new_and_return':
                                drawIndex = headerreport?.findIndex(
                                    (h) => h.fieldName === optionMeasure.measureField
                                );

                                break;

                            default:
                                break;
                        }

                        // ! Trục X
                        const typeofVertical = headerreport[0]?.dataFormat;
                        const nameofVertical = headerreport[0]?.name;

                        const SampleColors = {
                            ["Tiktok Shop"]: "#F00044",
                            Shopee: "#F97415",
                            Lazada: "#0E146A",
                            Staff: "#14B8A5",
                            Harasocial: "#03A9F4",
                            POS: "#4CAF50",
                            Phone: "#EC4699",
                            Web: "#3C83F6",
                            Facebook: "#0865FE",
                            Zalo: "#07B6D5",
                            Draft: "#00579B",
                            Sendo: "#BC0105",
                            Tiki: "#51CBFF",
                            "Google Shop": "#FBBC05",
                            Harafunnel: "#6366F2",
                            "Facebook Shop": "#F59F0A",
                            "App Android": "#A855F7",
                            "App IOS": "#6B7280",
                            HaraPOS: "#82CB15",
                            Livestream: "#811D1D",
                            Instagram: "#6C49CB",
                            'Khách vãng lai': "#D1D5DB",
                            'Khách cũ': "#3C83F6",
                            'Khách mới': "#F97415",
                        };

                        const legends = data.map((y) => {
                            const nameChannel = valueHandler(typeofVertical, nameofVertical, y[0], type);

                            return {
                                name: t(nameChannel),
                                color: SampleColors[nameChannel] || generatingColorForString(nameChannel),
                            };
                        });

                        setDoughnutLegends(legends);

                        if (drawIndex !== -1) {
                            const typeHorizon = headerreport[drawIndex].dataFormat;
                            setTypehorizontal(typeHorizon);

                            // ! Trục Y
                            renderData = data.map((y) => typeHorizon === "percent" ? y[drawIndex] * 100 : y[drawIndex]
                            );

                            if (headerreport && headerreport?.length) {
                                setFieldName(t(headerreport[drawIndex].fieldName));
                            }
                        }
                    }

                    break;
                }

                case "treemap": {
                    const { headerreport, data } = dataSpecificChart?.data;

                    renderData = data.map((value) => {
                        let label = !value[0] ? "Khác" : value[0];
                        let valueNumber = value[1];

                        return {
                            name: label,
                            value: valueNumber,
                        }
                    });

                    if (headerreport && headerreport?.length) {
                        let drawIndex = headerreport?.findIndex(
                            (h) => h.name === "CustomerCountSpecial"
                        );

                        setFieldName(t(headerreport[drawIndex].fieldName));
                    }

                    break;
                }

                default:
                    break;
            }

            setRender(renderData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSpecificChart, isLoadingSpecificChart, displayChart])

    const handleBuildCheckboxMeasure = (list) => {
        return list.map((y) => {
            let foundIndex = measureSelected?.findIndex((si: any) => si.id === y.id);

            return {
                key: y.measureField,
                value: foundIndex !== -1,
            };
        });
    };

    let getRenderCheckbox: Array<any> = useMemo(() => {
        let newMeasure = measure.filter((metric) => {
            return (
                metric.isDraw &&
                xmlRenderCheckbox[reportNameSystem]?.includes(metric.measureName)
            );
        });

        return mapOrder(
            handleBuildCheckboxMeasure(newMeasure),
            xmlRenderCheckbox[reportNameSystem],
            "key"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measureSelected]);

    const disabled = (item) => {
        const atLeastOne = getRenderCheckbox.filter((y) => y.value).length === 1;
        return atLeastOne && item.value;
    };

    const renderEmptyChart = () => {
        return <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center" style={{
            height: `calc(${HEIGHT_CHART[displayChart] + calculateHeightLegend()}px)`
        }}>
            <EmptyTemplate
                text={t("no_data")}
                subText={t("change_fitler_hint")} />
        </div>
    }

    const renderChart = (type) => {
        switch (type) {
            case "stackedbar": {
                const isRenderChart = !!headers?.length && !!data?.length;

                const configClassName = !!headers?.length && !!data?.length ? "hrv-report-detail-chart" : "";

                return (
                    <>
                        <div ref={chartContainerRef} className={configClassName} >
                            {isRenderChart ? (
                                <StackedBar
                                    proData={{
                                        labels: label,
                                        data: render,
                                        namefield: fieldName,
                                    }}
                                    colorBar="#3C83F6"
                                    hoverColorBar="#61A6FA"
                                    typeHorizonal={headers[1]?.dataFormat}
                                    typeVertical={headers[0]?.name}
                                    configHeight={HEIGHT_CHART[displayChart]} />
                            ) : (
                                renderEmptyChart()
                            )}
                        </div>
                    </>
                );
            }

            case "stackedhorizalbar": {
                const isRenderChart = !!headers?.length && !!data?.length;

                return (
                    <div>
                        <div
                            ref={chartContainerRef}
                            className={`${!!headers?.length && !!data?.length
                                ? `hrv-report-detail-chart hrv-report-detail-chart-${label?.length}`
                                : ""}`}
                        >
                            {isRenderChart ? (
                                <>
                                    {displayChart !== null &&
                                        displayChart !== undefined ? (
                                        <StackedHorizontalBar
                                            proData={{
                                                labels: label,
                                                data: render,
                                                namefield: "",
                                            }}
                                            typeHorizonal={typehorizontal}
                                            configHeight={label?.length * 40 < HEIGHT_CHART[displayChart] ? HEIGHT_CHART[displayChart] : label?.length * 40}
                                            unit={t("giờ")}
                                            width={chartContainerRef?.current?.clientWidth} />
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                renderEmptyChart()
                            )}
                        </div>
                    </div>
                );
            }

            case "doughnut": {
                const isRenderChart = !!headers?.length && !!data?.length;

                return (
                    <div>
                        <div
                            ref={chartContainerRef}

                        >
                            {isRenderChart && !isLoadingSpecificChart ? (
                                <>
                                    {displayChart !== null &&
                                        displayChart !== undefined ? (
                                        <div style={{
                                            height: HEIGHT_CHART[displayChart],
                                        }}>
                                            <Doughnut
                                                prodata={{
                                                    labels: doughnutLegends.map((y: any) => y.name),
                                                    data: render,
                                                    namefield: fieldName,
                                                    color: doughnutLegends.map((y: any) => y.color),
                                                }}
                                                typeHorizonal={typehorizontal}
                                                height={HEIGHT_CHART[displayChart]}
                                                isShowLabel={false}
                                                isShowConnectingLine={reportNameSystem === "customers_new_and_return"}
                                                isShowPercentage={reportNameSystem === "customers_new_and_return"}
                                            />
                                        </div>

                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                renderEmptyChart()
                            )}
                        </div>
                    </div>
                );
            }

            case "groupedcolumn": {
                const isRenderChart = !isLoading && query?.data?.length && label?.length;

                const configClassName =
                    !!headers?.length && !!data?.length ? "hrv-report-detail-chart" : "";

                const key = md5(JSON.stringify({
                    labels: label,
                    data: render,
                    namefield: fieldName,
                    isGrouped
                }));

                return (
                    <div>
                        <div ref={chartContainerRef} className={configClassName} style={{
                            height: HEIGHT_CHART[displayChart],
                        }}>
                            {!isLoadingChart ? (
                                <>
                                    {isRenderChart ? (
                                        <BarChart
                                            proData={{
                                                labels: label,
                                                data: render,
                                                namefield: `${ShowDateRange(startdate, enddate)}`,
                                            }}
                                            colorBar="#3C83F6"
                                            hoverColorBar="#61A6FA"
                                            typeHorizonal={typehorizontal}
                                            isGrouped={isGrouped}
                                            key={key}
                                            configHeight={chartContainerRef?.current?.clientHeight || HEIGHT_CHART[displayChart]}
                                            typeVertical={headers[0]?.name}
                                            indexAxis={ruleDrawY()}
                                            reverseNegative={REVERSE_NEGATIVE_FIELD_NAME.some(field => t(field) === fieldName)}
                                        />
                                    ) : (
                                        renderEmptyChart()
                                    )}
                                </>
                            ) : (
                                <div
                                    className="hrv-report-d-flex hrv-report-items-center"
                                    style={{
                                        height: 440
                                    }}
                                >
                                    <div className="hrv-report-w-100">
                                        <WrapperSpinner />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }

            case "line": {
                const isRenderChart = !isLoading && query?.data?.length && headers.length;
                return <div>
                    <div ref={chartContainerRef} style={{
                        height: HEIGHT_CHART[displayChart]
                    }}>
                        {!isLoadingChart ? (
                            <>
                                {isRenderChart ? (
                                    <div style={{
                                        maxHeight: HEIGHT_CHART[displayChart]
                                    }} className="hrv-report-relative">
                                        <LineChart
                                            query={query}
                                            queryPeriod={queryPeriod}
                                            isLoading={isLoading}
                                            configHeight={405}
                                            isAutoSetDateType={false}
                                            groupByCustom={headers[0].name.toLowerCase()}
                                        />
                                    </div>
                                ) : (
                                    renderEmptyChart()
                                )}
                            </>
                        ) : (
                            <div
                                className="hrv-report-d-flex hrv-report-items-center"
                                style={{
                                    height: 440
                                }}
                            >
                                <div className="hrv-report-w-100">
                                    <WrapperSpinner />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }

            case "groupedhorizontalcolumn": {
                const isRenderChart = !isLoading && data?.length && label?.length;

                const configClassName =
                    !!headers?.length && !!data?.length ? "hrv-report-detail-chart" : "";

                const key = md5(JSON.stringify({
                    labels: label,
                    data: render,
                    namefield: fieldName,
                    isGrouped
                }));

                return (
                    <div>
                        <div ref={chartContainerRef} className={configClassName} style={{
                            height: HEIGHT_CHART[displayChart],
                        }}>
                            {!isLoading ? (
                                <>
                                    {isRenderChart ? (
                                        <BarChart
                                            proData={{
                                                labels: label,
                                                data: render,
                                                namefield: 'no input',
                                            }}
                                            colorBar="#3C83F6"
                                            hoverColorBar="#61A6FA"
                                            isGrouped={isGrouped}
                                            key={key}
                                            configHeight={chartContainerRef?.current?.clientHeight}
                                            indexAxis={
                                                headers[0]?.dataFormat === 'timestamp' ? "x" : "y"
                                            }
                                            typeHorizonal={typehorizontal}
                                            typeVertical={headers[0]?.dataFormat}
                                            isStacked={true}
                                        />
                                    ) : (
                                        renderEmptyChart()
                                    )}
                                </>
                            ) : (
                                <div
                                    className="hrv-report-d-flex hrv-report-items-center"
                                    style={{
                                        height: 440
                                    }}
                                >
                                    <div className="hrv-report-w-100">
                                        <WrapperSpinner />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            }

            case "treemap": {
                return <div ref={chartContainerRef} >
                    <TreemapChart
                        data={render}
                        configHeight={HEIGHT_CHART[displayChart]}
                        chartColor="#61A6FA"
                        fieldName={fieldName}
                        typeHorizonal={typehorizontal}
                        width={chartContainerRef?.current?.clientWidth}
                    />
                </div>
            }

            default: {
                const isRenderChart = !!headers?.length && !!data?.length;
                const configClassName = !!headers?.length && !!data?.length ? "hrv-report-detail-chart" : "";
                return (
                    <>
                        <div ref={chartContainerRef} className={configClassName} >
                            {isRenderChart ? (
                                <>
                                    {displayChart !== null &&
                                        displayChart !== undefined ? (
                                        <div style={{
                                            height: HEIGHT_CHART[displayChart]
                                        }}>
                                            <BarChart
                                                proData={{
                                                    labels: label,
                                                    data: render,
                                                    namefield: `${ShowDateRange(startdate, enddate)}`,
                                                }}
                                                colorBar="#3C83F6"
                                                hoverColorBar="#61A6FA"
                                                typeHorizonal={typehorizontal}
                                                configHeight={HEIGHT_CHART[displayChart]}
                                                typeVertical={headers[0]?.name.toLowerCase()}
                                                indexAxis={ruleDrawY()}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                renderEmptyChart()
                            )}
                        </div>
                    </>
                );
            }
        }
    };

    const renderLegend = (type) => {
        switch (type) {
            case "stackedbar": {
                return (
                    <>
                        {render.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-mr-8"
                                >
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: colors[index].color }}
                                    ></div>

                                    <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{item.name}</p>
                                </div>
                            );
                        })}
                    </>
                );
            }

            case "stackedhorizalbar": {
                return (
                    <>
                        <div className="hrv-report-wrapper-labels hrv-report-row hrv-report-items-center hrv-report-justify-center" style={{
                            width: "100%"
                        }}>
                            {mapOrder(
                                render,
                                xmlRenderCheckbox[reportNameSystem],
                                "fieldName"
                            ).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-left hrv-report-md-justify-center hrv-report-mr-8 hrv-report-mt-4"
                                    >
                                        <div
                                            className="hrv-report-legend-square"
                                            style={{ background: item.color }}
                                        ></div>

                                        <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{item.name}</p>
                                    </div>
                                );
                            })}


                        </div>

                        {dataSpecificChart?.data?.data?.length >= 12 && (
                            <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-mt-16" style={{
                                width: "100%"
                            }}>
                                <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                    {t("Biểu đồ hiển thị tối đa 15 giá trị đầu tiên")}
                                </p>
                            </div>
                        )}
                    </>

                );
            }

            case "doughnut": {
                return (
                    <>
                        {doughnutLegends.map((val: any, idx) => {
                            return (
                                <div
                                    className="hrv-report-mr-12 hrv-report-row hrv-report-items-center hrv-report-justify-center"
                                    key={`${idx}`}
                                >
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: val.color }}
                                    >
                                    </div>

                                    <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{val.name}</p>
                                </div>
                            );
                        })}
                    </>
                );
            }

            case "groupedcolumn": {
                return (
                    <>
                        {!!label?.length && <>
                            <div className="hrv-report-d-flex hrv-report-items-center">
                                <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mr-20">
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: "#3C83F6" }}
                                    ></div>

                                    <div>
                                        <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                            {ShowDateRange(startdate, enddate)}
                                        </span>
                                    </div>
                                </div>

                                {optionCompare !== "" && optionCompare !== t("noCompare") && <div className="hrv-report-d-flex hrv-report-items-center">
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: "#7ED4FC" }}
                                    ></div>

                                    <div>
                                        <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9" >{ShowDateRange(compareDateStart, compareDateEnd)}</span>
                                    </div>
                                </div>}


                            </div>

                            {query?.data?.length >= LIMIT_GROUPED_BY_DISCOUNT && <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-mt-16" style={{
                                width: "100%"
                            }}>
                                <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                    {t("Hiển thị top 10 giá trị cao nhất")}
                                </p>
                            </div>}</>}

                    </>
                );
            }

            case "groupedhorizontalcolumn": {
                return (
                    <>
                        {!!label?.length && <>
                            <div className="hrv-report-d-flex hrv-report-items-center">
                                <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mr-20">
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: "#3C83F6" }}
                                    ></div>

                                    <div>
                                        <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                            {t("ReceiveCostAmount")}
                                        </span>
                                    </div>
                                </div>

                                <div className="hrv-report-d-flex hrv-report-items-center">
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: "#F97415" }}
                                    ></div>

                                    <div>
                                        <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9" >{t("ReturnCostAmount")}</span>
                                    </div>
                                </div>


                            </div>

                            {data?.length >= LIMIT_GROUPED_BY_DISCOUNT && <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-mt-16" style={{
                                width: "100%"
                            }}>
                                <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                    {t("Hiển thị top 10 giá trị cao nhất")}
                                </p>
                            </div>}</>}
                    </>
                );
            }

            case "line": {
                return <div style={{
                    height: 16
                }}></div>;
            }

            case "treemap": {

                break;
            }

            default: {
                return (
                    <>
                        {ruleDrawY() === "y" && data?.length >= LIMIT_GROUPED_BY_DISCOUNT && <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-my-16" style={{
                            width: "100%"
                        }}>
                            <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                {t("Hiển thị top 10 giá trị cao nhất")}
                            </p>
                        </div>}


                        <div
                            className="hrv-report-legend-square"
                            style={{ background: "#3C83F6" }}
                        ></div>


                        <div>{ShowDateRange(startdate, enddate)}</div>
                    </>
                );
            }
        }
    };

    const calculateHeightLegend = useCallback(() => {
        switch (displayChart) {
            case "line":
                return 32;

            default:
                return 36
        }
    }, [displayChart]);

    return (
        <div
            className={`hrv-report-whitebox-no-bs hrv-report-mt-16 hrv-report-pb-16`}
        >
            {measureDraw && measureDraw.length ?
                <>
                    <Box textStyle='h5' className="hrv-report-mb-8">{t("Chỉ số hiển thị")}</Box>
                    <Box maxW={300}>
                        <HSelection
                            key={`option_to_draw_chart`}
                            dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                            isOptGroup={false}
                            options={measureDraw}
                            name="measureName"
                            value="measureField"
                            defaultValue={optionMeasure.measureField}
                            onChange={(value) => {
                                const findMeasure = measureDraw.find(measure => measure.measureField === value);
                                dispatch(actions.setMeasureYAxis(findMeasure));
                            }}
                            matchWidth={true}
                            minW={300}
                        />
                    </Box>
                </>

                : <Box textStyle='h4' className="hrv-report-mb-16">{t(fieldName)}</Box>
            }

            {getRenderCheckbox && getRenderCheckbox.length ? (
                <div className="hrv-report-container-checkbox hrv-report-row hrv-report-mb-8">
                    {getRenderCheckbox?.map((metric, idx) => {
                        return (
                            <HRVCheckbox
                                className={`hrv-report-d-inline-flex hrv-report-mt-8 hrv-report-col-12 hrv-report-sm-col-6 hrv-report-md-col-4 hrv-report-py-2`}
                                title={metric.key}
                                checked={metric.value}
                                onChange={({ check }) => {
                                    onHandleCheckboxChart &&
                                        onHandleCheckboxChart({
                                            name: metric.key,
                                            check: check,
                                            type: "measure",
                                        });
                                }}
                                disabled={disabled(metric) || isLoading} />
                        );
                    })}
                </div>
            ) : (
                <></>
            )}

            {isLoading ? (
                <div
                    className="hrv-report-d-flex hrv-report-items-center"
                    style={{
                        minHeight: `${HEIGHT_CHART[displayChart] + calculateHeightLegend()}px`
                    }}
                >
                    <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-w-100" style={{
                        height: '100%'
                    }}>
                        <WrapperSpinner />
                    </div>

                </div>
            ) : (
                <>
                    {renderChart(displayChart)}

                    {!!headers?.length && !!data?.length && (
                        <div className="hrv-report-row hrv-report-items-center hrv-report-justify-center hrv-report-mt-16">
                            {renderLegend(displayChart)}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
