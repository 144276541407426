import { Metric } from "web-vitals";

function sendToGTM(metric: Metric) {
  const dataLayer = (window as any)?.dataLayer || [];
  dataLayer.push({
    event: "web_vitals",
    cwv_metric: metric.name,
    cwv_value: Math.round(
      metric.name === "CLS" ? metric.delta * 1000 : metric.delta,
    ),
    cwv_id: metric.id,
  });
}

const reportWebVitals = () => {
  import("web-vitals").then(({ onLCP, onINP, onCLS, onFCP, onTTFB }) => {
    onLCP(sendToGTM);
    onINP(sendToGTM);
    onCLS(sendToGTM);
    onFCP(sendToGTM);
    onTTFB(sendToGTM);
  });
};

export default reportWebVitals;
