import { useTrans } from "locales/hook";
import WidgetNavItem from "../WidgetNavItem";
import { Box, Collapse, useDisclosure } from "@chakra-ui/react";

function WidgetNavList(props) {
    const { list, limit, onHandleToggleWidget } = props;
    const { isOpen, onToggle } = useDisclosure();
    const t = useTrans();

    const renderWithLimit = list.slice(0, limit);
    const renderWithHiddenWithoutLimit = list.slice(limit, list.length);

    return (
        <>
            {
                renderWithLimit.map((reportItem, idx) => {
                    return (
                        <WidgetNavItem
                            {...reportItem}
                            key={idx}
                            onHandleToggleWidget={onHandleToggleWidget}
                        />
                    );
                })
            }

            <Collapse in={isOpen} animateOpacity>
                <Box>
                    {renderWithHiddenWithoutLimit.map((reportItem, idx) => {
                        return (
                            <WidgetNavItem
                                {...reportItem}
                                key={idx}
                                onHandleToggleWidget={onHandleToggleWidget}
                            />
                        );
                    })}

                </Box>
            </Collapse>


            {!!renderWithHiddenWithoutLimit.length &&
                <div onClick={onToggle} className="hrv-report-cursor-pointer">
                    {isOpen ?
                        <div className="hrv-report-d-flex hrv-report-items-center">
                            <span className="hrv-report-fs-12 hrv-report-text-color-blue-2 hrv-report-mr-4">{t("Thu gọn")}</span>
                            <span
                                style={{
                                    transform: `rotate(180deg)`,
                                    display: "inline-flex"
                                }}
                            >
                                {svgArrow}
                            </span>
                        </div> :

                        <div className="hrv-report-d-flex hrv-report-items-center">
                            <span className="hrv-report-fs-12 hrv-report-text-color-blue-2 hrv-report-mr-4">{t("Xem tất cả")}</span>

                            <span>
                                {svgArrow}
                            </span>
                        </div>
                    }
                </div>}
        </>
    )
}

export default WidgetNavList;

const svgArrow = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M6.175 6.9126L10 10.7293L13.825 6.9126L15 8.0876L10 13.0876L5 8.0876L6.175 6.9126Z" fill="#2463EB" />
</svg>