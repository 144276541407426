import { forwardRef, useDeferredValue, useImperativeHandle } from "react";
import { useTrans } from "locales/hook";
import { useSearchLocal } from "hooks/useSearchLocal";

import HRVCheckbox from "components/Checkbox";
import { Box, useMediaQuery } from "@chakra-ui/react";

interface IProps {
    options: Array<any>;
    onHandleChange: any;
    type: any;
    optionGroups?: Array<string>;
    disabledItems: Array<string>;
    keyword: string;
    isAllowAdd?: boolean
    disabledAtLeastOneItemInGroup?: Array<any>
}

const CheckBoxContainerV2 = forwardRef(({
    options,
    optionGroups,
    onHandleChange,
    type,
    disabledItems,
    keyword,
    isAllowAdd = true,
    disabledAtLeastOneItemInGroup = []
}: IProps, ref) => {
    const t = useTrans();

    const [isDesktop] = useMediaQuery('(min-width: 556px)');
    const { searchGroupBy } = useSearchLocal();
    const reOptions = useDeferredValue(searchGroupBy(keyword, options));

    useImperativeHandle(ref, () => ({ getList: () => { return reOptions } }), [reOptions]);

    const handleOnchangeCheckbox = (checkbox) => {
        onHandleChange && onHandleChange({ ...checkbox, type });
        const { name, check } = checkbox;

        const newResult = [...reOptions];
        const updateIndex = newResult.findIndex((obj) => obj.key === name);

        newResult[updateIndex].value = check;
    };

    const disabled = (item) => {
        if (disabledItems.includes(item.key)) {
            return true;
        }

        const atLeastOne = options.filter((y) => y.value).length === 1 && item.value;

        let atLeastOneInGroup = false;

        if (
            disabledAtLeastOneItemInGroup?.length
            && item.hasOwnProperty('group')
            && disabledAtLeastOneItemInGroup.includes(item.group)
            && type === "group_by"
        ) {
            const findItemsInGroup = reOptions.filter(option => disabledAtLeastOneItemInGroup.includes(option.group));
            const findItemsActiveInGroup = findItemsInGroup.filter(item => item.value).length;
            atLeastOneInGroup = findItemsActiveInGroup === 1 && item.value;
        }

        return atLeastOne || atLeastOneInGroup;
    };

    const renderOptions = (reOptions) => {
        if (optionGroups === undefined) {
            return reOptions?.map((option, idx) => {
                return (
                    <Box
                        key={`checkbox_group_${idx}`}
                        className="hrv-report-open-group_checkbox_item"
                        w={isDesktop ? "50%" : "100%"}
                        pr={4}
                    >
                        <HRVCheckbox
                            id={option.id}
                            title={option.key}
                            checked={option.value}
                            onChange={handleOnchangeCheckbox}
                            disabled={!isAllowAdd || disabled(option)}
                        />
                    </Box>
                );
            });
        } else {
            return optionGroups.map((group, gIndex) => {
                let elements: Array<any> = [];

                elements.push(
                    <Box
                        key={`checkbox_group_area_${gIndex}`}
                        pb={1}
                        pt={8}
                        fontSize={"12px"}
                        fontWeight={600}
                        w={"100%"}
                    >
                        {t(group)}
                    </Box>,
                );

                reOptions?.forEach((option, idx) => {
                    if (group === option.group) {
                        elements.push(
                            <Box
                                key={`checkbox_group_${idx}`}
                                className="hrv-report-open-group_checkbox_item"
                                w={isDesktop ? "50%" : "100%"}
                                pr={4}
                            >
                                <HRVCheckbox
                                    id={option.id}
                                    title={option.key}
                                    checked={option.value}
                                    onChange={handleOnchangeCheckbox}
                                    disabled={
                                        !isAllowAdd || disabled(option)
                                    }
                                    className={`${!option.value
                                        ? "hrv-unchecked"
                                        : "hrv-checked"
                                        }`}
                                />
                            </Box>,
                        );
                    }
                });

                return elements.length > 1 ? elements : [];
            });
        }
    };

    return (
        <>
            {!!reOptions.length ? <div className={`hrv-report-open-group_${type}`} >
                <div className="hrv-report-open-group_checkbox">
                    <Box display="flex" w="100%" flexWrap="wrap" alignItems="flex-start">
                        {renderOptions(reOptions)}
                    </Box>
                </div>
            </div> : null}
        </>

    );
})

export default CheckBoxContainerV2;
