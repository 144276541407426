import dayjs from "components/Dayjs";
import { DateShowType } from "./constants";

export const dayAdd = (
  date,
  diffCounts,
  type: DateShowType = DateShowType.day
): Date => {
  let changedDate = new Date(+date);

  switch (type) {
    case DateShowType.month:
      let mmCount = diffCounts % 12;
      let yyCount = Number.parseInt(diffCounts / 12 + "");
      let dd = changedDate.getDate();
      let mm = changedDate.getMonth() + mmCount + 1;
      let yy = changedDate.getFullYear() + yyCount;
      changedDate.setFullYear(yy, mm, 0);
      let lastDateOfMonth = changedDate.getDate();

      // Nếu input date có ngày giữa tháng thì lấy ngày giữa tháng, ngược lại lấy ngày cuối tháng lastDateOfMonth
      if (lastDateOfMonth > dd) {
        changedDate.setDate(dd);
      };

      break;

    case DateShowType.year:
      changedDate.setFullYear(changedDate.getFullYear() + diffCounts);
      break;

    case DateShowType.day:
    default:
      changedDate.setTime(changedDate.getTime() + diffCounts * 86400000);
      break;
  }
  return changedDate;
};

export const GetDuration = function (fromDate, toDate, type?) {
  if (type === void 0) {
    type = "days";
  }
  var end = dayjs(toDate);
  var start = dayjs(fromDate);
  var result = end.diff(start, type);
  return result ? Math.abs(result) : 0;
};

export const GetDateShowType = function (fromDate, toDate) {
  let type = DateShowType.day;
  let duration = GetDuration(fromDate, toDate, "years");
  if (duration > 1) type = DateShowType.year;
  else {
    duration = GetDuration(fromDate, toDate, "months");
    if (duration > 1) type = DateShowType.month;
    else {
      duration = GetDuration(fromDate, toDate, "days");
      if (duration > 1) type = DateShowType.day;
      else type = DateShowType.hour;
    }
  }
  ++duration;
  return {
    duration,
    type,
  };
};

export const GetComparisonDate = (date, diff, compareType) => {
  date = dayjs(date);
  let compare = date.subtract(diff, "days");
  return compare;
};
