import { memo, Fragment, useState, useEffect } from "react";
import { useStore } from "pages/ReportListV2/context/store";
import { useTrans } from "locales/hook";
import useGeneralSettings from "hooks/useGeneralSettings";
import useRoles from "hooks/useRoles";
import useApp from "hooks/useApp";

import ReportCard from "../../Card";
import CardLoading from "../../CardLoading";
import { Box } from "@chakra-ui/react";

import { DEFAULT_LIST, INVENTORY_METHOD_ADVANCE } from "pages/ReportListV2/context/store/constants";

const ReportListPopular = (props: any) => {
  const { onClickDetail } = props;
  const { state } = useStore();
  const { plan_name } = useApp();
  const { isLoadingReportDefault, limitFeatures, reportsDefault } = state;
  const t = useTrans();
  const roles = useRoles();

  const generalSettings = useGeneralSettings();

  const [renderDefaultReports, setRenderDefaultReports] = useState<any>([]);

  useEffect(() => {
    let result: any = [...DEFAULT_LIST];
    let newResult = [];

    // ? Có tính năng limit thì ẩn đi
    // if (limitFeatures.length) {
    //   result = result.filter((rep) => !limitFeatures.includes(rep.tab));
    // }

    // ? Kiểm tra gói 
    result = result.filter((rep) =>
      !rep.hasOwnProperty("packages") ||
      rep.packages.includes(plan_name)
    );

    // ? Check quyền xem báo cáo
    if (roles.length) {
      result = result.filter((rep) => roles.some((role) => {
        return rep?.roles?.includes(role) || rep?.roles?.length === 0
      }));
    }


    if (reportsDefault?.length) {
      newResult = result.map((reportItem) => {

        if (reportItem.isHasChild) {
          let newChildReport = reportItem.childList.map((childReport) => {
            let findReportByName = reportsDefault.find(
              (y) =>
                y.reportNameSystem === childReport.reportNameSystem &&
                y.isSystemReport,
            );

            if (findReportByName) {
              return {
                ...childReport,
                id: findReportByName.id,
              };
            } else {
              return { ...childReport };
            }
          });

          return {
            ...reportItem,
            childList: newChildReport,
          };
        } else {
          let findReportByName = reportsDefault.find(
            (y) =>
              y.reportNameSystem === reportItem.reportNameSystem &&
              y.isSystemReport,
          );

          if (findReportByName) {
            return {
              ...reportItem,
              id: findReportByName.id,
              name: findReportByName.name,
            };
          } else {
            return { ...reportItem };
          }
        }
      });
    }

    // ? TH: Có Tồn kho nâng cao thì mới hiển thị những báo cáo tồn kho liên quan
    if (generalSettings.inventoryMethod !== INVENTORY_METHOD_ADVANCE) {
      newResult = newResult.filter((report: any) => {
        return report.tab !== "inventory";
      });
    }

    setRenderDefaultReports(newResult.splice(0, 8));
  }, [limitFeatures, reportsDefault, generalSettings, roles]);

  return (
    <div>
      <Box textStyle="h2" px={[5, 5, 0]}
        mb={4}>
        {t("Báo cáo phổ biến nhất")}
      </Box>

      <div >
        <div
          className="hrv-report-row"
          style={{
            margin: "-8px -8px",
          }}
        >
          {isLoadingReportDefault ? (
            <>
              {Array(8)
                .fill(0)
                .map((_, idx) => {
                  return (
                    <div
                      className="hrv-report-md-col-3 hrv-report-col-6 hrv-report-p-8"
                      key={`report_item_${idx}`}
                    >
                      <CardLoading />
                    </div>
                  );
                })}
            </>
          ) : (
            <>
              {renderDefaultReports.map((report, idx) => {
                return (
                  <Fragment key={`report_item_${idx}`}>
                    <div
                      className={`hrv-report-lg-col-3 hrv-report-md-col-6 hrv-report-col-12 hrv-report-p-8`}
                    >
                      <ReportCard {...report} onClickDetail={onClickDetail} />
                    </div>
                  </Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ReportListPopular);
