/**
 * Author: Hara Report
 * Function: Create for Mobile action with touch in tooltip component
 * @returns 
 */

import { ReactNode, useState } from "react";
import { Box, Tooltip } from "@chakra-ui/react";

function TooltipWithTouch({ children, label, ...restToolTipProps }: { children: ReactNode;[key: string]: any }) {
    const [isLabelOpen, setIsLabelOpen] = useState(false);

    const diffTypeLabel = () => {
        if (typeof label === 'string') {
            if (label.includes("dangerouslySetInnerHTML")) {
                return <div dangerouslySetInnerHTML={{ __html: label.replace("dangerouslySetInnerHTML", "") }} />
            } else {
                return !!label.length && <span
                    style={{
                        whiteSpace: "pre-line"
                    }}
                >
                    {label || ""}
                </span>
            }
        } else {
            return label
        }
    }

    return (
        <Tooltip label={diffTypeLabel()} isOpen={isLabelOpen} {...restToolTipProps}>
            <Box
                onMouseEnter={() => setIsLabelOpen(true)}
                onMouseLeave={() => setIsLabelOpen(false)}
                onClick={() => setIsLabelOpen(true)}
                display={"inline-block"}
                {...restToolTipProps.style}
            >
                {children}
            </Box>
        </Tooltip>
    );
}

export default TooltipWithTouch