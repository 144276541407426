import localeTranslations from "locales";
import { useAppProvider } from "providers/AppProvider";

declare global {
    interface String {
        formatUnicorn(args: any): string;
    }
}

export const useTrans = () => {
    const { locale } = useAppProvider();

    const translate = (locale: any, localeTranslations: any, key: any, ...args: any) => {
        if (!key || !localeTranslations) return String(key);

        const translations = localeTranslations[locale] || localeTranslations;
        const result = translations[key] || key;

        return typeof result === "string" ? result?.formatUnicorn(args) : key;
    };


    // eslint-disable-next-line
    String.prototype.formatUnicorn = function (): string {
        var e = this.toString();

        if (!arguments.length) return e;
        var t = typeof arguments[0],
            n =
                "string" === t || "number" === t
                    ? Array.prototype.slice.call(arguments)
                    : arguments[0];
        for (var i in n) e = e.replace(new RegExp("\\{" + i + "\\}", "gi"), n[i]);
        return e;
    };

    const t = (key: any, ...args: any) => {
        return translate(locale, localeTranslations, key, ...args);
    };

    return t
}