import { CheckboxState, ItemState } from ".";
import { Item } from "./TreeList";

export const updateItemStates = (oldState: ItemState[], items: Item[], clickedId: number) => {
  const newState: any = oldState.map((i) => ({ ...i }));
  // getters
  const getItemState = (id: number) => {
    return newState.find((i) => i.id === id)?.state;
  };
  // setters
  const updateParent = (id: number) => {
    const item: any = items.find((i) => i.id === id);
    const parent = items.find((i) => i.id === item.parentId);
    if (!parent) return;
    const childIds = items.filter((i) => i.parentId === parent.id).map((i) => i.id);
    const childStates = childIds.map((childId) => getItemState(childId));
    if (childStates.length === childStates.filter((s) => s === CheckboxState.CHECKED).length) {
      newState.find((i) => i.id === parent.id).state = CheckboxState.CHECKED;
    } else if (childStates.length === childStates.filter((s) => s === CheckboxState.UNCHECKED).length) {
      newState.find((i) => i.id === parent.id).state = CheckboxState.UNCHECKED;
    } else {
      newState.find((i) => i.id === parent.id).state = CheckboxState.INDETERMINATE;
    }
    updateParent(parent.id);
  };

  const setUnchecked = (id: number) => {
    newState.find((i) => i.id === id).state = CheckboxState.UNCHECKED;
    items
      .filter((i) => i.parentId === id)
      .map((i) => i.id)
      .forEach((childId) => setUnchecked(childId));

    updateParent(id);
  };

  const setChecked = (id: number) => {
    newState.find((i) => i.id === id).state = CheckboxState.CHECKED;

    items
      .filter((i) => i.parentId === id)
      .map((i) => i.id)
      .forEach((childId) => setChecked(childId));

    updateParent(id);
  };

  // actual logic
  const itemState = getItemState(clickedId);

  if (itemState === CheckboxState.CHECKED) {
    setUnchecked(clickedId);
  } else {
    setChecked(clickedId);
  }
  return newState;
};

export function buildTree(items: any[]): any {
  const itemMap: { [key: string]: any } = {};
  // Create nodes
  items.forEach(item => {
    itemMap[item.id] = {
      ...item,
      children: [],
    };
  });

  // Build tree structure
  const rootItems: any[] = [];

  items.forEach(item => {
    const parentId = item?.specialInfo?.parentId?.toString() || "0";

    if (parentId === "0") {
      rootItems.push(itemMap[item.id]);
    } else {
      itemMap[parentId].children?.push(itemMap[item.id]);
    }
  });

  return rootItems;
}