import { useAppProvider } from "providers/AppProvider";

const useApp = () => {
  const {
    history,
    updateBreadcrumb,
    redirectAdminPath,
    toogleFullScreen,
    shopFeatures,
    isLoading,
    isFullScreen,
    roles,
    userId,
    betaFeatures,
    isWebview,
    shopInfo,
    user,
    setMenuActive,
    isDisplaySummary,
    setIsDisplaySummary,
    plan_name
  } = useAppProvider();

  return {
    history,
    updateBreadcrumb,
    redirectAdminPath,
    toogleFullScreen,
    shopFeatures,
    isLoading,
    isFullScreen,
    roles,
    userId,
    betaFeatures,
    isWebview,
    shopInfo,
    user,
    setMenuActive,
    isDisplaySummary,
    setIsDisplaySummary,
    plan_name
  };
};

export default useApp;
