import DragZone from "components/DragZone";

import { useTrans } from "locales/hook";

import { reorder } from "helpers/helpers";
import TooltipWithTouch from "components/TooltipWithTouch";

// ! Icon CreateChart
import CreateChartSVG from "assets/images/icons/create-chart.svg";
import CreateChartActiveSVG from "assets/images/icons/create-chart-active.svg";

// ! Icon HeatMap

import CreateHeatmapSVG from "assets/images/icons/create-heatmap.svg";
import CreateHeatmapActiveSVG from "assets/images/icons/create-heatmap-active.svg";

/**
 * ! Muốn custom riêng 1 drag vui lòng clone ra 1 drag zone để cấu hình
 */

function DragContainerV2({
    editable,
    list,
    onDelete,
    type,
    onHandleDragEnd,
    useMetricToDrawChart = false, // cờ này bật true sẽ chạy tính năng vễ chart
    useMetricToDrawHeatMap = false, // cờ này bật true lên thì tính năng vẽ heatmap bật
    optionYAxis = {
        measureField: "",
        measureName: "",
    },
    optionsHeatMap = [],
    onChangeYAxis,
    onChangeHeatMap,
    isDrag,
    isDelete,
    disabledGroupItems,
    disabledAtLeastOneItemInGroup
}: any) {
    const t = useTrans();

    list.forEach((i) => {
        i.isDragDisabled = false;
    });

    const handleDeleteItem = (item) => {
        onDelete && onDelete(item);
    };

    const handleChangeYAxis = (value) => {
        onChangeYAxis && onChangeYAxis(value);
    };

    const handleChangeHeatmap = (value, isChecked) => {
        onChangeHeatMap && onChangeHeatMap(value, isChecked);
    };

    const hiddenDelete = (field) => {
        if (isDelete) {
            if (disabledGroupItems.length) {
                if (type === 'group_by' && disabledGroupItems.includes(field.groupPropertyName)) {
                    return true;
                } else if (type === 'measure' && disabledGroupItems.includes(field.measureField)) {
                    return true;
                } else {
                    return false
                }
            } else if (disabledAtLeastOneItemInGroup?.length && type === "group_by") {
                const findItemsInGroup = list.filter(item => disabledAtLeastOneItemInGroup.includes(item.groupAreaName));

                return findItemsInGroup.length === 1 && disabledAtLeastOneItemInGroup.includes(field.groupAreaName)
            } else {
                return false
            }
        } else {
            return true;
        }
    }

    const renderItem = (value, isLast, isActiveClass) => {
        return (
            <div
                className={`${!value.hasOwnProperty("isDisplay") || value.isDisplay
                    ? ""
                    : "hrv-report-d-none"
                    } hrv-report-d-block`}

            >
                <div
                    className={`${!isLast ? "hrv-report-border-bottom-gray-8" : ""
                        } hrv-report-px-16 hrv-report-py-8 hrv-report-justify-between hrv-report-items-center hrv-report-d-flex hrv-report-measure-wrapper ${isActiveClass}`}
                    style={{
                        background: "#fff",
                        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.24)",
                        borderRadius: "4px",
                    }}
                >
                    <div
                        className={`${!isDrag ? "hrv-report-my-2" : ""
                            } hrv-report-items-center hrv-report-d-flex`}
                    >
                        <span>{isDrag ? svgDot : ""}</span>

                        <p className={`${isDrag ? "hrv-report-ml-8" : ""} hrv-report-m-0`}>
                            {type === "group_by"
                                ? t(value.groupPropertyName)
                                : t(value.measureField)}
                        </p>
                    </div>

                    {useMetricToDrawHeatMap && !value.heatmapSupport && (
                        <div
                            className="hrv-report-d-flex hrv-report-items-center hrv-report-absolute"
                            style={{
                                right: 16,
                            }}
                        >
                            <>
                                {optionsHeatMap.includes(value.measureField) ? (
                                    <div
                                        className="hrv-report-cursor-pointer hrv-report-px-6 hrv-report-py-6"
                                        onClick={() => {
                                            handleChangeHeatmap(value, false);
                                        }}
                                    >
                                        <TooltipWithTouch
                                            label={
                                                <span
                                                    className="hrv-report-fs-12 hrv-report-d-flex hrv-report-text-center"
                                                    style={{
                                                        lineHeight: "18px",
                                                    }}
                                                >
                                                    {t("Tắt hiển thị biểu đồ nhiệt")}
                                                </span>
                                            }
                                            placement="left"
                                            hasArrow
                                        >
                                            <CreateHeatmapActiveSVG />
                                        </TooltipWithTouch>
                                    </div>
                                ) : (
                                    <div
                                        className="hrv-report-measure-to-draw-heatmap hrv-report-cursor-pointer hrv-report-px-6 hrv-report-py-6 hrv-report-chart-icons-default_hover"
                                        onClick={() => {
                                            handleChangeHeatmap(value, true);
                                        }}
                                    >
                                        <TooltipWithTouch
                                            label={
                                                <span
                                                    className="hrv-report-fs-12 hrv-report-d-flex hrv-report-text-center"
                                                    style={{
                                                        lineHeight: "18px",
                                                    }}
                                                >
                                                    {t("Bật hiển thị biểu đồ nhiệt")}
                                                </span>
                                            }
                                            placement="left"
                                            hasArrow
                                        // transitionName=""
                                        // overlayClassName="hrv-report-custom-tooltip_activeHeatmap"
                                        >
                                            <CreateHeatmapSVG />
                                        </TooltipWithTouch>
                                    </div>
                                )}
                            </>
                        </div>
                    )}

                    {useMetricToDrawChart ? (
                        <div
                            className="hrv-report-d-flex hrv-report-items-center hrv-report-absolute "
                            style={{
                                right: 14,
                            }}
                        >
                            <>
                                {optionYAxis?.measureField === value.measureField ? (
                                    <div className="hrv-report-mr-40 hrv-report-cursor-default hrv-report-px-6 hrv-report-py-6">
                                        <TooltipWithTouch
                                            label={
                                                <span
                                                    className="hrv-report-fs-12 hrv-report-d-flex hrv-report-text-center"
                                                    style={{
                                                        lineHeight: "18px",
                                                    }}
                                                >
                                                    {t("Đang chọn làm giá trị trục Y trên biểu đồ")}
                                                </span>
                                            }
                                            // overlayClassName="hrv-report-custom-tooltip_activeYAxis"
                                            placement="left"
                                            hasArrow
                                        >
                                            <CreateChartActiveSVG />
                                        </TooltipWithTouch>
                                    </div>
                                ) : (
                                    <div
                                        className="hrv-report-measure-to-draw-chart hrv-report-cursor-pointer hrv-report-px-6 hrv-report-py-6 hrv-report-chart-icons-default_hover"
                                        onClick={() => {
                                            handleChangeYAxis(value);
                                        }}
                                    >
                                        <TooltipWithTouch
                                            label={
                                                <span
                                                    className="hrv-report-fs-12 hrv-report-d-flex hrv-report-text-center"
                                                    style={{
                                                        lineHeight: "18px",
                                                    }}
                                                >
                                                    {t("Chọn làm giá trị trục Y trên biểu đồ")}
                                                </span>
                                            }
                                            placement="left"
                                            hasArrow
                                        // transitionName=""
                                        // overlayClassName="hrv-report-custom-tooltip_activeYAxis"
                                        >
                                            <CreateChartSVG />
                                        </TooltipWithTouch>
                                    </div>
                                )}
                            </>

                            {list.length > 1 &&
                                !hiddenDelete(value) &&
                                (
                                    <div
                                        className="hrv-report-cursor-pointer"
                                        onClick={() => {
                                            handleDeleteItem(value);
                                        }}
                                    >
                                        {svgCloseLine}
                                    </div>
                                )}
                        </div>
                    ) : (
                        <>
                            {list.length > 1 &&
                                !hiddenDelete(value) &&
                                (
                                    <div
                                        className="hrv-report-cursor-pointer"
                                        onClick={() => {
                                            handleDeleteItem(value);
                                        }}
                                    >
                                        {svgCloseLine}
                                    </div>
                                )}
                        </>
                    )}

                    {/* <div className="hrv-report-d-flex hrv-report-items-center">
            {useMetricToDrawChart && (
              <>
                {optionYAxis?.measureField === value.measureField ? (
                  <div className="hrv-report-mr-22">
                    <CreateChartActiveSVG />
                  </div>
                ) : (
                  <div className="hrv-report-measure-to-draw-chart hrv-report-cursor-pointer">
                    <Tooltip
                      title={t("Chọn làm giá trị trục Y trên biểu đồ")}
                      placement="left"
                      transitionName=""
                    >
                      <CreateChartSVG />
                    </Tooltip>
                  </div>
                )}
              </>
            )}

            <div
              className="hrv-report-cursor-pointer"
              onClick={() => {
                handleDeleteItem(value);
              }}
            >
              {svgCloseLine}
            </div>
          </div> */}
                </div>
            </div>
        );
    };

    const onDragEnd = (result) => {
        const items = reorder(list, result.source.index, result.destination.index);

        onHandleDragEnd && onHandleDragEnd(items);
    };

    return (
        <DragZone
            editable={isDrag}
            renderList={list}
            renderItem={renderItem}
            onHandleDragEnd={onDragEnd}
            activeScroll={true}
        />
    );
}

export default DragContainerV2;

const svgDot = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM7 12C7 10.9 7.9 10 9 10C10.1 10 11 10.9 11 12C11 13.1 10.1 14 9 14C7.9 14 7 13.1 7 12ZM9 20C10.1 20 11 19.1 11 18C11 16.9 10.1 16 9 16C7.9 16 7 16.9 7 18C7 19.1 7.9 20 9 20ZM17 6C17 7.1 16.1 8 15 8C13.9 8 13 7.1 13 6C13 4.9 13.9 4 15 4C16.1 4 17 4.9 17 6ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM13 18C13 16.9 13.9 16 15 16C16.1 16 17 16.9 17 18C17 19.1 16.1 20 15 20C13.9 20 13 19.1 13 18Z"
            fill="#BDBDBD"
        />
    </svg>
);

const svgCloseLine = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M12.0007 10.586L16.9507 5.636L18.3647 7.05L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.05L7.05072 5.636L12.0007 10.586Z"
            fill="#9CA3B0"
        />
    </svg>
);
