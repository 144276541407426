import useWindowDimensions from "hooks/useWindowDimension";
import { useTrans } from "locales/hook";

import TemplateCard from "./TemplateCard"

import { TEMPLATE_EXAMPLE } from "./constants"

function DashboardTemplate({ onHandleClick }) {
    const { width } = useWindowDimensions();
    const t = useTrans();

    return (
        <div className="hrv-report-pt-4">
            <div className="hrv-report-pb-16 hrv-report-border-bottom-gray-10">
                <p className="hrv-report-m-0" style={{
                    width: '84%'
                }}>
                    {t("suggest_template_dashboard")}
                </p>
            </div>


            <div
                className="hrv-report-py-24"
                style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(
                    ${width <= 1200 ? width <= 768 ? width <= 552 ? "1" : "2" : "3" : "4"}, 1fr)`,
                    gridAutoRows: "1fr",
                    gridGap: "24px",
                }}>
                {TEMPLATE_EXAMPLE.map((data, index) => {
                    return <TemplateCard
                        key={index}
                        {...data}
                        onHandleClick={onHandleClick}
                    />
                })}
            </div>
        </div>

    )
}

export default DashboardTemplate