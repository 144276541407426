/**
 * @Author Hara Ads Team
 * @Created_at 12-10-2023
 * @Param id
 * @Return
 */

import Model from "models";
import { EffectCallback, useEffect, useState } from "react";

function useLastestView(id: any) {
  const { LastestView } = Model();

  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const useEffectSeLastestView = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, []);
  };

  useEffectSeLastestView(() => {
    fetchApiLastedView();
  });

  const fetchApiLastedView = async () => {
    setIsLoading(true);

    const { data, isError } = await LastestView(id);

    if (isError) {
      setIsLoading(false);
      return [];
    } else {
      setIsLoading(false);
      setData(data);
    }
  };

  return { data, isLoading };
}

export default useLastestView;
