import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';

const firebaseConfig = {
    apiKey: "AIzaSyAXVeMZxtUOO7UujfX_Hph3JADnSWFJ73k",
    authDomain: "hara-report.firebaseapp.com",
    projectId: "hara-report",
    storageBucket: "hara-report.appspot.com",
    messagingSenderId: "271586609368",
    appId: "1:271586609368:web:e1799989873b3a15036fad"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const create = async (cl, dataSubmit) => {
    const collect = collection(db, cl);
    const result = await addDoc(collect, dataSubmit);

    return result.id;
}

export const getList = async (cl) => {
    const collect = collection(db, cl);
    const snapshot = await getDocs(collect);
    const returnData = snapshot.docs.map(doc => doc.data());

    return returnData;
}
