import WrapperSpinner from "components/Spinner";
import { useTrans } from "locales/hook";

import "./styles.scss";

interface IProps {
  total: number;
  current?: number;
  onChangePage: any;
  limit?: any;
  isLoading: boolean;
}

function HPagination(props: IProps) {
  const { total, limit = 100, onChangePage, isLoading = false } = props;

  const t = useTrans()
  const handleLoadMore = () => {
    !isLoading && onChangePage && onChangePage();
  };

  return (
    <div className="hrv-report-table-pagination hrv-report-d-flex hrv-report-justify-center hrv-report-mt-14">
      <div className="hrv-report-mr-16 hrv-report-mb-0 hrv-report-fs-14 hrv-report-text-color-gray-9">
        {t("Displaying")} {total} {t("result")}
      </div>

      <div
        className={`${isLoading
          ? "hrv-report-text-color-gray-7"
          : "hrv-report-text-color-blue-2"
          } hrv-report-mb-0 hrv-report-fs-14 hrv-report-cursor-pointer hrv-report-fw-600`}
        onClick={handleLoadMore}
      >
        <div className="hrv-report-d-flex">
          <div>{t("See_more")} {limit} {t("result")}</div>

          {isLoading && (
            <div className="hrv-report-ml-16" style={{ marginTop: -8 }}>
              <WrapperSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default HPagination;
