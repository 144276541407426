import {
  SET_LIMIT_FEATURES,
  DEFAULT_REPORT_TAB,
  AUTHOR_LIST,
  SET_FAVORITES,
  SET_AUTHORS,
  FETCHING_REPORT_LIST,
  FETCHING_REPORT_LIST_SUCCESS,
  FETCHING_REPORT_LIST_ERROR,
  TOGGLE_FAVORITE_BTN,
  TOGGLE_POPUP_DELETE,
  TOGGLE_POPUP_CHANGENAME,
  SET_REPORT_TAB_SELECTED,
  FETCHING_REPORT_LIST_ALL,
  FETCHING_REPORT_LIST_ALL_SUCCESS,
  FETCHING_REPORT_LIST_ALL_ERROR,
  SET_SORT_VALUE,
} from "./constants";

export const initialState = {
  defaultState: false,
  limitFeatures: [],
  searchReportName: "",

  defaultListAuthors: AUTHOR_LIST,
  authors: [], // ! default

  isFavorite: false,

  reportTab: DEFAULT_REPORT_TAB,
  reportTabSelected: [],

  reportsDefault: [],
  isLoadingReportDefault: true,
  reportAll: [],
  isLoadingReportAll: true,

  isOpenPopupChangeName: false,
  isOpenPopupDelete: false,

  sortValue: {
    dimension: "",
    direction: "",
  },
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_LIMIT_FEATURES:
      return { ...state, limitFeatures: action.payload };

    case SET_FAVORITES:
      return { ...state, isFavorite: action.payload };

    case SET_AUTHORS:
      return { ...state, authors: action.payload };

    case TOGGLE_FAVORITE_BTN:
      return { ...state };

    case FETCHING_REPORT_LIST:
      return { ...state, isLoadingReportDefault: true };

    case FETCHING_REPORT_LIST_SUCCESS:
      return {
        ...state,
        isLoadingReportDefault: false,
        reportsDefault: action.payload,
      };

    case FETCHING_REPORT_LIST_ERROR:
      return { ...state, isLoadingReportDefault: false };

    case FETCHING_REPORT_LIST_ALL:
      return { ...state, isLoadingReportAll: true };

    case FETCHING_REPORT_LIST_ALL_SUCCESS:
      return {
        ...state,
        reportAll: action.payload,
      };

    case FETCHING_REPORT_LIST_ALL_ERROR:
      return { ...state, isLoadingReportAll: false };

    case TOGGLE_POPUP_CHANGENAME:
      return { ...state, isOpenPopupChangeName: action.payload };

    case TOGGLE_POPUP_DELETE:
      return { ...state, isOpenPopupDelete: action.payload };

    case SET_REPORT_TAB_SELECTED:
      return { ...state, reportTabSelected: action.payload };

    case SET_SORT_VALUE:
      return { ...state, sortValue: action.payload };

    default:
      throw new Error("Action invalid");
  }
};

export default reducer;
