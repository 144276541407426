import { AppProvider } from "providers/AppProvider";
import { Outlet } from 'react-router-dom';
import RoutePropagator from 'RoutePropagator';

function RootPage() {
    return (
        <AppProvider>
            <RoutePropagator />
            <Outlet />
        </AppProvider>
    )
}

export default RootPage