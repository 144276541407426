import TableReportHeader from "components/Table/components/header";
import { mapOrder } from "helpers/helpers";
import { useTrans } from "locales/hook";
import { Fragment, useMemo } from "react";

function GroupHeader(props) {
    const { reportNameSystem, canReadMACost, dataHeaders } = props;

    const t = useTrans();

    const HEADER_IMPORT_AND_EXPORT_INVEN: any = useMemo(() => {
        return {
            left: [
                {
                    rowSpan: 2,
                    name: "ProductId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "VariantId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "SKU",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "Barcode",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "ProductCategoryId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "Qty_OpeningStock",
                    alignment: "right",
                    type: "Measure",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "CosAmountOpening",
                    alignment: "right",
                    check: canReadMACost,
                    type: "Measure",
                    isGroup: false,
                    isDrag: false,
                },
                {
                    colSpan: 5,
                    name: "Import_quantity",
                    alignment: "center",
                    type: "Measure",
                    isGroup: true,
                },
                {
                    colSpan: 4,
                    name: "Export_quantity",
                    alignment: "center",
                    type: "Measure",
                    isGroup: true,
                },
                {
                    rowSpan: 2,
                    name: "Qty_ClosingStock",
                    alignment: "right",
                    type: "Measure",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "CosAmountClosing",
                    alignment: "right",
                    check: canReadMACost,
                    type: "Measure",
                    isGroup: false,
                },
            ],
            right: [
                {
                    name: "Qty_Init_In",
                    alignment: "right",
                },
                {
                    name: "Qty_Transfer_In",
                    alignment: "right",
                },
                {
                    name: "Qty_Adjustment_In",
                    alignment: "right",
                },
                {
                    name: "Qty_PurchaseOrder_In",
                    alignment: "right",
                },
                {
                    name: "Qty_SaleOrderRestock_In",
                    alignment: "right",
                },
                {
                    name: "Qty_Transfer_Out",
                    alignment: "right",
                },
                {
                    name: "Qty_Adjustment_Out",
                    alignment: "right",
                },
                {
                    name: "Qty_SaleOrderFulfill_Out",
                    alignment: "right",
                },
                {
                    name: "Qty_PurchaseOrderReturn_Out",
                    alignment: "right",
                    style: { borderRight: "1px solid #e5e7eb" },
                },
            ],
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canReadMACost, dataHeaders]);

    const HEADER_ENTER_AND_RETURNS_BY_SUPPLIERS: any = useMemo(() => {
        return {
            left: [
                {
                    rowSpan: 2,
                    name: "ProductId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "ProductTypeName",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "LocId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "SupplierId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "Phone",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "InvId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "VariantId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "SKU",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "Barcode",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "TransactionDate",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "TypeId",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    rowSpan: 2,
                    name: "Email",
                    alignment: "left",
                    type: "GroupProperty",
                    isGroup: false,
                },
                {
                    colSpan: 6,
                    name: "Nhập hàng",
                    alignment: "center",
                    type: "Measure",
                    isGroup: true,
                },
                {
                    colSpan: 6,
                    name: "Trả hàng",
                    alignment: "center",
                    type: "Measure",
                    isGroup: true,
                },
                {
                    colSpan: 4,
                    name: "Thực nhập",
                    alignment: "center",
                    type: "Measure",
                    isGroup: true,
                },
            ],
            right: [
                {
                    name: "ReceiveQty",
                    alignment: "right",
                },
                {
                    name: "ReceiveCostAmount",
                    alignment: "right",
                },
                {
                    name: "ReceiveDiscountAmount",
                    alignment: "right",
                },
                {
                    name: "ReceiveOtherExpensesNCCAmount",
                    alignment: "right",
                },
                {
                    name: "ReceiveOtherExpensesAmount",
                    alignment: "right",
                },
                {
                    name: "TotalReceiveAmount",
                    alignment: "right",
                },
                {
                    name: "ReturnQty",
                    alignment: "right",
                },
                {
                    name: "ReturnCostAmount",
                    alignment: "right",
                },
                {
                    name: "ReturnDiscountAmount",
                    alignment: "right",
                },
                {
                    name: "ReturnOtherExpensesNCCAmount",
                    alignment: "right",
                },
                {
                    name: "ReturnOtherExpensesAmount",
                    alignment: "right",
                },
                {
                    name: "TotalReturnAmount",
                    alignment: "right",
                },
                {
                    name: "ReturnQtyPercent",
                    alignment: "right",
                },
                {
                    name: "ActuallyReceivedQty",
                    alignment: "right",
                },
                {
                    name: "TotalAmount",
                    alignment: "right",
                },
                {
                    name: "CostPriceReference",
                    alignment: "right",
                    style: { borderRight: "1px solid #e5e7eb" },
                },
            ],
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataHeaders]);

    let groupHeader: any = reportNameSystem === "stock_invreceive_by_suppliers" ? HEADER_ENTER_AND_RETURNS_BY_SUPPLIERS : HEADER_IMPORT_AND_EXPORT_INVEN;

    // & Refresh header by groupproperty value
    let orderingGroupByHeader = groupHeader["left"].filter(y => y.type === "GroupProperty" && dataHeaders.findIndex(d => d.name === y.name) !== -1);
    const getMeasureHeader = groupHeader["left"].filter(y => y.type === "Measure");

    orderingGroupByHeader = mapOrder(orderingGroupByHeader, dataHeaders.map(y => y.name), 'name');

    groupHeader["left"] = orderingGroupByHeader.concat(getMeasureHeader);

    return (
        <>
            <tr>
                {groupHeader["left"].map((data, index) => {
                    let thProps: any = {};

                    if (data.hasOwnProperty("colSpan")) {
                        thProps.colSpan = data.colSpan;
                    } else if (data.hasOwnProperty("rowSpan")) {
                        thProps.rowSpan = data.rowSpan;
                    }

                    return (
                        <Fragment key={index}>
                            {!data.hasOwnProperty("check") ? (
                                <th
                                    className={`hrv-report-text-${data.alignment} ${index === 0 ? "hrv-report-first-column" : ""
                                        }`}
                                    key={index}
                                    {...thProps}
                                >
                                    {index === 0 ? (
                                        t(data.name)
                                    ) : (
                                        <TableReportHeader
                                            reportNameSystem={reportNameSystem}
                                            name={t(data.name)}
                                        />
                                    )}
                                </th>
                            ) : data.check ? (
                                <th
                                    className={`hrv-report-text-${data.alignment} ${index === 0 ? "hrv-report-first-column" : ""
                                        }`}
                                    key={index}
                                    {...thProps}
                                >
                                    <TableReportHeader
                                        reportNameSystem={reportNameSystem}
                                        name={t(data.name)}
                                    />
                                </th>
                            ) : (
                                <></>
                            )}
                        </Fragment>
                    );
                })}
            </tr>

            <tr>
                {groupHeader["right"].map((data, index) => {
                    return (
                        <th
                            className={`hrv-report-text-${data.alignment}`}
                            key={`${index}_abc`}
                            style={{ ...data.style }}
                        >
                            <TableReportHeader
                                reportNameSystem={reportNameSystem}
                                name={data.name}
                            />
                        </th>
                    );
                })}
            </tr>
        </>
    );
}

export default GroupHeader;
