import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
import { useRef } from "react";
import { useEmbedApp } from "@haravan/reactapp";

import Modal from "components/Modal";
import { Button, Input } from "@chakra-ui/react";
import HRVCheckbox from "components/Checkbox";

interface IProps {
    type: string // Export, Duplicate, Delete, Invalid date, About, Export
    onClickClose?: Function
    onClickApply?: Function
}

const HModal: any = Modal;
const HInput: any = Input;

// * Khi sinh ra 1 popup mới trong màn hình Chi tiết báo cáo, Nội dung Popup đó sẽ được định nghĩa bên trong đây
function ModalReportDetail(props: IProps) {
    const {
        type,
        onClickClose,
        onClickApply,
    } = props;

    const t = useTrans();
    const { showMessage } = useEmbedApp();

    const { state, dispatch, actions } = useStore();
    const {
        is_hidden_annouce_export,
        duplicateName,
        isLoadingDuplicate,
        messageHeaderAbout,
        messageBodyAbout,
        messageFooterAbout
    } = state;

    const inputRef: any = useRef();

    const handleClose = () => {
        onClickClose && onClickClose();
    }

    const handleChangeInput = () => {
        const targetValue = inputRef.current.value;
        dispatch(actions.setDuplicateName(targetValue));

        if (!targetValue.length) return showMessage(t("Vui lòng nhập tên báo cáo"), 'error')
        if (targetValue.length > 100) return showMessage(t("The_report_name_cannot_exceed_100_characters"), 'error')
    }

    const ModalContent = () => {
        let header: any = "";
        let body: any = "";
        let footer: any = () => {
            return ""
        }

        switch (type) {
            case "export":
                header = "export";

                body = () => {
                    return <div
                        className="hrv-report-fs-14 hrv-report-mb-0 hrv-report-text-color-black-6"
                    >
                        <div dangerouslySetInnerHTML={{ __html: t("modal_export_content_xlsx") }}></div>

                        <HRVCheckbox
                            value={is_hidden_annouce_export}
                            onChange={(event) => {
                                dispatch(actions.setIsDisplayAnnouceExport(event.check))
                            }}
                            className="hrv-report-text-color-black-6 hrv-report-pb-0 hrv-report-pt-16"
                        >
                            {t("Không hiện lại thông báo này cho lần sau")}
                        </HRVCheckbox>
                    </div>
                };

                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    dispatch(actions.setIsDisplayAnnouceExport(false))
                                    handleClose && handleClose();
                                }}
                                variant="default-default"
                                className="hrv-report-mr-8">
                                {t("cancelLabel")}
                            </Button>

                            <Button
                                onClick={() => {
                                    onClickApply && onClickApply("export")
                                }}
                                variant="default-primary"
                            >
                                {t("export_report")}
                            </Button>
                        </div>
                    </div>
                };

                break;

            case "duplicate":
                header = "duplicate_report"
                body = () => {
                    return <div>
                        <p className="hrv-report-mb-4 hrv-report-fw-600 hrv-report-fs-14 hrv-report-mb-0">
                            {t("Tên báo cáo")}
                        </p>

                        <HInput
                            defaultValue={duplicateName}
                            ref={inputRef}
                            placeholder={t("Nhập tên báo cáo")}
                            variant={"autocomplete"}
                            size="m"
                            onBlur={handleChangeInput}
                        />
                    </div>
                };
                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    handleClose && handleClose();
                                }}
                                variant="default-default"
                                className="hrv-report-mr-8"
                                isDisabled={isLoadingDuplicate}
                            >
                                {t("cancelLabel")}
                            </Button>

                            <Button
                                onClick={() => {
                                    onClickApply && onClickApply("duplicate")
                                }}
                                variant="default-primary"
                                isDisabled={!duplicateName.trim().length || duplicateName.trim().length > 100}
                                isLoading={isLoadingDuplicate}
                            >
                                {t("saveLabel")}
                            </Button>
                        </div>
                    </div>
                }

                break;

            case "delete":
                header = "Xóa báo cáo";
                body = () => {
                    return <p className="hrv-report-fs-14 hrv-report-mb-0">
                        {t("Báo cáo sẽ không thể phục hồi lại, bạn có muốn tiếp tục?")}
                    </p>
                }
                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    handleClose && handleClose();
                                }}
                                variant="default-default"
                                className="hrv-report-mr-8"
                            >
                                {t("cancelLabel")}
                            </Button>

                            <Button
                                onClick={() => {
                                    onClickApply && onClickApply("delete")
                                }}
                                variant="critical-primary"
                            >
                                {t("agreeLabel")}
                            </Button>
                        </div>
                    </div>
                }
                break;

            case "invalidDate":
                header = "Không thể xuất báo cáo";
                body = () => {
                    return <p className="hrv-report-fs-14 hrv-report-mb-0">
                        {t(
                            "Thời gian lựa chọn vượt quá 1 năm. Vui lòng điều chỉnh lại bộ lọc thời gian để có thể xuất được dữ liệu.",
                        )}
                    </p>
                }
                footer = () => {
                    return <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center">
                        <span></span>

                        <div className="hrv-report-d-flex">
                            <Button
                                onClick={() => {
                                    handleClose && handleClose();
                                }}
                                variant="default-primary"
                            >
                                {t("Đã hiểu")}
                            </Button>
                        </div>
                    </div>
                }
                break;

            case "about":
                header = messageHeaderAbout;
                body = () => {
                    return <div
                        className="hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-color-black-6 hrv-report-mb-0"
                        dangerouslySetInnerHTML={{ __html: messageBodyAbout }}
                    ></div>
                }
                footer = () => {
                    return <div
                        className="
                            hrv-report-d-flex 
                            hrv-report-justify-between
                            hrv-report-w-full
                            "
                    >
                        <a href={messageFooterAbout} target="_blank" rel="noreferrer"
                            className='hrv-report-d-flex hrv-report-items-center hrv-report-link' style={{
                                lineHeight: "20px"
                            }}>
                            <span className="hrv-report-mr-4">{svgInfo}</span>

                            {t("learn_more")}
                        </a>

                        <Button
                            variant="default-default"
                            onClick={() => {
                                handleClose()
                            }}
                        >
                            {t("Đóng")}
                        </Button>
                    </div>
                }
                break;

            default:
                header = "Chưa được định nghĩa";

                body = () => {
                    return "Chưa được định nghĩa"
                };

                footer = () => {
                    return "Chưa được định nghĩa"
                };

                break;
        }

        return {
            header,
            body,
            footer
        }
    }

    const headerModal = () => {
        return <div
            className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-justify-between"
        >
            <p
                className="
                hrv-report-mb-0
                hrv-report-text-color-black-6
                hrv-report-fs-16 
                hrv-report-fw-600
                "
            >{t(ModalContent().header)}</p>

            <span
                style={{
                    width: 18,
                }}
                className="
                hrv-report-d-flex 
                hrv-report-items-center 
                hrv-report-cursor-pointer
                hrv-report-justify-between
                "
                onClick={() => {
                    handleClose();
                }}
            >
                {svgClose}
            </span>
        </div>
    }

    return (
        <HModal
            renderHeader={headerModal}
            renderBody={ModalContent().body}
            renderFooter={ModalContent().footer() ? ModalContent().footer : null}
            onClose={handleClose}
            visible={type.length}
            closable={false}
            isCentered
            variant="about"
        />
    )
}

export default ModalReportDetail;

const svgClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8327 5.34167L14.6577 4.16667L9.99935 8.82501L5.34102 4.16667L4.16602 5.34167L8.82435 10L4.16602 14.6583L5.34102 15.8333L9.99935 11.175L14.6577 15.8333L15.8327 14.6583L11.1743 10L15.8327 5.34167Z" fill="#9CA3B0" />
    </svg>
);

const svgInfo = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.16669 5.83335H10.8334V7.50002H9.16669V5.83335ZM9.16669 9.16669H10.8334V14.1667H9.16669V9.16669ZM10 1.66669C5.40002 1.66669 1.66669 5.40002 1.66669 10C1.66669 14.6 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6 1.66669 10 1.66669ZM10 16.6667C6.32502 16.6667 3.33335 13.675 3.33335 10C3.33335 6.32502 6.32502 3.33335 10 3.33335C13.675 3.33335 16.6667 6.32502 16.6667 10C16.6667 13.675 13.675 16.6667 10 16.6667Z" fill="#2463EB" />
</svg>