import { Column } from "../";

interface IProps {
  dataColumn?: number;
}

function Row(props: IProps) {
  const { dataColumn = 5 } = props;

  const columns = [...Array(dataColumn)].map((i, idx) => <Column key={idx} />);

  return <tr>{columns}</tr>;
}

export default Row;
