import { ReactNode, memo, useCallback, useEffect, useRef, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { createPortal } from "react-dom";

import HeaderTableDefault from "../TableByDefault/header";

/**
 * Do sử dụng styling Header là Position Fixed 
 * => Desktop container >= 1245px set header giữa màn hình và maxWidth theo container width
 * => Mobile container < 1245px set maxWidth theo thiết bị và không cần center header position
 */

type TypeTableScrollable = {
  children: ReactNode;
  isVisibleDrilldown: boolean;
  reportNameSystem: string;
  canReadMACost: boolean;
  dataHeaders: any;
  sortValue: any;
  sortable: boolean;
  onHandleSortTable: Function;
  displayVirtual?: boolean
}

function TableScrollable({
  children,
  isVisibleDrilldown,
  reportNameSystem,
  canReadMACost,
  dataHeaders,
  sortValue,
  sortable,
  onHandleSortTable,
  displayVirtual = false
}: TypeTableScrollable) {

  const containerRef = useRef<any>(null);
  const refVirtualHeader = useRef<any>(null);

  const [headersWidth, setHeadersWidth] = useState({});
  const [totalWidth, setTotalWidth] = useState(0);
  const [positionTop, setPositionTop] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [spacingTableFromTop, setSpacingTableFromTop] = useState(0);

  const setAttributeToHeaderTable = () => {
    if (containerRef.current !== null) {
      const container = containerRef.current as HTMLElement;
      const table = container.querySelector("table");
      let headerWidthVirtual = {}; // State cho Header Table giả lập

      if (table !== null) {
        table.setAttribute(
          "data-item-original-width",
          table.getBoundingClientRect()?.width?.toString() || "0",
        );

        setTotalWidth(table.clientWidth)

        table.querySelectorAll("thead tr th").forEach((th, index): any => {
          let resignth: any = th;
          resignth.setAttribute("data-item-original-index", index?.toString());
          let nameTh = resignth.getAttribute("attr-name");

          headerWidthVirtual = {
            ...headerWidthVirtual,
            [nameTh]: th.getBoundingClientRect().width
          }

          let widthFixed: any = th.getBoundingClientRect().width;

          th.setAttribute(
            "data-item-original-width",
            widthFixed?.toString() || "0",
          );
        });

        setHeadersWidth(headerWidthVirtual)
      }
    }
  }

  const handleScrollLeft = (left) => {
    refVirtualHeader.current.scrollLeft = left;

    if (left > 0) {
      refVirtualHeader.current.classList.add("hrv-report-table-container__scrolling");
    } else {
      refVirtualHeader.current.classList.remove("hrv-report-table-container__scrolling")
    }
  }

  useEffect(() => {
    // * Nếu Table xuất hiện thì sẽ set attribute cho Header Table
    setAttributeToHeaderTable();
    calculateTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, containerRef, isVisibleDrilldown]);

  // ! Xử lý scroll ngang của table => cập nhật offset cho sticky header
  const handleTableScroll = (e) => {
    if (refVirtualHeader.current !== null) {
      handleScrollLeft(e.target.scrollLeft);

    }
  };

  const handleTableScrollHeader = (e) => {
    if (refVirtualHeader.current !== null) {
      let left = e.target.scrollLeft;
      const { current }: any = containerRef;
      const horizontalScrollContainer = current.querySelector(
        ".hrv-report-table-container",
      );
      horizontalScrollContainer.scrollLeft = left;

      if (left > 0) {
        horizontalScrollContainer.classList.add("hrv-report-table-container__scrolling");
      } else {
        horizontalScrollContainer.classList.remove("hrv-report-table-container__scrolling")
      }
    }
  };

  // ! Xử lý position top của header
  const calculateTop = () => {
    if (containerRef.current !== null) {
      const reportHeaderDetail = document.getElementById(
        "report_detail_header",
      );

      const drilldown = document.getElementById(
        "drilldown-wrapper",
      );

      const container = containerRef.current as HTMLElement;
      const table: any = container.querySelector("table");
      let top = table?.getBoundingClientRect().top || 0;
      const filterHeight = reportHeaderDetail !== null ? reportHeaderDetail?.getBoundingClientRect().height - 1 : 0;
      const drilldownHeight = drilldown !== null ? drilldown?.getBoundingClientRect().height - 1 : 0;

      let threshold = filterHeight + drilldownHeight;

      if (top < threshold) {
        setPositionTop(threshold);
        setIsVisible(true);

      } else {
        setPositionTop(0);
        setIsVisible(false);
      }
    }
  }

  useEffect(() => {
    const container = containerRef.current as HTMLElement;
    const table: any = container.querySelector("table");

    if (spacingTableFromTop === 0) {
      setSpacingTableFromTop(table.getBoundingClientRect().top)
    }
  }, [containerRef])

  // * Effect Listener cho scroll Table thì Header scroll (Table > Header)
  useEffect(() => {
    const { current }: any = containerRef;
    const horizontalScrollContainer = current.querySelector(
      ".hrv-report-table-container",
    );
    horizontalScrollContainer?.removeEventListener("scroll", handleTableScroll);
    horizontalScrollContainer?.addEventListener("scroll", handleTableScroll);

    return () => {
      horizontalScrollContainer?.removeEventListener(
        "scroll",
        handleTableScroll,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // * Effect Listener cho scroll header thì table scroll (Header > Table)
  useEffect(() => {
    const { current }: any = refVirtualHeader;

    current?.removeEventListener("scroll", handleTableScrollHeader);
    current?.addEventListener("scroll", handleTableScrollHeader);

    return () => {
      current?.removeEventListener(
        "scroll",
        handleTableScrollHeader,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ! End Xử lý scroll ngang của table => cập nhật offset cho sticky header

  // ! Xử lý sticker header
  useScrollPosition(
    ({ currPos }) => {
      calculateTop()
    },
    [containerRef.current],
    undefined,
    true,
  );
  // ! End lý sticker header

  const renderTable = useCallback(() => {
    return <table style={{
      minWidth: totalWidth,
    }} className={`${[
      "stock_transactionhistory_adv",
      "stock_transactionhistory_alltime",
      "stock_invreceive_by_suppliers"
    ].includes(reportNameSystem)
      ? "hrv-report-table-bordered"
      : ""
      }`}
    >
      <HeaderTableDefault
        reportNameSystem={reportNameSystem}
        canReadMACost={canReadMACost}
        dataHeaders={dataHeaders}
        sortValue={sortValue}
        sortable={sortable}
        onHandleSortTable={onHandleSortTable}
        headersWidth={headersWidth}
      />
    </table>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headersWidth]);

  return (
    <>
      {createPortal(<div className="wrapper-container-embbed" style={{
        position: "fixed",
        top: positionTop,
        left: "50%",
        transform: "translateX(-50%)",
        overflow: "hidden",
        zIndex: 12,
        visibility: isVisible ? "visible" : "hidden",
        maxWidth: containerRef?.current?.getBoundingClientRect().width,
        padding: 0 // Bỏ padding so với screen

        // left: width < DESKTOP_WIDTH ? "unset" : "50%",
        // transform: width < DESKTOP_WIDTH ? "unset" : "translateX(-50%)",
      }}>
        <div
          className={`
          hrv-report-table-container 
          hrv-report-relative 
          hrv-report-virtual-header
          `}
          style={{
            overflow: displayVirtual && totalWidth > window.innerWidth ? "auto" : 'hidden'
          }}
          ref={refVirtualHeader}
        >
          {renderTable()}
        </div>
      </div>, document.body)}

      <div ref={containerRef} className="hrv-report-relative">
        {children}
      </div>
    </>
  );
}

export default memo(TableScrollable);
