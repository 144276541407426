// ! Hooks
import { useEffect, useState } from "react";
import { useTrans } from "locales/hook";

// ! Helpers
import { GetDateShowType } from "helpers/date";
import { ShowDateRange, generatingColorForString, implementLongStringInChart, mapOrder, valueHandler, xmlRenderCheckbox } from "helpers/helpers";

// ! Constants
import { category_chart } from "pages/ReportDashboardV2/context/store/constants";
import { DRAW_BY_Y_AXIS_FOR_SPECIFIC_REPORTS, LIMIT_GROUPED_BY_DISCOUNT, REPORT_OPEN_COMPARE, REVERSE_NEGATIVE_FIELD_NAME, SAMPLE_COLORS } from "../constants";
import { colorsForPageViews, colorsForShipments } from "components/Chart/constants";

interface IProps {
    displayChart: string
    isLoading: boolean
    data: any
    startdate: any
    enddate: any
    compareDateStart: any,
    compareDateEnd: any,
    reportNameSystem: string
    measureSelected: any,
    optionMeasure: any
}

export const useBuildChart = (props: IProps) => {
    const {
        isLoading,
        data: dataChart,
        displayChart,
        startdate,
        enddate,
        compareDateStart,
        compareDateEnd,
        reportNameSystem,
        measureSelected,
        optionMeasure
    } = props;

    const t = useTrans();

    const [isRender, setIsRender] = useState(false);
    const [labels, setLabels] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [typeVertical, setTypeVertical] = useState("");
    const [typeHorizontal, setTypeHorizontal] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [isGrouped, setIsGrouped] = useState(false);
    const [indexAxis, setIndexAxis] = useState('x');
    const { type } = GetDateShowType(startdate, enddate);

    // Kiểm tra biến so sánh trong kì với ngày bắt đầu so sánh và data kỳ trước
    // Không chọn ngày bắt đầu và kỳ trước không có dữ liệu => không so sánh
    const checkCompare = (previousBody) => {
        let isCompare = false;

        if (REPORT_OPEN_COMPARE.includes(reportNameSystem)) {
            isCompare = typeof compareDateStart !== 'undefined'
                ? previousBody?.length
                    ? true
                    : false
                : false;
        }

        return isCompare
    }

    const buildLabels = (head, body) => {
        // * Lấy định nghĩa cột đầu tiên
        // Loại dữ liệu (money, date, ....)
        const typeOfVertical = head?.current[0]?.dataFormat;

        // Tên cột 
        const nameOfVertical = head?.current[0]?.name;
        const { current: currentBody } = body;
        const { current: currentHead } = head;

        switch (displayChart) {
            case category_chart.STACKEDBAR: {
                return currentBody.map((y) => y[0]);
            }

            case category_chart.GROUPEDHORIZONTALCOLUMN: {
                return currentBody.map((y) => y[0]);
            }

            case category_chart.DOUGHNUT: {
                const legends = currentBody.map((y) => {
                    const nameChannel = valueHandler(typeOfVertical, nameOfVertical, y[0], type);

                    return {
                        name: t(nameChannel),
                        color: SAMPLE_COLORS[nameChannel] || generatingColorForString(nameChannel),
                    };
                });

                return legends
            }

            case category_chart.GROUPEDCOLUMN: {
                switch (reportNameSystem) {
                    case "sales_overview_promotion":
                        const drawY = 'TotalDiscount'; // Vẽ theo chỉ số tổng giảm giá

                        let drawIndex = currentHead?.findIndex((h) => h.name === drawY);

                        if (drawIndex !== -1) {
                            const labels = currentBody?.map((y) => {
                                if (nameOfVertical === 'DiscountId') {
                                    return y[0];
                                } else if (nameOfVertical === "DiscountMethod") {
                                    return t(`DiscountMethod_value_${y[0]}`);
                                } else if (nameOfVertical === "DiscountType") {
                                    return t(`DiscountType_value_${y[0]}`);
                                } else {
                                    return implementLongStringInChart(valueHandler(typeOfVertical, nameOfVertical, y[0], type)?.toString().split(" "));
                                }
                            }
                            );

                            return labels;
                        }

                        break;

                    default:
                        break;
                }

                break;
            }

            case category_chart.TREEMAP: {
                if (reportNameSystem === "customers_by_rfm") {
                    return currentHead;
                } else {
                    return currentBody.map(value => value[0] || "--");
                }
            }

            case category_chart.STACKEDCOLUMN: {
                return currentBody?.map((y) => {
                    return y[0];
                });
            }

            case category_chart.BAR: {
                const labels = currentBody.map((row) => {
                    let value = row[0];

                    if (nameOfVertical === "CustomerId" && value === 'guest') {
                        value = t(value);
                    }

                    if (nameOfVertical === "CustomerAgeGroup") {
                        value = t(`CustomerAgeGroup_value_${value}`)
                    }

                    if (nameOfVertical === "CustomerGender") {
                        value = t(value)
                    }

                    return valueHandler(typeOfVertical, nameOfVertical, value, type);
                });


                return labels;
            }

            default:

                break;
        }
    }

    const buildData = (head, body) => {
        let renderData: any = [];
        const {
            current: currentBody,
            previous: previousBody,
            currentDataLink,
            previousDataLink
        } = body;

        const { current: currentHead, previous: previousHead } = head;

        const isCompare = checkCompare(previousBody)

        switch (displayChart) {
            // ["PageView"]
            case category_chart.STACKEDBAR: {
                currentBody.forEach((row, rowIndex) => {
                    let renderDataIndex = 0;

                    row.forEach((col, id) => {
                        const measure = measureSelected.find(
                            (m) => m.measureName === currentHead[id].name
                        );

                        if (measure !== undefined) {
                            if (measure?.isDraw) {
                                if (rowIndex === 0) {
                                    const getColours = colorsForPageViews[reportNameSystem][currentHead[id].name];

                                    renderData.push({
                                        name: t(measure.measureField),
                                        data: [col],
                                        hover: getColours.hover,
                                        color: getColours.color
                                    });
                                } else {
                                    renderData[renderDataIndex].data.push(col);
                                }

                                renderDataIndex++;
                            }
                        }
                    });
                });

                break;
            }

            case category_chart.DOUGHNUT: {
                const NetAmountValue = [
                    'NetSaleAmountByLocAssigned',
                    'NetSaleAmount',
                    "CustomerCountSpecial"
                ];

                if (currentHead && currentBody) {
                    let drawIndex = currentHead?.findIndex(
                        (h) => NetAmountValue.includes(h.name)
                    );

                    switch (reportNameSystem) {
                        case 'customers_new_and_return':
                            drawIndex = currentHead?.findIndex(
                                (h) => h.fieldName === optionMeasure.measureField
                            );

                            break;

                        default:
                            break;
                    }

                    if (drawIndex !== -1) {
                        const typeHorizon = currentHead[drawIndex].dataFormat;
                        setTypeHorizontal(typeHorizon);

                        // ! Trục Y
                        renderData = currentBody?.map(
                            (y) => typeHorizon === "percent" ? y[drawIndex] * 100 : y[drawIndex]
                        );

                        if (currentHead && currentHead?.length) {
                            setFieldName(t(currentHead[drawIndex].fieldName));
                        }
                    }
                }

                break;
            }

            case category_chart.GROUPEDCOLUMN: {
                let drawY = '';
                let drawX = currentHead[0]?.fieldName;

                if (reportNameSystem === "sales_overview_promotion") {
                    drawY = "TotalDiscount"
                };

                let drawIndex = currentHead?.findIndex((h) => h.name === drawY);
                let drawIndexInQuery = currentHead?.findIndex((h) => h.fieldName === drawY);
                let drawXInQuery = currentHead?.findIndex((h) => h.fieldName === drawX);
                if (drawIndex !== -1) {
                    if (isCompare) {
                        setIsGrouped(true);

                        const generateDataSet = (label, data, color) => ({
                            label,
                            data,
                            backgroundColor: color,
                            hoverBackgroundColor: color,
                            borderRadius: 6,
                            categoryPercentage: (currentBody?.length * 0.7) / LIMIT_GROUPED_BY_DISCOUNT,
                            barPercentage: 1.0
                        });

                        const processData = (data, index, multiplier) =>
                            data?.map((item) => item[index] * multiplier) || [];

                        const calculateData = (current, previous, index, multiplier) =>
                            current?.map((item) => {
                                const matchIndex = previous?.findIndex(prevItem => prevItem[drawXInQuery] === item[drawXInQuery]);
                                return matchIndex !== -1 ? previous[matchIndex][index] * multiplier : 0;
                            }) || [];

                        const isReversed = REVERSE_NEGATIVE_FIELD_NAME.includes(drawY) ? -1 : 1;

                        renderData = [
                            generateDataSet(
                                `${ShowDateRange(startdate, enddate)}`,
                                processData(currentBody, drawIndexInQuery, isReversed),
                                "#3C83F6"
                            ),
                            generateDataSet(
                                `${ShowDateRange(compareDateStart, compareDateEnd)}`,
                                calculateData(currentDataLink, previousDataLink, drawIndexInQuery, isReversed),
                                "#7ED4FC"
                            ),
                        ];
                    } else {
                        setIsGrouped(false);

                        if (drawIndex !== -1) {
                            // ! Trục Y
                            renderData = currentBody?.map((y) => y[drawIndexInQuery] * (REVERSE_NEGATIVE_FIELD_NAME.includes(drawY) ? -1 : 1));

                            if (currentHead && currentHead?.length) {
                                setFieldName(t(currentHead[drawIndex].fieldName));
                            }
                        }
                    }
                }


                break;
            }

            case category_chart.LINE: {
                if (reportNameSystem === "customers_new_and_return") {

                    const keys = ['Khách mới', 'Khách cũ', 'Khách vãng lai'];
                    const hiddenField = "CustomerType"; // Loại bỏ Loại Khách hàng khi vẽ chart

                    const findHiddenFieldIndex = currentHead.findIndex(y => y.name === hiddenField);
                    const result = {};

                    const removeWithKeyIndex = (array, index, key) => {
                        if (!array?.length) return undefined;

                        return array
                            .filter(value => value[index] === key)
                            .map(value => value.filter((_, i) => i !== index));
                    };

                    if (findHiddenFieldIndex !== -1) {
                        const buildHeader = (header) =>
                            header?.filter((_, i) => i !== findHiddenFieldIndex) || undefined;

                        keys.forEach(key => {
                            result[key] = {
                                current: {
                                    data: removeWithKeyIndex(currentBody, findHiddenFieldIndex, key),
                                    headerreport: buildHeader(currentHead)
                                },
                                previous: {
                                    data: removeWithKeyIndex(previousBody, findHiddenFieldIndex, key),
                                    headerreport: buildHeader(previousHead)
                                }
                            };
                        });
                    }

                    renderData = result;
                    setTypeVertical("Day")
                } else {
                    renderData = {
                        Day: {
                            current: {
                                data: currentBody,
                                headerreport: currentHead
                            },
                            previous: {
                                data: previousBody,
                                headerreport: previousHead

                            }
                        }
                    }
                }

                break;
            }

            case category_chart.TREEMAP: {
                if (reportNameSystem === "customers_by_rfm") {
                    renderData = currentBody
                } else {
                    renderData = currentBody.map(value => value[1]);
                }

                break;
            }

            case category_chart.STACKEDCOLUMN: {
                renderData = [];

                const dayOfWeek = [2, 3, 4, 5, 6, 7, 1];

                if (currentHead[0]?.name === "DayOfWeek") {
                    let renewData: any = [...currentBody];

                    // TH Là ngày trong tuần
                    let importDataFormDaysOfWeek = dayOfWeek.map((dow) => {
                        const findDayOfWeekInData = renewData?.findIndex(
                            (data) => data[0] === dow
                        );

                        if (findDayOfWeekInData === -1) {
                            if (currentBody[0] && currentBody[0]?.length) {
                                let fillArray = Array(
                                    currentBody[0].length - 2
                                ).fill(0);

                                return [dow, ...fillArray];
                            } else {
                                return [dow]
                            }
                        } else {
                            return currentBody[findDayOfWeekInData];
                        }
                    });

                    renderData = currentHead?.slice(1)?.map((item) => {
                        const index = currentHead.findIndex(
                            (headerItem) => headerItem.name === item.name
                        );

                        return {
                            name: t(item.name),
                            data: importDataFormDaysOfWeek.map((row) => row[index]),
                            color: colorsForShipments[reportNameSystem][item.name].color,
                        };
                    });
                } else {
                    // * TH Không là ngày trong tuần
                    renderData = currentHead?.slice(1)?.map((item) => {
                        const index = currentHead.findIndex(
                            (headerItem) => headerItem.name === item.name
                        );
                        return {
                            fieldName: item.name,
                            name: t(item.name),
                            data: currentBody.map((row) => row[index]),
                            color: colorsForShipments[reportNameSystem][item.name].color!,
                        };
                    });
                }

                renderData = mapOrder(
                    renderData,
                    xmlRenderCheckbox[reportNameSystem],
                    "fieldName"
                );
                break;
            }

            case category_chart.BAR: {
                renderData = currentBody.map(y => y[1] * (REVERSE_NEGATIVE_FIELD_NAME.includes(optionMeasure.measureField) ? -1 : 1));

                break;
            }

            default:
                break;
        }

        return renderData;
    }

    const buildChart = () => {
        // * Giá trị hiện tại
        const {
            data: dataCurrent,
            headerreport: headersCurrent,
            datalink: dataLinkCurrent
        } = dataChart?.current;

        // * Giá trị kỳ so sánh
        const {
            data: dataPeriod,
            headerreport: headersPrevious,
            datalink: dataLinkPreviod
        } = dataChart?.previous || {};

        let newLabels = [];
        let newData = [];

        // ! kiểm tra báo cáo có sử dụng tính năng so sánh theo kì
        const isComparePeriod = REPORT_OPEN_COMPARE.includes(reportNameSystem);

        const labelConfig = {
            current: headersCurrent,
            ...(isComparePeriod && { previous: headersPrevious })
        };

        const dataConfig = {
            current: dataCurrent,
            ...(isComparePeriod && { previous: dataPeriod })
        };

        const dataConfigWithLinks = {
            current: dataCurrent,
            ...(isComparePeriod && {
                currentDataLink: dataLinkCurrent,
                previous: dataPeriod,
                previousDataLink: dataLinkPreviod
            })
        };

        newLabels = buildLabels(labelConfig, dataConfig);
        newData = buildData(labelConfig, dataConfigWithLinks);

        setLabels(newLabels);
        setData(newData);

        if (reportNameSystem === "web_pageviews_month") {
            setFieldName(t("Lượt xem trang"));
            setTypeHorizontal(headersCurrent[1]?.dataFormat)
            setTypeVertical(headersCurrent[0]?.name)
        }
        if (reportNameSystem === "sales_overview_promotion") {
            const drawY = 'TotalDiscount';
            let drawIndex = headersCurrent?.findIndex((h) => h.name === drawY);
            let typeY = headersCurrent[drawIndex].dataFormat;

            setFieldName(t(headersCurrent[drawIndex].fieldName));
            setTypeVertical(headersCurrent[0]?.name);
            setTypeHorizontal(typeY);
        }

        if (reportNameSystem === "customers_by_location") {
            let drawIndex = headersCurrent?.findIndex((h) => h.name === 'CustomerCountSpecial');
            setTypeHorizontal(headersCurrent[drawIndex]?.dataFormat)
            setFieldName(t(headersCurrent[drawIndex].fieldName));
        }

        if (["order_shipments_overview_carriers_internal_speed", "order_shipments_detail_carriers_internal_speed"].includes(reportNameSystem)) {
            setFieldName(t("Thời gian xử lý"))
            setTypeHorizontal(headersCurrent[0]?.name);
        }

        if (reportNameSystem === "customers_by_rfm") {
            setFieldName(t("Số khách hàng"))
        }

        if (["sales_orders_staff"].includes(reportNameSystem)) {
            setTypeHorizontal(headersCurrent[1]?.dataFormat);
            setTypeVertical(headersCurrent[0]?.name);
        }

        if (
            !DRAW_BY_Y_AXIS_FOR_SPECIFIC_REPORTS.includes(reportNameSystem) || headersCurrent[0]?.dataFormat === 'timestamp'
        ) {
            setIndexAxis("x")
        } else {
            setIndexAxis("y")
        }

        setIsRender(true);
    }

    useEffect(() => {
        setIsRender(false);

        if (!isLoading) {
            buildChart();
        } else {
            setData([])
            return
        }
    }, [
        isLoading,
        displayChart,
        compareDateStart,
        compareDateEnd,
        optionMeasure,
        reportNameSystem
    ]);

    if (isLoading) {
        return {
            isRender: false,
            data,
            labels,
            fieldName,
            typeVertical,
            typeHorizontal,
            isGrouped,
            indexAxis
        }
    }

    return {
        isRender,
        data,
        labels,
        fieldName,
        typeVertical,
        typeHorizontal,
        isGrouped,
        indexAxis
    }
}