import { useTrans } from "locales/hook";
import { Box, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import TooltipWithTouch from "components/TooltipWithTouch";

interface IProps {
  name: string;
  createdDate?: string;
  id: any;
  tab: any;
  isSystemReport: boolean;
  reportNameSystem: string;
  isHasChild: boolean;
  childList?: Array<any>;
  onClickDetail: Function;
  icon: any
}

export default function ReportCard(props: IProps) {
  const t = useTrans();
  const overflowingText = useRef<any>()
  const [overflowActive, setOverflowActive] = useState<boolean>(false);

  const {
    name,
    id,
    tab,
    isSystemReport,
    isHasChild,
    childList,
    reportNameSystem,
    onClickDetail,
    icon
  } = props;

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [overflowActive]);

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer)
      return (
        textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth
      );
    return false;
  };

  const onClickHandler = () => {
    onClickDetail && onClickDetail(tab, id, reportNameSystem)
  };

  const onClickChildHandler = (tab, id) => {
    onClickDetail && onClickDetail(tab, id, reportNameSystem)
  };

  const nametag = () => {
    return (
      <div className="hrv-report-card__tag">
        <span>{t(tab)}</span>
      </div>
    );
  };

  const ContentCard = () => {
    return <div className="hrv-report-d-flex hrv-report-items-center">
      <span className="hrv-report-mr-16">
        {icon}
      </span>

      <div>
        {nametag()}

        <ComponentWrapperWithTooltip label={isSystemReport ? t(name) : name}>
          <p className="hrv-report-card__name hrv-report-card__name_fixed_height" ref={overflowingText}>
            {isSystemReport ? t(name) : name}
          </p>
        </ComponentWrapperWithTooltip>
      </div>
    </div>
  }

  const ComponentWrapperWithTooltip = ({ children, label }: any) => {
    return overflowActive ? <TooltipWithTouch label={label} hasArrow>{children}</TooltipWithTouch> : <>{children}</>
  }

  return (
    <>
      {childList ? <>  <Menu flip={true} placement="bottom-end">
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Box} w="100%"
              className="hrv-report-card hrv-report-cursor"
              onClick={(e) => {
                e.stopPropagation();

                if (!isHasChild) {
                  onClickHandler && onClickHandler();
                }
              }}
            >
              <ContentCard />
            </MenuButton>

            <MenuList className="hrv-report-scrollbar_custom" minW={"unset"} w={'164px'}>
              <div
                className="hrv-report-py-4 hrv-report-pr-4 hrv-report-pl-6 hrv-report-scrollbar_custom"
                style={{
                  height: "198px",
                  maxHeight: "198px",
                  overflowY: "auto",
                  width: "160px",
                }}
              >
                {childList &&
                  childList.map((item, idx) => {
                    return (
                      <MenuItem display="block" key={idx} onClick={() => {
                        onClickChildHandler && onClickChildHandler(item.tab, item.id);
                      }} p={0}>
                        <div className="hrv-report-card__popover-item">
                          <p>{t(item.name)}</p>
                        </div>
                      </MenuItem>
                    );
                  })}
              </div>
            </MenuList>
          </>
        )
        }
      </Menu>
      </> : <div className="hrv-report-card hrv-report-cursor hrv-report-w-full"
        onClick={() => {
          onClickHandler && onClickHandler();
        }}>
        <ContentCard />
      </div>}
    </>

  );
}
