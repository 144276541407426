import React, { useState } from "react";
import { Button, Checkbox } from "@chakra-ui/react";
import { CheckboxState } from ".";

export type Item = {
  id: number;
  name: string;
  parentId: number;
};

type TreeListProps = {
  items: Item[];
  idsToRender?: number[];
  indentLevel?: number;
  onClick?: (id: number) => void;
  getStateForId: (id: number) => CheckboxState;
  expandedKeys?: Array<string | number>;
  isMultiple?: boolean
  selectedId?: any
};

const TreeList: React.FC<TreeListProps> = ({
  items,
  getStateForId,
  idsToRender = [],
  indentLevel = 0,
  onClick = () => { },
  expandedKeys = [],
  isMultiple = false,
  selectedId = null
}: any) => {
  const [expandedList, setExpandedList] = useState(expandedKeys);

  const toggleExpand = (id) => {
    const findExpanedIndex = expandedList.findIndex(i => i === id);
    let expandedListNew = [...expandedList];

    if (findExpanedIndex === -1) {
      expandedListNew.push(id)
    } else {
      expandedListNew.splice(findExpanedIndex, 1)
    }

    setExpandedList(expandedListNew)
  };

  if (!idsToRender.length) {
    idsToRender = items.filter((i) => !i.parentId).map((i) => i.id);
  };

  const getChildNodes = (parentId: number) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    if (!nodeItems.length) return null;

    return (
      <TreeList
        items={items}
        idsToRender={nodeItems.map((i) => i.id)}
        indentLevel={indentLevel + 1}
        onClick={onClick}
        getStateForId={getStateForId}
        selectedId={selectedId}
        isMultiple={isMultiple}
      />
    );
  };

  const DropDown = (props) => {
    const { isRoot, isExpanded, item } = props;

    return <>
      {isRoot ? (
        <Button
          variant='default-tertiary'
          mr={2}
          className={`hrv-report-rotate-${isExpanded ? '90' : '0'}`}
          onClick={() => {
            toggleExpand(item.id)
          }}
          size={"s"}
        >
          <SvgDown />
        </Button>
      ) : (
        <span style={{ minWidth: !isRoot ? 30 : 0 }} />
      )}
    </>
  }

  const MultipleState = (props) => {
    const { checkboxState, item } = props;

    return <Checkbox
      isChecked={checkboxState === CheckboxState.CHECKED}
      isIndeterminate={checkboxState === CheckboxState.INDETERMINATE}
      onChange={(e) => onClick(item.id)}
    >
      {item.name}
    </Checkbox>
  }

  const SingleState = (props) => {
    const { item, checkboxState } = props;

    return <div
      className="
        hrv-report-py-6 
        hrv-report-px-8 
        hrv-report-w-full 
        hrv-report-cursor-pointer
        hrv-report-treeview-single-item
      "
      onClick={() => {
        onClick(item.id)
      }}
      style={{
        background: checkboxState === CheckboxState.CHECKED && +item.id === +selectedId ? "rgba(190, 219, 254, 0.3)" : "",
        borderRadius: 8
      }}
    >
      <p className="hrv-report-mb-0 hrv-report-text-color-black-6">{item.name}</p>
    </div>
  }

  return (
    <div >
      {idsToRender.map((id) => {
        const item = items.find((i) => i.id === id);
        const checkboxState = getStateForId(id);
        const isRoot = item.specialInfo.isRoot;
        const isDisplay = item.specialInfo.isDisplay;
        const isExpanded = expandedList.includes(item.id);

        return (
          <React.Fragment key={item.id}>
            <div
              className={`${!isDisplay ? "hrv-report-d-none" : "hrv-report-d-flex"} hrv-report-py-2 hrv-report-items-center`}
              style={{ paddingLeft: indentLevel * 20 }}
            >
              <DropDown
                isExpanded={isExpanded}
                item={item}
                isRoot={isRoot}
              />

              {isMultiple ?
                <MultipleState
                  item={item}
                  checkboxState={checkboxState}
                /> :
                <SingleState
                  item={item}
                  checkboxState={checkboxState}
                />}
            </div>

            {isExpanded && isRoot && getChildNodes(item.id)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default TreeList;

const SvgDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
    <path d="M0.912598 8.825L4.72926 5L0.912598 1.175L2.0876 0L7.0876 5L2.0876 10L0.912598 8.825Z" fill="#4B5563" />
  </svg>
);