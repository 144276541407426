export const defaultValue = [
    {
        id: 1,
        key: "r",
        property: "Recency",
        dataformat: "number",
        value: [
            0, 0, 0, 0, 0
        ]
    },
    {
        id: 2,
        key: "f",
        property: "Frequency",
        dataformat: "number",
        value: [
            0, 0, 0, 0, 0
        ]
    },
    {
        id: 3,
        key: "m",
        property: "Monetary",
        dataformat: "money",
        value: [
            0, 0, 0, 0, 0
        ]
    }
]

export const DEFAULT_TABS: any = [
    {
        id: 0,
        title: "R",
        heading: 'Recency (R)',
        description: [
            "lastes_order_time",
            "this_metric_measures_the_time_since_a_customer_last_purchase_until_now"
        ]
    },
    {
        id: 1,
        title: "F",
        heading: 'Frequency (F)',
        description: [
            "purchase_frequency",
            "this_index_measures_the_number_of_customer_orders_during_the_statistical_period"
        ]
    },
    {
        id: 2,
        title: "M",
        heading: 'Monetary (M)',
        description: [
            "Total_amount_spent_by_guests",
            "this_index_measures_the_total_net_revenue_brought_in_by_customers_during_the_statistical_period"
        ]
    }
];

export const POINT_SCALE_OPERATORS_RFM = {
    r: {
        1: "morethan",
        2: "fromto",
        3: "fromto",
        4: "fromto",
        5: "fromto"
    },
    f: {
        1: "lowerthan",
        2: "fromtobelow",
        3: "fromtobelow",
        4: "fromtobelow",
        5: "morethan"
    },
    m: {
        1: "lowerthan",
        2: "fromtobelow",
        3: "fromtobelow",
        4: "fromtobelow",
        5: "morethan"
    }
}

export const POINT_SCALE_COLORS_RFM = {
    1: {
        bgColor: "#fee1e1",
        borderColor: '#ef4343'
    },
    2: {
        bgColor: "#ffedd6",
        borderColor: '#f97415'
    },
    3: {
        bgColor: "#fef9c3",
        borderColor: '#e7b008'
    },
    4: {
        bgColor: "#ebfcca",
        borderColor: '#82cb15'
    },
    5: {
        bgColor: "#cbfbf0",
        borderColor: 'rgba(20, 184, 165, 1)'
    },
}
