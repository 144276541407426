import HTable from "components/HTable";
import { Row } from "./components";

import "./styles.scss";

interface IProps {
  dataRow?: number;
  dataColumn?: number;
  className?: string;
  displayHeader?: boolean;
}

const SkeletonTable = (props: IProps) => {
  const { dataRow = 5, dataColumn = 5, className = "", displayHeader = true } = props;
  const rows = (dataRow) => {
    return [...Array(dataRow)].map((i, idx) => (
      <Row key={`key_prop_row-${idx}`} dataColumn={dataColumn} />
    ));
  };

  return (
    <HTable
      className={`skeleton-table ${className}`}
      renderTableHeader={rows(displayHeader ? 1 : 0)}
      renderTableBody={rows(dataRow)}
    />
  );
};


export { SkeletonTable };
