// import { useEmbedApp } from "@haravan/reactapp";
import { SetPushInApp } from "helpers/helpers";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function useHistory() {
    // const { history } = useEmbedApp();
    const navigate = useNavigate();
    const navigator = useMemo(
        () => ({
            replace: (path) => navigate(path, { replace: true }),
            push: (path) => navigate(path, { replace: false, state: { propagator: true } }),
            pushInApp: (path) => navigate(path, { replace: true, state: { propagator: false } }),
            pushState: ({ pathName, state }) => navigate(pathName, { replace: false, state: { propagator: true, ...state } }),
        }),
        [navigate],
    );

    const push = (path) => {
        navigator.push(path);
    }

    const pushInApp = (path) => {
        SetPushInApp(true);
        navigator.pushInApp(path);
    }

    const pushState = (path, state?: any) => {
        navigator.pushState({
            pathName: path,
            state
        });
    }

    const replace = (path) => {
        navigate(path, { replace: true });
    }
    return { push, pushInApp, pushState, replace };
}