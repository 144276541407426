
function DragPlaceholder(props) {
    const { snapshot } = props;
    return (
        <div style={{
            position: "absolute",
            top: snapshot.clientY,
            left: snapshot.clientX,
            height: snapshot.clientHeight,
            width: snapshot.clientWidth,
            borderRadius: '4px',
            background: "rgba(17, 24, 39, 0.10)",
        }} />
    )
}

export default DragPlaceholder