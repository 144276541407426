import useApp from "hooks/useApp";
import { Outlet } from "react-router-dom";

const DashboardLayout = ({ children }: any) => {
  const { isLoading, isFullScreen } = useApp();

  return (
    <div
      className={`${isFullScreen
        ? "hrv-report-mt-24 hrv-report-mb-16 hrv-report-px-32"
        : `wrapper-container-embbed__new hrv-report-container__new`
        }`}
    >
      {!isLoading ? <Outlet /> : <div className="hrv-report-loading-main"></div>}
    </div>
  );
};

export default DashboardLayout;
