import { useTrans } from "locales/hook";
import EmptyTemplate from "components/EmptyTemplate";

// Độ dài trang bằng height chart + height legend

export const EmptyChart = (props) => {
    const t = useTrans();
    const { chartHeight, legendHeight } = props;

    return <div
        className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center"
        style={{
            height: `calc(${chartHeight + legendHeight}px)`
        }}
    >
        <EmptyTemplate
            text={t("no_data")}
            subText={t("change_fitler_hint")}
        />
    </div>
}