function Counting(props) {
  const { count } = props;

  return (
    <>
      {count ? (
        <div
          className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-mr-8 hrv-report-my-2 hrv-report-px-2"
          style={{
            border: "1px solid #91C3FD",
            background: "#F0F6FF",
            borderRadius: "50%",
            width: "fit-content",
            aspectRatio: "1 / 1",
            minWidth: "20px",
          }}
        >
          <span className="hrv-report-fs-12 hrv-report-text-color-blue-5 hrv-report-fw-600">
            {count}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Counting;
