
import ErrorSVG from "assets/images/error/error.svg";

import { useDetectPage } from "hooks/useDetectPage";
import {
    // useLocation,
    useNavigate,
    useRouteError
} from "react-router-dom";

import { Button } from "@chakra-ui/react";
import md5 from "helpers/md5";
import { useEffect } from "react";
import { formatDate } from "helpers/helpers";
import dayjs from "components/Dayjs";
import { create } from "firebase/config";

function ErrorPage() {
    useDetectPage("Page Error");
    let error: any = useRouteError(); // ! Get message when user have error
    const tradeId = String(md5(`Error_meesage_${Date.now()}`));
    const getENV = import.meta.env.VITE_APP_ENV;

    useEffect(() => {
        try {
            create(
                "report-error", {
                tradeId,
                errorMgs: error.message,
                created_at: formatDate(dayjs(), "DD/MM/YYYY HH:mm"),
                pathUrl: `${import.meta.env.VITE_APP_HARAVAN_CLIENT_NAME}${location.pathname}`,
                timestamp: String(Date.now()),
                env: getENV
            })
        } catch (error) {
            console.log(error)
        }
    }, [])


    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate("/listing", { replace: true, state: { propagator: true } });
    };

    return (
        <div className="wrapper-container-embbed">
            <div className="hrv-report-row hrv-report-px-8" style={{
                maxHeight: "100vh"
            }}>
                <div className="hrv-report-col-12 hrv-report-text-center">
                    <div className="hrv-report-mg-auto hrv-report-mt-90">
                        <ErrorSVG />
                    </div>

                    <div className="hrv-report-mg-auto hrv-report-text-center hrv-report-mt-16">
                        <h4 className="hrv-report-fs-18 hrv-report-fw-600 hrv-report-text-color-black-6 hrv-report-mt-10">
                            Đã có lỗi xảy ra
                        </h4>

                        <p className="hrv-report-fs-16 hrv-report-mb-0 hrv-report-mt-8 hrv-report-text-color-gray-9">
                            Vui lòng thử tải lại trang hoặc liên hệ bộ phận hỗ trợ.
                        </p>

                        <p className="hrv-report-fs-16 hrv-report-mt-8 hrv-report-text-color-gray-9">
                            TradeId: {tradeId}
                        </p>
                    </div>


                    <div className="hrv-report-text-center hrv-report-mt-16">
                        <Button variant={"solid-primary"} onClick={handleRedirect}>Trở lại Danh sách báo cáo</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
