import InventoryScreen from "assets/images/template/inventory-screen.png";
import InventorySVG from "assets/images/template/inventory-template.svg";

export const InventoryTemplate = {
    id: 5,
    name: "Quản lý tồn kho",
    description: "Theo dõi tình trạng hàng hóa, quản lý tồn kho và dự đoán nhu cầu để tối ưu hóa chuỗi cung ứng.",
    actions: ["preview"],
    icon: <InventorySVG />,
    template: [
        {
            "reportScreenId": 100741,
            "systemName": "sales_orders_salechannel",
            "description": "Số lượng đơn hàng, doanh thu thuần phân theo kênh bán (bao gồm cả online và offline)",
            "isDropdownMeasure": false,
            "widgetId": "1000000002",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 2,
                "x": 0,
                "y": 2,
                "i": "1000000002",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "SourceName",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "channel",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Doanh thu thuần - từ kênh bán hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100741,
            "systemName": "sales_orders_salechannel",
            "description": "Tỷ trọng đơn hàng phân theo kênh bán (bao gồm cả online và offline)",
            "isDropdownMeasure": false,

            "widgetId": "1000000009",
            "supportChartTypes": [
                "column",
                "horizontalbar",
                "doughnut"
            ],
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 1,
                "i": "1000000009",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                },
                {
                    "measureName": "GrossSaleAmount",
                    "measureField": "GrossSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "PaidAmount",
                    "measureField": "PaidAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ReturnsAmount",
                    "measureField": "ReturnsAmount",
                    "isDisplay": false,
                    "isSelected": false
                },
                {
                    "measureName": "ActuallyReceived",
                    "measureField": "ActuallyReceived",
                    "isDisplay": false,
                    "isSelected": false
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "SourceName",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "SalesChannelBranchId",
                    "fieldName": "SalesChannelBranchId",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "doughnut",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Tỷ trọng đơn hàng theo kênh bán",
            "isCompare": false,
            "isAllowConfig": true,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Tổng số lượng đơn hàng từ tất cả các kênh bán hàng",
            "isDropdownMeasure": false,

            "widgetId": "1000000001",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 0,
                "y": 4,
                "i": "1000000001",
                "minW": 4,
                "maxW": 8,
                "minH": 3,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "Date",
                    "fieldName": "Date",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "line",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Lượng đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100738,
            "systemName": "sales_orders_product",
            "description": "Top các sản phẩm có số lượng bán hoặc doanh thu thuần ghi nhận cao nhất. Tính từ tất cả các kênh bán hàng",
            "isDropdownMeasure": true,

            "widgetId": "1000000005",
            "supportChartTypes": null,
            "template": {
                "w": 4,
                "h": 3,
                "x": 4,
                "y": 4,
                "i": "1000000005",
                "minW": 3,
                "maxW": 8,
                "minH": 2,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": true,
                    "isSelected": false
                },
                {
                    "measureName": "NetSaleAmount",
                    "measureField": "NetSaleAmount",
                    "isDisplay": true,
                    "isSelected": true
                }
            ],
            "groupProperties": [
                {
                    "id": 0,
                    "name": "ProductId",
                    "fieldName": "ProductId",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                },
                {
                    "conjunction": "or",
                    "dimension": "saleitemtype",
                    "query": [
                        {
                            "conjunction": "or",
                            "displayText": "Phân loại là Sản phẩm",
                            "query": "Sản phẩm",
                            "symbol": "is",
                            "value": "product"
                        }
                    ]
                }
            ],
            "chartType": "product",
            "isDisplay": true,
            "isQuery": true,
            "isSummary": false,
            "typeName": "sales",
            "reportName": "Sản phẩm bán chạy",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 5
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số đơn hàng được tạo trong kỳ thống kê. Dữ liệu này không bao gồm đơn huỷ",
            "isDropdownMeasure": false,

            "widgetId": "1000000021",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 0,
                "i": "1000000021",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "OrderCountSpecial",
                    "measureField": "OrderCountSpecial",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Số đơn hàng",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số lượng đơn hàng trung bình/ngày = Số đơn hàng (/) Số ngày trong kỳ thống kê",
            "isDropdownMeasure": false,

            "widgetId": "1000000033",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 2,
                "y": 0,
                "i": "1000000033",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "AvgCountOrderDate",
                    "measureField": "AvgCountOrderDate",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "sales",
            "reportName": "Số đơn hàng trung bình/ngày",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100730,
            "systemName": "stock_onhand",
            "description": "Giá trị hàng trong kho dựa trên giá vốn. Giá trị tồn = Số lượng tồn (*) Giá vốn hiện tại. Dữ liệu tính đến ngày hiện tại",
            "isDropdownMeasure": false,

            "widgetId": "1000000042",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 6,
                "y": 0,
                "i": "1000000042",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Qty_Value",
                    "measureField": "Qty_Value",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Giá trị tồn",
            "isCompare": false,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100730,
            "systemName": "stock_onhand",
            "description": "Số lượng sản phẩm tồn trong kho. Dữ liệu tính đến ngày hiện tại",
            "isDropdownMeasure": false,

            "widgetId": "1000000041",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 4,
                "y": 0,
                "i": "1000000041",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Qty_OnHand",
                    "measureField": "Qty_OnHand",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": false,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": null,
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Số lượng tồn",
            "isCompare": false,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Là số sản phẩm đã bán được trong kỳ thống kê. Nếu 1 mã sản phẩm được bán với số lượng 10, thì số sản phẩm đã bán được tính là 10. Dữ liệu này không bao gồm đơn huỷ",
            "isDropdownMeasure": false,

            "widgetId": "1000000038",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 0,
                "y": 1,
                "i": "1000000038",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "Quantity",
                    "measureField": "Quantity",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Số sản phẩm đã bán",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        },
        {
            "reportScreenId": 100755,
            "systemName": "sales_transaction_by_month",
            "description": "Số sản phẩm trung bình/ngày = Số lượng sản phẩm đã bán (/) Số ngày trong kỳ thống kê",
            "isDropdownMeasure": false,

            "widgetId": "1000000039",
            "supportChartTypes": null,
            "template": {
                "w": 2,
                "h": 1,
                "x": 2,
                "y": 1,
                "i": "1000000039",
                "minW": 2,
                "maxW": 8,
                "minH": 1,
                "maxH": 100,
                "moved": false,
                "static": false
            },
            "measures": [
                {
                    "measureName": "AvgQuantityPerDay",
                    "measureField": "AvgQuantityPerDay",
                    "isDisplay": false,
                    "isSelected": true
                }
            ],
            "groupProperties": [],
            "filters": [
                {
                    "id": 0,
                    "name": "Location",
                    "fieldName": "LocationId",
                    "isSelected": true,
                    "isDisplay": false
                },
                {
                    "id": 0,
                    "name": "Channel",
                    "fieldName": "SourceName",
                    "isSelected": true,
                    "isDisplay": false
                }
            ],
            "filterDataDefaults": [
                {
                    "conjunction": "and",
                    "dimension": "OrderStatusId",
                    "query": [
                        {
                            "conjunction": "and",
                            "displayText": "Trạng thái đơn hàng không là Hủy",
                            "query": "Hủy",
                            "symbol": "isnot",
                            "value": "3"
                        }
                    ]
                }
            ],
            "chartType": "number",
            "isDisplay": true,
            "isQuery": false,
            "isSummary": true,
            "typeName": "inventory",
            "reportName": "Số sản phẩm trung bình/ngày",
            "isCompare": true,
            "isAllowConfig": false,
            "isPermission": true,
            "orderNumber": 0,
            "pagination": {
                "offset": 0,
                "limit": 100
            },
            "order_by": {
                "dimension": "day",
                "direction": "desc"
            }
        }
    ],
    previewUrl: InventoryScreen
};