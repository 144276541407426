import { memo, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { color } from 'chart.js/helpers';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';
import { getOrCreateTooltip } from 'helpers/chartutils';

// ! Custom hooks
import useWindowDimensions from 'hooks/useWindowDimension';
import { FormatMoneyLocale, formatNumber } from 'helpers/helpers';

// * Register chart
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TreemapController,
    TreemapElement
);

const HChart: any = Chart;

/**
 * 
 * @param data Array<{ name: string, value: string | number }> 
 * @param type Enum <"single" | "multiple">
 * @param configHeight number 
 * @returns 
 */

interface ITreemapProps {
    data: Array<any>
    type?: "single" | 'multiple'
    configHeight: number
    chartColor: string
    fieldName: string
    typeHorizonal: string
    width?: any
}

function TreemapChart(props: ITreemapProps) {
    const {
        data = [],
        configHeight,
        chartColor,
        fieldName,
        typeHorizonal,
    } = props;

    const { width: widthScreen } = useWindowDimensions();
    const refChart = useRef<any>();
    const values = Boolean(data?.length) ? data?.map(y => y.value) : [""];
    const maxValue = Math.max(...values);

    if (!Boolean(maxValue)) {
        return <>
            <p>Vui lòng thử lại</p>
        </>
    };

    const implementUnit = (value: string | number, typeHorizonal: string) => {
        let data = value;

        if ("money" === typeHorizonal) {
            data = `${FormatMoneyLocale(value)}`;
        }

        if (typeHorizonal === "percent") {
            data = formatNumber(value, false) + "%";
        }

        if (typeHorizonal === "hour") {
            data = "0";
        }

        if (typeHorizonal === "number") {
            data = formatNumber(value, false);
        }

        if (typeHorizonal === "") {
            data = "0";
        }

        return data
    }

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        };

        const dataIndex = tooltip?.dataPoints[0]?.dataIndex
        const { value, name } = tooltip?.dataPoints[0]?.dataset?.data[dataIndex]._data

        if (tooltip.body) {
            const bodyLines = tooltip.body.map((b) => b.lines);
            const tableHead = document.createElement("thead");

            // * Config title
            const tr = document.createElement("tr");
            tr.style.borderWidth = "0px";
            const th = document.createElement("th");
            th.style.borderWidth = "0px";
            th.style.paddingBottom = "8px";
            th.style.fontSize = "14px";
            th.style.fontWeight = "500";
            let text = document.createTextNode(name);

            th.appendChild(text);
            th.classList.add("tooltip-container-header");
            th.classList.add("hrv-report-px-12");
            th.classList.add("hrv-report-pt-10");
            tr.appendChild(th);
            tableHead.appendChild(tr);

            const tableBody = document.createElement("tbody");

            bodyLines.forEach((_, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement("span");
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.marginRight = "10px";
                span.style.height = "12px";
                span.style.width = "12px";
                span.style.borderRadius = "2px";
                span.style.display = "inline-block";

                const tr = document.createElement("tr");
                tr.style.backgroundColor = "inherit";
                tr.style.borderWidth = "0px";

                const td = document.createElement("td");
                td.style.borderWidth = "0px";

                const labelCurrentContainer = document.createElement("div");
                labelCurrentContainer.classList.add("hrv-report-d-flex");
                labelCurrentContainer.classList.add("hrv-report-mb-10");
                labelCurrentContainer.classList.add("hrv-report-w-100");
                labelCurrentContainer.classList.add("hrv-report-px-12");

                labelCurrentContainer.innerHTML = `
                <div class="hrv-report-d-flex hrv-report-items-center hrv-report-w-100">
                  ${span?.outerHTML || ""}
                  <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-mr-20">${fieldName}</div>
                  <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-ml-auto">${implementUnit(value, typeHorizonal)}</div>
                </div>
              `;

                td.appendChild(labelCurrentContainer);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector("table");

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);

            tooltipEl.style.opacity = 1;

            const { x, y } = chart.tooltip._eventPosition;

            const widthOfTooltip = tooltipEl.clientWidth;
            const heightOfTooltip = tooltipEl.clientHeight;

            // * Độ rộng của 1 wrapper bọc bên ngoài chart (có thể là widget hoặc 1 box nào đó ???)
            let widthOfWrapperChart = 0;

            if (refChart && refChart.current) {
                widthOfWrapperChart = refChart.current.clientWidth;
            }

            //* Tooltip for Desktop
            if (widthScreen > 758) {
                let isFloatRight = x + widthOfTooltip > widthOfWrapperChart - x / 2;
                const diff = 40 * 2;
                let isBottom = y + heightOfTooltip > configHeight;

                if ((widthOfTooltip > widthOfWrapperChart / 2) && (x < widthOfWrapperChart / 2)) {
                    isFloatRight = false
                };

                if (isFloatRight) {
                    // Bên phải
                    tooltipEl.style.left = x - (widthOfTooltip / 2 + diff) + "px";
                } else {
                    // Bên trái
                    tooltipEl.style.left = x + (widthOfTooltip / 2 - diff) + "px";
                }

                if (isBottom) {
                    tooltipEl.style.top = y - heightOfTooltip + "px";
                } else {
                    tooltipEl.style.top = y + 40 + "px";
                }


                tooltipEl.style.font = tooltip.options.bodyFont.string;
            }

            // * Tooltip for Mobile
            else {
                tooltipEl.style.left = tooltipEl.offsetWidth / 2 + "px";
                tooltipEl.style.top = configHeight / 2 + "px";
            }
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: "cursor",
                external: externalTooltipHandler,
            },
        },

    };

    // * Split string to fit width
    // function splitLabelToFit(label: string, maxWidth: number, ctx: any) {
    //     const words = label.split(' ');
    //     const lines: any = [];
    //     let currentLine: any = '';

    //     for (let i = 0; i < words.length; i++) {
    //         const word = words[i];
    //         const newLine = currentLine + ' ' + word;
    //         const width = ctx.chart.ctx.measureText(newLine).width;

    //         if (width < maxWidth) {
    //             currentLine = newLine;
    //         } else {
    //             lines.push(currentLine);
    //             currentLine = word;
    //         }
    //     }
    //     lines.push(currentLine);
    //     return words;
    // }

    const config = {
        type: 'treemap',
        data: {
            datasets: [
                {
                    tree: data,
                    key: 'value',
                    labels: {
                        display: true,
                        align: "left",
                        position: "top",
                        overflow: "fit",
                        formatter: (context) => context.raw._data.name,
                        padding: 5,
                        font: {
                            size: 12,
                            weight: '600'
                        },

                    },
                    borderColor: "#BEDBFE",
                    borderWidth: 0.5,
                    spacing: 0,
                    backgroundColor(context) {
                        if (context.type !== 'data') return 'transparent';

                        function calculateAlpha(value, maxValue) {
                            const shadeIntensity = 1.25;
                            return (value / maxValue) * shadeIntensity;
                        };

                        function colorFromRaw(ctx) {
                            if (ctx.type !== 'data') {
                                return 'transparent';
                            }
                            const value = ctx.raw.v;

                            const alpha = calculateAlpha(value, maxValue);
                            return color(chartColor).alpha(alpha).rgbString()
                        }

                        return colorFromRaw(context);
                    },
                },
            ],
        },
    };

    return <div
        style={{
            height: configHeight
        }}
        ref={refChart}
    >
        <HChart
            type="treemap"
            data={config.data}
            options={options}
            height={"100%"}
        />
    </div>;
}

export default memo(TreemapChart)