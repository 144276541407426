import useSWR from "swr";
import { useFetchJson } from "@haravan/reactapp";

import md5 from "helpers/md5";
import { xmlRenderCheckbox } from "helpers/helpers";

const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES + "/";
const REPORT_CALL_URI_V2 = REPORT_URI + `reports/`;

interface IInput {
    id: string | number | undefined
    name: string
    body: any
    preventFetch: boolean
}

// && Referecence: https://swr.vercel.app/docs/conditional-fetching.en-US#conditional
export function useGetDataToDrawChart(input: IInput) {
    const { id, name, body, preventFetch = false } = input;
    const { metrics, filters, groupby, startdate, enddate, optionMeasure } = body;

    const fetchJson = useFetchJson();

    const useHRVSWR: any = useSWR;

    let payload = {};
    let apiurl = "";

    if (!preventFetch) {
        switch (name) {
            case "order_shipments_detail_carriers_internal_speed":
            case "order_shipments_overview_carriers_internal_speed":
                const getMetricForChart = metrics.filter((value) => {
                    return xmlRenderCheckbox[name].includes(value.measureName);
                });

                payload = {
                    filters,
                    groupby: [groupby[0]],
                    metrics: getMetricForChart,
                    order_by: {
                        dimension: groupby[0],
                        direction: "desc",
                    },
                    daterange: {
                        startdate: startdate,
                        enddate: enddate,
                    },
                    pagination: { offset: 0, limit: 15 },
                    // Hiện tại limit chỉ cho lấy 15 records
                };

                break;

            case "sales_orders_salechannel_branch":
                payload = {
                    filters,
                    groupby: ["sourcename"],
                    metrics: [
                        {
                            measureField: "NetSaleAmount",
                            measureName: "NetSaleAmount",
                        },
                    ],
                    order_by: {
                        dimension: "NetSaleAmount",
                        direction: "desc",
                    },
                    daterange: {
                        startdate: startdate,
                        enddate: enddate,
                    },
                    pagination: { offset: 0, limit: 100 },
                    // Hiện tại limit chỉ cho lấy 12 records
                };

                break;

            case "customers_new_and_return":
                payload = {
                    filters,
                    groupby: ["customertype"],
                    metrics: [
                        {
                            measureField: optionMeasure.measureField,
                            measureName: optionMeasure.measureName
                        },
                    ],
                    order_by: {
                        dimension: "customertype",
                        direction: "asc",
                    },
                    daterange: {
                        startdate,
                        enddate,
                    },
                    pagination: { offset: 0, limit: 100 },
                };

                break;

            case "customers_by_location":
                payload = {
                    filters,
                    groupby: ["customerprovinceid"],
                    metrics: [
                        {
                            measureField: "CustomerCountSpecial",
                            measureName: "CustomerCountSpecial"
                        },
                    ],
                    order_by: {
                        dimension: "customerprovinceid",
                        direction: "asc",
                    },
                    daterange: {
                        startdate,
                        enddate,
                    },
                    pagination: { offset: 0, limit: 100 },
                };

                break;

            default:
                break;
        }

        const key = md5(JSON.stringify(payload));
        apiurl = `${REPORT_CALL_URI_V2}${id}/query?key=${key}`;
    }

    const fetcher = (url: string) =>
        fetchJson(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        });


    const { data, isLoading, mutate } = useHRVSWR(
        preventFetch ? null : apiurl,
        fetcher,
        {
            revalidateOnFocus: false,
            refreshInterval: 0,
        },
    );

    return { data, isLoading, mutate }
}