import { EffectCallback, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTrans } from "locales/hook";
import { useAutoCompleteV2 } from "components/Filter/swr/useAutocomplete";

// ! Components
import { Popover, PopoverContent, PopoverTrigger, PopoverBody, Tag, TagCloseButton, TagLabel, Box, PopoverFooter, Button, Input, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import HChip from "components/Chip";
import SelectionMulti from "components/Filter/components/selectionMulti";
import HLimitLongString from "components/LimitLongString";
import HSelection from "components/Selection";
import TreeContainer from "../Filter/components/TreeContainer/container";

// ! Helper
import { FormatDecimalQuantity, middleEllipsisHandler, xmlInputWithFieldName } from "helpers/helpers";

const KEY_ID = 'chip_group_';

interface IProps {
    onHandleClose: any;
    optionsDimension: Array<any>;
    reportId: any;
    dateEnd: any;
    dateStart: any;
    onHandleApplyFilterInChip: Function;
    query: any;
    idx: any;
    disableFilterField?: Array<any>;
}

const DEFAULT_QUERY_TEMPLATE = {
    conjunction: "or",
    displayText: "",
    query: "",
    symbol: "is",
    value: "",
}

const DEFAULT_ERRORS = {
    from: "",
    to: ""
}

function ChipGroup(props: IProps) {
    const {
        query,
        idx,
        onHandleClose,
        optionsDimension,
        reportId,
        dateEnd,
        dateStart,
        onHandleApplyFilterInChip,
        disableFilterField
    } = props;

    const { isOpen, onClose, onOpen } = useDisclosure();

    const [operators, setOperators] = useState<any>([]);
    const [operatorSelected, setOperatorSelected] = useState("is");
    const [dimensionOpen, setDimensionOpen] = useState("");
    const [keyword, setKeyword] = useState<any>("");
    const [defaultQuery, setDefaultQuery] = useState<any>([]);

    const [valueInText, setValueInText] = useState("");
    const [valueInNumber, setValueInNumber] = useState("");
    const [enableClickOutside, setEnableClickOutside] = useState(true);
    const [valueInRangeTo, setValueInRangeTo] = useState("");

    const [errorMgs, setErrorMgs] = useState(DEFAULT_ERRORS)
    const [isDisabledButton, setIsDisabledButton] = useState(false)

    const { data, isLoading } = useAutoCompleteV2({
        id: reportId,
        body: {
            enddate: dateEnd,
            startdate: dateStart,
            dimension: dimensionOpen,
            keyword: keyword || "",
        },
        preventFetch: !dimensionOpen.length
    });

    const t = useTrans();
    const containerRef = useRef<any>();

    useEffect(() => {
        handleAutoChangeOperatorWhenChangeDimension(dimensionOpen);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensionOpen]);

    const useEffectGetQuery = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(effect, [dimensionOpen])
    };

    useEffectGetQuery(() => {
        if (xmlInputWithFieldName(dimensionOpen) === "treeListWithSingle") {
            let cloneQuery = [...defaultQuery];

            cloneQuery = cloneQuery.map(query => {
                const findSelectedSingleTree = data.data.find(y => y.id === query.value)

                return {
                    ...query,
                    fullName: findSelectedSingleTree?.specialInfo?.fullName || ""
                }
            });

            setDefaultQuery(cloneQuery)
        }

        if (xmlInputWithFieldName(dimensionOpen) === "numberField") {
            if (defaultQuery[0]?.value?.length && defaultQuery[0]?.value.includes("|")) {
                let [from, to] = defaultQuery[0]?.value.split("|")

                setValueInNumber(from)
                setValueInRangeTo(to)

                buildQueryForInput(`${from}|${to}`, 'range');
            } else {
                setValueInNumber(defaultQuery[0]?.value);

                buildQueryForInput(defaultQuery[0]?.value, 'number');
            }
        }
    });

    useEffect(() => {
        if (!errorMgs?.from?.length && !errorMgs?.to?.length) {
            setIsDisabledButton(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMgs])

    useOutsideClick({
        ref: containerRef, handler: (e: any, ...restProps) => {
            if (!isOpen) return;
            // onClose();

            handleClose();
        },
        enabled: enableClickOutside
    })

    const mappingQuery = (query) => {
        const arrayString = query.map(qu => t(qu.query))
        return arrayString.join(", ")
    }

    const handleAutoChangeOperatorWhenChangeDimension = (currentDimension) => {
        if (currentDimension && currentDimension.length) {
            const dimen = optionsDimension.find(
                (y) => y.fieldName === currentDimension
            );

            if (JSON.stringify(operators) !== JSON.stringify(dimen?.acceptOperators)) {
                setOperators(dimen.acceptOperators);
            }
        }
    };

    const handleChooseMultipleQuery = (query) => {
        const { check, name, value } = query;
        const cloneQuery: any = [...defaultQuery];

        let final: any = [];

        if (check) {
            const findItem = data.data.find(y => y.value === value);

            cloneQuery.push({
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${findItem.displayname_vi}`,
                query: name,
                value: findItem.value,
            })


            final = cloneQuery
        } else {
            const findIndex = defaultQuery.findIndex(y => y.query === name);

            cloneQuery.splice(findIndex, 1);

            final = cloneQuery;
        }

        setDefaultQuery(final)
    }


    const handleChooseSingleQuery = (query) => {
        const { name, value } = query;

        const findItem = data.data.find(y => y.value === value);

        const final = [
            {
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${findItem.displayname_vi}`,
                query: name,
                value: findItem.value,
            }
        ]
        setDefaultQuery(final)
    }

    const handleChooseTreeViewCheckbox = (list) => {
        let final = list.map(item => {
            return {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${item.displayname_vi}`,
                query: item.name,
                value: item.value,
                fullName: item.specialInfo.fullName
            }
        });

        setDefaultQuery(final)
    }

    // const buildQueryForInput = (value, type) => {
    //     const cloneQuery = [...defaultQuery];

    //     const isExistInArray = cloneQuery.findIndex(y => slugify(y.value) === slugify(value));

    //     if (isExistInArray === -1 || !cloneQuery.length) {
    //         if (type === 'string') {
    //             cloneQuery.push({
    //                 ...DEFAULT_QUERY_TEMPLATE,
    //                 conjunction: operatorSelected === 'is' ? 'or' : "and",
    //                 symbol: operatorSelected,
    //                 displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value}`,
    //                 query: value,
    //                 value,
    //             })
    //         }

    //         if (type === 'number') {
    //             cloneQuery[0] = {
    //                 ...DEFAULT_QUERY_TEMPLATE,
    //                 conjunction: operatorSelected === 'is' ? 'or' : "and",
    //                 symbol: operatorSelected,
    //                 displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value || "0"}`,
    //                 query: value || 0,
    //                 value: value === 0 ? 0 : `${FormatDecimalQuantity(value || 0)}`,
    //             }
    //         }

    //         if (type === 'range') {
    //             cloneQuery[0] = {
    //                 ...DEFAULT_QUERY_TEMPLATE,
    //                 conjunction: operatorSelected === 'is' ? 'or' : "and",
    //                 symbol: operatorSelected,
    //                 displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value}`,
    //                 query: value,
    //                 value: value,
    //             }
    //         }
    //     }
    // }

    const buildQueryForInput = useCallback((value, type) => {
        let cloneQuery = [...defaultQuery];
        if (type === 'string') {
            // ! Xử lý khi dữ liệu input vào thuộc type là String (thêm query đó vào)
            cloneQuery.push({
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value}`,
                query: value,
                value,
            })
        }

        if (type === 'number') {
            // ! Xử lý khi dữ liệu input vào thuộc type là Number (thay thế query đó)
            cloneQuery[0] = {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: operatorSelected,
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value || "0"}`,
                query: value || "0",
                value: value === 0 ? 0 : `${FormatDecimalQuantity(value || 0)}`,
            }
        }

        if (type === 'range') {
            // ! Xử lý khi dữ liệu input vào thuộc type là Range (Biến đổi kiểu dữ liệu)
            cloneQuery[0] = {
                ...DEFAULT_QUERY_TEMPLATE,
                conjunction: operatorSelected === 'is' ? 'or' : "and",
                symbol: 'range',
                displayText: `${t(dimensionOpen)} ${t(operatorSelected)} ${value}`,
                query: value,
                value: value,
            }
        }

        setDefaultQuery(cloneQuery)

        setTimeout(() => {
            setValueInText("");
        }, 60);
    }, [operatorSelected, t]);


    const handleChangeOperator = (operator) => {
        const changeOperatorInQuery = [...defaultQuery];

        if (operator !== 'range') {
            setValueInRangeTo("")
        }

        let final = changeOperatorInQuery.map(query => {
            let fixQuery = query.query;
            let fixValue = query.value;

            if (operator !== 'range') {
                fixQuery = query.query.includes("|") ? query.query.split("|")[0] : query.query;
                fixValue = query.value.includes("|") ? query.value.split("|")[0] : query.value;
            }

            return {
                ...query,
                symbol: operator,
                displayText: `${t(dimensionOpen)} ${t(operator)} ${fixValue}`,
                conjunction: operator === 'is' ? 'or' : "and",
                query: fixQuery,
                value: fixValue
            }
        });
        setDefaultQuery(final);

        setValueInText("");

        setErrorMgs(DEFAULT_ERRORS);
    }

    const handleClose = () => {
        // ! Clear State
        setDimensionOpen("");
        setOperatorSelected("");
        setDefaultQuery([]);
        onClose();
    }

    const findFilterName = useMemo(() => {
        return optionsDimension?.find(y => y.fieldName === query.dimension)?.filterName || ""

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.dimension])

    const tagNameHandler = (query) => {
        let symbol = query?.query[0]?.symbol;
        let value = '';

        if (symbol === 'range') {
            let [from, to] = query.query[0]?.value?.split("|");

            value = `${t('from')} ${from} ${t('to')} ${to}`
        } else {
            value = mappingQuery(query.query)
        }

        return `${t(findFilterName)} ${t(symbol)} ${value}`
    }

    const handleCheckValidData = () => {
        let messages: any = {};

        if (valueInNumber === null || typeof valueInNumber === 'undefined' || !valueInNumber.length) {
            messages = {
                ...errorMgs,
                from: t("Not_be_empty")
            }

            setIsDisabledButton(true)
        }

        if (valueInRangeTo === null || typeof valueInRangeTo === 'undefined' || !valueInRangeTo.length) {
            messages = {
                ...errorMgs,
                to: t("Not_be_empty")
            }

            setIsDisabledButton(true)

        }

        if (parseInt(valueInNumber) > parseInt(valueInRangeTo)) {
            messages = {
                ...errorMgs,
                to: t("not_less_than_from")
            }

            setIsDisabledButton(true)
        }

        setErrorMgs(messages)
    }

    return (
        <div key={idx} ref={containerRef}>
            <Popover
                isOpen={isOpen}
                placement={"bottom-start"}
                isLazy
                autoFocus={false}
                variant="report-filter"
                flip={true}
                closeOnBlur={true}
                closeOnEsc={true}
            >
                {!!query.query.length ? <PopoverTrigger >
                    <Tag
                        key={`${KEY_ID}${idx}`}
                        size="lg"
                        variant='solid'
                        p={4}
                    >
                        <TagLabel fontSize={'14px'} lineHeight={"20px"} fontWeight={600} _hover={{
                            cursor: "pointer",
                            color: '#333333'
                        }}
                            style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                            }}
                            onClick={() => {
                                const getPopoverOpen = document.querySelector(".chakra-popover__content")
                                const isExistPopupOpen = getPopoverOpen !== null

                                if (!isExistPopupOpen) {
                                    setEnableClickOutside(true)

                                    if (isOpen) {
                                        handleClose();
                                    } else {
                                        if (disableFilterField?.includes(query.dimension)) return;

                                        onOpen();
                                        setDimensionOpen(query.dimension)
                                        setOperatorSelected(query?.query[0]?.symbol)
                                        setDefaultQuery(query.query)
                                    }
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            <HLimitLongString
                                placement="bottom"
                                lengthText={60}
                                customClass="hrv-report-m-0 hrv-report-dotted-1-line hrv-chip-tag"
                                notDisplayTooltip={false}
                                text={`${tagNameHandler(query)}`}
                            />
                        </TagLabel>

                        <TagCloseButton
                            onClick={() => {
                                onHandleClose && onHandleClose(query.dimension);

                                handleClose();
                            }}
                            _focusVisible={{
                                outline: "none",
                            }}
                            _focus={{
                                outline: "none",
                            }}
                        />
                    </Tag>
                </PopoverTrigger> : null}

                {isOpen ? <PopoverContent w={440} zIndex={20} maxW={"100vw"} >
                    <PopoverBody p={8} maxW={"100vw"} >
                        <Box display="flex" alignItems='center' mb={4} >
                            <Box mr={4}>
                                {t(findFilterName)}
                            </Box>

                            <Box>
                                <HSelection
                                    key={`symbol_${query.dimension}`}
                                    dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                                    isOptGroup={false}
                                    options={operators}
                                    selectKey={query.dimension}
                                    name="symbol"
                                    value="symbol"
                                    defaultValue={operatorSelected}
                                    disabled={!operators.length}
                                    onChange={(value) => {
                                        setOperatorSelected(value)

                                        handleChangeOperator(value);

                                        if (value === 'range') {
                                            handleCheckValidData()
                                        }
                                    }}
                                    matchWidth={false}
                                    minW={150}
                                />
                            </Box>
                        </Box>


                        <Box >
                            {xmlInputWithFieldName(dimensionOpen) === "inputMultipleSearchDB" ? <SelectionMulti
                                label={`${idx}_autocomplete`}
                                options={data ? data?.data : []}
                                placeholder={t("search")}
                                name="displayname_vi"
                                valueName="value"
                                onChangeSearch={(keyword) => {
                                    setKeyword(keyword);
                                }}
                                isLoading={isLoading}
                                withFetchAPI={true}
                                isMultiple={true}
                                chooseList={defaultQuery}
                                onChoose={handleChooseMultipleQuery}
                                onOpenSearch={(enable) => {
                                    setEnableClickOutside(!enable)
                                }}
                            /> : null}

                            {/** Gọi API */}
                            {xmlInputWithFieldName(dimensionOpen) === "inputSingleSearchDB" ? (
                                <SelectionMulti
                                    label={`${idx}_autocomplete`}
                                    options={data ? data?.data : []}
                                    placeholder={t("search")}
                                    name="displayname_vi"
                                    valueName="value"
                                    onChangeSearch={(keyword) => {
                                        setKeyword(keyword);
                                    }}
                                    isLoading={isLoading}
                                    withFetchAPI={true}
                                    isMultiple={true}
                                    chooseList={defaultQuery}
                                    onChoose={handleChooseSingleQuery}
                                    index={idx}
                                    onOpenSearch={(enable) => {
                                        setEnableClickOutside(!enable)
                                    }}
                                />
                            ) : null}


                            {xmlInputWithFieldName(dimensionOpen) === "inputMultipleSearchLocal" ? <SelectionMulti
                                label={`${idx}_autocomplete`}
                                options={data ? data?.data : []}
                                placeholder={t("search")}
                                name="displayname_vi"
                                valueName="value"
                                onChangeSearch={(keyword) => {
                                    setKeyword(keyword);
                                }}
                                isLoading={isLoading}
                                withFetchAPI={false}
                                isMultiple={true}
                                chooseList={defaultQuery}
                                onChoose={handleChooseMultipleQuery}
                                onOpenSearch={(enable) => {
                                    setEnableClickOutside(!enable)
                                }}
                            /> : null}


                            {xmlInputWithFieldName(dimensionOpen) ===
                                "inputField" ? (
                                <Input
                                    size="m"
                                    placeholder={t("enter_value")}
                                    value={valueInText}
                                    onChange={(e) => {
                                        setValueInText(e.target.value)
                                    }}
                                    onBlur={(event: any) => {
                                        if (valueInText.length) {
                                            buildQueryForInput(valueInText, "string")
                                        }
                                    }}
                                />
                            ) : null}

                            {/** Không gọi API */}
                            {xmlInputWithFieldName(dimensionOpen) === "inputSingleSearchLocal" ? (
                                <SelectionMulti
                                    label={`${idx}_autocomplete`}
                                    options={data ? data?.data : []}
                                    placeholder={t("search")}
                                    name="displayname_vi"
                                    valueName="value"
                                    onChangeSearch={(keyword) => {
                                        setKeyword(keyword);
                                    }}
                                    isLoading={isLoading}
                                    withFetchAPI={false}
                                    isMultiple={false}
                                    chooseList={defaultQuery}
                                    onChoose={handleChooseSingleQuery}
                                    onOpenSearch={(enable) => {
                                        setEnableClickOutside(!enable)
                                    }}
                                />
                            ) : null}


                            {/* Input number */}
                            {xmlInputWithFieldName(dimensionOpen) ===
                                "numberField" && operatorSelected !== 'range' ? (
                                <Input
                                    size="m"
                                    type="number"
                                    placeholder={t("enter_value")}
                                    value={valueInNumber}
                                    onChange={(e: any) => {
                                        setValueInNumber(e.target.value);
                                        buildQueryForInput(`${FormatDecimalQuantity(e.target.value)}`, 'number')
                                    }}

                                />
                            ) : null}


                            {/* Input range */}
                            {xmlInputWithFieldName(dimensionOpen) ===
                                "numberField" && operatorSelected === 'range' ? (
                                <Box display={'flex'} alignItems={"flex-start"} w={"100%"}>
                                    <Box w={"50%"} display={'flex'} alignItems={"flex-start"} >
                                        <Box mx={4} pt={5}>{t("from")}</Box>

                                        <Box flex={1} >
                                            <Input
                                                size="m"
                                                errorBorderColor="red"
                                                isInvalid={Boolean(errorMgs?.from?.length)}
                                                type="number"
                                                placeholder={t("enter_value")}
                                                value={valueInNumber}
                                                onChange={(e: any) => {
                                                    let value = e.target.value;

                                                    setValueInNumber(value)

                                                    buildQueryForInput(`${FormatDecimalQuantity(value)}|${FormatDecimalQuantity(valueInRangeTo)}`, 'range');

                                                    setErrorMgs({
                                                        ...errorMgs,
                                                        from: ""
                                                    })
                                                }}
                                                onBlur={() => {
                                                    handleCheckValidData()
                                                }}
                                            />

                                            {Boolean(errorMgs?.from?.length) ? <Box textColor={"#DC2828"} mt={3}>
                                                {errorMgs?.from}
                                            </Box> : ""}
                                        </Box>
                                    </Box>

                                    <Box w={"50%"} display={'flex'} alignItems={"flex-start"} >
                                        <Box mx={4} pt={5}>{t("to")}</Box>

                                        <Box flex={1} position={'relative'}>
                                            <Input
                                                size="m"
                                                isInvalid={Boolean(errorMgs?.to?.length)}
                                                type="number"
                                                placeholder={t("enter_value")}
                                                value={valueInRangeTo}
                                                onChange={(e: any) => {
                                                    setValueInRangeTo(e.target.value);

                                                    buildQueryForInput(`${FormatDecimalQuantity(valueInNumber)}|${FormatDecimalQuantity(e.target.value)}`, 'range');

                                                    setErrorMgs({
                                                        ...errorMgs,
                                                        to: ""
                                                    })
                                                }}
                                                onBlur={() => {
                                                    handleCheckValidData()
                                                }}
                                            />

                                            {Boolean(errorMgs?.to?.length) ? <Box textColor={"#DC2828"} mt={3}>
                                                {errorMgs?.to}
                                            </Box> : ""}
                                        </Box>

                                    </Box>
                                </Box>

                            ) : null}


                            {/* Tree View */}
                            {xmlInputWithFieldName(dimensionOpen) ===
                                "treeListWithSingle" ? (
                                <TreeContainer
                                    l label={`${idx}_treeview`}
                                    options={data ? data?.data : []}
                                    placeholder={t("search")}
                                    name="displayname_vi"
                                    valueName="value"
                                    isLoading={isLoading}
                                    isMultiple={false}
                                    onChoose={handleChooseTreeViewCheckbox}
                                    chooseList={defaultQuery}
                                    onOpenSearch={(enable) => {
                                        setEnableClickOutside(!enable)
                                    }}
                                />
                            ) : null}
                        </Box>

                        {
                            !!defaultQuery.length && xmlInputWithFieldName(dimensionOpen) !== "numberField" && (
                                <div className="hrv-report-row">
                                    {defaultQuery.map((filter, idx) => {
                                        const isTreeview = xmlInputWithFieldName(dimensionOpen) === "treeListWithSingle"
                                        const isShowTooltip = isTreeview && filter?.fullName?.trim().split(">").length > 2;

                                        return <HChip
                                            size="md"
                                            key={idx}
                                            content={
                                                isTreeview ?
                                                    middleEllipsisHandler(filter?.fullName?.trim() || "") :
                                                    t(filter.query.trim())
                                            }
                                            onHandleClose={() => {
                                                handleChooseMultipleQuery({
                                                    check: false,
                                                    name: filter.query,
                                                });
                                            }}
                                            tooltipLabel={isShowTooltip ? filter?.fullName?.trim() || "" : ""}
                                            showTooltip={isShowTooltip}
                                        />
                                    })}
                                </div>
                            )
                        }
                    </PopoverBody>


                    <PopoverFooter border="unset" px={8} pb={8} pt={0} display="flex" justifyContent="flex-end">
                        <Button variant='default-default' mr={4} onClick={() => {
                            handleClose();
                        }}>
                            {t("Huỷ")}
                        </Button>

                        <Button variant='default-primary' onClick={() => {
                            if (defaultQuery.length) {
                                onHandleApplyFilterInChip && onHandleApplyFilterInChip({
                                    dimension: dimensionOpen,
                                    conjunction: 'and',
                                    query: defaultQuery
                                })
                            } else {
                                onHandleClose && onHandleClose(dimensionOpen)
                            }


                            setTimeout(() => {
                                handleClose()
                            }, 60)
                        }} isDisabled={isDisabledButton}>
                            {t("Áp dụng")}
                        </Button>
                    </PopoverFooter>
                </PopoverContent> : ""}

            </Popover>
        </div >
    )
}

export default ChipGroup