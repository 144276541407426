import { useTrans } from "@haravan/reactapp"
import { useGTM } from "hooks/useGTM";
import { Button } from "@chakra-ui/react"

function ButtonColumnShow(props) {
    const { isDisplay, onClick } = props
    const t = useTrans();
    const { attributes, detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "detail",
            "ut-name": 'column_show'
        }
    });
    const handleClick = () => {
        onClick && onClick()
    }

    return (
        <>
            {isDisplay ? <Button
                variant="default-default"
                leftIcon={svgColumn}
                onClick={handleClick}
                className={`hrv-report-tour-in-columnshow ${detectClass}`}
                {...attributes}
            >
                {t("choose_columns")}
            </Button> : ""}
        </>

    )
}

export default ButtonColumnShow


// ! SVG Imports
const svgColumn = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M4 8H20V5H4V8ZM14 19V10H10V19H14ZM16 19H20V10H16V19ZM8 19V10H4V19H8ZM3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3Z"
            fill="#4B5563"
        />
    </svg>
);