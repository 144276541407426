import { formatNumber } from "helpers/helpers"

const Money = ({
    value,
    className = "",
    suffix = "đ",
    suffixClass = "hrv-report-border-bottom-gray-12",
    prefix = "",
    prefixClass = ""
}) => {
    return <div className={className}>
        {prefix !== undefined && prefix !== '' && <span className={prefixClass}>{prefix}</span>}
        {formatNumber(Math.round(value), false)}{" "}
        {suffix !== undefined && suffix !== '' && <span className={suffixClass}>{suffix}</span>}
    </div>
}



export default Money;