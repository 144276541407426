import { category_chart } from "pages/ReportDashboardV2/context/store/constants";

export interface IProps {
  headers: any;
  data: any;
  measureSelected: any;
  displayChartMode: any;
  isLoading: boolean;
  startdate: any;
  enddate: any;
  measure: Array<any>;
  reportNameSystem: string;
  onHandleCheckboxChart: Function;
  query: any;
  queryPeriod: any;
  compareDateStart: any;
  compareDateEnd: any;
  optionCompare: any;
  isLoadingChart: any;
  reportId: string | number | undefined;
  dataFetchingTable: any;
  measureDraw: any;
  optionMeasure: any
}

export const svgDoughnut = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M10.854 4.23398C13.404 4.60065 15.4207 6.61732 15.7874 9.16732H18.3124C17.9207 5.23398 14.7874 2.10065 10.854 1.70898V4.23398ZM15.7874 10.834C15.4207 13.384 13.404 15.4007 10.854 15.7673V18.2923C14.7874 17.9007 17.9207 14.7673 18.3124 10.834H15.7874ZM9.18738 15.7673C6.36238 15.359 4.18738 12.934 4.18738 10.0007C4.18738 7.06732 6.36238 4.64232 9.18738 4.23398V1.70898C4.97904 2.12565 1.68738 5.67565 1.68738 10.0007C1.68738 14.3257 4.97904 17.8757 9.18738 18.2923V15.7673Z" fill="#4B5563" />
</svg>

export const svgLine = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M3.41663 15.4083L8.41663 10.4L11.75 13.7333L18.8333 5.76667L17.6583 4.59167L11.75 11.2333L8.41663 7.90001L2.16663 14.1583L3.41663 15.4083Z" fill="#9CA3B0" />
</svg>

export const HEIGHT_CHART = {
  bar: 440,
  doughnut: 300,
  stackedbar: 440,
  stackedhorizalbar: 404,
  groupedcolumn: 420,
  line: 420,
  groupedhorizontalcolumn: 500,
  "": 440,
  treemap: 500,
  RFM: 400
}

// Type of Report Call API to draw not use data from Table
export const REPORT_CAN_CALL_API_TO_DRAW = [
  "sales_overview_promotion", // Báo cáo hiệu quả khuyến mãi
  "sales_orders_salechannel_branch", // Báo cáo sàn thương mại điện tử
  "customers_new_and_return", // Báo cáo Khách mới, khách cũ
  "customers_by_location", // Theo vị trí địa lý
  "order_shipments_detail_carriers_internal_speed", // Chi tiết - Tốc độ xử lý đơn nội bộ (development)
  "order_shipments_overview_carriers_internal_speed", // Tổng quan - Tốc độ xử lý đơn nội bộ (development)
  "customers_by_rfm",
  "sales_orders_staff", // Bán hàng theo nhân viên
  "web_pageviews_month", // * Báo cáo Pageviews
  "", // * Nhập trả hàng theo nhà cung cấp
];

const DRAWING_DENSITY = 'density';
const DRAWING_TREND = 'trend';

const SWITCH_CUSTOMERS_NEW_AND_RETURN: any = [
  {
    id: DRAWING_DENSITY,
    icon: svgDoughnut,
    title: "Tỷ trọng",
    chartType: category_chart.DOUGHNUT,
    isDefault: true,
    groupbytable: ["CustomerType"]
  },
  {
    id: DRAWING_TREND,
    icon: svgLine,
    title: "Xu hướng",
    chartType: category_chart.LINE,
    isDefault: false,
    groupbytable: ["Day", "CustomerType"]
  }
];

// & Report có thể vẽ theo xu hướng hay tỷ trọng
export const REPORT_CAN_CHANGE_CHART_TYPE = [
  "customers_new_and_return",
  // "customers_by_rfm"
];

export const OPTIONS_DRAW_BY_REPORT = {
  "customers_new_and_return": SWITCH_CUSTOMERS_NEW_AND_RETURN,
  // "customers_by_rfm": SWITCH_CUSTOMERS_BY_RFM,
}

export const Colours = [
  {
    color: "#3C83F6", // Xanh dương
    hover: "#61A6FA",
  },
  {
    color: "#F97415", // Cam
    hover: "#FB923C",
  },
] as any;

export const LIMIT_GROUPED_BY_DISCOUNT = 10;

