import { useTrans } from "locales/hook";
import "./styles.scss";

// * trend = 0 not show | trend = true uptrend | trend = false downtrend
function StatusPeriod(props) {
  const {
    trend,
    value,
    isDisplayBehindText,
    className,
    infinity = false,
    reverse = false
  } = props;

  const t = useTrans();

  let containerClass = "hrv-report-dashboard-period-descrease";
  let displayValue = value;

  if (trend) {
    containerClass = "hrv-report-dashboard-period-increase";
  }

  if (value === "0") {
    containerClass = "hrv-report-dashboard-period-normal";
  }

  if (infinity) {
    containerClass = "hrv-report-dashboard-period-normal";
    displayValue = "--";
  }

  return (
    <>
      {trend === 0 ? (
        <></>
      ) : (
        <div
          className={`hrv-report-fs-12 hrv-report-dashboard-period ${containerClass} ${className}`}
        >
          {value !== "0" && !infinity ? (
            <>
              {trend
                ? reverse
                  ? svgUptrendReverse
                  : svgUptrend
                : reverse
                  ? svgDownTrendReverse
                  : svgDownTrend}
            </>
          ) : (
            <></>
          )}

          <span>{`${displayValue}${!infinity ? "%" : ""}`}</span>

          {isDisplayBehindText && (
            <span className="hrv-report-dashboard-period-text">
              {t("compared_with_the_same_period")}
            </span>
          )}
        </div>
      )}
    </>
  );
}

export default StatusPeriod;

const svgUptrend = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M8 5.33331L12 9.33331H4L8 5.33331Z" fill="#16A249" />
  </svg>
);

const svgUptrendReverse = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M8 10.6667L4 6.66666L12 6.66666L8 10.6667Z" fill="#16A249" />
  </svg>
);

const svgDownTrend = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M8 10.6667L4 6.66666L12 6.66666L8 10.6667Z" fill="#DC2828" />
  </svg>
);

const svgDownTrendReverse = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path d="M8 5.33331L12 9.33331H4L8 5.33331Z" fill="#DC2828" />
  </svg>
);
