import { Fragment, ReactElement, useEffect, useState } from "react";
import "./styles.scss";
import { useTrans } from "locales/hook";

interface IProps {
    options: Array<TOption>
    defaultTab: string | number
    onHandleChangeTab: Function
    containerClasses?: string
}

type TOption = {
    id: number,
    title: string | ReactElement
    icon?: any
    chartType?: string
}

function SwitchTabs(props: IProps) {
    const t = useTrans();
    const { options, defaultTab, onHandleChangeTab, containerClasses } = props;
    const [activeTab, setActiveTab] = useState(defaultTab)

    useEffect(() => {
        setActiveTab(defaultTab)
    }, [defaultTab]);

    return (
        <div className={`hara-embed-segmented-controls ${containerClasses}`}>
            {options.map((option: TOption, idx) => {
                return (
                    <Fragment key={idx}>
                        <input
                            type="radio"
                            checked={option.id === activeTab}
                            readOnly
                        />

                        <label
                            onClick={() => {
                                onHandleChangeTab && onHandleChangeTab(option.id, option.chartType)
                            }}
                        >
                            {option.icon ? <span className="hrv-report-mr-6">{option.icon}</span> : ""}
                            {t(option.title)}
                        </label>
                    </Fragment>
                );
            })}
        </div>
    )
}

export default SwitchTabs