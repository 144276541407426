import { useStore } from "pages/ReportStandard/context/store";
import { useSummaryV2 } from "models/swr/report";
import { useTrans } from "locales/hook";

import { SkeletonCircle, SkeletonText, Tooltip } from "@chakra-ui/react";

import { convertEndDate, convertStartDate, formatNumber } from "helpers/helpers";

import "./style.scss";

interface IProps {
    name: string;
    unit: string;
    color: string;
    groupby: string;
    metrics: Array<any>;
    filters: Array<any>;
    order_by: Array<any>;
    icon: any;
    textColor: string;
    description: string;
    onHandleOnClickItem: Function;
    reportId: string | number
}

function PanelCategory(props: IProps) {
    const { name, unit, color, groupby, metrics, filters, order_by, icon, textColor, description, onHandleOnClickItem, reportId } = props;
    const { state } = useStore();
    const t = useTrans();


    const { startdate, enddate, dataFetchingTable } = state

    const getLocIdFilter = dataFetchingTable.filters ? dataFetchingTable.filters.filter(y => y.dimension === "LocId") : []

    const dataFetching = {
        order_by,
        filters: [...filters, ...getLocIdFilter],
        daterange: {
            startdate: convertStartDate(startdate),
            enddate: convertEndDate(enddate),
        },
        groupby: groupby,
        metrics: metrics,
        pagination: {
            "offset": 0,
            "limit": 100
        },
    }

    const { data: summary, isLoading } = useSummaryV2(reportId, dataFetching);

    const numbericValue = summary ? summary?.data[0]?.value : 0

    const handleOnClickItem = () => {
        onHandleOnClickItem && onHandleOnClickItem(filters)
    }

    return (
        <>
            {isLoading ? <div className="hrv-report-panel-category">
                <div className="hrv-report-mr-12">
                    <SkeletonCircle size={"40px"} />
                </div>

                <div style={{
                    width: "100%"
                }}>
                    <SkeletonText mt='0' noOfLines={2} spacing='4' skeletonHeight='3' />
                </div>
            </div> :
                <div className="hrv-report-panel-category" onClick={handleOnClickItem}>
                    <div className="hrv-report-p-8 hrv-report-mr-12"
                        style={{
                            background: color,
                            borderRadius: "50%"
                        }}
                    >
                        {icon}
                    </div>

                    <div>
                        <Tooltip hasArrow label={<div dangerouslySetInnerHTML={{
                            __html: t(description)
                        }} >

                        </div>}>
                            <p style={{
                                borderBottom: "1px dashed #E5E7EB",
                                display: "inline-block",
                                marginBottom: 0
                            }} >
                                <span style={{
                                    display: "-webkit-box",
                                    WebkitLineClamp: 1,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    WebkitBoxOrient: "vertical",
                                }} className="hrv-report-fs-12 hrv-report-fw-500">
                                    {t(name)}
                                </span>
                            </p>
                        </Tooltip>

                        <div>
                            <span className="hrv-report-fw-600 hrv-report-fs-14 hrv-report-mr-4" style={{
                                color: textColor
                            }}>{formatNumber(numbericValue)}</span>
                            <span className="hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-color-gray-9">{t(unit)}</span>
                        </div>
                    </div>
                </div >}
        </>

    )
}

export default PanelCategory
