import { memo, useEffect, useState, Fragment, forwardRef, useImperativeHandle, useRef } from "react";
import { useTrans } from "locales/hook";
import useDebouncedCallback from "hooks/useDebounceCallback";

import { Box } from "@chakra-ui/react";
import HRVInputSearch from "components/InputSearch";
import EmptyTemplate from "components/EmptyTemplate";
import WidgetNavList from "./components/WidgetNavList";

import { isEmpty } from "helpers/helpers";

import "./styles.scss";

const DashboardSidebar = forwardRef(({
  inConfigMode,
  templateDashboard,
  onHandleToggleWidget,
  onHandleSearchReport,
  limit
}: any, ref) => {
  const [animation, setAnimation] = useState(false);
  const [keyword, setKeyword] = useState("");
  const refAside: any = useRef();

  const t = useTrans();

  useImperativeHandle(ref, () => ({
    resetSearch() {
      setKeyword("");
    }

  }));


  const handleChange = (value: string) => {
    onHandleSearchReport && onHandleSearchReport(value);
    // Thực hiện các hành động cần thiết khi giá trị debounce thay đổi
  };

  const [debouncedCallback] = useDebouncedCallback(handleChange, 500, [keyword])

  const onHandleSearch = (e) => {
    setKeyword(e);
    debouncedCallback(e)
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimation(inConfigMode);
    }, 100);
  }, [inConfigMode]);

  const isEmptyList = isEmpty(templateDashboard);
  const heightSearch = 80;
  const heightContent = refAside?.current?.clientHeight - heightSearch - 16;

  return (
    <aside
      className={`hrv-report-dashboard-sidebar ${animation ? "hrv-report-dashboard-sidebar-show" : ""
        }`}
      ref={refAside}
    >
      <div>
        <div className="hrv-report-pr-6 hrv-report-mb-16">
          <Box textStyle="h5" className="hrv-report-fs-12 hrv-report-text-color-black-6" mb={4}>
            {t("report_library")}
          </Box>

          <HRVInputSearch
            size="m"
            placeholder={t("search_report")}
            value={keyword}
            onChange={onHandleSearch}
            isShowCloseButton
          />
        </div>

        <div className="hrv-report-scrollbar_custom hrv-report-py-8" style={{
          overflow: 'auto',
          height: heightContent,
        }}>
          {!isEmptyList ? (
            <>
              {Object.keys(templateDashboard).map((key, idx) => {
                const isDisplayGroup =
                  templateDashboard[key]?.filter((y) => !y.isDisplay).length > 0;

                const reportDisplay = templateDashboard[key].filter(report => !report.isDisplay);

                return (
                  <Fragment key={idx}>
                    {isDisplayGroup && (
                      <>
                        <h4 className="hrv-report-mb-0 hrv-report-fs-12 hrv-report-fw-600 hrv-report-text-color-gray-6">
                          {t(key)}
                        </h4>

                        <ul className="hrv-report-p-0 hrv-report-mb-16">
                          <WidgetNavList
                            limit={limit}
                            list={reportDisplay}
                            onHandleToggleWidget={onHandleToggleWidget}
                          />
                        </ul>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <EmptyTemplate text={t("no_data")} />
          )}
        </div>
      </div>

    </aside>
  );
})

export default memo(DashboardSidebar);
