import { memo } from "react";
import { Provider } from "./context";
import { Main } from "./components";
import ScrollReset from "components/ScrollReset";

const ReportListV2 = () => {
  return (
    <Provider>
      <ScrollReset />

      <Main />
    </Provider>
  );
};

export default memo(ReportListV2);
