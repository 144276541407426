import { useCallback, useEffect, useRef, useState } from "react";

import { Box, Button } from "@chakra-ui/react"

import { svgBackDrillDown } from "./assets";
import { useTrans } from "locales/hook";
import HLimitLongString from "components/LimitLongString";
import { formatNumber, valueHandler } from "helpers/helpers";

interface IProps {
    defaultVisible: boolean;
    onHandleBack?: Function;
    onHandleSave?: Function;
    defaultValue: { name: string; value: any } | any;
    isShowCloseBtn?: boolean
}

enum FormatType {
    LINK = 'link',
    NUMBERLINK = 'numberlink',
    MONEY = "money",
    NUMBERDAY = "numberday",
    NUMBERHOUR = "numberhour"
}

function DrillDown(props: IProps) {
    const {
        defaultVisible = false,
        onHandleBack,
        onHandleSave,
        defaultValue,
        isShowCloseBtn = true
    } = props;
    // const [isVisible, setIsVisible] = useState(defaultVisible)
    const [isActiveScroll, setIsActiveScroll] = useState(false);
    const [positionTop, setPositionTop] = useState(0)
    const refDrilldown = useRef<any>(null);
    const t = useTrans();

    useEffect(() => {
        const reportHeaderDetail: any = document.getElementById(
            "report_detail_header",
        );

        setPositionTop(reportHeaderDetail?.offsetHeight)
    }, [])


    const handleScroll = useCallback(() => {
        if (defaultVisible && refDrilldown !== null) {
            if (window?.scrollY > refDrilldown?.current?.offsetTop - refDrilldown?.current?.offsetHeight) {
                setIsActiveScroll(true);
            } else {
                setIsActiveScroll(false)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultVisible, refDrilldown])

    useEffect(() => {
        if (!defaultVisible) {
            return window.removeEventListener("scroll", handleScroll);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultVisible]);

    useEffect(() => {
        setTimeout(() => {
            handleScroll()
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultVisible]);

    const contentCell = (payload) => {
        const { name, value, format } = payload;

        switch (format) {
            case FormatType.LINK:
                return <HLimitLongString
                    customClass="hrv-report-mb-0"
                    text={value}
                    isLink={false}
                    lengthText={30}
                    placement="bottom"
                />

            case FormatType.NUMBERLINK:
                return <HLimitLongString
                    customClass="hrv-report-mb-0"
                    text={formatNumber(value)}
                    lengthText={3000}
                    isLinkSecondary
                    tooltipSecondary={t("Xem vận đơn")}
                />

            // case FormatType.MONEY:
            //     return <MoneyWithLocale
            //         value={value}
            //         currencyCode={currencyCode}
            //         className="hrv-report-transparent-bottom hrv-report-text-right"
            //     />

            // case FormatType.NUMBERDAY:
            //     return <HLimitLongString
            //         text={displayByTime}
            //     />

            // case FormatType.NUMBERHOUR:
            //     return <HLimitLongString
            //         text={displayByTime}
            //     />

            default:
                return <HLimitLongString
                    text={valueHandler(format, name, value)}
                    customClass="hrv-report-mb-0"
                    lengthText={1000}
                />
        }
    }

    return (
        <>
            <div
                style={{
                    marginTop: "16px",
                    padding: "8px 12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#111827",
                    borderTopRightRadius: `${isActiveScroll ? 0 : 8}`,
                    borderTopLeftRadius: `${isActiveScroll ? 0 : 8}`,
                    position: "sticky",
                    top: positionTop,
                    left: 0,
                    zIndex: 100,
                    display: `${defaultVisible ? "flex" : "none"}`
                }}
                ref={refDrilldown}
                id={"drilldown-wrapper"}
            >
                <Box display={'flex'} alignItems={'center'} >
                    <Button variant={"solid-primary[100]"} maxH={"32px"} p={5} onClick={() => {
                        onHandleBack && onHandleBack()
                    }}>
                        <Box display={'flex'} alignItems={'center'}>
                            <div className="hrv-report-mr-4">{svgBackDrillDown}</div>

                            <span>
                                {t("Quay lại")}
                            </span>
                        </Box>
                    </Button>

                    <Box ml={6} >
                        <p
                            className="hrv-report-fs-10 hrv-report-fw-500 hrv-report-mb-0"
                            style={{
                                color: "#6F747C"
                            }}
                        >
                            {t("Đang xem chi tiết")}
                        </p>

                        <span
                            className="hrv-report-d-flex hrv-report-md-flex-row hrv-report-flex-col hrv-report-fs-14 hrv-report-fw-600 hrv-report-text-color-white hrv-report-mb-0 hrv-report-flex-wrap"
                        >
                            <span>{t(defaultValue.name)} :</span> <span className="hrv-report-ml-0 hrv-report-md-ml-4">{contentCell(defaultValue)}</span>
                        </span>
                    </Box>
                </Box>

                {isShowCloseBtn ? <Button px={4} py={3} maxH={"32px"} variant={"solid-default[100]"} onClick={() => {
                    onHandleSave && onHandleSave()
                }}>
                    {t("Đóng")}
                </Button> : null}
            </div>
        </>
    )
}

export default (DrillDown);
