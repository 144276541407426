import { memo, useRef } from "react";
import { useTrans } from "locales/hook";

import { EmptyChart } from "./EmptyChart";
import { BarChart, Doughnut, StackedBar, TreemapChart, StackedHorizontalBar } from "components/Chart";
import LineChart from "components/MetricsDiscount/components/chart/line";
import TreemapRFM from "components/Chart/TreeMap/rfm";

import { category_chart } from "pages/ReportDashboardV2/context/store/constants";
import { HEIGHT_CHART } from "..";
import { REPORT_OPEN_COMPARE, REVERSE_NEGATIVE_FIELD_NAME } from "../constants";
import { ShowDateRange } from "helpers/helpers";
import md5 from "helpers/md5";

interface IMetricsChartProps {
    labels: any;
    data: any;
    displayChart: any;
    typeHorizontal: any;
    typeVertical: any;
    fieldName: any;
    heightLegend: any;
    reportNameSystem: any;
    isGrouped: any;
    startdate: any;
    enddate: any;
    indexAxis: any;
    optionMeasure: any;
    optionCompare?: any;
    compareDateStart?: any;
    compareDateEnd?: any;
  }

function MetricsChart(props: IMetricsChartProps) {
    const {
        labels,
        data,
        displayChart,
        typeHorizontal,
        typeVertical,
        fieldName,
        heightLegend,
        reportNameSystem,
        isGrouped,
        startdate,
        enddate,
        indexAxis,
        optionMeasure
    } = props;

    const t = useTrans();
    const chartContainerRef = useRef<any>();
    const isComparison = REPORT_OPEN_COMPARE.includes(reportNameSystem);

    if (category_chart.LINE === displayChart) {
        // Trường hợp không đúng format của data thì sẽ return;
        if (typeof data !== 'object' && Array.isArray(data) && data !== null) return <></>;
        // Trường hợp tất cả các key current data đều không có dữ liệu
        const isEmpty = Object.keys(data).every((key) => {
            return !data[key].current?.data?.length
        })

        if (isEmpty) {
            return <EmptyChart
                chartHeight={HEIGHT_CHART[displayChart]}
                legendHeight={heightLegend - 16}
            />
        }
    } else {
        if (isComparison) {
            let emptyChartState = false;

            if (data[0]?.hasOwnProperty('label')) {
                // * data trả ra có so sánh kì
                if (Boolean(!data[0]?.data?.length) && Boolean(!data[1]?.data?.length)) {
                    emptyChartState = true
                }
            } else {
                // * data trả ra không so sánh kì
                if (Boolean(!data?.length)) {
                    emptyChartState = true
                }
            }

            if (emptyChartState) {
                return <EmptyChart
                    chartHeight={HEIGHT_CHART[displayChart]}
                    legendHeight={heightLegend - 16}
                />
            }
        } else {
            if (!data || !data?.length) {
                return <EmptyChart
                    chartHeight={HEIGHT_CHART[displayChart]}
                    legendHeight={heightLegend - 16}
                />
            }
        }
    }

    const key = md5(JSON.stringify({
        labels,
        data,
        namefield: fieldName,
        isGrouped
    }));

    return <div className="hrv-report-detail-chart">
        {/* Biểu đồ Cột chồng */}
        {category_chart.STACKEDBAR === displayChart &&
            <StackedBar
                proData={{
                    labels,
                    data,
                    namefield: fieldName,
                }}
                colorBar="#3C83F6"
                hoverColorBar="#61A6FA"
                typeHorizonal={typeHorizontal}
                typeVertical={typeVertical}
                configHeight={HEIGHT_CHART[displayChart]}
            />
        }

        {/* Biểu đồ thanh được nhóm */}
        {category_chart.GROUPEDHORIZONTALCOLUMN === displayChart &&
            // <div
            //     // ref={chartContainerRef}
            //     className={'hrv-report-detail-chart'}
            //     style={{
            //         height: HEIGHT_CHART[displayChart],
            //     }}>
            //     <BarChart
            //         proData={{
            //             labels,
            //             data,
            //             namefield: 'Don\'t Have namefield',
            //         }}
            //         colorBar="#3C83F6"
            //         hoverColorBar="#61A6FA"
            //         // isGrouped={isGrouped}
            //         // key={key}
            //         // configHeight={chartContainerRef?.current?.clientHeight}
            //         // indexAxis={
            //         //     headers[0]?.dataFormat === 'timestamp' ? "x" : "y"
            //         // }
            //         typeHorizonal={typeHorizontal}
            //         typeVertical={typeVertical}
            //         isStacked={true}
            //     />
            // </div>

            <div>abc</div>
        }

        {/* Biểu đồ Tròn */}
        {category_chart.DOUGHNUT === displayChart && <div
            style={{
                height: HEIGHT_CHART[displayChart],
            }}
        >
            <Doughnut
                prodata={{
                    labels: labels.map((y: any) => y.name),
                    data,
                    namefield: fieldName,
                    color: labels.map((y: any) => y.color),
                }}
                typeHorizonal={typeHorizontal}
                height={HEIGHT_CHART[displayChart]}
                isShowLabel={false}
                isShowConnectingLine={reportNameSystem === "customers_new_and_return"}
                isShowPercentage={reportNameSystem === "customers_new_and_return"}
            />
        </div>}

        {/* Biểu đồ thanh được nhóm */}
        {category_chart.GROUPEDCOLUMN === displayChart && <div
            ref={chartContainerRef}
            className='hrv-report-detail-chart'
            style={{
                height: HEIGHT_CHART[displayChart],
            }}
        >
            <BarChart
                proData={{
                    labels,
                    data,
                    namefield: `${ShowDateRange(startdate, enddate)}`,
                }}
                colorBar="#3C83F6"
                hoverColorBar="#61A6FA"
                typeHorizonal={typeHorizontal}
                isGrouped={isGrouped}
                key={key}
                configHeight={chartContainerRef?.current?.clientHeight || HEIGHT_CHART[displayChart]}
                typeVertical={typeVertical}
                indexAxis={indexAxis}
                reverseNegative={REVERSE_NEGATIVE_FIELD_NAME.some(field => t(field) === fieldName)}
            />
        </div>}

        {/* Biểu đồ đường */}
        {category_chart.LINE === displayChart && <div
            style={{
                maxHeight: HEIGHT_CHART[displayChart]
            }}
            className="hrv-report-relative"
        >
            <LineChart
                data={data}
                isLoading={false}
                configHeight={HEIGHT_CHART[displayChart]}
                isAutoSetDateType={false}
                groupByCustom={typeVertical?.toLowerCase()}
            />
        </div>
        }

        {/* Biểu đồ treemap */}
        {category_chart.TREEMAP === displayChart && reportNameSystem !== "customers_by_rfm" && <div ref={chartContainerRef}>
            <TreemapChart
                data={data?.map((_, key) => {
                    return {
                        name: labels[key],
                        value: data[key]
                    }
                })}
                configHeight={HEIGHT_CHART[displayChart]}
                chartColor="#61A6FA"
                fieldName={fieldName}
                typeHorizonal={typeHorizontal}
                width={chartContainerRef?.current?.clientWidth}
            />
        </div>
        }

        {/* Biểu đồ ngang chồng */}
        {category_chart.STACKEDCOLUMN === displayChart && <div
            ref={chartContainerRef}
        >
            <StackedHorizontalBar
                proData={{
                    labels,
                    data,
                    namefield: "",
                }}
                typeHorizonal={typeHorizontal}
                configHeight={labels?.length * 40 < HEIGHT_CHART[displayChart] ? HEIGHT_CHART[displayChart] : labels?.length * 40}
                unit={t("giờ")}
                width={chartContainerRef?.current?.clientWidth} />
        </div>
        }

        {/* Biểu đồ RFM phân vùng */}
        {category_chart.TREEMAP === displayChart && reportNameSystem === "customers_by_rfm" && <div>
            <TreemapRFM
                data={data}
                labels={labels}
                configHeight={HEIGHT_CHART["RFM"]}
                optionMeasure={optionMeasure}
            />
        </div>}

        {/* Biểu đồ cột */}
        {
            category_chart.BAR === displayChart && <div
                style={{
                    height: HEIGHT_CHART[category_chart.BAR]
                }}
            >
                <BarChart
                    proData={{
                        labels,
                        data,
                        namefield: `${ShowDateRange(startdate, enddate)}`,
                    }}
                    colorBar="#3C83F6"
                    hoverColorBar="#61A6FA"
                    typeHorizonal={typeHorizontal}
                    configHeight={HEIGHT_CHART[category_chart.BAR]}
                    typeVertical={typeVertical}
                    indexAxis={indexAxis}
                    reverseNegative={REVERSE_NEGATIVE_FIELD_NAME.includes(optionMeasure.measureField)}
                />
            </div>
        }
    </div>;
}

export default memo(MetricsChart);