import { useFetchJson } from "@haravan/reactapp";
import { buildQueryString } from "helpers/helpers";
import md5 from "helpers/md5";
import useApi from "hooks/useApi";
import useSWR from "swr";

const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES + "/";
const REPORT_CALL_URI_V2 = REPORT_URI + `reports/`;
const REPORT_SCREEN_URI_V2 = REPORT_URI + `reportscreens/`;
const REPORT_CALL_URI_DASHBOARDV2 = REPORT_URI + `analyticdashboard/`;

const useHRVSWR: any = useSWR;

export default function Model() {
  const { fetchWithToken, postWithToken, deleteWithToken, putWithToken } =
    useApi();

  const ReportList = async (tab) => {
    const res = await fetchWithToken(`${REPORT_CALL_URI_V2}${tab}`);

    try {
      const response = await res.json();

      if (response.errors.length) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ReportSummary = async (id, data) => {
    let apiUrl = `${REPORT_CALL_URI_V2}${id}/summary`;

    const res = await postWithToken(apiUrl, data);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ReportData = async (id, data, reportNameSystem = null) => {
    let apiUrl = `${REPORT_CALL_URI_V2}${id}/query`;

    const res = await postWithToken(apiUrl, data);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ReportRetryData = async (id, data, reportNameSystem = null) => {
    const maxRetries = 3;
    const retryDelay = 3000; // milliseconds

    let retries = 0;

    const tryRequest = async () => {
      let apiUrl = `${REPORT_CALL_URI_V2}${id}/query`;

      try {
        const res = await postWithToken(apiUrl, data);
        const response = await res.json();

        if (response.errors && response.errors.length > 0) {
          throw new Error('Request error');
        } else {
          return {
            data: response.data,
            isError: false,
          };
        }
      } catch (error) {
        if (retries < maxRetries) {
          retries++;

          console.log(`Retrying request (${retries}/${maxRetries})...`);

          await new Promise(resolve => setTimeout(resolve, retryDelay));
          return tryRequest();
        } else {
          return {
            data: error,
            isError: true,
          };
        }
      }
    };

    return tryRequest();
  };

  const ReportInfo = async (id, widget_id, dashboard_id) => {
    const query = buildQueryString({
      ...(widget_id && widget_id?.length && { widget_id }),
      ...(dashboard_id && dashboard_id?.length && { dashboard_id }),
    })

    const res = await fetchWithToken(`${REPORT_CALL_URI_V2}${id}${query}`);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AutoComplete = async (id, querystring) => {
    const res = await fetchWithToken(
      `${REPORT_CALL_URI_V2}${id}/auto_complete${querystring}`,
    );

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AutoCompleteV2 = async (id, querystring) => {
    const res = await fetchWithToken(
      `${REPORT_CALL_URI_V2}${id}/auto_complete${querystring}`,
    );

    try {
      const response = await res.json();
      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AutoCompleteById = async (id, querystring) => {
    const res = await fetchWithToken(
      `${REPORT_CALL_URI_V2}${id}/auto_completebyid${querystring}`,
    );

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AutoCompleteGateway = async (id, body) => {
    const res = await postWithToken(
      `${REPORT_CALL_URI_V2}${id}/gatewayfilter`,
      body,
    );

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const Export = async (id, data) => {
    const res = await postWithToken(`${REPORT_URI}reports/${id}/export`, data);

    try {
      const response = await res.json();

      if (response.hasError) {
        return Promise.resolve({
          data: response.errors[0],
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const Duplicate = async (id, data) => {
    const res = await postWithToken(
      `${REPORT_CALL_URI_V2}${id}/clonereport`,
      data,
    );

    try {
      const response = await res.json();

      if (response.hasError) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const Delete = async (id) => {
    const res = await deleteWithToken(`${REPORT_CALL_URI_V2}${id}`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AnalyticDashboard = async () => {
    const res = await fetchWithToken(`${REPORT_CALL_URI_DASHBOARDV2}`);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const AnalyticWidget = async (id: string | number) => {
    const res = await fetchWithToken(`${REPORT_CALL_URI_DASHBOARDV2}${id}`);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const SaveAnalyticWidget = async (body: any) => {
    const res = await putWithToken(`${REPORT_CALL_URI_DASHBOARDV2}`, body);

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ReportDataV2 = (id, data, reportNameSystem = null) => {
    const fetchJson = useFetchJson();
    const key = md5(JSON.stringify(data));

    const fetcher = (url: string) =>
      fetchJson(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

    let apiUrl = `${REPORT_CALL_URI_V2}${id}/query?k=${key}`;


    return useHRVSWR(apiUrl, fetcher, {
      revalidateOnFocus: false,
      refreshInterval: 0,
    })
  };

  const AddDashboard = async () => {
    const res = await postWithToken(`${REPORT_CALL_URI_DASHBOARDV2}`, "");

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const DeleteDashboard = async (dashboardId) => {
    const res = await deleteWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}${dashboardId}`,
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const CloneDashboard = async (dashboardId) => {
    const res = await postWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}${dashboardId}/clone`,
      "",
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const LastestView = async (id) => {
    const res = await postWithToken(
      `${REPORT_URI}reportscreens/${id}/lastviewed`,
      "",
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const Feedback = async (id) => {
    const res = await postWithToken(
      `${REPORT_URI}reportscreens/${id}/feedback`,
      "",
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ResetDashboard = async (dashboardId) => {
    const res = await postWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}${dashboardId}/reset`,
      "",
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ChangeNameDashboard = async (dashboardId, name) => {
    const res = await putWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}${dashboardId}/dashboard`,
      {
        name,
      });

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const UpdateWidget = async ({ dashboardId, widgetConfig }) => {
    const res = await putWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}${dashboardId}/dashboardwidget`,
      widgetConfig,
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ListWidgetContanst = async () => {
    const res = await fetchWithToken(
      `${REPORT_CALL_URI_DASHBOARDV2}widgets`,
    );

    try {
      const response = await res.json();

      if (response.errors && response.errors.length > 0) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ExportProcess = async (exportId) => {
    const res = await fetchWithToken(`${REPORT_URI}reports/export/${exportId}`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const StatusModalExport = async () => {
    const res = await fetchWithToken(`${REPORT_URI}reports/status_hidden_comfirm_export`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const ChangeStatusModalExport = async (status: boolean) => {
    const res = await postWithToken(`${REPORT_URI}reports/status_hidden_comfirm_export`, status);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const EditReport = async (id, arg) => {
    const res = await postWithToken(`${REPORT_SCREEN_URI_V2}${id}`, arg);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const OnboardingTour = async (arg) => {
    const res = await postWithToken(`${REPORT_CALL_URI_V2}tour`, arg);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const GetCurrentTour = async () => {
    const res = await fetchWithToken(`${REPORT_URI}tours`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  }

  const PostCurrentTour = async (payload: any) => {
    const res = await postWithToken(`${REPORT_URI}tours`, payload);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  }

  return {
    ReportList,
    ReportInfo,
    ReportData,
    ReportSummary,
    AutoComplete,
    AutoCompleteById,
    AutoCompleteGateway,
    Export,
    Duplicate,
    Delete,
    AutoCompleteV2,
    AnalyticDashboard,
    AnalyticWidget,
    SaveAnalyticWidget,
    ReportDataV2,
    AddDashboard,
    DeleteDashboard,
    CloneDashboard,
    ResetDashboard,
    ChangeNameDashboard,
    LastestView,
    UpdateWidget,
    ListWidgetContanst,
    ExportProcess,
    StatusModalExport,
    EditReport,
    OnboardingTour,
    ChangeStatusModalExport,
    ReportRetryData,
    Feedback,
    GetCurrentTour,
    PostCurrentTour
  };
}