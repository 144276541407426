import dayjs from "components/Dayjs";
import { SET_DATE_RANGE } from "./constants";

interface IState {
  startDate: any;
  endDate: any;
}

export const initialState: IState = {
  startDate: dayjs().subtract(6, "days"),
  endDate: dayjs(),
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_DATE_RANGE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    default:
      throw new Error("Action invalid");
  }
};

export default reducer;
