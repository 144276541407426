import { useFetchJson } from "@haravan/reactapp";
import useSWR from "swr";

const SETTING_URL =
  import.meta.env.VITE_APP_API_SERVICES + `/shop`;


export function useShopInfo() {
  const fetchJson = useFetchJson();
  return useSWR(`${SETTING_URL}/info`, fetchJson, {
    revalidateOnFocus: false,
    refreshInterval: 0,
  },);
}