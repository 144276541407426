import { memo } from "react";

import HTable from "components/HTable";

import "./styles.scss";


function TableList(props) {
  const { dataRenderHeader, dataRenderBody, sortValue, onHandleSortTable } =
    props;

  const handleSortTable = (name) => {
    onHandleSortTable && onHandleSortTable(name);
  };

  const HeaderTable = () => {
    return (
      <tr>
        {dataRenderHeader.map((item, index) =>
          item.sortable ? (
            <th
              key={`header-${index}`}
              className="hrv-report-bg-white hrv-report-cursor-pointer"
              style={{
                width: item.width,
              }}
            >
              <div
                className="hrv-report-d-flex hrv-report-flex-row hrv-report-items-center"
                onClick={() => {
                  item.sortable && handleSortTable(item.dataIndex);
                }}
              >
                <span className="hrv-report-fs-12 hrv-report-text-color-black-4">
                  {item.label}
                </span>

                <span className={`hrv-report-ml-4`}>
                  {sortValue.dimension !== item.dataIndex ? (
                    <>{svgDoubleArrow}</>
                  ) : (
                    <span
                      className={`${sortValue.direction === "asc"
                        ? "hrv-report-table-asc"
                        : ""
                        }`}
                    >
                      {svgArrowDown}
                    </span>
                  )}
                </span>
              </div>
            </th>
          ) : (
            <th
              key={`header-${index}`}
              className="hrv-report-bg-white"
              style={{
                width: item.width,
              }}
            >
              <div className="hrv-report-d-flex hrv-report-flex-row hrv-report-items-center">
                <span className=" hrv-report-fs-12 hrv-report-text-color-black-4 hrv-report-fw-500">
                  {item.label}
                </span>
              </div>
            </th>
          ),
        )}
      </tr>
    );
  };

  const BodyTable = () => {
    return (
      <>
        {dataRenderBody?.map((itemRow, indexRow) => (
          <tr
            key={`row-${indexRow}`}
          // onClick={(e) => {
          //   if (typeof onClickRow === "function") {
          //     onClickRow(itemRow.product_id, e);
          //   }
          // }}
          >
            {dataRenderHeader.length > 0 &&
              dataRenderHeader.map((itemCol, indexCol) => {
                return (
                  <td
                    key={`row-${indexRow}_col-${indexCol}`}
                  //   className={`${
                  //     isProductIdOpen(itemRow) ? "no-border-b" : ""
                  //   }`}
                  >
                    {itemCol.render
                      ? itemCol.render(itemRow[itemCol.dataIndex], itemRow)
                      : itemRow[itemCol.dataIndex]
                    }
                  </td>
                );
              })}
          </tr>
        ))}
      </>
    );
  };

  return (
    <div style={{
      overflowX: 'auto'
    }}>
      <HTable className="hrv-report-bg-white hrv-report-scrollbar hrv-report-border-4 hrv-report-custom-product-status-table"
        renderTableHeader={<HeaderTable />}
        renderTableBody={<BodyTable />}
      />
    </div>
  );
}

export default memo(TableList);

const svgDoubleArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1583_19978)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1138 8L10.0004 5.88667L7.8871 8L6.94043 7.06L10.0004 4L13.0538 7.06L12.1138 8ZM7.8871 12L10.0004 14.1133L12.1138 12L13.0604 12.94L10.0004 16L6.9471 12.94L7.8871 12Z"
        fill="#9CA3B0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1583_19978">
        <rect width="20" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const svgArrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.9997 12.5001L6.46387 8.96422L7.64303 7.78589L9.9997 10.1434L12.3564 7.78589L13.5355 8.96422L9.9997 12.5001Z"
      fill="#2463EB"
    />
  </svg>
);
