import { useFetchJson } from "@haravan/reactapp";
import md5 from "helpers/md5";
import useSWR from "swr";

const PRODUCT_URL =
  import.meta.env.VITE_APP_API_SERVICES + `/call/com_api/products/`;


export function useProductFeatureImage(productId) {
  const fetchJson = useFetchJson();
  return useSWR(`${PRODUCT_URL}${productId}/images/feature`, fetchJson);
}

export function useProductFeatureImagesByIds(type, reportData) {
  var productIds: any = [];
  if (type === "product" && reportData !== undefined && reportData.datalink !== undefined) {
    reportData.datalink.forEach((item) => {
      productIds.push(item[0]);
    });
  }
  const body = { values: productIds };
  const key = md5(JSON.stringify(body));
  const fetchJson = useFetchJson();
  const fetcher = (url: string) =>
    fetchJson(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  return useSWR(productIds.length === 0 ? null : `${PRODUCT_URL}feature_images?k=${key}`, fetcher);
}