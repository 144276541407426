import { memo } from "react";

import "./styles.scss";

interface IProps {
  classNames?: string;
  iconState?: any;
  iconStateActive?: any;
  name: string;
  isActive: boolean;
  onClick: Function;
  isNew?: Boolean;
}

function HPickup(props: IProps) {
  const {
    classNames,
    iconState,
    iconStateActive,
    name,
    isActive,
    onClick,
    isNew,
  } = props;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button
      className={`${classNames} ${isActive ? "hrv-report-pickup-active" : "hrv-report-pickup"
        } ${isNew ? "hrv-menu-tab-new-dot" : ""}`}
      onClick={handleClick}
      style={{
        outline: "unset",
        appearance: "unset",
      }}
    >
      {iconState ? (
        <span style={{
          height: 32,
          marginRight: 4
        }} className="hrv-report-d-flex hrv-report-items-center">
          {isActive ? iconStateActive : iconState}
        </span>
      ) : (
        ""
      )}

      <span className="hrv-report-pickup_text">{name}</span>
    </button>
  );
}

export default memo(HPickup);
