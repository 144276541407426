import { useState, useEffect } from "react";
import * as ChartUtils from "helpers/chartutils";
import { FormatDayOfWeek, FormatHourOfDay, ShowDateRange, formatDate } from "helpers/helpers";
import { GetDateShowType } from "helpers/date";

const parseQueryData = (response, chartShowType, diffCounts?) => {
  let result: Array<any> = [];
  let data = response?.data;

  if (data && data.length) {
    data?.forEach((item) => {
      let newDate = new Date(item[0]);

      if (diffCounts !== undefined) {
        newDate = ChartUtils.dayAdd(newDate, diffCounts, chartShowType);
      }

      if (chartShowType !== "hour") newDate.setHours(0, 0, 0, 0);

      let formated = { date: newDate, total: 0, order: item[1] };

      result.push(formated);
    });
  }

  return result;
};

/**
 *
 * @param isLoading: xử lý bất đồng bộ
 * @param query: response từ data current
 * @param queryPeriod: response từ data kỳ trước
 * @param compareDateStart: Ngày so sánh bắt đầu
 * @param compareDateEnd: Ngày so sánh kết thúc
 * @param startdate: Ngày bắt đầu
 * @param enddate: Ngày kết thúc
 * @param chartSetting: Cấu hình chart setting bên trong labels
 * @param groupBy: selection theo groupBy của người dùng chọn
 * @param isCompare: Có hỗ trợ so sánh theo kỳ hay không
 * @returns
 */

function useDataChart(
  isDone,
  query: any,
  queryPeriod: any,
  compareDateStart,
  compareDateEnd,
  startdate,
  enddate,
  chartSetting,
  chartType = "line",
  groupBy = "Date",
  isCompare = true,
) {
  const { type } = GetDateShowType(startdate, enddate);

  const typeDate = ["Date", "Month", "Day", "Year", "Hour"];

  let dataFormat = "number";

  const [dataChart, setDataChart] = useState<any>({
    labels: [],
    datasets: [],
  });

  const [dataCurrent, setDataCurrent] = useState<any>([]);
  const [dataPrevious, setDataPrevious] = useState([]);

  const handleDataChart = async (dataCurrent, dataPrevious) => {
    const labels: any = await handleLabels(dataCurrent);

    setDataCurrent(dataCurrent);
    setDataPrevious(dataPrevious);

    let datasets = await handleDataSets(dataCurrent, dataPrevious);

    setDataChart({
      labels,
      datasets,
    });
  };

  const handleLabels = (dataCurrent) => {
    let labels: any = [];
    // ! Kiếm tra groupby xem hiện tại đang group theo thời gian hay không ?
    if (typeDate.includes(groupBy)) {
      let format = "DD/MM";
      let dataChartTime = ChartUtils.getDataByKey("date", dataCurrent);

      dataChartTime.forEach((time: any) => {
        if (type === "hour") {
          format = "HH:mm";
        }
        if (type === "day") {
          format = "DD/MM";
          if (startdate.format("YYYY") !== enddate.format("YYYY")) {
            format = "DD/MM/YYYY";
          }
        }
        if (type === "month") {
          format = "MM/YYYY";
        }

        if (type === "year") {
          format = "YYYY";
        }

        dataChart.labels.push(formatDate(time, format));

        labels.push(formatDate(time, format));
      });
    } else {

      const findPosition = dataCurrent?.headerreport?.findIndex(
        (y) => y.name === groupBy,
      );

      let dataAlter = dataCurrent?.data;

      if (groupBy === "DayOfWeek") {
        dataAlter = ChartUtils.addDataDayOfWeek(dataCurrent?.data, findPosition)
      }

      if (groupBy === "HourOfDay") {
        dataAlter = ChartUtils.addDataHourOfDay(dataCurrent?.data, findPosition)

      }


      dataAlter?.forEach((value) => {
        if (groupBy === "HourOfDay") {
          let result = FormatHourOfDay(value[findPosition]);


          labels.push(result);
        } else if (groupBy === "DayOfWeek") {
          let result = FormatDayOfWeek(value[findPosition]);


          labels.push(result);
        } else {
          labels.push(value[findPosition]);
        }
      });
    }

    return labels;
  };

  const handleDataSets = (currentFormated, previousFormated) => {
    let datasets: any = [];

    let currentColor = "#3C83F6";

    if (typeDate.includes(groupBy)) {
      let currentData = {
        ...chartSetting,
        data: ChartUtils.getDataByKey(
          "order",
          currentFormated,
          dataFormat === "percentage",
        ),
        label: ShowDateRange(startdate, enddate),
        datasetKeyProvider: "current",
        borderColor: currentColor,
      };

      datasets.push(currentData);

      if (
        typeof compareDateStart !== "undefined" &&
        typeof compareDateEnd !== "undefined"
      ) {
        let previousColor = "#7ED4FC";

        let previousData = {
          ...chartSetting,
          data: ChartUtils.getDataByKey(
            "order",
            previousFormated,
            dataFormat === "percentage",
          ),
          label: ShowDateRange(compareDateStart, compareDateEnd),
          datasetKeyProvider: "previous",
          borderColor: previousColor,
          borderDash: [3, 3],
        };

        datasets.push(previousData);
      }
    } else {
      const findPosition = currentFormated?.headerreport?.findIndex(
        (y) => y.name === groupBy,
      );

      let currentData = {
        ...chartSetting,
        data: [],
        label: [],
        datasetKeyProvider: "current",
        borderColor: currentColor,
      };

      let dataAlter = currentFormated?.data

      if (findPosition !== -1) {
        if (groupBy === "DayOfWeek") {
          dataAlter = ChartUtils.addDataDayOfWeek(dataAlter, findPosition)
        }

        if (groupBy === "HourOfDay") {
          dataAlter = ChartUtils.addDataHourOfDay(dataAlter, findPosition)
        }

        currentData = {
          ...currentData,
          data: dataAlter?.map((y) => y[findPosition + 1]),
          label: dataAlter?.map((y) => y[findPosition]),
        };
      }

      datasets.push(currentData);
    }

    return datasets;
  };

  useEffect(() => {
    if (["product", "channel", "staff", "location"].includes(chartType)) return;

    if (isDone) {
      // ! Add date when that date not exist in array
      let currentFormated: Array<any> = [];
      let previousFormated: Array<any> = [];

      if (typeDate.includes(groupBy)) {
        let currentData: any = parseQueryData(query, type);

        let previousData: any =
          queryPeriod !== undefined &&
            queryPeriod.data &&
            queryPeriod.data.length
            ? parseQueryData(queryPeriod, type)
            : [];

        if (currentData.length > 0) {
          currentFormated = ChartUtils.formatData(
            currentData,
            startdate,
            enddate,
            type,
          );
        }

        if (
          typeof compareDateEnd !== "undefined" &&
          typeof currentFormated !== "undefined" &&
          isCompare
        ) {
          ChartUtils.map2Chart(currentData, previousData);
        }

        if (
          previousData.length > 0 &&
          typeof compareDateEnd !== "undefined" &&
          typeof currentFormated !== "undefined"
        ) {
          previousFormated = ChartUtils.formatData(
            previousData,
            compareDateStart,
            compareDateEnd,
            type,
          );
        }
      } else {
        let currentData: any = query;

        let previousData: any =
          queryPeriod !== undefined &&
            queryPeriod?.data &&
            queryPeriod?.data.length
            ? queryPeriod
            : [];

        currentFormated = currentData;
        previousFormated = previousData;
      }

      handleDataChart(currentFormated, previousFormated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDone,
    query,
    queryPeriod,
    compareDateStart,
    compareDateEnd,
    startdate,
    enddate,
    chartType,
  ]);

  return { dataChart, dataCurrent, dataPrevious };
}

export default useDataChart;
