function Popup(props) {
  const { visible, children } = props;

  return (
    <>
      {visible ? (
        <div
          className="hrv-report-absolute"
          style={{
            background: "#FFFFFF",
            filter:
              "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08))",
            borderRadius: "16px",
            top: "calc(100% + 8px)",
            zIndex: 999999,
          }}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Popup;
