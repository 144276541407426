import { Skeleton } from "@chakra-ui/react";

function CardLoading() {
  return (
    <div>
      <div className="hrv-report-card hrv-report-cursor">
        <div className="hrv-report-mb-8">
          <Skeleton height='16px' width={"50%"} />
        </div>

        <div>
          <Skeleton height='16px' mb={5} />
        </div>
      </div>
    </div>
  );
}

export default CardLoading;
