const SvgOrder = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <g id="shopping_cart">
    <path id="Vector" d="M13.7881 10.8337C14.4131 10.8337 14.9631 10.492 15.2464 9.97533L18.2298 4.56699C18.5381 4.01699 18.1381 3.33366 17.5048 3.33366H5.17142L4.38809 1.66699H1.66309V3.33366H3.32975L6.32975 9.65866L5.20475 11.692C4.59642 12.8087 5.39642 14.167 6.66309 14.167H16.6631V12.5003H6.66309L7.57975 10.8337H13.7881ZM5.96309 5.00033H16.0881L13.7881 9.16699H7.93809L5.96309 5.00033ZM6.66309 15.0003C5.74642 15.0003 5.00475 15.7503 5.00475 16.667C5.00475 17.5837 5.74642 18.3337 6.66309 18.3337C7.57975 18.3337 8.32975 17.5837 8.32975 16.667C8.32975 15.7503 7.57975 15.0003 6.66309 15.0003ZM14.9964 15.0003C14.0798 15.0003 13.3381 15.7503 13.3381 16.667C13.3381 17.5837 14.0798 18.3337 14.9964 18.3337C15.9131 18.3337 16.6631 17.5837 16.6631 16.667C16.6631 15.7503 15.9131 15.0003 14.9964 15.0003Z" fill="#21C45D" />
  </g>
</svg>

const SvgDelivery = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M16.6663 6.66634H14.1663V3.33301H2.49967C1.58301 3.33301 0.833008 4.08301 0.833008 4.99967V14.1663H2.49967C2.49967 15.5497 3.61634 16.6663 4.99967 16.6663C6.38301 16.6663 7.49967 15.5497 7.49967 14.1663H12.4997C12.4997 15.5497 13.6163 16.6663 14.9997 16.6663C16.383 16.6663 17.4997 15.5497 17.4997 14.1663H19.1663V9.99967L16.6663 6.66634ZM16.2497 7.91634L17.883 9.99967H14.1663V7.91634H16.2497ZM4.99967 14.9997C4.54134 14.9997 4.16634 14.6247 4.16634 14.1663C4.16634 13.708 4.54134 13.333 4.99967 13.333C5.45801 13.333 5.83301 13.708 5.83301 14.1663C5.83301 14.6247 5.45801 14.9997 4.99967 14.9997ZM6.84967 12.4997C6.39134 11.9913 5.74134 11.6663 4.99967 11.6663C4.25801 11.6663 3.60801 11.9913 3.14967 12.4997H2.49967V4.99967H12.4997V12.4997H6.84967ZM14.9997 14.9997C14.5413 14.9997 14.1663 14.6247 14.1663 14.1663C14.1663 13.708 14.5413 13.333 14.9997 13.333C15.458 13.333 15.833 13.708 15.833 14.1663C15.833 14.6247 15.458 14.9997 14.9997 14.9997Z" fill="#F97415" />
</svg>

const SvgReturn = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M7.35866 8.33366L8.68366 9.65866L7.50033 10.8337L4.16699 7.50033L7.50033 4.16699L8.67533 5.34199L7.35866 6.66699H14.167C15.0837 6.66699 15.8337 7.41699 15.8337 8.33366V15.8337H14.167V8.33366H7.35866Z" fill="#14B8A5" />
</svg>

const SvgVerify = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M16.667 2.5H3.33366C2.41699 2.5 1.66699 3.25 1.66699 4.16667V15.8333C1.66699 16.75 2.41699 17.5 3.33366 17.5H16.667C17.5837 17.5 18.3337 16.75 18.3337 15.8333V4.16667C18.3337 3.25 17.5837 2.5 16.667 2.5ZM16.667 15.8333H3.33366V4.16667H16.667V15.8333Z" fill="#A855F7" />
  <path fillRule="evenodd" clipRule="evenodd" d="M16.1753 8.68333L14.992 7.5L12.3503 10.1417L11.1753 8.95833L10.0003 10.1333L12.3503 12.5L16.1753 8.68333Z" fill="#A855F7" />
  <path d="M8.33366 5.83333H4.16699V7.5H8.33366V5.83333Z" fill="#A855F7" />
  <path d="M8.33366 9.16667H4.16699V10.8333H8.33366V9.16667Z" fill="#A855F7" />
  <path d="M8.33366 12.5H4.16699V14.1667H8.33366V12.5Z" fill="#A855F7" />
</svg>

export const TEMPLATE_DASHBOARD_SHIPMENTS = [
  {
    name: "order",
    icon: SvgOrder,
    isShow: true,
    itemsInclude: 3,
  },
  {
    name: "shipment",
    icon: SvgDelivery,
    isShow: true,
    itemsInclude: 3,
  },
  {
    name: "return",
    icon: SvgReturn,
    isShow: true,
    itemsInclude: 2,
  },
  {
    name: "verification",
    icon: SvgVerify,
    isShow: true,
    itemsInclude: 2,
  },
  {
    name: "cod",
    icon: SvgReturn,
    isShow: false,
  },
];
