import { Skeleton, SkeletonText } from "@chakra-ui/react"
import useWindowDimension from "hooks/useWindowDimension";

function SkeletonWidget() {
    const { width } = useWindowDimension();

    return (
        <div className="hrv-report-whitebox-no-bs" style={{
            height: width / 4,
            minHeight: 200
        }}>
            <SkeletonText noOfLines={1} skeletonHeight={"16px"} />

            <div className="hrv-report-mt-16" style={{
                height: '90%'
            }} >
                <Skeleton height={"100%"} />
            </div>
        </div>
    )
}

export default SkeletonWidget