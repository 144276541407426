/**
 * Author: Mạnh Đạt
 * Created at: 26/06/2024
 */

/*
    Phases:
    Logic: API => data => UI
    1. When component is mounted fetching API call by reportNameSystem.
       Because every single report have call different Data and Header to build Data.
    2. isLoading: false => API fetching done
       if API done => Hooks will build data to draw chart
    3. isRender: true => data build done
       Data build done and component know chartype to render and render to UI
    4. if isRefresh: true => data will do restart with step 1
    ** When filter change chart will draw again and start with step 1.
*/

// ! Hooks
import { EffectCallback, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
import { useMetrics } from "hooks/useMetrics";
import { useBuildChart } from "./hooks/useBuildChart";
import useWindowDimensions from "hooks/useWindowDimension";

// ! Components
import WrapperSpinner from "components/Spinner";
import MetricsChart from "./components/Chart"
import MetricsLegend from "./components/Legends"
import { Box } from "@chakra-ui/react";
import HSelection from "components/Selection";
import HRVCheckbox from "components/Checkbox";

// ! Constants
import { HEIGHT_CHART, OPTIONS_DRAW_BY_REPORT, REPORT_CAN_CALL_API_TO_DRAW, REPORT_CAN_CHANGE_CHART_TYPE } from ".";
import { ALLOW_CHANGE_DISPLAY_CHART, DEFAULT_DATA_FETCHING } from "pages/ReportStandard/context/store/constants";
import { mapOrder, xmlRenderCheckbox } from "helpers/helpers";
import { TIMESTAMP_OBJECT } from "./constants";
import SwitchTabs from "components/SwitchTabs";

interface IProps {
    reportNameSystem: string
    reportId: string | number | undefined
    isLoadingTable: boolean
    dataTable: any
    displayChartMode: string
    measureDraw: any
    optionMeasure: any
    onHandleCheckboxChart: Function
    onhandleChangeChartType: Function
}

function MetricsV2(props: IProps) {
    const t = useTrans();

    const {
        reportNameSystem,
        reportId,
        isLoadingTable,
        dataTable,
        displayChartMode,
        measureDraw,
        optionMeasure,
        onHandleCheckboxChart,
        onhandleChangeChartType
    } = props;

    const { state, dispatch, actions } = useStore();
    const [displayChart, setDisplayChart] = useState(displayChartMode);
    const defaultTab = REPORT_CAN_CHANGE_CHART_TYPE.includes(reportNameSystem) ?
        OPTIONS_DRAW_BY_REPORT[reportNameSystem].find(y => y.chartType === displayChartMode) : "";
    const [tab, setTab] = useState(defaultTab.id);
    const { width } = useWindowDimensions();

    const {
        startdate,
        enddate,
        compareDateStart,
        compareDateEnd,
        optionCompare,
        measureSelected,
        groupBySelected,
        sortValue,
        discount,
        dataFetchingTable,
        isRefresh,
        measure,
    } = state;

    const isCallApi = REPORT_CAN_CALL_API_TO_DRAW.includes(reportNameSystem);

    const useEffectDetectFirstColumn = (callback: EffectCallback) => {
        return useEffect(callback, [dataTable.current.headerreport])
    };

    // * Auto nhận biết bảng thay đổi cột đầu tiên để thay đổi loại chart khi vẽ
    useEffectDetectFirstColumn(() => {
        if (!isLoadingTable) {
            const indexName = dataTable?.current.headerreport[0].name;

            if (TIMESTAMP_OBJECT.includes(indexName) && ALLOW_CHANGE_DISPLAY_CHART.includes(reportNameSystem)) {
                setDisplayChart("line");
            } else {
                if (REPORT_CAN_CHANGE_CHART_TYPE.includes(reportNameSystem)) {
                    const findChartType = OPTIONS_DRAW_BY_REPORT[reportNameSystem].find(y => y.id === tab);
                    setDisplayChart(findChartType.chartType);
                } else {
                    setDisplayChart(displayChartMode)
                }
            }
        }
    })

    const body = {
        ...dataFetchingTable,
        optionCompare,
        compareDateStart,
        compareDateEnd,
        startdate,
        enddate,
        groupBySelected,
        sortValue,
        discount,
        optionMeasure
    };

    const { isLoading: isLoadingAPI, isValidating, data: dataFromAPI, mutate }: any = useMetrics(
        {
            id: reportId,
            name: reportNameSystem,
            body,
            // * Sử dụng Data của Table thay cho việc gọi API mới. 
            // * VD: Báo cáo PageViews
            keepUsingTableData: !isCallApi,
            preventFetch: !isCallApi || JSON.stringify(dataFetchingTable) === JSON.stringify(DEFAULT_DATA_FETCHING),
            displayChart
        }
    );

    const {
        isRender,
        data,
        labels,
        fieldName,
        typeHorizontal,
        typeVertical,
        isGrouped,
        indexAxis
    } = useBuildChart({
        data: isCallApi ? dataFromAPI : dataTable,
        displayChart,
        isLoading: isLoadingTable || isLoadingAPI || isValidating,
        startdate,
        enddate,
        compareDateStart,
        compareDateEnd,
        reportNameSystem,
        measureSelected,
        optionMeasure,
    });

    useEffect(() => {
        dispatch(actions.changeStatusRefresh(false));
        if (isRefresh) {
            mutate()
        }
    }, [isRefresh])

    const calculateHeightLegend = useCallback(() => {
        switch (displayChart) {
            case "line":
                return 32;

            default:
                return 36
        }
    }, [displayChart]);

    const handleBuildCheckboxMeasure = (list) => {
        return list.map((y) => {
            let foundIndex = measureSelected?.findIndex((si: any) => si.id === y.id);

            return {
                key: y.measureField,
                value: foundIndex !== -1,
            };
        });
    };

    let getRenderCheckbox: Array<any> = useMemo(() => {
        let newMeasure = measure.filter((metric) => {
            return (
                metric.isDraw &&
                xmlRenderCheckbox[reportNameSystem]?.includes(metric.measureName)
            );
        });

        return mapOrder(
            handleBuildCheckboxMeasure(newMeasure),
            xmlRenderCheckbox[reportNameSystem],
            "key"
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measureSelected]);

    const disabled = (item) => {
        const atLeastOne = getRenderCheckbox.filter((y) => y.value).length === 1;
        return atLeastOne && item.value;
    };

    const handleChangeTab = (tabId, chartType) => {
        if (tabId === tab) return;
        setTab(tabId);
        setDisplayChart(chartType);

        const tabActive = OPTIONS_DRAW_BY_REPORT[reportNameSystem].find(y => y.id === tabId);

        onhandleChangeChartType && onhandleChangeChartType(tabActive);
    };

    return (
        <div
            className={`hrv-report-whitebox-no-bs hrv-report-mt-16 hrv-report-pb-16`}
        >

            <div className="hrv-report-row hrv-report-mb-16 hrv-report-justify-between">
                {measureDraw && measureDraw.length ?
                    <div className="hrv-report-d-flex hrv-report-justify-start hrv-report-lg-mb-0 hrv-report-mb-16 hrv-report-lg-col-4 hrv-report-col-12">
                        <Box>
                            <HSelection
                                key={`option_to_draw_chart`}
                                dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                                isOptGroup={false}
                                options={measureDraw}
                                name="measureName"
                                value="measureField"
                                defaultValue={optionMeasure.measureField}
                                onChange={(value) => {
                                    const findMeasure = measureDraw.find(measure => measure.measureField === value);
                                    dispatch(actions.setMeasureYAxis(findMeasure));
                                }}
                                matchWidth={true}
                                variant="metric-tertiary"
                                title={`${t("view_by")}:`}
                                placeholder={t("measure")}
                                minW={240}
                            />
                        </Box>
                    </div>

                    : <Box textStyle='h4'>{t(fieldName)}</Box>
                }

                {REPORT_CAN_CHANGE_CHART_TYPE.includes(reportNameSystem) ?
                    <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-lg-col-4 hrv-report-col-12">
                        <div style={{
                            width: width < 992 ? "100%" : 'fit-content',
                        }}>
                            <SwitchTabs
                                options={OPTIONS_DRAW_BY_REPORT[reportNameSystem]}
                                defaultTab={tab}
                                onHandleChangeTab={handleChangeTab}
                            />
                        </div>
                    </div>
                    :
                    ""
                }

                <div className="hrv-report-lg-col-4 hrv-report-col-12"></div>
            </div>

            {/* <div className="hrv-report-row hrv-report-mb-16">
                {measureDraw && measureDraw.length ?
                    <div className="hrv-report-mb-16">
                        <Box>
                            <HSelection
                                key={`option_to_draw_chart`}
                                dropdownClassName="hrv-report-no-scroll-selection_dropdown"
                                isOptGroup={false}
                                options={measureDraw}
                                name="measureName"
                                value="measureField"
                                defaultValue={optionMeasure.measureField}
                                onChange={(value) => {
                                    const findMeasure = measureDraw.find(measure => measure.measureField === value);
                                    dispatch(actions.setMeasureYAxis(findMeasure));
                                }}
                                matchWidth={true}
                                variant="metric-tertiary"
                                title={`${t("view_by")}:`}
                                placeholder={t("measure")}
                                minW={180}
                            />
                        </Box>
                    </div>

                    : <Box textStyle='h4'>{t(fieldName)}</Box>
                }
            </div> */}


            {getRenderCheckbox && getRenderCheckbox.length ? (
                <div className="hrv-report-container-checkbox hrv-report-row hrv-report-mb-8">
                    {getRenderCheckbox?.map((metric, idx) => {
                        return (
                            <HRVCheckbox
                                className={`hrv-report-d-inline-flex hrv-report-mt-8 hrv-report-col-12 hrv-report-sm-col-6 hrv-report-lg-col-4 hrv-report-py-2`}
                                title={metric.key}
                                checked={metric.value}
                                onChange={({ check }) => {
                                    onHandleCheckboxChart &&
                                        onHandleCheckboxChart({
                                            name: metric.key,
                                            check: check,
                                            type: "measure",
                                        });
                                }}
                                disabled={disabled(metric) || !isRender}
                            />
                        );
                    })}
                </div>
            ) : (
                <></>
            )}

            {!isRender ? (
                <div
                    className="hrv-report-d-flex hrv-report-items-center"
                    style={{
                        minHeight: `${HEIGHT_CHART[displayChart] + calculateHeightLegend()}px`
                    }}
                >
                    <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-items-center hrv-report-w-100" style={{
                        height: '100%'
                    }}>
                        <WrapperSpinner />
                    </div>

                </div>
            ) : (
                <>
                    <MetricsChart
                        data={data}
                        labels={labels}
                        displayChart={displayChart}
                        optionCompare={optionCompare}
                        typeVertical={typeVertical}
                        typeHorizontal={typeHorizontal}
                        fieldName={fieldName}
                        heightLegend={calculateHeightLegend()}
                        reportNameSystem={reportNameSystem}
                        isGrouped={isGrouped}
                        startdate={startdate}
                        enddate={enddate}
                        indexAxis={indexAxis}
                        compareDateStart={compareDateStart}
                        compareDateEnd={compareDateEnd}
                        optionMeasure={optionMeasure}
                    />

                    <MetricsLegend
                        displayChart={displayChart}
                        data={data}
                        labels={labels}
                        startdate={startdate}
                        enddate={enddate}
                        compareDateEnd={compareDateEnd}
                        compareDateStart={compareDateStart}
                        reportNameSystem={reportNameSystem}
                    />
                </>
            )}
        </div>
    )
}

export default memo(MetricsV2)