import dayjs from "components/Dayjs";

import { useEffect, useState } from "react";
import { useTrans } from "locales/hook";
import { useStore } from "pages/ReportStandard/context/store";
import useWindowDimensions from "hooks/useWindowDimension";
import { useParams } from "react-router-dom";
import { useLoadMoreCoupon } from "components/CouponApply/swr/useLoadMoreCoupon";

import { ReportDatePicker } from "../components";

import { dayAdd } from "helpers/chartutils";
import { GetDateShowType } from "helpers/date";
import { getAdjustedComparisonDates } from "helpers/helpers";
import CouponApply from "components/CouponApply";


interface IRenderFilterByDiscountProps {
    onChangeDate: Function;
    dateStart: string;
    dateEnd: string;
    compareDateEnd?: string;
    compareDateStart?: string;
    onChangeCompareDate?: Function;
    autoCompleteFilterPanel?: any;
    filterTag?: any;
    onHandleChangeFilter?: Function
}

function RenderFilterByDetailDiscount(props: IRenderFilterByDiscountProps) {
    const t = useTrans();
    const { id }: any = useParams();

    const { state, actions, dispatch } = useStore();
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        dispatch(actions.setDimensionSelected("DiscountId"));
        dispatch(actions.setOperatorSelected("is"));
    }, [])


    const { width } = useWindowDimensions();
    const { isValidating, data, setSize, size } = useLoadMoreCoupon({
        preventFetch: false,
        dimension: "DiscountId",
        reportId: id,
        keyword,
    });

    let coupons = [];

    if (data && data.length) {
        for (let index = 0; index <= size - 1; index++) {
            const elements = data[index]?.data || [];
            coupons = coupons.concat(elements);
        }
    } else {
        coupons = []
    }

    const { optionCompare } = state;
    const {
        onChangeDate,
        dateStart,
        dateEnd,
        compareDateStart,
        compareDateEnd,
        onChangeCompareDate,
        onHandleChangeFilter,
        filterTag,

        // onHandleChangeCheckboxInFilterTypeSingle,
    } = props;

    let { duration: diffCounts, type: dateShowType } = GetDateShowType(
        dateStart,
        dateEnd
    );

    const [ranges, setRanges] = useState({
        [t("noCompare")]: [undefined, undefined],
        [t("previousPeriod")]: [
            dayjs(dayAdd(dateStart, diffCounts * -1, dateShowType)),
            dayjs(dayAdd(dateEnd, diffCounts * -1, dateShowType)),
        ],
        [t("previousYear")]: [
            dayjs(dateStart).subtract(1, "year"),
            dayjs(dateEnd).subtract(1, "year"),
        ],
    });

    useEffect(() => {
        const rangesPrevious = handlePreviousPeriodByRanges(dateStart, dateEnd);

        // ! Thay đổi lại bộ range bởi vì khi ngày thay đổi thì bộ option range của kỳ sẽ thay đổi => không cố định mà sẽ flexible theo bộ lọc ngày chuẩn
        setRanges(rangesPrevious);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd]);

    useEffect(() => {
        // ! Mặc định kỳ trước
        dispatch(actions.setOptionCompare(t("previousPeriod")));
        const getPreviousPeriod = getAdjustedComparisonDates(dateStart, dateEnd, diffCounts, dateShowType)

        onChangeCompareDate &&
            onChangeCompareDate(
                dayjs(getPreviousPeriod["start"]),
                dayjs(getPreviousPeriod["end"]),
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChangeDate = (start, end) => {
        // ! Thay đổi ngày lọc
        onChangeDate && onChangeDate(start, end);
        if (!optionCompare.length) return;

        if (optionCompare === t("custom") || optionCompare === t("noCompare")) {
            return;
        } else {
            dispatch(actions.setOptionCompare(t("previousPeriod")));

            const rangesPrevious = handlePreviousPeriodByRanges(start, end)

            onChangeCompareDate &&
                onChangeCompareDate(
                    rangesPrevious[t("previousPeriod")][0],
                    rangesPrevious[t("previousPeriod")][1],
                );
        }
    };

    const handleChangeInput = (keyword, page) => {
        setSize(page);
        setKeyword(keyword);
    }

    const handlePreviousPeriodByRanges = (newStartDate, newEndDate) => {
        let { duration: diffCounts, type: dateShowType } = GetDateShowType(
            newStartDate,
            newEndDate,
        );

        let newRangesPrevious = {
            [t("noCompare")]: [undefined, undefined],
            [t("previousPeriod")]: [
                dayjs(dayAdd(newStartDate, diffCounts * -1, dateShowType)),
                dayjs(dayAdd(newEndDate, diffCounts * -1, dateShowType)),
            ],
            [t("previousYear")]: [
                (dayjs(newStartDate).subtract(1, "year")),
                (dayjs(newEndDate).subtract(1, "year")),
            ],
        };

        const { start, end } = getAdjustedComparisonDates(newStartDate, newEndDate, diffCounts, dateShowType)

        newRangesPrevious[t("previousPeriod")] = [
            start,
            end
        ]


        return newRangesPrevious;

    }


    // Bấm "Áp dụng" bộ lọc Coupon
    const handleOnClickCheckbox = (value) => {
        onHandleChangeFilter && onHandleChangeFilter("DiscountId", value, coupons);
    };

    /**
   *
   * @param type: Loại của checkbox khi click: groupby hay measure
   * @param groupAreaName: Tên của cái group cần thay đổi (Chỉ aply cho type === groupname) đối với Measure thì thay đổi trực tiếp trong field luôn
   * @param value: Value gửi qua cho hàm này
   */
    // const handleClickSingle = ({ type, groupAreaName, value }: any) => {
    //     onHandleChangeCheckboxInFilterTypeSingle &&
    //         onHandleChangeCheckboxInFilterTypeSingle({
    //             type,
    //             groupAreaName,
    //             value,
    //         });
    // };

    return (
        <div
            className="hrv-report-items-center hrv-report-responsive-wrapper-filter hrv-report-d-flex hrv-report-flex-wrap"
            style={{
                width: "100%",
            }}
        >
            <div className="hrv-report-responsive-item-filter hrv-report-mr-8">
                <ReportDatePicker
                    onCallback={handleOnChangeDate}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    isShowIcon={true}
                    placement="center"
                    autoApply={width < 856}
                />
            </div>

            <div className="hrv-report-responsive-item-filter hrv-report-mr-8">
                <ReportDatePicker
                    dateStart={compareDateStart}
                    dateEnd={compareDateEnd}
                    customText={`${t("So sánh")}: `}
                    ranges={ranges}
                    isComparePeriod={true}
                    placement="center"
                    onCallback={(start, end, label) => {
                        if (label !== undefined) {
                            dispatch(actions.setOptionCompare(label));
                        }

                        if (label === t("noCompare")) {
                            onChangeCompareDate &&
                                onChangeCompareDate(undefined, undefined, label);
                        } else {
                            onChangeCompareDate && onChangeCompareDate(start, end, label);
                        }
                    }}
                    isShowIcon={false}
                    autoApply={width < 856}
                />
            </div>

            <div className="hrv-report-responsive-item-filter">
                <CouponApply
                    placeholder={t("Chọn khuyến mãi")}
                    onChange={handleChangeInput}
                    isLoading={isValidating}
                    result={coupons}
                    discount={
                        filterTag
                            ?.find((y) => y.dimension === "DiscountId")
                            ?.query || []
                    }
                    currentPage={size}
                    size="m"
                    onSubmit={handleOnClickCheckbox}
                />
            </div>
        </div>
    );
}

export default RenderFilterByDetailDiscount;
