import { useStore } from "pages/ReportStandard/context/store";

import ReportPromotionChart from "./components/chart";
import EmptyTemplate from "components/EmptyTemplate";

import { isEmpty } from "helpers/helpers";
import { useTrans } from "locales/hook";

interface IProps {
  query: any;
  queryPeriod: any;
  isLoading: boolean | undefined;
}

function MetricsDiscount(props: IProps) {
  const { query, queryPeriod, isLoading } = props;

  const { state } = useStore();
  const t = useTrans();

  const { discount, optionMeasure } = state;

  return (
    <div className="hrv-report-whitebox-no-bs">
      {isEmpty(discount) ? (
        <EmptyTemplate
          isHaveColor={true}
          text={t("Chọn CTKM / Mã giảm giá để xem báo cáo tương ứng")}
        />
      ) : (
        <ReportPromotionChart
          query={query}
          queryPeriod={queryPeriod}
          isLoading={isLoading}
          measureName={optionMeasure.measureName}
        />
      )}
    </div>
  );
}

export default MetricsDiscount;
