import { memo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

// ! Components
import { useMediaQuery } from "@chakra-ui/react";
import DesktopFilter from "./screen/Desktop";
import MobileFilter from "./screen/Mobile";

const DEFAULT_FILTER_SCORE = { conjunction: 'and', dimension: "", query: [] };

interface IProps {
    optionsDimension: Array<any>;
    filterDefault: Array<any>;
    dateStart: any;
    dateEnd: any;
    reportId?: string | number;
    submitFilterV2?: any;
    disableFilterField?: any;
    reportNameSystem: string
}

function FilterV2(props: IProps) {
    const [isDesktop] = useMediaQuery('(min-width: 556px)');

    const { optionsDimension, filterDefault, dateStart, dateEnd, reportId, submitFilterV2, disableFilterField, reportNameSystem } = props;

    const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
        defaultValues: {
            filter: filterDefault,
        },
    });

    const HGetValues: any = getValues;

    const { fields, append, remove } = useFieldArray<any>({
        control,
        name: "filter",
    });

    const onSubmit = (data) => {
        submitFilterV2 && submitFilterV2(data.filter);
    };

    // const handleCloseModal = () => {
    //     reset({ filter: filterDefault });

    //     setTimeout(() => {
    //         onClose();
    //     }, 10)
    // };


    return (
        <>
            {isDesktop ? <>
                <DesktopFilter
                    handleSubmit={handleSubmit(onSubmit)}
                    fields={fields}
                    watch={watch}
                    dateEnd={dateEnd}
                    remove={remove}
                    setValue={setValue}
                    control={control}
                    optionsDimension={optionsDimension}
                    reportId={reportId}
                    append={append}
                    getValues={HGetValues}
                    dateStart={dateStart}
                    defaultFilter={DEFAULT_FILTER_SCORE}
                    reset={reset}
                    filterDefault={filterDefault}
                    disableFilterField={disableFilterField}
                    reportNameSystem={reportNameSystem}
                />
            </> :
                <MobileFilter
                    defaultVisible={false}
                    handleSubmit={handleSubmit(onSubmit)}
                    fields={fields}
                    watch={watch}
                    dateEnd={dateEnd}
                    remove={remove}
                    setValue={setValue}
                    control={control}
                    optionsDimension={optionsDimension}
                    reportId={reportId}
                    append={append}
                    getValues={HGetValues}
                    dateStart={dateStart}
                    defaultFilter={DEFAULT_FILTER_SCORE}
                    reset={reset}
                    filterDefault={filterDefault}
                    disableFilterField={disableFilterField}
                    reportNameSystem={reportNameSystem}
                />
            }
        </>

    )
}

export default memo(FilterV2);