import React from "react";
import ReactDOM from "react-dom/client";
import { EmbedAppProvider } from "@haravan/reactapp";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { createTransport } from "@sentry/react";

/**
 * import Chakra UI library 
 */
import { ChakraBaseProvider } from '@chakra-ui/react'

import App from "./App";
import localeTranslations from "locales";

import reportWebVitals from "./reportWebVitals";
import { theme } from 'theme';
import { MENU_NAVIGATIONS } from "routes";

import "./index.css";
import "./assets/styles/common.scss";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const query = new URLSearchParams(window.location.search);
const pathname = window.location.pathname.replace(/^\//, '');
let returnUrl = `https://${query.get('shop')}/admin/${import.meta.env.VITE_APP_HARAVAN_CLIENT_NAME}/${pathname}`;
if (pathname === 'shipments/dashboard') {
  returnUrl = `https://${query.get('shop')}/admin/${pathname}`;
  document.title = 'Tổng quan vận chuyển';
} else {
  if (pathname === 'dashboard') {
    document.title = 'Bảng phân tích';
  } else {
    document.title = 'Danh sách báo cáo';
  }
}

// ! Chặn gửi sentry khi offline
function makeOfflineAwareTransport(options) {
  const makeRequest: any = (request) => {
    // Kiểm tra trạng thái mạng
    if (!navigator.onLine) {
      console.warn("Network offline. Skipping Sentry event.");
      return Promise.resolve();
    }

    // online => sẽ gửi request khi lỗi
    const requestOptions = {
      body: request.body,
      method: 'POST',
      referrerPolicy: 'origin',
      headers: options.headers,
      ...options.fetchOptions,
    };

    return fetch(options.url, requestOptions).then((response) => {
      return {
        statusCode: response.status,
        headers: {
          'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
          'retry-after': response.headers.get('Retry-After'),
        },
      };
    });
  };

  return createTransport(options, makeRequest);
}

Sentry.init({
  dsn: "https://cc1483978ccf16d67a4375d1b363ba1a@o213909.ingest.us.sentry.io/4507735656366080",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  sampleRate: 0.1,
  tracesSampleRate: 0.01,
  enabled: import.meta.env.VITE_APP_ENV === 'production',
  transport: (options) => makeOfflineAwareTransport(options),
});

// ! Workflow: EmbedAppProvider will run app and verify user with thier token -> access the app
root.render(
  <EmbedAppProvider
    options={{
      myharavan: import.meta.env.VITE_APP_MY_HARAVAN,
      client_id: `${import.meta.env.VITE_APP_HARAVAN_CLIENT_ID}`,
      autoRedirectInstall: true,
      installUrl: `${import.meta.env.VITE_APP_API_SERVICES}/auth/install?returnUrl=${encodeURIComponent(returnUrl)}`,
      fetchCors: true,
      navigations: MENU_NAVIGATIONS
    }}
    translations={localeTranslations}
    fallbackLocale={"vi"}
  >
    <ChakraBaseProvider theme={theme}>
      <App />
    </ChakraBaseProvider>
  </EmbedAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
