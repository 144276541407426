import { useMediaQuery } from "@chakra-ui/react";
import { useStore } from "pages/ReportStandard/context/store";

import DesktopColumnShow from "./screen/Desktop";
import MobileColumnShow from "./screen/Mobile";

import { getPermissionColumn } from "helpers/helpers";

function ColumnShowV2(props) {
    const {
        groupAreas,
        groupBy,
        reportNameSystem,
        measure,
        measureSelected,
        groupBySelected,
        handleChangeColumnShowModal,
        optionYAxis,
        onChangeYAxis,
        useMetricToDrawChart = true,
        useMetricToDrawHeatMap = false,
        optionsHeatMap,
        onChangeHeatmaps,
    }
        = props;

    const [isDesktop] = useMediaQuery('(min-width: 556px)');

    const { state } = useStore();

    const { info, defaultMeasure, defaultGroupBy } = state;

    const { groupPropertyInteractions, measureInteractions } = info;
    let groupByDisplay = getPermissionColumn("groupBy", "hide", reportNameSystem);
    let metricDisplay = getPermissionColumn("measure", "hide", reportNameSystem);


    const handleApply = (groupBySelectedItems, measureSelectedItems) => {
        handleChangeColumnShowModal && handleChangeColumnShowModal(groupBySelectedItems, measureSelectedItems);

    }

    return (
        <>
            {isDesktop ? <DesktopColumnShow
                groupAreas={groupAreas}
                groupBy={groupBy}
                onHandleApply={handleApply}
                reportNameSystem={reportNameSystem}
                measure={measure}
                groupBySelected={groupBySelected}
                measureSelected={measureSelected}
                groupPropertyInteractions={groupPropertyInteractions}
                measureInteractions={measureInteractions}
                defaultGroupBy={defaultGroupBy}
                defaultMeasure={defaultMeasure}
                optionYAxis={optionYAxis}
                onChangeYAxis={onChangeYAxis}
                useMetricToDrawChart={useMetricToDrawChart}
                useMetricToDrawHeatMap={useMetricToDrawHeatMap}
                optionsHeatMap={optionsHeatMap}
                onChangeHeatmaps={onChangeHeatmaps}
                isDisplay={groupByDisplay || metricDisplay}
            /> : <MobileColumnShow
                isDisplay={groupByDisplay || metricDisplay}
                groupAreas={groupAreas}
                groupBy={groupBy}
                onHandleApply={handleApply}
                reportNameSystem={reportNameSystem}
                measure={measure}
                groupBySelected={groupBySelected}
                measureSelected={measureSelected}
                groupPropertyInteractions={groupPropertyInteractions}
                measureInteractions={measureInteractions}
                defaultGroupBy={defaultGroupBy}
                defaultMeasure={defaultMeasure}
                optionYAxis={optionYAxis}
                onChangeYAxis={onChangeYAxis}
                useMetricToDrawChart={useMetricToDrawChart}
                useMetricToDrawHeatMap={useMetricToDrawHeatMap}
                optionsHeatMap={optionsHeatMap}
                onChangeHeatmaps={onChangeHeatmaps}
            />}
        </>


    )
}

export default ColumnShowV2