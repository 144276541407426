import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import * as ChartUtils from "helpers/chartutils";

import {
  FormatDecimalQuantity,
  GetCurrencyFormater,
  PointsToCommas,
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "helpers/helpers";

import { memo, useRef, useState } from "react";
import useWindowDimensions from "hooks/useWindowDimension";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartUtils.RegisterTooltipPositioner();

const lengthText = 20;

function DoughnutChart(props) {
  const {
    prodata,
    height,
    unit,
    isShowLabel = true,
    // isShowConnectingLine = false,
    isShowPercentage = false,
    typeHorizonal
  } = props;

  let formater = GetCurrencyFormater();
  const { width: widthScreen } = useWindowDimensions();
  const [heightLegend, setHeightLegend] = useState(0);
  const [tooltip, setTooltip] = useState({
    x: 0,
    y: 0,
    isDisplay: false,
    title: ""
  });
  const refChart = useRef<any>();

  const data = {
    labels: prodata.labels, // Group các channel
    label: unit ? unit : prodata.nameField,
    datasets: [
      {
        label: unit ? unit : prodata.nameField,
        data: prodata.data, // Group value của các channel
        backgroundColor: prodata.color,
        borderWidth: 0,
        hoverOffset: 1,
      },
    ],
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(
      "div.tooltip-container",
    );

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.classList.add("tooltip-container");
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
      tooltipEl.style.padding = "12px 12px 2px 12px";

      const table = document.createElement("table");
      table.style.margin = "0px";
      table.style.width = "100%";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const embedUnitToValue = (value, isShowUnit = true) => {
    let result = value;

    if (unit && isShowUnit) {
      result = formatNumber(value, false) + " " + unit;
    } else {
      if (typeHorizonal === "money") {
        result = PointsToCommas(formater.format(value));
      }

      if (typeHorizonal === "percent") {
        result = formatNumber(value, false) + "%";
      }
    }

    return result
  }

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b) => b.lines);
      const tableHead = document.createElement("thead");

      const tableBody = document.createElement("tbody");

      bodyLines.forEach((_, i) => {
        const colors = tooltip.labelColors[i];
        const dataPoint = tooltip.dataPoints[i];

        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.marginRight = "10px";
        span.style.height = "12px";
        span.style.width = "12px";
        span.style.borderRadius = "2px";
        span.style.display = "block";
        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = "0px";

        const td = document.createElement("td");
        td.style.borderWidth = "0px";
        let value = embedUnitToValue(dataPoint.raw);

        const labelCurrentContainer = document.createElement("div");
        labelCurrentContainer.classList.add("hrv-report-mb-10");

        // ! Show phần trăm thì sẽ show tất cả các label kèm value
        if (isShowPercentage) {
          const total = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
          let content = "";
          let summaryTotal: any = 0;

          chart.data.labels.forEach((label, i) => {
            let value = chart.data.datasets[0].data[i];
            let color = chart.data.datasets[0].backgroundColor[i];

            // let totalWithoutLastItem = total;

            if (i !== chart.data.labels.length - 1) {
              summaryTotal += FormatDecimalQuantity(formatPercentage(value, total).replace("%", ""));
            }

            let calcPercentageOfLastItem = FormatDecimalQuantity(100 - summaryTotal);

            const showPercentage = i === chart.data.labels.length - 1 ? calcPercentageOfLastItem + "%" : formatPercentage(value, total)

            const showValue = embedUnitToValue(value, false)

            let isActive = label === dataPoint.label;
            content += `<div class="hrv-report-doughnut-tooltip-group
              ${isActive ? "hrv-report-doughnut-tooltip-group-active" : ""}
            ">
              <div class="hrv-report-doughnut-tooltip-group-color" style="background: ${color}"></div>
              <div class="hrv-report-doughnut-tooltip-group-label">${label}</div>
              <div class="hrv-report-doughnut-tooltip-group-value">${showValue}</div>
              <div class="hrv-report-doughnut-tooltip-group-percentage">${showPercentage}</div>
            </div>`
          });

          labelCurrentContainer.innerHTML += `
              ${content}
          `
        } else {
          labelCurrentContainer.innerHTML += `
          <div class="hrv-report-d-flex hrv-report-items-center hrv-report-w-100 hrv-report-wrapper-tooltip hrv-report-flex-wrap">
          <div class="hrv-report-tooltip-outer">${span.outerHTML}</div>
          <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-mr-20 hrv-report-tooltip-label">${dataPoint.label}</div>
          <div class="hrv-report-mb-0 hrv-report-fs-14 hrv-report-fw-400 hrv-report-ml-auto hrv-report-tooltip-value">${value}</div>
        </div>
     </div>`;
        }

        td.appendChild(labelCurrentContainer);
        tr.appendChild(td);

        tableBody.appendChild(tr);
      });
      const tableRoot = tooltipEl.querySelector("table");
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      const { offsetTop: positionY } = chart.canvas;
      const { x, y } = chart.tooltip._eventPosition;

      tooltipEl.style.opacity = 1;
      tooltipEl.style.zIndex = 2;

      if (widthScreen > 758) {
        // * Độ rộng của tooltip
        const widthOfTooltip = tooltipEl.clientWidth;

        // * Độ rộng của 1 wrapper bọc bên ngoài chart (có thể là widget hoặc 1 box nào đó ???)
        let widthOfWrapperChart = 0;

        if (refChart && refChart.current) {
          widthOfWrapperChart = refChart.current.clientWidth;
        }

        let isFloatRight =
          x + widthOfTooltip > widthOfWrapperChart - x / 2 + 20;

        if ((widthOfTooltip > widthOfWrapperChart / 2) && (x < widthOfWrapperChart / 2)) {
          isFloatRight = false
        };

        if (isFloatRight) {
          // Bên phải
          tooltipEl.style.left = x - widthOfTooltip / 2 + 20 + "px";
        } else {
          // Bên trái
          tooltipEl.style.left = x + widthOfTooltip / 2 - 20 + "px";
        }
        if (y + tooltipEl.clientHeight > height) {
          tooltipEl.style.top = tooltip.caretY - tooltipEl.clientHeight + "px";
        } else {
          tooltipEl.style.top = positionY + tooltip.caretY + "px";
          tooltipEl.style.font = tooltip.options.bodyFont.string;
        }
        // tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
      } else {
        tooltipEl.style.left = widthScreen / 2 + "px";
        tooltipEl.style.top = 30 + "px";
      }
    }
  };

  // Plugin conneting line for labels (Tạm thời đóng chức năng này)
  // const doughnutLabelsLine: any = {
  //   id: "doughnutLabelsLine",
  //   afterDatasetsDraw(chart, args, options) {
  //     const { ctx, chartArea: { width, height } } = chart;

  //     chart.data.datasets.forEach((dataset, i) => {
  //       const meta = chart.getDatasetMeta(i);
  //       meta.data.forEach((datapoint, index) => {
  //         ctx.fillStyle = 'rgb(0, 0, 0)';
  //         let displayText = "";

  //         if (isShowPercentage) {
  //           const total = dataset.data.reduce((a, b) => a + b, 0);

  //           const percentage = formatPercentage(dataset.data[index], total);
  //           displayText = percentage
  //         } else {
  //           displayText = dataset.data[index]
  //         }


  //         const { x, y } = datapoint.tooltipPosition();
  //         const halfwidth = width / 2;
  //         const halfheight = height / 2;

  //         const xLine = x >= halfwidth ? x + 20 : x - 20
  //         const yLine = y >= halfheight ? y + 20 : y - 20
  //         const extraLine = x >= halfwidth ? 20 : -20

  //         ctx.textAlign = 'left';
  //         ctx.textBaseline = 'middle';
  //         const postionOfText = xLine + extraLine * (x >= halfwidth ? 1.5 : 3.25);

  //         ctx.fillText(displayText, postionOfText, yLine);

  //         ctx.beginPath();
  //         ctx.moveTo(x, y);
  //         ctx.lineTo(xLine, yLine);
  //         ctx.lineTo(xLine + extraLine, yLine);

  //         ctx.strokeStyle = dataset.backgroundColor[index];
  //         ctx.stroke();
  //       });
  //     });
  //   }
  // }

  const options = {
    maintainAspectRatio: false, // Set to false to allow custom width and height
    responsive: true,
    cutout: height / 3.25,
    interaction: {
      mode: "index",
      intersect: true,
    },
    layout: {
      padding: 8,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 12,
          boxHeight: 12,
          generateLabels: function (chart) {
            const getLegendHeight = chart.legend.height;

            setHeightLegend(getLegendHeight);

            if (isShowLabel) {
              const labels = chart.data.labels;
              const colors = chart.data.datasets[0].backgroundColor;


              return labels.map((label, index) => ({
                text: label.length > lengthText ? label.slice(0, lengthText) + "..." : label,
                fillStyle: colors[index],
                borderRadius: 2,
                strokeStyle: colors[index]
              }));
            }
          },
        },
        onHover: (event, legendItem, legends) => {
          if (legendItem.text.length > lengthText) {
            const findIndexLegendInHover = legends.legendItems.findIndex(y => y.text.toLowerCase() === legendItem.text.toLowerCase())
            setTooltip({
              x: legends.legendHitBoxes[findIndexLegendInHover].left,
              y: legends.legendHitBoxes[findIndexLegendInHover].top,
              isDisplay: true,
              title: event.chart.data.labels[findIndexLegendInHover]
            });
            return
          } else {
            setTooltip({
              x: 0,
              y: 0,
              isDisplay: false,
              title: ""
            })
          }
        }
      },
      tooltip: {
        enabled: false,
        position: "cursor",
        external: externalTooltipHandler,
      },
    },
  } as any;

  let summaryCount: any = data.datasets[0].data.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  );

  // Get units
  if (unit) {
    summaryCount = formatNumber(summaryCount, false) + " " + unit;
  } else {
    if (typeHorizonal === "money") {
      if (Math.round(summaryCount).toString().length > 10) {
        summaryCount = formatCurrency(Math.round(summaryCount), true)
      } else {
        summaryCount = PointsToCommas(formater.format(summaryCount));
      }
    }

    if (typeHorizonal === "percent") {
      summaryCount = formatNumber(summaryCount, false) + "%";
    }
  }

  // const plugins: any = [
  //   (isShowConnectingLine && widthScreen > 550 ? doughnutLabelsLine : {})
  // ];

  const extendPositionCenter = isShowPercentage ? Math.floor(height * 0.05) : 0;

  return (
    <>
      <div style={{
        maxHeight: height,
        height,
      }}
        ref={refChart}
        className="hrv-report-relative"
      >
        <Doughnut
          options={options}
          data={data}
        // plugins={plugins}
        />

        <div
          className="hrv-report-absolute hrv-report-text-center"
          style={{
            top: `calc((${height}px - ${heightLegend}px - ${Math.floor(height * 0.07) + extendPositionCenter}px) / 2)`,
            left: '50%',
            transform: "translateX(-50%)"
          }}>

          <h2 style={{
            fontSize: Math.floor(height * 0.07),
            fontWeight: 600,
            color: "#212121"
          }} className="hrv-report-mb-0">
            {summaryCount}
          </h2>

          {isShowPercentage
            && <p
              style={{
                fontSize: Math.floor(height * 0.05),
                color: "rgb(17, 24, 39)"
              }}
            >100%
            </p>
          }

        </div>

        <ChakraTooltip hasArrow label={tooltip.title} >
          <div className="hrv-report-absolute" style={{
            top: tooltip.y,
            left: tooltip.x,
            opacity: 0,
          }}>
            {tooltip.title.slice(0, lengthText) + "..."}
          </div>
        </ChakraTooltip>
      </div>

    </>
  );
}

export default memo(DoughnutChart);
