import { useTrans } from 'locales/hook';
import { Button } from '@chakra-ui/react';
import { queryString } from 'helpers/helpers';

import InventoryAdvanceSVG from "assets/images/inventory_advance.svg";

function ModeAdvanceInventory() {
    const t = useTrans();
    const shop: any = queryString.shop;

    return (
        <div
            style={{
                minHeight: 324,
            }}
            className="
                    hrv-report-w-full 
                    hrv-report-d-flex
                    hrv-report-items-center
                    hrv-report-py-40
                    hrv-report-flex-col
                 "
        >
            <div className="hrv-report-d-flex hrv-report-md-justify-end hrv-report-justify-center"
                style={{
                    width: 180,
                    height: 180
                }}
            >
                <InventoryAdvanceSVG />
            </div>

            <div className="hrv-report-flex-col hrv-report-d-flex hrv-report-items-center">
                <p className='hrv-report-mb-4 hrv-report-fs-14 hrv-report-text-color-black-6'>
                    {t("Reporting_is_only_supported_in_mode")} <strong>{t("Advanced_Inventory")}</strong>.
                </p>

                <p className='hrv-report-mb-8 hrv-report-fs-14 hrv-report-text-color-black-6'>
                    {t("To_view_reports")}
                </p>
                <div>
                    <Button
                        variant='default-default'
                        style={{
                            display: 'inline-flex'
                        }}
                        size={"l"}
                        onClick={() => {
                            window.open(
                                `https://${shop}/admin/settings/general`,
                            );
                        }}
                    >
                        {t("Cấu hình")}
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default ModeAdvanceInventory