import { useEmbedApp, useTrans } from "@haravan/reactapp";
import { Fragment, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

import {
    Box, Button, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    SkeletonText,
} from "@chakra-ui/react";

import {
    svgFullscreen, svgClone, svgDeleteRed, svgArrow, svgEdit, svgConfig, svgSmallScreen, svgReset,
    svgEditName
} from ".";
import { useGTM } from "hooks/useGTM";

const HButton: any = Button;

export const DashboardHeader = forwardRef((props: any, ref) => {
    const {
        onClickConfig,
        onClickFullscreen,
        isFullScreen,
        isConfigMode,
        dashboardList,
        defaultTemplateDashboard,
        isLoading,
        onHandleOpenModalConfirm,
        onHandleCloneDashboard,
        onHandleChangeNameDashboard,
        templateDashboard
    } = props;

    const { detectClass } = useGTM({
        enabled: true,
        variables: {
            "ut-screen": "dashboard",
            "ut-name": 'fullscreen'
        }
    });

    const t = useTrans();

    const [isChangeNameMode, setIsChangeNameMode] = useState(false);
    const [valueName, setValueName] = useState("");
    const [errorMgs, setErrorMgs] = useState("");

    const { showMessage } = useEmbedApp();

    useImperativeHandle(ref, () => ({
        reset() {
            setIsChangeNameMode(false);
        },
        checkValidation() {
            if (errorMgs.length) {
                setIsChangeNameMode(false);
                setValueName(defaultTemplateDashboard.name);
                setErrorMgs("");
            }

            setIsChangeNameMode(false);
        },
        toggleEditorName(isOpen) {
            setIsChangeNameMode(isOpen);
            setValueName(defaultTemplateDashboard.name);
        }
    }));

    useEffect(() => {
        setIsChangeNameMode(false);
    }, [isFullScreen]);

    useEffect(() => {
        if (errorMgs.length) {
            showMessage(errorMgs, "error");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMgs]);

    const contentTransaction = (dashboard) => {
        const { dashboardId } = dashboard;

        const actionsList = [
            {
                action_name: "Đổi tên",
                action_icon: svgEditName,
                action_handle: () => {
                    setIsChangeNameMode(true);
                    setValueName(defaultTemplateDashboard.name);
                },
                button_variant: "default-tertiary",
                isActive: true,
                className: detectClass,
                attributes: {
                    "ut-screen": "dashboard",
                    "ut-name": 'rename_inside_actions'
                }
            },
            {
                action_name: "Replication",
                action_icon: svgClone,
                button_variant: "default-tertiary",
                isActive: true,
                action_handle: () => {
                    onHandleCloneDashboard && onHandleCloneDashboard(dashboardId);
                },
                className: detectClass,
                attributes: {
                    "ut-screen": "dashboard",
                    "ut-name": 'clone_inside_actions'
                }
            },
            {
                action_name: "reset_default",
                action_icon: svgReset,
                action_handle: () => {
                    onHandleOpenModalConfirm && onHandleOpenModalConfirm(dashboardId, 'reset');
                },
                button_variant: "default-tertiary",
                isActive: true,
                className: detectClass,
                attributes: {
                    "ut-screen": "dashboard",
                    "ut-name": 'reset_inside_actions'
                }
            },
            {
                action_name: "Delete",
                action_icon: svgDeleteRed,
                button_variant: "critical-tertiary",
                action_handle: () => {
                    onHandleOpenModalConfirm &&
                        onHandleOpenModalConfirm(dashboardId, "delete");
                },
                isActive: true,
                className: detectClass,
                attributes: {
                    "ut-screen": "dashboard",
                    "ut-name": "delete_inside_actions"
                }
            },
        ];

        return (
            <>
                {actionsList.map((opt, idx) => {
                    const isHidden = ["Delete"].includes(opt.action_name) && dashboardList.length === 1;

                    return (
                        <Fragment key={idx}>

                            {!isHidden && <MenuItem as={Box}>
                                <HButton
                                    variant={opt.button_variant}
                                    onClick={() => {
                                        opt.isActive && opt.action_handle();
                                    }}
                                    leftIcon={opt.action_icon}
                                    isDisabled={!opt.isActive}
                                    style={{
                                        width: "100%",
                                        justifyContent: "start",
                                        fontWeight: 400,
                                    }}
                                    className={opt.className}
                                    {...opt.attributes}
                                >
                                    {t(opt.action_name)}
                                </HButton>
                            </MenuItem>}
                        </Fragment>
                    );
                })}
            </>
        );
    };

    // const handleClickAddDashboard = async () => {
    //     onHandleClickAddDashboard && onHandleClickAddDashboard();
    //     setIsChangeNameMode(false);
    // };

    const checkValidInputNameDashboard = (value) => {
        let error = "";

        if (!value.trim().length) {
            error = t("Not_be_empty");
        }

        if (value.length > 100) {
            error = t("The_dashboard_name_cannot_exceed_100_characters");
        }

        if (dashboardList
            .filter((t) => t.dashboardId !== defaultTemplateDashboard.dashboardId)
            .findIndex((y) => y.name === value.trim()) !== -1) {
            error = t("The_dashboard_name_already_exists");
        }

        setErrorMgs(error);
    };

    const handleChangeNameDashboard = () => {
        setIsChangeNameMode(false);

        const tabId = defaultTemplateDashboard.dashboardId;

        onHandleChangeNameDashboard &&
            onHandleChangeNameDashboard(
                tabId,
                valueName.trim()
            );
    };

    let final: any = [];

    Object.keys(templateDashboard).forEach((key) => {
        const getReportDisplay = templateDashboard[key].filter((t) => t.isDisplay);

        if (getReportDisplay.length) {
            final.push(...getReportDisplay);
        }
    });

    const isDisplayToolbar = useCallback(() => {
        let isDisplay = false;

        if (!isConfigMode) {
            if (!final.length) {
                // Trong chế độ tuỳ chỉnh nếu không có widget nào tồn tại thì trả về false
                return false
            }

            if (!isChangeNameMode) {
                isDisplay = true
            }
        }

        return isDisplay
    }, [isConfigMode, isChangeNameMode, final]);

    return (
        <div
            className="
            hrv-report-mt-16 hrv-report-d-flex hrv-report-items-center hrv-report-justify-between hrv-report-md-mx-0 hrv-report-mx-16"
        >
            {isLoading ? (
                <div
                    style={{
                        width: "100%",
                    }}
                    className="hrv-report-skeleton-full hrv-report-mb-16"
                >
                    <SkeletonText noOfLines={1} skeletonHeight='30' />
                </div>
            ) : (
                <>
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        {isChangeNameMode || isConfigMode ? (
                            <div
                                style={{
                                    height: 40,
                                    flex: 1,
                                    margin: "0 4px"
                                }}
                            >
                                <Input
                                    placeholder={t("Enter_dashboard_name")}
                                    value={valueName}
                                    onChange={(event) => {
                                        const searchKey = event.target.value;

                                        setValueName(searchKey);
                                        checkValidInputNameDashboard(searchKey);
                                    }}
                                    onBlur={() => {
                                        // ! Check valid Input
                                        if (!errorMgs.length) {
                                            handleChangeNameDashboard();
                                        }
                                    }}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: 18,
                                        width: "100%",
                                    }}
                                    variant="autocomplete"
                                    className={`${errorMgs.length ? "has-error" : ""}`} />

                            </div>
                        ) : (
                            <div className="hrv-report-d-flex hrv-report-items-center hrv-report-dashboard-name hrv-report-mr-8" style={{
                                width: "fit-content",
                                flex: 1
                            }} onClick={() => {
                                setIsChangeNameMode(true);
                                setValueName(defaultTemplateDashboard.name);
                            }}>
                                <span
                                    className="hrv-report-mb-0 hrv-report-fs-18 hrv-report-fw-600 hrv-report-text-color-black-6"
                                    style={{
                                        lineHeight: "40px",
                                        overflow: 'hidden',
                                        display: "-webkit-box",
                                        WebkitLineClamp: 1,
                                        textOverflow: "ellipsis",
                                        WebkitBoxOrient: "vertical",
                                    }}

                                >
                                    {defaultTemplateDashboard.isSystem ? t(defaultTemplateDashboard.name) : defaultTemplateDashboard.name}
                                </span>


                                <span className="hrv-report-mx-8">{svgEdit}</span>
                            </div>
                        )}
                    </div>

                    {isDisplayToolbar() && (
                        <div
                            className="hrv-report-md-d-flex hrv-report-d-none hrv-report-items-center hrv-report-justify-end"
                            style={{
                                width: 400,
                            }}
                        >
                            {!isConfigMode && !isFullScreen && (
                                <Button
                                    variant={'default-default'}
                                    iconSpacing={2}
                                    leftIcon={svgConfig}
                                    onClick={() => {
                                        setIsChangeNameMode(true);

                                        setValueName(defaultTemplateDashboard.name);

                                        onClickConfig && onClickConfig();
                                    }}
                                    className={detectClass}
                                    ut-screen={"dashboard"}
                                    ut-name={'adjust'}
                                >
                                    {t("adjust")}
                                </Button>
                            )}

                            {!isFullScreen &&
                                <Button
                                    variant={'default-default'}
                                    leftIcon={svgFullscreen}
                                    className={`hrv-report-ml-8 ${detectClass}`}
                                    iconSpacing={2}
                                    onClick={() => {
                                        onClickFullscreen && onClickFullscreen();
                                    }}
                                    ut-screen={"dashboard"}
                                    ut-name={'fullscreen'}
                                >
                                    {t("fullScreen")}
                                </Button>
                            }

                            {!isConfigMode && !isFullScreen && (
                                <Box ml={4}>
                                    <Menu placement="bottom-end" size="m">
                                        <MenuButton
                                            as={Button}
                                            variant={'default-default'}
                                            rightIcon={svgArrow}
                                            iconSpacing={2}
                                        >
                                            {t("actions")}
                                        </MenuButton>

                                        <MenuList>
                                            {contentTransaction(defaultTemplateDashboard)}
                                        </MenuList>
                                    </Menu>
                                </Box>
                            )}

                            {!isConfigMode && isFullScreen && (
                                <Button
                                    variant={'default-default'}
                                    leftIcon={svgSmallScreen}
                                    className={`hrv-report-ml-16 ${detectClass}`}
                                    iconSpacing={2}
                                    onClick={() => {
                                        onClickFullscreen && onClickFullscreen();
                                    }}
                                    ut-screen={"dashboard"}
                                    ut-name={'minimize_screen'}
                                >
                                    {t("MinimizeScreen")}
                                </Button>
                            )}
                        </div>
                    )}

                    {isConfigMode && (
                        <Button
                            variant={'default-tertiary'}
                            className={`${detectClass} hrv-report-ml-8 hrv-report-px-20`}
                            onClick={() => {
                                onHandleOpenModalConfirm &&
                                    onHandleOpenModalConfirm(
                                        defaultTemplateDashboard.dashboardId,
                                        "reset"
                                    );
                            }}
                            leftIcon={svgReset}
                            ut-screen={"dashboard"}
                            ut-name={'reset_outside_actions'}
                        >
                            {t("Reset_default")}
                        </Button>
                    )}
                </>
            )}
        </div>
    );
});