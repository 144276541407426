// * React and Hooks
import { memo, useEffect, useRef, useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { useTrans } from "locales/hook";
import useDebouncedCallback from "hooks/useDebounceCallback";

// * Components
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import DiscountCard from "./components/DiscountCard";
import Popup from "./components/Popup";
import InputWithTooltip from "./components/Tooltip";
import WrapperSpinner from "components/Spinner";
import VirtualizedContainer from "components/VirtualizedContainer";
import { isEmpty } from "helpers/helpers";

type TProps = {
  placeholder: string;
  onChange: Function;
  isLoading: boolean;
  result: any[];
  onHandleOnClick: Function;
  isChooseDiscount: any;
  discount: any;
  isSystemReport: boolean;
  size?: string;
  currentPage: number
};

const LIMIT_VIRTUALIZED_ACTIVE = 20

function HSearchInput(props: TProps) {
  const {
    placeholder,
    onChange,
    isLoading,
    result,
    onHandleOnClick,
    isChooseDiscount,
    discount,
    size = "l",
    currentPage
  } = props;

  const clickRef = useRef<any>();
  const t = useTrans();

  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState(discount?.displayname_vi || "");

  const handleChangeDebounced = (words) => {
    onChange && onChange(words, 1);
  };

  const [debouncedCallback] = useDebouncedCallback(handleChangeDebounced, 500, [keyword]);

  useEffect(() => {
    if (!isEmpty(discount)) {
      onHandleOnClick && onHandleOnClick(discount);
    }
  }, [discount])

  const handleOnClick = (value) => {
    if (discount.displayname_vi && discount.displayname_vi === value.displayname_vi) return;

    onHandleOnClick && onHandleOnClick(value);
  };

  const isAtBottom = (e): boolean => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
      ) < 1;

    return bottom;
  };

  const emptyContent = () => {
    return <div className="hrv-report-text-center hrv-report-py-8 hrv-report-px-16">
      <p className="hrv-report-fs-14 hrv-report-fw-600 hrv-report-mb-4 hrv-report-text-color-black-6">{`${t(
        `Không tìm thấy kết quả ${keyword?.length ? "cho" : ""}`
      )} ${keyword?.length ? `"${keyword}"` : ""}`}</p>

      <p className="hrv-report-fs-14 hrv-report-fw-400 hrv-report-text-color-gray-9 hrv-report-mb-0">
        {t("Vui lòng tìm kiếm khuyến mãi với từ khóa khác")}
      </p>
    </div>
  }

  const content = () => {
    const heightOfWrapper = 400;
    const heightOfContentCheckbox = heightOfWrapper - 16;

    return (
      <div
        className="hrv-report-discount-search hrv-report-scrollbar_custom hrv-report-py-8"
        style={{
          maxWidth: "376px",
          width: clickRef?.current?.clientWidth,
          paddingRight: 4,
          maxHeight: heightOfWrapper,
          paddingBottom: 10,
        }}
      >
        <div>
          {isLoading && currentPage !== 1 && (
            <div
              className="hrv-report-absolute hrv-report-d-flex hrv-report-items-center hrv-report-justify-center"
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "16px",
                top: 0,
                zIndex: 9
              }}
            >
              <WrapperSpinner />
            </div>
          )}

          {isLoading && currentPage === 1 ? (
            <div
              className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-px-8"
              style={{
                height: "200px",
              }}
            >
              <WrapperSpinner />
            </div>
          ) : (
            <VirtualizedContainer
              onScroll={(event) => {
                if (isAtBottom(event)) {
                  const page = currentPage + 1;

                  onChange && onChange(keyword, page);
                }
              }}
              customClass="hrv-report-scrollbar_custom"
              maxHeight={heightOfContentCheckbox}
              list={result}
              emptyContent={emptyContent}
              limit={LIMIT_VIRTUALIZED_ACTIVE}
              renderItems={(item) => {
                return <DiscountCard
                  {...item}
                  callback={handleOnClick}
                />
              }}
            />
          )}
        </div>
      </div >
    );
  };

  const onClickOutside = () => {
    setVisible(false);

    setKeyword(discount.displayname_vi)
  };

  useClickOutside(clickRef, onClickOutside);

  return (
    <div ref={clickRef} className="hrv-report-relative">
      <InputWithTooltip
        discount={discount}
        isChooseDiscount={isChooseDiscount}
        visible={visible}
      >
        <InputGroup>
          <InputLeftElement h={'full'} pl={6} w={'fit-content'} children={isChooseDiscount ? `${t("Khuyến mãi")}:` : ""} color="#9ca3b0" />

          <Input
            size={size}
            pl={isChooseDiscount ? "100px" : 6} w={343} variant="autocomplete" id="discountChoosen"
            className="hrv-report-v2-input" autoComplete={"off"} placeholder={placeholder}
            value={keyword}
            onChange={(e) => {
              const key = e.target.value;

              setKeyword(key);
              debouncedCallback(key);
            }}
            onFocus={() => {
              setVisible(true);
            }} />

          <InputRightElement h={'full'} w={10}>
            {svgArrow}
          </InputRightElement>
        </InputGroup>
      </InputWithTooltip>

      <Popup visible={visible}>{content()}</Popup>
    </div>
  );
}

export default memo(HSearchInput);

const svgArrow = (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87461 1.00002L5.99461 4.88002L2.11461 1.00002C1.72461 0.61002 1.09461 0.61002 0.704609 1.00002C0.314609 1.39002 0.314609 2.02002 0.704609 2.41002L5.29461 7.00002C5.68461 7.39002 6.31461 7.39002 6.70461 7.00002L11.2946 2.41002C11.6846 2.02002 11.6846 1.39002 11.2946 1.00002C10.9046 0.62002 10.2646 0.61002 9.87461 1.00002Z"
      fill="#9CA3B0"
    />
  </svg>
);
