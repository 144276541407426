import { ReactNode } from "react";

interface IProps {
    innerRef?: any;
    children: ReactNode;
}

function TabContainer(props: IProps) {
    const { innerRef, children, ...restProps } = props;

    return (
        <ul
            {...restProps}
            ref={innerRef}
            style={{
                display: "flex",
                minWidth: "1px",
                alignItems: "center",
                margin: 0,
                listStyle: "none",
            }}
        >
            {children}
        </ul>
    )
}

export default TabContainer