import { EffectCallback, useCallback, useEffect, useState } from "react";
import { buildTree, updateItemStates } from "./updateItemStates";
import TreeList from "./TreeList";

export enum CheckboxState {
  UNCHECKED,
  CHECKED,
  INDETERMINATE,
}

export type ItemState = {
  id: number;
  state: CheckboxState;
}

const TreeDemo = (props) => {
  const {
    options,
    chooseList,
    onChangeCheckbox,
    allList,
    isMultiple
  } = props;

  const [itemStates, setItemStates] = useState<any>([]);
  const [renderer, setRenderer] = useState([]);
  const [listStates, setListStates] = useState([]);
  const [selectedId, setSelectedId] = useState<any>(null); // ! Chỉ sử dụng cho trường hợp single

  // * Config for status list of all elements
  // * When checked List changed create new status list value
  const useDefinedNeededValues = (effect: EffectCallback) => {
    return useEffect(effect, [allList])
  }

  useDefinedNeededValues(() => {
    if (allList.length) {
      const renderList = makeRenderByList(allList);

      let newStates = renderList.map((i: any) => {
        return {
          id: i.id,
          state: CheckboxState.UNCHECKED,
        }
      });
      const selectedValues = new Set(renderList.map((y: any) => +y.value));

      if (chooseList.length) {
        if (isMultiple) {
          chooseList.forEach((selected) => {
            const isExistInSelected = selectedValues.has(+selected.value);

            if (isExistInSelected) {
              newStates = updateItemStates(newStates, renderList, +selected.value)
            }
          });
        } else {
          setSelectedId(+chooseList[0]?.value || null)
        }
      }

      setListStates(newStates)
    }
  })

  // * make new format for flatten list
  const makeRenderByList = (list: any) => {
    const cloneNode = JSON.parse(JSON.stringify(list));
    const newRender = cloneNode.map((item) => {
      return {
        ...item,
        id: !parseInt(item.id) ? +item.id + 1 : +item.id,
        name: item.displayname_vi,
        parentId: item.specialInfo.parentId
      }
    });

    return newRender
  };

  useEffect(() => {
    const renderList = makeRenderByList(options);
    setRenderer(renderList)
  }, [options]);

  useEffect(() => {
    if (renderer.length) {
      let newStates = renderer.map((i: any) => {
        return {
          id: i.id,
          state: CheckboxState.UNCHECKED,
        }
      });

      const selectedValues = new Set(renderer.map((y: any) => +y.value));

      if (chooseList.length) {
        chooseList.forEach((selected) => {
          const isExistInSelected = selectedValues.has(+selected.value)

          if (isExistInSelected) {
            newStates = updateItemStates(newStates, renderer, +selected.value)
          }
        });
      }

      setItemStates(newStates)
    }
  }, [renderer])

  const getStateForId = useCallback(
    (id: number) => {
      return itemStates.find((i) => i.id === id)?.state;
    },
    [itemStates]
  );

  const buildDataForPayload = (hardList) => {
    // * Cây đầy đủ (Flatten Array)
    let fullTreeState = [];
    // * Payload
    const payload: any = [];
    // * Full danh sách (Flatten Array)
    const cloneAllList = JSON.parse(JSON.stringify(allList));
    // * Full danh sách đã thêm trạng thái
    const renderList = makeRenderByList(allList);

    // * Chỉ lấy trạng thái checked và indeterminate
    const getChecked = hardList.filter(y => y.state >= 1);

    getChecked.forEach((item) => {
      const itemChecked = cloneAllList.find(y => +y.value === item.id);
      let isIndeterminate = false;

      if (itemChecked.specialInfo.isRoot) {
        isIndeterminate = true
      }
      if (itemChecked && !isIndeterminate) {
        payload.push(itemChecked);
      }
    });

    // * Gán Full danh sách (Flatten Array) bằng 1 trạng thái mới từ getChecked
    fullTreeState = renderList.map((i: any) => {
      const findStateIndex = getChecked.findIndex(item => item.id === i.id);
      const isExist = findStateIndex !== -1
      return {
        ...i,
        checked: isExist ? getChecked[findStateIndex].state === CheckboxState.CHECKED : false,
        indeterminate: isExist ? getChecked[findStateIndex].state === CheckboxState.INDETERMINATE : false
      }
    });

    // * sau khi có được trạng thái mới build thành Tree (Nested Array)
    const buildTreeWithFullTree = buildTree(fullTreeState)

    function findElements(data) {
      const result: any = [];

      function checkElement(element) {

        if (!element.checked && element.indeterminate) {

          for (const child of element.children) {
            checkElement(child);
          }
        } else if (element.checked) {
          result.push(element);
        }
      }

      for (const item of data) {
        checkElement(item);
      }

      return result;
    }

    // * sau khi có được nested Array trả về danh sách được chọn chỉ lấy trạng thái duy nhất là checked 
    return findElements(buildTreeWithFullTree)
  }

  /**
   * 
   * @param softList Danh sách item theo ngữ cảnh
   * @param selectedId Id mà user đang click
   */
  const buildDataForHardListFollowBySoftList = (softList, selectedId) => {
    const cloneAllList = JSON.parse(JSON.stringify(allList));
    const renderList = makeRenderByList(allList);
    const detectCheckedItem = cloneAllList.find(y => +y.id === selectedId);
    let hardList = [];
    let newState: any = [...listStates];

    // ! Reset List nếu chọn single
    if (!isMultiple) {
      newState = newState.map(y => {
        return {
          ...y,
          state: CheckboxState.UNCHECKED
        }
      })
    };

    if (detectCheckedItem.specialInfo.isRoot) {
      const isChecked = softList.find(y => y.id === selectedId).state;

      const softTree = buildTree(softList.map((j) => {
        const find = renderList.find(y => + y.id === j.id)

        return {
          ...find,
          checked: isMultiple ? isChecked === 1 : false,
          indeterminate: isMultiple ? isChecked === 2 : false
        }
      }));

      function findLastestElements(data) {
        const result: any = [];

        function pushElement(element) {
          if (!element.children.length) {
            result.push(element)
          } else {
            for (const child of element.children) {
              pushElement(child);
            }
          }
        }

        function checkElement(element) {
          if (element.id !== selectedId) {
            for (const child of element.children) {
              checkElement(child);
            }
          } else {
            if (element.children.length) {
              for (const child of element.children) {
                pushElement(child);
              }
            }
          }
        }

        for (const item of data) {
          checkElement(item);
        }

        return result;
      }

      const findLastestChildren = findLastestElements(softTree);


      findLastestChildren.forEach((item) => {
        newState = updateItemStates(newState, renderList, +item.value);
      });

      hardList = newState;
    } else {
      hardList = updateItemStates(newState, renderList, selectedId);
    }

    return hardList
  }

  const clickHandler = useCallback((id) => {
    /**
     * *Comment: Trong ngữ cảnh mà user đang thao tác: ví dụ search sẽ cho ra danh sách khác với danh sách ban đầu
     * TODO: Giải quyết bài toán 1 item có thể có thể là (checked, unchecked, indeterminate) khi ở trạng thái danh sách khách nhau. Ví dụ: ở danh sách đầy đủ item đó là indeterminate nhưng ở trạng thái đang search item đó trạng thái là checked. Vì thế bài toán là phải chuyển đổi trạng thái khác nhau cho nút check để hoàn thiện UI.
     * @Params softList: Đánh dấu danh sách theo trạng thái thao tác
     * @Params hardList: Đánh dấu danh sách theo trạng thái đầy đủ
    */

    let handleItemState = [...itemStates];
    // ! Reset List nếu chọn single
    if (!isMultiple) {
      handleItemState = handleItemState.map(y => {
        return {
          ...y,
          state: CheckboxState.UNCHECKED
        }
      });

      setSelectedId(id)
    }

    const softList = updateItemStates(handleItemState, renderer, id);
    const hardList: any = buildDataForHardListFollowBySoftList(softList, id);

    // * set state mới theo danh sách ngữ cảnh
    setItemStates(softList);

    // * set state mới theo danh sách đầy đủ
    setListStates(hardList);

    // !Noted: Luôn luôn lấy kết quả từ danh sách đầy đủ (hardLisit) bởi vì lúc đó nút check sẽ đúng nhất khi gửi qua BE.
    let result: any = []

    if (isMultiple) {
      // ! TH: chọn nhiều sẽ trả giá trị là parentId nếu children được checked full
      result = buildDataForPayload(hardList);
    } else {
      // ! TH: chọn ít sẽ trả về giá trị item nào đang checked theo id
      const findResult: any = allList.find(item => +item.id === id);
      result = [findResult];
    }

    onChangeCheckbox && onChangeCheckbox(result)
  }, [itemStates, listStates, chooseList]);

  return <TreeList
    items={renderer}
    onClick={clickHandler}
    getStateForId={getStateForId}
    isMultiple={isMultiple}
    selectedId={selectedId}
  />;
};

export default TreeDemo;
