// ! Hooks
import { memo } from 'react';
import { useTrans } from 'locales/hook';
// ! Helpers
import { ShowDateRange, mapOrder, xmlRenderCheckbox } from 'helpers/helpers';
// ! Constants
import { category_chart } from 'pages/ReportDashboardV2/context/store/constants';
import { Colours, LIMIT_GROUPED_BY_DISCOUNT } from '..';
import { REPORT_OPEN_COMPARE } from '../constants';

function MetricsLegend(props) {
    const {
        displayChart,
        data,
        labels,
        startdate,
        enddate,
        compareDateStart,
        compareDateEnd,
        reportNameSystem
    } = props;
    const t = useTrans();

    // Kiểm tra biến so sánh trong kì với ngày bắt đầu so sánh và data kỳ trước
    // Không chọn ngày bắt đầu và kỳ trước không có dữ liệu => không so sánh
    const checkCompare = () => {
        let isCompare = false;

        if (REPORT_OPEN_COMPARE.includes(reportNameSystem)) {
            isCompare = typeof compareDateStart !== 'undefined'
                ? true
                : false
        }

        return isCompare
    }

    const dotColor = (dateType) => {
        return <>
            <div
                className="hrv-report-legend-square"
                style={{ background: dateType === "current" ? "#3C83F6" : "#7ED4FC" }}
            >
            </div>
        </>
    };

    const emptyChart = () => {
        let emptyState = false;

        if (data[0]?.hasOwnProperty('label')) {
            // * data trả ra có so sánh kì
            if (Boolean(!data[0]?.data?.length) && Boolean(!data[1]?.data?.length)) {
                emptyState = true
            }
        } else {
            // * data trả ra không so sánh kì
            if (Boolean(!data?.length)) {
                emptyState = true
            }
        }
        return emptyState
    }

    return (
        <div className="hrv-report-row hrv-report-items-center hrv-report-justify-center hrv-report-mt-16">
            {
                category_chart.STACKEDBAR === displayChart && <>
                    {data.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-center hrv-report-mr-8"
                            >
                                <div
                                    className="hrv-report-legend-square"
                                    style={{ background: Colours[index].color }}
                                ></div>

                                <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{item.name}</p>
                            </div>
                        );
                    })}
                </>
            }

            {
                category_chart.DOUGHNUT === displayChart && <>{labels?.map((val: any, idx) => {
                    return (
                        <div
                            className="hrv-report-mr-12 hrv-report-row hrv-report-items-center hrv-report-justify-center"
                            key={`${idx}`}
                        >
                            <div
                                className="hrv-report-legend-square"
                                style={{ background: val.color }}
                            >
                            </div>

                            <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{val.name}</p>
                        </div>
                    );
                })}</>
            }

            {
                [category_chart.BAR, category_chart.GROUPEDCOLUMN].includes(displayChart) && <>
                    {!emptyChart() && <>
                        <div className="hrv-report-d-flex hrv-report-items-center">
                            <div className="hrv-report-d-flex hrv-report-items-center hrv-report-mr-20">
                                {dotColor("current")}

                                <div>
                                    <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                        {ShowDateRange(startdate, enddate)}
                                    </span>
                                </div>
                            </div>

                            {checkCompare() && <div className="hrv-report-d-flex hrv-report-items-center">
                                {dotColor("previous")}

                                <div>
                                    <span className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9" >{ShowDateRange(compareDateStart, compareDateEnd)}</span>
                                </div>
                            </div>}
                        </div>

                        {data[0].data?.length === LIMIT_GROUPED_BY_DISCOUNT &&
                            <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-mt-16"
                                style={{
                                    width: "100%"
                                }}
                            >
                                <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                    {t("Hiển thị top 10 giá trị cao nhất")}
                                </p>
                            </div>
                        }
                    </>
                    }

                </>
            }

            {
                category_chart.LINE === displayChart &&
                <div
                    style={{
                        height: 0
                    }}
                >
                </div>
            }

            {
                category_chart.STACKEDCOLUMN === displayChart && <>
                    <div className="hrv-report-wrapper-labels hrv-report-row hrv-report-items-center hrv-report-justify-center" style={{
                        width: "100%"
                    }}>
                        {mapOrder(
                            data,
                            xmlRenderCheckbox[reportNameSystem],
                            "fieldName"
                        ).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="hrv-report-d-flex hrv-report-items-center hrv-report-justify-left hrv-report-md-justify-center hrv-report-mr-8 hrv-report-mt-4"
                                >
                                    <div
                                        className="hrv-report-legend-square"
                                        style={{ background: item.color }}
                                    ></div>

                                    <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">{item.name}</p>
                                </div>
                            );
                        })}


                    </div>

                    {data?.length >= 12 && (
                        <div className="hrv-report-d-flex hrv-report-justify-center hrv-report-mt-16" style={{
                            width: "100%"
                        }}>
                            <p className="hrv-report-mb-0 hrv-report-fs-13 hrv-report-text-color-gray-9">
                                {t("Biểu đồ hiển thị tối đa 15 giá trị đầu tiên")}
                            </p>
                        </div>
                    )}
                </>
            }
        </div>
    )
}

export default memo(MetricsLegend)