import HSelection from 'components/Selection';

interface IProps {
  handleChangeGroupBy: any;
  selected: string;
  items: Array<any>;
}

export default function ReportGroupBy(props: IProps) {
  const { selected, items, handleChangeGroupBy } = props;
  let renderItems = items.sort((a, b) => { return a.orderNumber - b.orderNumber });

  return <>
    <HSelection
      key={"symbol_" + selected}
      dropdownClassName="hrv-report-no-scroll-selection_dropdown"
      isOptGroup={false}
      options={renderItems}
      name="groupPropertyName"
      value="dataFormat"
      defaultValue={selected}
      onChange={(value) => {
        handleChangeGroupBy && handleChangeGroupBy(value);
      }}
    />
  </>
}