import { Skeleton } from "@chakra-ui/react";

function Column(props) {

  return (
    <td className="hara-embed-px-12 hara-embed-pt-22 hara-embed-pb-0">
      <Skeleton height={"16px"} />
    </td>
  );
}

export default Column;
