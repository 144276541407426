// ! thay đổi ngày, ngày so sánh, trục y, filter, mã hoặc CTKM
/**
 * @Author Hara Ads Team
 * @Created_at 29-5-2023
 * @Param id, reportname, dataToFetchChart, dependencies, condition
 * @Return Chart Data (Current and Previous)
 */

import { GetDateShowType } from "helpers/date";
import Model from "models";
import { EffectCallback, useEffect, useState } from "react";

function useFetchDataChart(
  id: any,
  reportname: any,
  dataToFetchChart: any,
  dependencies: Array<any>,
  condition: boolean | undefined,
  groupby?: any,
  pagination?: any,
  callback?: Function | undefined,
) {
  const [current, setCurrent] = useState<any>([]);
  const [previous, setPrevious] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { ReportData } = Model();

  const { type } = GetDateShowType(
    dataToFetchChart.startdate,
    dataToFetchChart.enddate,
  );


  const useEffectFetchDataChart = (effect: EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, dependencies);
  };

  useEffectFetchDataChart(() => {
    fetchSummaryData();
  });

  const fetchDataCurrentChart = async () => {
    let cloneDataFetch = JSON.parse(JSON.stringify(dataToFetchChart));

    // ! Sử dụng Measure tuỳ chỉnh
    const useOptionalMeasure = cloneDataFetch.optionMeasure;
    const payload = {
      filters: cloneDataFetch.filters,
      groupby: groupby && groupby?.length ? groupby : [type],
      metrics: useOptionalMeasure
        ? [
          {
            measureField: cloneDataFetch.optionMeasure.measureField,
            measureName: cloneDataFetch.optionMeasure.measureName,
          },
        ]
        : cloneDataFetch.metrics,
      order_by: cloneDataFetch.order_by,
      daterange: {
        startdate: cloneDataFetch.startdate,
        enddate: cloneDataFetch.enddate,
      },
      pagination: pagination ? pagination : { offset: 0, limit: 365 }, // Hiện tại limit chỉ cho lấy 365 records 1 lần để vẽ
    };

    const { data, isError } = await ReportData(id, payload, reportname);
    if (isError) {
      return [];
    } else {
      return data;
    }
  };

  const fetchDataPreviousChart = async () => {
    let cloneDataFetch = JSON.parse(JSON.stringify(dataToFetchChart));

    // ! Sử dụng Measure tuỳ chỉnh
    const useOptionalMeasure = cloneDataFetch.optionMeasure;

    const payload = {
      filters: cloneDataFetch.filters,
      groupby: groupby && groupby?.length ? groupby : [type],
      metrics: useOptionalMeasure
        ? [
          {
            measureField: cloneDataFetch.optionMeasure.measureField,
            measureName: cloneDataFetch.optionMeasure.measureName,
          },
        ]
        : cloneDataFetch.metrics,
      order_by: cloneDataFetch.order_by,
      daterange: {
        startdate: cloneDataFetch.compareDateStart,
        enddate: cloneDataFetch.compareDateEnd,
      },
      pagination: pagination ? pagination : { offset: 0, limit: 365 }, // Hiện tại limit chỉ cho lấy 365 records 1 lần để vẽ
    };

    const { data, isError } = await ReportData(id, payload, reportname);

    if (isError) {
      return [];
    } else {
      return data;
    }
  };

  const fetchSummaryData = async () => {
    if (typeof condition === "undefined") return;

    setIsLoading(true);

    if (!condition) {
      const data = await fetchDataCurrentChart();
      setCurrent(data);
      setPrevious([]);

      setIsLoading(false);
      callback && callback();
    } else {
      const dataCurrent = await fetchDataCurrentChart();
      const dataPrevious = await fetchDataPreviousChart();
      setCurrent(dataCurrent);
      setPrevious(dataPrevious);
      setIsLoading(false);
      callback && callback();
    }
  };

  return { current, previous, isLoading, fetchSummaryData };
}

export default useFetchDataChart;
