import dayjs from "components/Dayjs";
import {
  DEFAULT_ACTION,
  DEFAULT_DATA_FETCHING,
  DUPLICATE_ACTION,
  DUPLICATE_ACTION_ERROR,
  DUPLICATE_ACTION_SUCCESS,
  DELETE_ACTION,
  DELETE_ACTION_ERROR,
  DELETE_ACTION_SUCCESS,
  EXPORT_ACTION,
  EXPORT_ACTION_ERROR,
  EXPORT_ACTION_SUCCESS,
  FETCH_INFO,
  FETCH_INFO_ERROR,
  FETCH_INFO_SUCCESS,
  FETCH_STATISTIC,
  FETCH_STATISTIC_ERROR,
  FETCH_STATISTIC_SUCCESS,
  FETCH_SUMMARY,
  FETCH_SUMMARY_ERROR,
  FETCH_SUMMARY_SUCCESS,
  LIMIT_DATA,
  SET_DATE,
  SET_DIMEMSION_SELECTED,
  SET_FETCH_DATA_PAYLOAD,
  SET_FILTER_SELECTED,
  SET_GROUP_BY,
  SET_GROUP_BY_SELECTED,
  SET_MEASURE,
  SET_MEASURE_SELECTED,
  SET_OPERATOR_SELECTED,
  SET_SORT_TABLE,
  SET_HIDDEN_PAGINATION,
  SET_OFFSET,
  CHOOSE_COUNPON,
  SET_DATE_COMPARE,
  SET_OPTION_COMPARE,
  FETCH_COMPARE_STATISTIC,
  FETCH_COMPARE_STATISTIC_SUCCESS,
  FETCH_COMPARE_STATISTIC_ERROR,
  FETCH_COMPARE_SUMMARY,
  FETCH_COMPARE_SUMMARY_SUCCESS,
  FETCH_COMPARE_SUMMARY_ERROR,
  SET_MEASURE_Y_AXIS,
  GET_AUTO_COMPLETE_DEFAULT_SUCCESS,
  SET_DEFAULT_GROUP_BY,
  SET_DEFAULT_MEASURE,
  SET_KEYWORD_SEARCH,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_SUCCESS,
  GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_ERROR,
  SET_HEATMAP_OPTIONS,
  SET_GROUP_BY_FILTER_PANEL,
  SET_QUERY_STRING_BY_AUTOCOMPLETE,
  SET_PARAMS_FROM_LISTING,
  SET_PARAMS_FROM_DASHBOARD,
  SET_EXPORT_ID,
  GET_DATA_PRINT,
  GET_DATA_PRINT_ERROR,
  GET_DATA_PRINT_SUCCESS,
  TOGGLE_DISPLAY_ANNOUCE_EXPORT,
  TOGGLE_IS_FAVOURITE,
  SET_REPORT_NAME,
  SET_ONBOARDING_DATA,
  GET_DATA_EXPORT,
  GET_DATA_EXPORT_ERROR,
  GET_DATA_EXPORT_SUCCESS,
  TOGGLE_VISIBLE_DRILLDOWN,
  SET_DRILLDOWN_STATE,
  CHANGE_STATUS_REFRESH,
  SET_MESURE_DRAW_LIST,
  GET_AUTO_COMPLETE,
  GET_AUTO_COMPLETE_SUCCESS,
  GET_AUTO_COMPLETE_ERROR,
  SET_MODAL_TYPE,
  SET_DUPLICATE_NAME,
  SET_ABOUT_CONTENT
} from "./constants";

export const initialState = {
  defaultState: false,
  isLoadingPage: false,
  isLoadingTable: true,
  statistical_table_data: {},
  summary: [],
  info: {},

  groupBy: [],
  filter: [], // Dimension
  operator: [], // Toán tử của dimension
  query: [], // gồm key và value với key là giá trị theo dimension Name và value lấy từ API
  measure: [],
  groupAreas: [], // phân nhóm các group by

  // * Giá trị sẽ trả lại default khi searchkey = "" và không cần gọi lại API khi search key empty
  defaultQuery: [],

  measureSelected: [],
  groupBySelected: [],
  filterSelected: [],
  operatorSelected: "",

  offset: 0,
  limit: LIMIT_DATA,

  // * Ngày lọc
  enddate: dayjs().endOf("month"),
  startdate: dayjs().startOf("month"),

  // * Ngày so sánh
  compareDateEnd: undefined,
  compareDateStart: undefined,

  // * Default value cho nút đặt lại (action: fetch info xong => lưu default groupby và measure lại => những lần sau đó bấm đặt lại là sử dụng giá trị này)
  defaultGroupBy: [],
  defaultMeasure: [],

  dataFetchingTable: DEFAULT_DATA_FETCHING,

  sortValue: {
    dimension: "",
    direction: "",
  },

  benchmark: [],

  dimensionSelected: "",

  messageHeaderAbout: "",
  messageBodyAbout: "",
  messageFooterAbout: "",

  isLoadingExport: false,
  messageExport: "",

  isLoadingDuplicate: false,
  isLoadingDelete: false,

  isHiddenPagination: false,

  discount: {},
  optionCompare: "",
  statistical_compare_table_data: {},
  summary_compare: [],

  //* Chart Data
  isLoadingChart: false,
  currentChartData: [],
  previousChartData: [],
  optionMeasure: {},
  measureDraw: [],
  // * search keyword for column show
  keywordSearch: "",
  // * Loading Default Filter (Hiện tại: báo cáo tình trạng giao hàng theo thời gian)
  autoCompleteFilterPanel: {},
  isLoadingAutoCompleteFilterPanel: false,

  heatmapOptions: [],

  // * groupby Selection outside cho filter bên ngoài
  groupByFilterPanel: {},

  // * Lưu những gì trên queryString
  queryStringByAutoComplete: {},

  // * Lưu lại từ listing
  typeFilterFromListing: [],
  isFavoriteFromListing: false,
  authors: [],
  historyPath: "",

  exportId: '',
  exportData: {},

  isLoadingPrint: true,
  dataPrint: {},
  is_hidden_annouce_export: true,

  isFavourite: false,

  reportName: "",
  onboardingData: {
    tourNames: [],
  },

  isLoadingExportClient: true,
  dataExport: {},

  isVisibleDrilldown: false,
  drilldownState: {},

  isRefresh: false,

  modalType: "",
  duplicateName: ""
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      return { ...state, defaultState: action.payload };

    case FETCH_INFO:
      return { ...state, isLoadingPage: true };

    case SET_FETCH_DATA_PAYLOAD:
      return { ...state, dataFetchingTable: action.payload };

    case FETCH_INFO_SUCCESS:
      return {
        ...state,
        isLoadingPage: false,
        info: action.payload,
        groupBy: action.payload.lstReportScreenGroupProperty,
        filter: action.payload.lstReportScreenFilter,
        measure: action.payload.lstReportScreenMeasure,
        groupAreas: action.payload.groupAreas,
        reportName: action.payload.reportName,
        onboardingData: {
          ...state.onboardingData,
          tourNames: action.payload?.tourNames || [],
        }
      };

    case FETCH_INFO_ERROR:
      return { ...state, isLoadingTable: false };

    case FETCH_STATISTIC:
      return { ...state, isLoadingTable: true };

    case FETCH_STATISTIC_SUCCESS:
      return {
        ...state,
        isLoadingTable: false,
        statistical_table_data: action.payload,
      };

    case FETCH_STATISTIC_ERROR:
      return { ...state, isLoadingTable: false };

    case FETCH_SUMMARY:
      return { ...state, isLoadingTable: true };

    case FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingTable: false,
        summary: action.payload,
      };

    case FETCH_SUMMARY_ERROR:
      return { ...state, isLoadingTable: false, summary: action.payload };

    case SET_DATE:
      return {
        ...state,
        startdate: action.payload.startDate,
        enddate: action.payload.endDate,
      };

    case SET_GROUP_BY:
      return { ...state, groupBy: action.payload };

    case SET_MEASURE:
      return { ...state, measure: action.payload };

    case SET_GROUP_BY_SELECTED:
      return { ...state, groupBySelected: action.payload };

    case SET_MEASURE_SELECTED:
      return { ...state, measureSelected: action.payload };

    case SET_FILTER_SELECTED:
      return { ...state, filterSelected: action.payload };

    case SET_SORT_TABLE:
      return { ...state, sortValue: action.payload };

    case SET_DIMEMSION_SELECTED:
      return { ...state, dimensionSelected: action.payload };

    case SET_OPERATOR_SELECTED:
      return { ...state, operatorSelected: action.payload };

    case SET_ABOUT_CONTENT:
      return {
        ...state,
        messageHeaderAbout: action.payload.header,
        messageBodyAbout: action.payload.body,
        messageFooterAbout: action.payload.footer,
      };

    case EXPORT_ACTION:
      return { ...state, isLoadingExport: true };

    case EXPORT_ACTION_SUCCESS:
      return { ...state, isLoadingExport: false };

    case EXPORT_ACTION_ERROR:
      return { ...state, isLoadingExport: false };

    case DUPLICATE_ACTION:
      return { ...state, isLoadingDuplicate: true };

    case DUPLICATE_ACTION_SUCCESS:
      return { ...state, isLoadingDuplicate: false };

    case DUPLICATE_ACTION_ERROR:
      return { ...state, isLoadingDuplicate: false };

    case DELETE_ACTION:
      return { ...state, isLoadingDelete: true };

    case DELETE_ACTION_SUCCESS:
      return { ...state, isLoadingDelete: false };

    case DELETE_ACTION_ERROR:
      return { ...state, isLoadingDelete: false };

    case SET_HIDDEN_PAGINATION:
      return { ...state, isHiddenPagination: action.payload };

    case SET_OFFSET:
      return { ...state, offset: action.payload };

    // ! Các actions của báo cáo khuyến mãi
    case CHOOSE_COUNPON:
      return { ...state, discount: action.payload };

    case SET_DATE_COMPARE:
      return {
        ...state,
        compareDateStart: action.payload.startDate,
        compareDateEnd: action.payload.endDate,
      };

    case SET_OPTION_COMPARE:
      return {
        ...state,
        optionCompare: action.payload,
      };

    case FETCH_COMPARE_STATISTIC:
      return { ...state, isLoadingTable: true };

    case FETCH_COMPARE_STATISTIC_SUCCESS:
      return {
        ...state,
        isLoadingTable: false,
        statistical_compare_table_data: action.payload,
      };

    case FETCH_COMPARE_STATISTIC_ERROR:
      return { ...state, isLoadingTable: false };

    case FETCH_COMPARE_SUMMARY:
      return { ...state, isLoadingTable: true };

    case FETCH_COMPARE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingTable: false,
        summary_compare: action.payload,
      };

    case FETCH_COMPARE_SUMMARY_ERROR:
      return {
        ...state,
        isLoadingTable: false,
        summary_compare: action.payload,
      };

    case SET_MEASURE_Y_AXIS:
      return {
        ...state,
        optionMeasure: action.payload,
      };

    case GET_AUTO_COMPLETE_DEFAULT_SUCCESS:
      return {
        ...state,
        defaultQuery: action.payload,
      };

    case SET_DEFAULT_GROUP_BY:
      return {
        ...state,
        defaultGroupBy: action.payload,
      };

    case SET_DEFAULT_MEASURE:
      return {
        ...state,
        defaultMeasure: action.payload,
      };

    case SET_KEYWORD_SEARCH:
      return {
        ...state,
        keywordSearch: action.payload,
      };

    case GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL:
      return {
        ...state,
        isLoadingAutoCompleteFilterPanel: true,
      };

    case GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_SUCCESS:
      return {
        ...state,
        autoCompleteFilterPanel: action.payload,
        isLoadingAutoCompleteFilterPanel: false,
      };

    case GET_AUTO_COMPLETE_DEFAULT_FILTER_PANEL_ERROR:
      return {
        ...state,
        isLoadingAutoCompleteFilterPanel: false,
      };

    case SET_HEATMAP_OPTIONS:
      return {
        ...state,
        heatmapOptions: action.payload,
      };

    case SET_GROUP_BY_FILTER_PANEL:
      return {
        ...state,
        groupByFilterPanel: action.payload,
      };

    case SET_QUERY_STRING_BY_AUTOCOMPLETE:
      return {
        ...state,
        queryStringByAutoComplete: action.payload,
      };

    case SET_PARAMS_FROM_LISTING:
      return {
        ...state,
        typeFilterFromListing: action.payload.type,
        isFavoriteFromListing: action.payload.isFavorite,
        searchKeyFromListing: action.payload.search,
        authors: action.payload.authors,
      };

    case SET_PARAMS_FROM_DASHBOARD:
      return {
        ...state,
        historyPath: action.payload.historyPath,
      };

    case SET_EXPORT_ID:
      return {
        ...state,
        exportId: action.payload,
      };

    case TOGGLE_DISPLAY_ANNOUCE_EXPORT: {
      return { ...state, is_hidden_annouce_export: action.payload }
    }


    case GET_DATA_PRINT:
      return {
        ...state,
        isLoadingPrint: true,
      };


    case GET_DATA_PRINT_ERROR: return {
      ...state,
      isLoadingPrint: false,
      dataPrint: {},
    };

    case GET_DATA_PRINT_SUCCESS: return {
      ...state,
      isLoadingPrint: false,
      dataPrint: action.payload
    };

    case TOGGLE_IS_FAVOURITE: return {
      ...state,
      isFavourite: action.payload
    }

    case SET_REPORT_NAME: return {
      ...state,
      reportName: action.payload
    }

    case SET_ONBOARDING_DATA: return {
      ...state,
      onboardingData: action.payload
    }


    case GET_DATA_EXPORT:
      return {
        ...state,
        isLoadingExportClient: true,
      };


    case GET_DATA_EXPORT_ERROR: return {
      ...state,
      isLoadingExportClient: true,
      dataExport: {},
    };

    case GET_DATA_EXPORT_SUCCESS: return {
      ...state,
      isLoadingExportClient: false,
      dataExport: action.payload
    };

    case TOGGLE_VISIBLE_DRILLDOWN: return {
      ...state,
      isVisibleDrilldown: action.payload
    }

    case SET_DRILLDOWN_STATE: return {
      ...state,
      drilldownState: action.payload
    }

    case CHANGE_STATUS_REFRESH: return {
      ...state,
      isRefresh: action.payload
    }

    case SET_MESURE_DRAW_LIST: return {
      ...state,
      measureDraw: action.payload
    }

    case GET_AUTO_COMPLETE:
      return { ...state, isLoadingAutoComplete: true };

    case GET_AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        isLoadingAutoComplete: false,
        query: action.payload,
      };

    case GET_AUTO_COMPLETE_ERROR:
      return { ...state, isLoadingAutoComplete: false };

    // * Modal new
    case SET_MODAL_TYPE:
      return { ...state, modalType: action.payload };

    case SET_DUPLICATE_NAME:
      return { ...state, duplicateName: action.payload };

    default:
      throw new Error("Action invalid");
  }
};

export default reducer;
