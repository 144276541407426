import { useFetch } from "@haravan/reactapp";

export default function useApi() {
  const trigger = useFetch();

  const fetchWithToken = async (url) => {
    return await trigger(url);
  };

  const postWithToken = async (url, arg) => {
    return await trigger(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(arg),
    });
  };

  const putWithToken = async (url, arg) => {
    return await trigger(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(arg),
    });
  };

  const deleteWithToken = async (url) => {
    return await trigger(url, {
      method: "DELETE",
    });
  };

  return {
    fetchWithToken,
    postWithToken,
    putWithToken,
    deleteWithToken,
  };
}
