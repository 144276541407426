import { memo } from "react";
import { Provider } from "./context";
import Main from "./components/Main";

const ShipmentDashboard = () => {
  return (
    <Provider>
      <Main />
    </Provider>
  );
};

export default memo(ShipmentDashboard);
