/**
 * Author: Mạnh Đạt
 * Create_at 12/3/2024
 * Description
 * Step 1: When users click on print button, toast and isLoading appears 
 * Step 2: APIs fetching API 
 * Step 3: After that, when have response from server trigger useEffectBuildData to build Data for table
 * Step 4: Data from table in fullfilled status and useEffectReadyToPrint
 * Step 5: Popup Print will appear => show Toast
 * Step 6: If this component unmount => close all toasts
 * user trigger button repeat all above steps.
 */

import { useTrans } from '@haravan/reactapp';
import { EffectCallback, useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useStore } from 'pages/ReportStandard/context/store';
import useApp from 'hooks/useApp';
import useWindowDimensions from 'hooks/useWindowDimension';

import { TablePrint } from './custom/table/tablePrint';
import { HeaderReport } from './custom/reportTemplate';
import { Box, Button, CloseButton, useToast } from '@chakra-ui/react';
import WrapperSpinner from 'components/Spinner';

import { formatDate, formatNumber, isEmpty, replaceParams } from 'helpers/helpers';

import { LIMIT_PRINT_PROJECT } from 'pages/ReportStandard/context/store/constants';

import "./styles.scss";


function PrintMachine(props) {
    const {
        reportNameSystem,
        canReadMACost,
        sortValue,
        groupBySelected,
        isLoadingTable,
        handlePrintApi,
        isLoadingPrint,
        dataPrint,
        reportName,
        optionsFilter,
        currentFilter,
        allowed
    } = props;

    const t = useTrans();
    const { state, dispatch, actions } = useStore();
    const { isDisplaySummary } = useApp();
    const { width } = useWindowDimensions();
    const toast = useToast();
    const toastIdRef = useRef<any>(null);
    const contentRef = useRef<any>(null);
    const { enddate, startdate } = state;
    const [isDisabledPrint, setIsDisabledPrint] = useState(false);
    const [isBuildingData, setIsBuildingData] = useState(true)
    const [renewSummary, setRenewSummary] = useState<any>([]);

    const useEffectReadyToPrint = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(effect, [isLoadingPrint, isBuildingData, dataPrint])
    }

    const useEffectBuildData = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(effect, [dataPrint, isDisplaySummary])
    }

    const useEffectCleanupToast = (effect: EffectCallback) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(effect, [])
    }

    // * This useEffect will trigger and show popup Print in Browser
    useEffectReadyToPrint(() => {
        if (!isLoadingPrint && !isBuildingData && !isEmpty(dataPrint)) {
            handlePrint();

            setIsDisabledPrint(false);

            closeToast();
            dispatch(actions.getDataPrintSuccess({}))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    // * This useEffect will build data after have response from APIs
    useEffectBuildData(() => {
        setIsBuildingData(true);

        const { headerreport: headers, summary } = dataPrint;
        if (isDisplaySummary) {
            if (
                summary !== undefined &&
                headers !== undefined &&
                summary.length &&
                headers.length
            ) {
                if (headers !== undefined && summary !== undefined) {
                    const arrLength = headers.length - summary.length;

                    if (arrLength > 0) {
                        const reSummary =
                            [...Array(headers?.length - summary?.length).fill(0), ...summary] ||
                            [];
                        setRenewSummary(reSummary);
                        setIsBuildingData(false)
                    }
                }
            }
        } else {
            setIsBuildingData(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    // * This useEffect will close all toast when component unmounted
    useEffectCleanupToast(() => {
        return () => {
            toast.closeAll();
        }
    });


    const handlePrint = useReactToPrint({
        content: () => {
            const tableData = contentRef.current.cloneNode(true);
            const PrintHeaderEle = document.createElement('div');
            const header = HeaderReport({
                reportNameSystem,
                filters: optionsFilter,
                selectFilter: currentFilter,
                name: `${t('Báo cáo')} ${t(reportName)}`,
                dateRange: {
                    startdate: formatDate(startdate, 'DD/MM/YYYY'),
                    enddate: formatDate(enddate, 'DD/MM/YYYY')
                },
                translate: t
            });
            PrintHeaderEle.innerHTML = header;
            PrintHeaderEle.appendChild(tableData);

            return PrintHeaderEle
        },
        removeAfterPrint: true,
    });

    const handleClickPrint = () => {
        handlePrintApi();

        setIsDisabledPrint(true);

        addToast({
            status: "default",
            isClosable: false,
            description: t("print_description"),
            duration: null
        })
    };

    // ! Add toast
    const addToast = ({ status, isClosable, description, duration }) => {
        toastIdRef.current = toast({
            variant: status,
            icon: status === 'success' ? <span className="hrv-report-d-flex hrv-report-items-center">
                {svgCheckedIcon}</span> : <Box display="flex" alignItems={'center'}>
                <WrapperSpinner size="sm" variant="white" />
            </Box>,
            description: <Box display="flex" alignItems={'center'} justifyContent={"space-between"}>
                <p className="hrv-report-mb-0">
                    {description}
                </p>

                {isClosable && <CloseButton size="sm" ml={2} onClick={closeToast} />}
            </Box>,
            duration: duration,
        })
    }

    const closeToast = () => {
        if (toastIdRef.current) {
            toast.close(toastIdRef.current)
        }
    }

    const dataToPrint = dataPrint?.data && dataPrint?.data.length ? dataPrint.data.slice(0, LIMIT_PRINT_PROJECT) : [];

    return (
        <>
            <Button
                variant={width < 768 ? "ghost-default" : "default-default"}
                onClick={() => {
                    handleClickPrint();
                }}
                isDisabled={!allowed || isDisabledPrint}
                style={{
                    justifyContent: "start"
                }}
                w={width < 768 ? "100%" : "fit-content"}
            >
                {t("print_report")}
            </Button>

            <div
                style={{
                    height: 0,
                    width: 0,
                    overflow: "hidden",
                    display: 'none',
                    opacity: 0,
                }}
            >
                {
                    !isLoadingPrint && <div ref={contentRef}>
                        <div id="content">
                            {!isLoadingPrint && !isBuildingData ? <>
                                {dataPrint?.data && dataPrint?.data.length > LIMIT_PRINT_PROJECT ? <p className="hrv-report-text-center">
                                    {replaceParams(t("print_limit"), {
                                        LIMIT: formatNumber(LIMIT_PRINT_PROJECT)
                                    })}
                                </p> : null}

                                <TablePrint
                                    data={dataToPrint}
                                    headers={dataPrint?.headerreport}
                                    dataLink={dataPrint?.datalink}
                                    dependdata={dataPrint?.dependdata}
                                    reportNameSystem={reportNameSystem}
                                    canReadMACost={canReadMACost}
                                    sortValue={sortValue}
                                    summary={renewSummary}
                                    groupBySelected={groupBySelected}
                                    isLoadingTable={isLoadingTable}
                                />
                            </> : null}
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default PrintMachine;

const svgCheckedIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.99984 1.6665C5.39984 1.6665 1.6665 5.39984 1.6665 9.99984C1.6665 14.5998 5.39984 18.3332 9.99984 18.3332C14.5998 18.3332 18.3332 14.5998 18.3332 9.99984C18.3332 5.39984 14.5998 1.6665 9.99984 1.6665ZM8.33317 14.1665L4.1665 9.99984L5.3415 8.82484L8.33317 11.8082L14.6582 5.48317L15.8332 6.6665L8.33317 14.1665Z" fill="white" />
</svg>