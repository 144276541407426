import { Fragment, useMemo } from "react";

import { comparisonDate, formatDate, limitString } from "helpers/helpers";
import { useTrans } from "locales/hook";
import HRVCheckbox from "components/Checkbox";

type TProps = {
  callback: Function;
  displayname_vi: string;
  specialInfo: Record<string, any>;
  id: string;
  isChecked?: boolean;
  isCheckbox?: boolean;
  onCheck?: Function;
  value?: any
};

function DiscountCard(props: TProps) {
  const {
    callback,
    displayname_vi,
    specialInfo,
    id,
    isChecked,
    isCheckbox = false,
    onCheck,
    value
  } = props;
  const t = useTrans();

  const STATUS_DISABLED = 2;

  const STATUS_COLOR = useMemo(() => {
    return {
      active: {
        color: "rgba(6, 96, 70, 1)",
        bgColor: "rgba(209, 250, 229, 1)",
        borderColor: "#85EFAC",
        text: t("Đang khuyến mãi"),
      },
      paused: {
        color: "rgba(134, 78, 14, 1)",
        bgColor: "rgba(254, 243, 200, 1)",
        borderColor: "#FDDF49",
        text: t("Tạm ngưng"),
      },
      overdue: {
        color: "rgba(152, 27, 27, 1)",
        bgColor: "rgba(254, 225, 225, 1)",
        borderColor: "#dc2828",
        text: t("Hết hạn"),
      },
      not_active: {
        color: "#4b5563",
        bgColor: "#f3f4f6",
        borderColor: "#d1d5db",
        text: t("Chưa kích hoạt"),
      },
    };
  }, [t]);

  const handleStatusDiscount = (discount) => {
    if (comparisonDate(discount.end_date, Date.now())) {
      if (discount.status === STATUS_DISABLED) {
        return "paused";
      }
      if (comparisonDate(discount.start_date, Date.now())) {
        return "not_active";
      }
      return "active";
    } else {
      return "overdue";
    }
  };

  const ContainerDisount = (props) => {
    const { children } = props;

    if (!isCheckbox) {
      return <Fragment>
        {children}
      </Fragment>
    } else {
      return <HRVCheckbox
        title={displayname_vi}
        value={value}
        id={id}
        onChange={(item) => {
          onCheck && onCheck(item)
        }}
        checked={isChecked}
        variant='coupon'
        className="hrv-report-border-bottom-gray-8"
      >
        {children}
      </HRVCheckbox>
    }
  }

  return (
    <ContainerDisount>
      <div
        className={`hrv-report-cursor-pointer hrv-report-p-8 hrv-report-row ${isCheckbox ? "" : "hrv-report-border-bottom-gray-8"}`}
        onClick={() => {
          callback &&
            callback({
              displayname_vi,
              specialInfo,
              id,
            });
        }}
      >
        <div className="hrv-report-w-full hrv-report-d-flex hrv-report-justify-between hrv-report-items-center hrv-report-mb-4">
          <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-fw-500 hrv-report-text-color-gray-9 ">
            {specialInfo.is_promotion ? t("CTKM") : t("Mã KM")}
          </p>

          <p className="hrv-report-fs-12 hrv-report-mb-0 hrv-report-fw-400 hrv-report-text-color-gray-9">
            {t("Từ")} {formatDate(specialInfo.start_date, "DD/MM/YYYY")}{" "}
            {specialInfo.end_date
              ? `- 
          ${formatDate(specialInfo.end_date, "DD/MM/YYYY")}`
              : ""}
          </p>
        </div>

        <div className="hrv-report-w-full hrv-report-row hrv-report-justify-between hrv-report-items-center">
          <p className="hrv-report-fs-14 hrv-report-fw-600 hrv-report-text-color-black-6 hrv-report-mb-0"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              flex: 1,
              paddingRight: 8
            }}>
            {!displayname_vi.includes(" ") ? limitString(displayname_vi, 0, 24) : displayname_vi}
          </p>

          <div
            className="hrv-report-items-start hrv-report-justify-end hrv-report-d-flex"
            style={{
              height: "20px!important",
            }}
          >
            <div
              className="hrv-report-d-flex hrv-report-items-center hrv-report-py-1 hrv-report-px-4"
              style={{
                background: `${STATUS_COLOR[handleStatusDiscount(specialInfo)].bgColor
                  }`,
                color: `${STATUS_COLOR[handleStatusDiscount(specialInfo)].color}`,
                borderRadius: "4px",
              }}
            >
              <span
                className="hrv-report-fs-12 hrv-report-fw-500 hrv-report-text-center"
              >
                {STATUS_COLOR[handleStatusDiscount(specialInfo)].text}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ContainerDisount>

  );
}

export default DiscountCard;
