import TooltipWithTouch from "components/TooltipWithTouch";
import { useTrans } from "locales/hook";

function InputWithTooltip({ children, visible, isChooseDiscount, discount }) {
  const t = useTrans();

  return (
    <div>
      <TooltipWithTouch
        label={
          <>
            <span className="hrv-report-fs-12 hrv-report-fw-500">
              {discount?.displayname_vi}
            </span>
            <p className="hrv-report-mt-4 hrv-report-fs-12 hrv-report-mb-0 hrv-report-text-color-gray-10">
              {discount?.specialInfo?.is_promotion
                ? t("Chương trình khuyến mãi")
                : t("Mã khuyến mãi")}
            </p>
          </>
        }
        // isOpen={isChooseDiscount && !visible}
        placement="bottom-start"
        hasArrow
        isDisabled={!isChooseDiscount && visible}
      >
        <div>
          {children}
        </div>
      </TooltipWithTouch>

    </div>

  );
}

export default InputWithTooltip;
