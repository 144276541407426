import useApi from "hooks/useApi";

const REPORT_URI = import.meta.env.VITE_APP_API_SERVICES + "/";
const REPORT_CALL_URI_V2 = REPORT_URI + `reportscreens`;

export default function ModelReportListing() {
  const { fetchWithToken, postWithToken, deleteWithToken } = useApi();

  const ReportList = async (query) => {
    const res = await fetchWithToken(`${REPORT_CALL_URI_V2}${query}`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const Delete = async (id) => {
    const res = await deleteWithToken(`${REPORT_CALL_URI_V2}/${id}`);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  const EditReport = async (id, arg) => {
    const res = await postWithToken(`${REPORT_CALL_URI_V2}/${id}`, arg);

    try {
      const response = await res.json();

      if (response.errors) {
        return Promise.resolve({
          data: response.errors,
          isError: true,
        });
      } else {
        return Promise.resolve({
          data: response.data,
          isError: false,
        });
      }
    } catch (error) {
      return Promise.resolve({
        data: error,
        isError: true,
      });
    }
  };

  return {
    ReportList,
    EditReport,
    Delete,
  };
}
