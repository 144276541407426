/**
 * @Author Hara Ads Team
 * @Created_at 12-10-2023
 * @Param id
 * @Return
 */

import Model from "models";
import { useState } from "react";

function useFeedback(id: any) {
    const { Feedback } = Model();

    const [data, setData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);



    const fetchApiFeedback = async () => {
        setIsLoading(true);

        const { data, isError } = await Feedback(id);

        if (isError) {
            setIsLoading(false);
            return [];
        } else {
            setIsLoading(false);
            setData(data);
        }
    };

    return { data, isLoading, fetchApiFeedback };
}

export default useFeedback;
