import { useTrans } from "locales/hook";
import MoneyWithLocale from "components/Money/locale";
import StatusPeriod from "components/StatusPeriod";

import { GetComparison, formatNumber, GetCurrencyCode } from "helpers/helpers";

export default function SummaryComparePeriodBox({
  value,
  period_value,
  unit = "đ",
  suffixClass = "hrv-report-border-bottom-black-6",
  hasBehindText = true,
}) {
  const t = useTrans();
  const currencyCode = GetCurrencyCode();
  const summaryComparison = GetComparison(Number(value), Number(period_value));
  let isDisplayBehindText = true;

  if (summaryComparison.infinity) {
    isDisplayBehindText = false;
  }

  return (
    <div
      className="hrv-report-border-8 hrv-report-border-black-7 hrv-report-mb-16 hrv-report-p-8"
      style={{
        width: "fit-content",
      }}
    >
      <div className="hrv-report-d-flex">
        <div className="hrv-report-text-color-black-6 hrv-report-fw-600 hrv-report-mb-0 hrv-report-mr-20">
          {unit === t("unit_order") ? (
            <>
              {formatNumber(value, false)}{" "}
              <span className={suffixClass}>{unit}</span>
            </>
          ) : (
            <MoneyWithLocale
              value={value}
              currencyCode={currencyCode}
              className="hrv-report-fs-16 hrv-report-text-right"
            />
          )}
        </div>

        <StatusPeriod
          value={summaryComparison.value}
          trend={summaryComparison.trend}
          infinity={summaryComparison.infinity}
          reverse={summaryComparison.reverse}
          isDisplayBehindText={hasBehindText && isDisplayBehindText}
        />
      </div>
    </div>
  );
}