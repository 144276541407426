import { useRef } from "react";
import { useTrans } from "locales/hook";
import { useDisclosure } from "@chakra-ui/react";
import { useClickOutside } from "hooks/useClickOutside";
import { useGTM } from "hooks/useGTM";

import "./styles.scss";

import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  MenuGroup
} from '@chakra-ui/react';

function HSelection({
  options,
  isOptGroup,
  onChange,
  name,
  value,
  placeholder,
  defaultValue,
  className,
  selectKey,
  hasIcon = false,
  disabled,
  matchWidth = true,
  minW,
  nameUserTracking = "",
  screenUserTracking = "",
  enabledTracker = true,
  variant = "selection",
  title = "",
  ...restProps
}: any) {
  const ref = useRef<any>()
  const { isOpen, onClose } = useDisclosure();
  const { attributes, detectClass } = useGTM({
    enabled: enabledTracker,
    variables: {
      'ut-name': nameUserTracking,
      'ut-screen': screenUserTracking
    }
  });

  useClickOutside(ref, () => {
    if (isOpen) {
      onClose()
    }
  })

  const t = useTrans();

  const handleChange = (value) => {
    onChange && onChange(value);
  };

  return (
    <>
      {isOptGroup ? (
        <Menu
          matchWidth={matchWidth}
          variant="selection"
          flip={true}
          autoSelect={false}
          isLazy
        >
          <MenuButton
            className={detectClass}
            as={Button}
            isDisabled={disabled}
            w="100%"
            variant="selection"
            rightIcon={svgArrow}
            style={{
              background: disabled ? "#F3F4F6" : "",
              borderColor: disabled && "#E5E7EB"
            }}
            {...attributes}
          >
            {t(defaultValue) || t(placeholder)}
          </MenuButton>


          <MenuList className="hrv-report-scrollbar_custom" ref={ref}>
            {Object.keys(options).map((property, index) => {
              const keyDefined = `option_group_${index}`;

              return (
                <MenuGroup title={t(property)} key={keyDefined}>
                  {options[property].map((item, optionId) => {
                    const keyOptDefined = `option_child_${optionId}`;

                    return (
                      <MenuItem isDisabled={defaultValue === item[value]} key={keyOptDefined} value={item[value]} onClick={() => {
                        handleChange(item[value])
                      }}>
                        {t(item[name])}
                      </MenuItem>
                    );
                  })}
                </MenuGroup>
              );
            })}
          </MenuList>
        </Menu>
      ) : (
        <Menu
          matchWidth={matchWidth}
          variant="selection"
          flip={true}
          placement="bottom"
        >
          <MenuButton
            className={detectClass}
            as={Button}
            w="100%"
            variant={variant}
            rightIcon={svgArrow}
            isDisabled={disabled}
            style={{
              background: disabled ? "#F3F4F6" : "",
              borderColor: disabled && "#E5E7EB"
            }}
            {...attributes}
          >
            <span className={`${!!title.length ? "hrv-report-fs-16 hrv-report-mr-4 hrv-report-fw-500 hrv-report-text-color-gray-9" : ""}`}>
              {!!title.length ? title : ""}
            </span>

            {t(defaultValue) || t(placeholder)}
          </MenuButton>

          <MenuList className="hrv-report-scrollbar_custom" ref={ref} minW={minW ? minW : "unset"}>
            {options?.map((item, idx) => {
              return (
                <MenuItem key={idx} value={item[value]} isDisabled={defaultValue === item[value]} onClick={() => {
                  handleChange(item[value])
                }}
                >
                  <div className="hrv-report-d-flex hrv-report-items-center"> {hasIcon && <span className="hrv-report-mr-4">{item.icon}</span>}

                    <span>{t(item[name])}</span></div>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu >
      )
      }
    </>
  );
}

export default HSelection;

const svgArrow = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M6.175 6.91248L10 10.7291L13.825 6.91248L15 8.08748L10 13.0875L5 8.08748L6.175 6.91248Z" fill="#9CA3B0" />
</svg>